import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.sass']
})
export class ResetPasswordDialogComponent implements OnInit {
  resetPasswordForm:FormGroup
  username: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ResetPasswordDialogComponent,
    private formBuilder: FormBuilder,
    private api : ApiService,
    private route: ActivatedRoute,
    private toaster : ToastrService,
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    ) { 
      this.username = ((dialogData['username']) ? dialogData['username'] : '');
    }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      password:[''],
    })
    
  }

  

  resetPassword() {
    let request = this.resetPasswordForm.value
    this.api.adminResetPassword(this.username,request).subscribe(result => {
      this.toaster.success('password updated successully');
      this.dialogRef.close(result);
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    });
  }
}
