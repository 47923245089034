import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomerListService {
  isContact = false;
  toggle(flag:boolean) {
    this.isContact = flag
  }
  
  download(fileName: string, data) {
    if (data.length) {
      let header: string = "";
      let file: string = "";
      const keys = Object.keys(data[0]);
      keys.forEach((value) => {
        const title = value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
        header = header ? (header + "," + title) : title
      })
      file = header;
      data.forEach((row) => {
        // file = file + "\n" + Object.values(row).map((i) => { return Object.prototype.toString.call(i) === "object"  
        // ? [i]: i  }).join(",");
        file = file + "\n" + Object.values(row).map((i) => {
          return i;
        }).join(",");
      })
      console.log(file , header)
      let blob = new Blob([file], { type: 'text/csv;charset=utf-8;' });
      let url = window.URL.createObjectURL(blob);
      fileName = fileName  + ".csv"
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      window.URL.revokeObjectURL(url);
    }
  }
  
}
