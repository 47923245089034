import { DataDrivenField, DdfFieldData } from './ddf.interfaces';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { map } from 'rxjs/operators';
import { InAppLogger } from '../general-utils/InAppLogger';
import { DynamicFieldDefinitionRead } from './Configuration/Configurators.interfaces';
import { DynamicFieldValidators } from './ddf.enums';

const logger = new InAppLogger('DataDrivenForm');

export class DataDrivenForm {
  formGroup: FormGroup = new FormGroup({});
  fields: Array<DataDrivenField> = [];

  constructor(
    public sourceData: Array<DynamicFieldDefinitionRead>,
    initialData?: any,
  ) {
    const DynamicFieldData: Array<DdfFieldData> = DataDrivenForm.buildFieldData(sourceData)
    DynamicFieldData.forEach(fieldData => this.addControlFromData(fieldData, initialData));
  }

  private static getControlNameForField(parentControlName: string, childControlName: string): string {
    return `${parentControlName}_${childControlName}`;
  }


  public static buildFieldData(feilds: DynamicFieldDefinitionRead[]): DdfFieldData[] {

    let fieldData: DdfFieldData[] = []
    feilds.forEach((field) => {
      let obj = <DdfFieldData>{
        identifier: field.id,
        label: field.label,
        type: field.field_type,
        validators: field.is_required ? [DynamicFieldValidators.REQUIRED] : [],
        is_unique: field.is_unique,
        choices: field.choices && field.choices.length ? field.choices : undefined,
        multiple_choices: field.multiple_choices && field.multiple_choices.length ? field.multiple_choices : undefined,
        field_length: field.field_length,
        is_editable:field.is_editable
      }

      fieldData.push(obj)
    })
    return fieldData
  }

  get value(): any {
    const value: any = {};
    this.fields.map(field => {
      if(field.control.value)
      value[field.fieldData.identifier] = field.control.value;
    });
    return value;
  }

  private addControlFromData(fieldData: DdfFieldData, initialContent: any, controlName?: string): void {
    const control = new FormControl('', this.getValidators(fieldData));
    controlName = controlName || fieldData.label;
    if (fieldData.choices) {
      // add control value events:
      control.valueChanges.pipe(
        map(() => this.onValueChange(control, controlName, fieldData, initialContent))
      ).subscribe(() => {
        logger.log(`Field ${fieldData.identifier}(${fieldData.label}) has changed value: ${control.value}`);
      });
    }
    this.formGroup.addControl(controlName, control);
    this.fields.push({ control, fieldData, controlName, parent: this.formGroup });
    const value = initialContent[fieldData.label] || '';

    if (value) {
      control.setValue(value);
    }
  }

  private onValueChange(control: FormControl, controlName: string, fieldData: DdfFieldData, initialData: any) {
    const newValue = String(control.value);
    // remove all previous nested fields if any
    this.removeNestedControls(controlName);


  }

  private removeNestedControls(parentControlName: string) {
    const controlNamePrefix = DataDrivenForm.getControlNameForField(parentControlName, '');
    const controlNamesToRemove =
      Object.keys(this.formGroup.controls)
        .filter(cName => cName.startsWith(controlNamePrefix));

    // remove all fields in the form group starting with the prefix
    controlNamesToRemove.forEach(cName => this.formGroup.removeControl(cName));
    this.fields = this.fields.filter(field => !controlNamesToRemove.includes(field.controlName));
  }

  private getValidators(fieldData: DdfFieldData): Array<ValidatorFn> {
    // FIXME validators not implemented
    return [];
  }
}
