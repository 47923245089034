import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AddCustomerContactDialogComponent } from 'src/app/circle-one/app/components/add-customer-contact-dialog/add-customer-contact-dialog.component';
import { Phone } from 'src/app/circle-one/telephony/model/phone.model';

@Component({
  selector: 'app-incoming-popup',
  templateUrl: './incoming-popup.component.html',
  styleUrls: ['./incoming-popup.component.sass']
})
export class IncomingPopupComponent implements OnInit {
  contactData: any;
  isCustomer: any;
  newContactBtn: boolean = false
  loggerName = '[/app/customers]';
  mobileNo: any;
  contactLength: number;

  constructor(
    private dialogRef: MatDialogRef<IncomingPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IncomingPopupComponent,
    public phoneModel: Phone,
    public router: Router,
    public addCustomerContactDialog: MatDialog,

  ) {
    console.log(this.dialogData)
    this.contactData = ((dialogData['contactData']) ? dialogData['contactData'] : []);
    this.mobileNo = ((this.contactData['phone_number']) ? this.contactData['phone_number'] : 0);
    this.isCustomer = ((dialogData['isCustomer']) ? dialogData['isCustomer'] : []);
    this.contactLength = this.contactData['contactLength'];
    console.log(this.contactLength)
    this.phoneModel.ibCallPopup = this.dialogRef
    this.phoneModel.ibMbNo = this.contactData.phone_number
    this.phoneModel.mobileNo = this.mobileNo
    console.log(this.mobileNo)
    console.log(this.contactData)
    console.log(this.isCustomer)
  }

  ngOnInit() {
    // this.phoneModel.extState && this.phoneModel.extState.unsubscribe();

    if (this.isCustomer == 'new') {
      this.newContactBtn = true
    }
  }
  close() {
    let currentUrl = localStorage.getItem('urlRoute');
    let allData = { 'path': currentUrl };
    // this.router.navigate([currentUrl]);
    this.dialogRef.close();
  }

  openAddCustomerContactDialog() {
    
    this.dialogRef.close();

    const addContactDialog = this.addCustomerContactDialog.open(AddCustomerContactDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: { bulkUpload: true, 'mobileNo': this.mobileNo }
    });

    addContactDialog.afterClosed().subscribe(result => {
      console.log(`${this.loggerName} Contact dialog closed`);
    });
  }

  routeSelection(event) {
    console.log(this.contactData.id)
    this.dialogRef.close();

    if (event == 'email') {
      let urlRoute = '/app/email/emails';
      let allData = { 'path': urlRoute, 'id': this.contactData.id };
      this.router.navigate([urlRoute]);
    } else if (event == 'ticket') {
      let urlRoute = '/app/tickets/ticket-list';
      let allData = { 'path': urlRoute, 'id': this.contactData.id };
      this.router.navigate([urlRoute]);
    } else if (event == 'contact') {
      if (this.isCustomer == 'contact') {
        if(this.contactLength > 0){
          console.log('all contact')
          this.router.navigate(['/app/customers'])
        }else{
          this.router.navigate(['/app/customers/contact', this.contactData.contactId, 'edit'])
        }
      } else {
        this.router.navigate(['/app/customers'])
      }
    }
    // else if (event == 'Other'){
    //   window.open(this.contactData.ext_url);
    // }
  }
  

}
