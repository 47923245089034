import { Subscription } from "rxjs";

export class Phone {
  url: Window = null
  extension: any;
  password: any;
  isphoneLogin: string;
  callStatus: string = '';
  mobileNo: any;
  callTimerEvent: Subscription;
  pauseCode: any = 'LUNCH';
  telLoginEvent: any;
  onConference: string;
  ibCallStatus: string;
  ibMbNo: any;
  agentStatus: string;
  inboundCall: Subscription;
  loginCampaign: any;
  campType: any;
  leadId: any;
  phoneEvent: any;
  callTransfer: string = '';
  callData: any = [];
  isCustomer: string;
  disposCode: any;
  dialPadCall: any;
  pauseResumeFlag: boolean;
  callId: any;
  campaignId: any;
  leadMbNo: any;
  priSelected: number;
  contactData: any;
  extState: Subscription;
  breakId: any;
  phoneSessionId: any;
  interactionId: string;
  uniqueId: any;
  startIbEvent: any;
  selectedPauseCode: string;
  ibCallPopup: any;
  thresholdTimer: any;
  hangupFlag: number;
  phoneDomainUrl: string;
  extStatus: any;
  loginToken: Subscription;
  phone_loin_type: any;
  lastUrl: any;
  allBackUrls: any = [];
  campListPNum: number;
  campFilterType: string;
  campSearchVal: string;
  campDate: any;
  campMatTabList: any[];
  campSelectedTab: number;
  campMailType: any;
  campLoader: boolean;
  type_status: boolean;
  campStatusType: any;
  campStatusStatus: any;
  campCloseStatus: any;
}
