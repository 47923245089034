import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { Subscription } from 'rxjs';
import { EventNotificationComponent } from 'src/app/circle-one/app/components/event-notification/event-notification.component';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { Inject } from '@angular/core';
import { NotificationTypes, WebSocketService } from 'src/app/code-modules/websocket/web-socket.service';
import { Router } from '@angular/router';
import { NotificationRead } from 'src/app/code-modules/api/interfaces/Business-Rule/business-rule-api-interfaces';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';

export interface EventData {



}

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.sass']
})
export class NotificationPopupComponent implements OnInit {
  displayedColumns: string[] = ['sr.no', 'title', 'event_date', 'start_time', 'created'];

  dataSource: MatTableDataSource<EventData>;
  customerTags: any = [];
  allContact: any = [];
  subscribers: Array<Subscription> = []
  totalEvents: number;
  contactNext = false;
  contactPrevious = false;
  userRole: string;
  teamData: any;
  teamId: any = '';
  userId: any;
  notificationData: any = [];
  eventData: any = [];
  notifications: NotificationRead[] = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: EventNotificationComponent,
    public dialogRef: MatDialogRef<NotificationPopupComponent>,
    public confirmDialog: MatDialog,
    private api: ApiService,
    public eventNotificaionMatDialog: MatDialog,
    public toaster: ToastrService,
    public datepipe: DatePipe,
    private context: AppContextService,
    private socketConnection: WebSocketService,
    private router: Router,

  ) {


    this.context.currentUser$.subscribe((currentuser) => {
      this.userRole = ((currentuser['role']) ? currentuser['role'] : null);
      this.userId = ((currentuser['id']) ? currentuser['id'] : 0);
    })

    this.notificationData = this.context.notifications;

    console.log(this.notificationData);
  }

  ngOnInit() {
    this.listEvent()
  }

  listEvent() {
    // if (this.userRole != 'admin') {
    //   this.teamData = JSON.parse(localStorage.getItem('currentTeam'));
    //   this.teamId = ((this.teamData[0]['id']) ? this.teamData[0]['id'] : null);
    //   if (this.teamId == null) {
    //     this.teamId = ''
    //   }
    // }else{
    //   this.userId=''
    // }
    // this.eventData = []
    // this.notificationData.forEach(element => {
    //   var splitReportData = {};
    //   if (element.notification_type == 'ticket-event') {
    //     splitReportData = {
    //       'id': element['id'], 'title': element.data['title'], 'event_date': element.data['event_date'], 'start_time': element.data['start_time'],
    //       'guests': element.data['guests'], 'description': element.data['description'], 'event_id': element.data['id']
    //     };
    //     this.eventData.push(splitReportData)
    //   }
    // });

    // this.dataSource = new MatTableDataSource<EventData>(this.eventData);


    // let date = new Date();
    // let latest_date = this.datepipe.transform(date, 'yyyy-MM-dd');
    // const EventList = this.api.listEventData(latest_date,this.teamId,'',this.userId).subscribe((result) => {
    //   this.totalEvents = result.count;
    //   this.contactNext = result.next && result.next !== null ? true : false
    //   this.contactPrevious = result.previous && result.previous !== null ? true : false
    //   this.dataSource = new MatTableDataSource<EventData>(result.results);
    //   this.allContact = result;
    // })
    // this.subscribers.push(EventList)
    this.allContact = []
    this.dataSource = new MatTableDataSource([]);
    this.notificationData.forEach(element => {
      console.log(element)
      var splitReportData = {};
      let eventId = ((element.data['id']) ? element.data['id'] : 0);
      this.api.singleEventDetails(eventId).subscribe(result => {
        splitReportData = {
          'id': result.id, 'title': result.title, 'event_date': result.event_date, 'start_time': result.start_time
        }
        this.allContact.push(splitReportData)
        this.dataSource = new MatTableDataSource<EventData>(this.allContact);

      }, );
    });
  }
  openConfirmDialog() {
    const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: "Do you really want to delete these record? This process cannot be undone."
    });
    return dialogRef;
  }

  editEvent(id) {
    const dialogRef = this.eventNotificaionMatDialog.open(EventNotificationComponent, {
      panelClass: 'co-dialog-panel',
      data: { bulkUpload: true, event_id: id },
      // position: { left: '230px', top: '50px' }
      height: '513px',
      width: '532px'

    });
    dialogRef.afterClosed().subscribe(result => {
      this.listEvent()
    });
  }



  deleteEvent(id) {
    let notification = this.notificationData.filter(function (element) {
      return element.data.id == id;
    });
    this.socketConnection.clear(notification[0])
    event.stopPropagation();
    const dialogRef = this.openConfirmDialog()
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const deleteAccount = this.api.deleteEvent(id).subscribe((success) => {
          this.toaster.success("Task deleted successfully")
          this.dialogRef.close()
          this.router.navigate(['/phone-load']);
          this.listEvent()
        }, (err) => {
          this.toaster.error("Something went wrong")
        })
        this.subscribers.push(deleteAccount)
      }
    })
  }

  ViewEvent(id, event_id) {
   
    let notification = this.notificationData.filter(function (element) {
      return element.data.id == id;
    });
    console.log(notification)
    this.socketConnection.clear(notification[0]);
    let notification_id = ((notification[0]['id']) ? notification[0]['id'] : 0);

    this.notificationData = this.notificationData.filter((item) => item.id !== notification_id)
    this.context.notifications = this.notificationData
    console.log(this.context.notifications)

    this.router.navigate(['/app/tickets/']);
    this.dialogRef.close()
    this.router.navigate(['/phone-load']);
    const dialogRef = this.eventNotificaionMatDialog.open(EventNotificationComponent, {
      panelClass: 'co-dialog-panel',
      data: { bulkUpload: true, event_id: event_id, 'type': 'view', 'notification': notification[0] },
      height: '513px',
      width: '532px'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
