import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateString'
})
export class DateStringPipe implements PipeTransform {

  transform(value: string, condition?: string): string {
    if(value) {
      if(condition === 'time') {
        return value.match(/\d{1,2}:\d{1,2}:\d{1,2}/)[0]
      }
      return value.match(/(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(y)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?)\s+\d{1,2}/)[0];
    }
    return ''
  }

}
