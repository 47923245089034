import { Directive, ElementRef, TemplateRef, ViewContainerRef, OnInit, Input, HostListener } from '@angular/core';
import { AppContextService } from '../../app-context/app-context.service';
import { UserRoles } from '../../saas/saas.enums';

const enum Action {
  HIDDEN = "hidden",
  DISABLED = "disabled"
}

@Directive({
  selector: '[adminOnly]'
})
export class AdminOnlyDirective implements OnInit {
  
  accessPermission: boolean = false;
  actionType: string;


  // @Input()
  // set adminOnly(value: string) {
  //  // this.actionType = value
  //   this.updateView();

  // }
 
  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    public contex: AppContextService
  ) {

  }

  ngOnInit() {
    this.contex.currentUser$.subscribe((user) => {
      if (user.role && user.role === UserRoles.ADMIN || user.role && user.role === UserRoles.MANAGER) {
        this.accessPermission = true
        this.updateView();
      }
    })
  }
  updateView() {
    if (this.accessPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    }
    else {
      this.viewContainer.clear();
    }
  }
}
