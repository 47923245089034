import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DynamicComponentComponent } from 'src/app/circle-one/business-rules/components/ticket-sla/dynamic-component/dynamic-component.component';


@Component({
  selector: 'app-generate-level-dialog',
  templateUrl: './generate-level-dialog.component.html',
  styleUrls: ['./generate-level-dialog.component.sass']
})
export class GenerateLevelDialogComponent implements OnInit {
  levelName:string;
  // time :number= 0;
  // minutes:number = 0;
  i = 2
  // email = ""
  levelData = {
    time : 0,
    minutes :0,
    email : ""
  }
  constructor(
    public dialogRef: MatDialogRef<DynamicComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData,
  ) { }

  ngOnInit() {
    console.log(this.inputData ,"inpu")
    if(this.inputData.length === 0){
      this.levelName = `Escalation Level 2`
    }else
    if(Object.keys(this.inputData[0].level).length === 0){
      this.levelName = `Escalation Level ${this.i}`
      console.log("level1",this.levelName)
    }
    else{
      for (const key in this.inputData[0].level) {
        if (this.inputData[0].level.hasOwnProperty(key)) {
          this.levelName = `Escalation Level ${parseInt(key.split(' ')[2]) + 1}`
          console.log("level1234",this.levelName)
        }
      }
    }
  }

  onSubmit(form,formData){
    console.log('submitted formdata',formData); 
    let request = {
      level : this.levelName,
      email : formData.email,
      time : formData.time,
      minutes : formData.minutes
    }
   
    this.dialogRef.close(request)
  }


}
