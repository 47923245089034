import { Pipe, PipeTransform } from '@angular/core';
import {UserProfileRead} from '../../api/interfaces/User/user-api-interfaces';

@Pipe({ name: 'fullName' })
export class FullNamePipe implements PipeTransform {
  transform(value: UserProfileRead): string {
    let fullname = ""
    if (value) {
      if (value.first_name) {
        fullname = value.first_name
        if (value.first_name && value.last_name)
          fullname = fullname + " " + value.last_name
      } else {
        fullname = value.username.split('@')[0];
      }

    }
    return fullname;
  }
}
