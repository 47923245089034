import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { TicketDetailsComponent } from 'src/app/circle-one/tickets/components/ticket-details/ticket-details.component';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { DispositionDialogDataObject } from 'src/app/code-modules/api/interfaces/Business-Rule/business-rule-api-interfaces';
import { InternalNoteCreate, TicketRead } from 'src/app/code-modules/api/interfaces/Ticket/ticket-api-interfaces';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { NotesTypes } from 'src/app/code-modules/saas/saas.enums';
import { DynamicFieldDefinitionRead } from 'src/app/code-modules/utils/data-driven-forms/Configuration/Configurators.interfaces';
import { DataDrivenForm } from 'src/app/code-modules/utils/data-driven-forms/DataDrivenForm';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';
import { DispositionDialogComponent } from '../disposition-dialog/disposition-dialog.component';

@Component({
  selector: 'app-workflow-dialog',
  templateUrl: './workflow-dialog.component.html',
  styleUrls: ['./workflow-dialog.component.sass']
})
export class WorkflowDialogComponent implements OnInit {
  allStatus = []
  selectedStatus;
  dynamicField = {}
  dynamicForm = new DataDrivenForm([]);
  ticketDetailsArray = []
  ticketNameId: number
  ticket: TicketRead;
  internalNote: InternalNoteCreate = {
    content: '',
    attachments: [],
    ticket: undefined
  }
  NotesTypes = NotesTypes
  attachment: File
  attachmentString: string
  constructor(
    public dialogRef: MatDialogRef<WorkflowDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private api: ApiService,
    public toaster: ToastrService,
    public context: AppContextService,
  ) { }

  ngOnInit() {
    if (this.data.workflow) {
      this.ticketNameId = this.data.workflow.id
    }
    this.loadTicketDetails()
    console.log(this.data, "In workflow")
    this.data.workflow.all_statuses.filter((data) => {
      console.log(Object.prototype.toString.call(data), data.status)
      // if(data.status_type !== 'closed'){
      if (data.status_type !== 'reopen') {

        Object.prototype.toString.call(data.status) === "[object Object]" ?
          this.allStatus.push(data.status.status) : this.allStatus.push(data.status)
        console.log(data.status.status, "status");
      }
      // }

    })
    // if(this.allStatus.includes('Resolved') || this.allStatus.includes('Closed')){
    //   const indexResolved = this.allStatus.indexOf('Resolved');
    //   if (indexResolved > -1) {
    //     this.allStatus.splice(indexResolved, 1);
    //   }
    //   const index = this.allStatus.indexOf('Closed');
    //   if (index > -1) {
    //     this.allStatus.splice(index, 1);
    //   }
    // }
    console.log(this.allStatus)
  }
  loadTicketDetails() {
    this.api.getTciketDetails(this.data.ticket.id)
      .subscribe((ticket) => {
        this.ticket = ticket
        this.internalNote.ticket = ticket.id
        console.log(this.ticket, "ticket")
        if (this.ticketNameId) {
          this.loadTickentDynamicFields()
        }
      })

  }
  onFileInput(event, noteTypes: NotesTypes) {
    this.attachment = event.target.files[0];
    if (this.attachment) {
      const arr = this.attachment.name.split('.')
      const file_type = arr.length > 1 ? arr[arr.length - 1] : undefined
      if (file_type && file_type !== 'exe') {
        const reader = new FileReader(); // HTML5 FileReader API
        reader.onload = () => {
          this.attachmentString = reader.result as string;
          var uploadFile = {
            file_name: this.attachment.name,
            file: this.attachmentString
          }
          // this.uploadProgress.completed = true;
          if (noteTypes === NotesTypes.INTERNAL)
            this.internalNote.attachments.push(uploadFile);
        };
        reader.readAsDataURL(this.attachment);
      } else {
        this.toaster.error("Unsupported file format")
      }
    }
  }
  removeAttachment(index, noteTypes: NotesTypes) {
    if (noteTypes === NotesTypes.INTERNAL)
      this.internalNote.attachments.splice(index, 1);
    else
      this.internalNote.attachments.splice(index, 1);
  }
  addInternalNote() {
    if (this.internalNote.content) {
      this.api.createInternalNote(this.internalNote).subscribe((success) => {

        this.toaster.success("Saved")
        this.loadTicketDetails();
        this.internalNote.content = "";
        this.internalNote.attachments = []
      }, (err) => {
        this.toaster.error(ErrorToaster(err))
        // this.toaster.error("Something went wrong")
      })
    }
  }
  submit() {
    if (this.internalNote.content) {
      this.api.createInternalNote(this.internalNote).subscribe((success) => {

        // this.toaster.success("Saved")
        // this.loadTicketDetails();
        this.internalNote.content = "";
        this.internalNote.attachments = []
      }, (err) => {
        this.toaster.error(ErrorToaster(err))
        // this.toaster.error("Something went wrong")
      })
    }
    let reqBody = {
      dynamic_fields: this.dynamicForm.formGroup.value,
      status: "",
      closed_status: ""
    }
    if (this.selectedStatus == undefined) {
      this.toaster.warning('Please select ticket status.'); return;
    } else {
      this.data.workflow.all_statuses.filter((data) => {
        console.log(Object.prototype.toString.call(data), data)

        if (Object.prototype.toString.call(data.status) === "[object Object]") {
          if (data.status_type == 'wip') {
            if (data.status.status == this.selectedStatus) {
              reqBody.status = data
              console.log(this.dynamicForm.formGroup, "kkkkkkkkk")

              console.log(reqBody, "LLLLLLLLLLLL")
              console.log(data.status.status, "data.status.status");
              this.dialogRef.close(reqBody)

            }

          }

        }
        if (Object.prototype.toString.call(data) === "[object Object]") {
          if (data.status_type == 'closed') {
            if (data.status.status == this.selectedStatus) {
              reqBody.closed_status = data
              console.log(reqBody, "LLLLLLLLLLLL")
              console.log(data.status.status, "data.status.status");
              this.dialogRef.close(reqBody)

            }
          }
          console.log(data, this.selectedStatus, "Match")
          if (data.status == this.selectedStatus) {
            reqBody.status = data
            console.log(reqBody, "LLLLLLLLLLLL")
            console.log(data.status, "data.status");
            this.dialogRef.close(reqBody)
          }
        }
      })
    }


  }
  loadTickentDynamicFields() {
    this.context.ticketNameDynamicFields$.subscribe(fieldData => {
      console.log(fieldData)
      if (fieldData.length) {
        let fieldDataArray: DynamicFieldDefinitionRead[] = []
        // fieldDataArray = []
        fieldData.filter((data) => {
          console.log(data.ticket_name, this.ticketNameId)
          if (data.ticket_name === this.ticketNameId) {
            fieldDataArray.push(data)
          }
        })

        console.log(fieldDataArray, fieldData, this.ticket.dynamic_fields, "123456")
        this.dynamicForm = new DataDrivenForm(fieldDataArray, this.ticket.dynamic_fields || {});
        console.log(this.dynamicForm)
        this.ticketDetailsArray = []
        // if(this.selectedDisposition){
        this.dynamicForm.sourceData.filter((data) => {
          this.dynamicForm.fields.filter((row) => {
            if (data.ticket_name === this.ticketNameId) {
              if (data.id === row.fieldData.identifier) {
                this.ticketDetailsArray.push(row)
              }
            }
          })
        })

        // }
      } else {
        this.dynamicForm = new DataDrivenForm([]);
      }
    });
    console.log("ticketDetailsAraay", this.ticketDetailsArray)
  }

}
