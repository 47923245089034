import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { UtilsModule } from 'src/app/code-modules/utils/utils.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { DialpadComponent } from './components/dialpad/dialpad.component';
import { TelephonyLoginComponent } from './components/telephony-login/telephony-login.component';
import { Phone } from '../telephony/model/phone.model';
import { NotificationPopupComponent } from './components/notification-popup/notification-popup.component';
import { IncomingPopupComponent } from './components/incoming-popup/incoming-popup.component';
import { SwitchTeamDialogComponent } from './components/side-nav/switch-team-dialog/switch-team-dialog.component';
import { TelephonyDispositionDialogComponent } from './components/telephony-disposition-dialog/telephony-disposition-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RemoveSpecialCharPipe } from './components/remove-special-char.pipe';
import { AddCustomerContactDialogComponent } from '../app/components/add-customer-contact-dialog/add-customer-contact-dialog.component';
import { AddCustomerAccountDialogComponent } from '../app/components/add-customer-account-dialog/add-customer-account-dialog.component';

@NgModule({
  declarations: [SideNavComponent, DialpadComponent, TelephonyLoginComponent, NotificationPopupComponent, IncomingPopupComponent, SwitchTeamDialogComponent, TelephonyDispositionDialogComponent, RemoveSpecialCharPipe],
  imports: [
    CommonModule,
    UtilsModule,
    SharedModule,
    RouterModule,
    PdfViewerModule
  ],
  exports:[
    SideNavComponent,
    TelephonyLoginComponent
   
  ],
  providers:[
    Phone,
  ],
  entryComponents:[
    DialpadComponent,
    TelephonyLoginComponent,
    NotificationPopupComponent,
    IncomingPopupComponent,
    SwitchTeamDialogComponent,
    TelephonyDispositionDialogComponent,
    AddCustomerContactDialogComponent,
    AddCustomerAccountDialogComponent
  ]
})
export class NavbarModule { }
