import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'specialCharPipe'
})
export class SpecialCharPipe implements PipeTransform {

  transform(value: string): string {
    var regExpr = /[^a-zA-Z0-9-. ]/g;

    let updateVal = value.replace(regExpr, " ")


    return this.titleCase(updateVal);
   
  }
  titleCase(str) {
   var splitStr = str.toLowerCase().split(' ');
   for (var i = 0; i < splitStr.length; i++) {    
       splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
   }
   return splitStr.join(' '); 
}

}