import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';


export const passwordMatchValidator: ValidatorFn = (
  control: FormGroup
): ValidationErrors | null => {
  if (control.get('password').value === control.get('confirmPassword').value) {
    return null;
  } else {
    return { passwordMatch: true };
  }
};
