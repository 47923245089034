import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective  implements OnInit{
  focus: boolean = false;
  @Input()
  set appFocus(value: boolean) {
    this.focus = value
    // if(value)
    //this.setFocus();
  }

  constructor(private element: ElementRef) { }

  ngOnInit() {
    if(this.focus){
      setTimeout(() =>  this.setFocus())
    }
  }

  setFocus() {
    //console.log("focus", this.element)
    this.element.nativeElement.focus();
  }

}
