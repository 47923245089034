import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../../code-modules/api/api-services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { DataDrivenForm } from 'src/app/code-modules/utils/data-driven-forms/DataDrivenForm';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { InAppLogger } from 'src/app/code-modules/utils/general-utils/InAppLogger';
import { ErrorToaster, ValidationMessages } from 'src/app/code-modules/utils/validations/genral-validation';
import { CustomerListService } from '../customers.service';
import { ContactCreateUpdate, ContactRead, Tag } from '../../../../../code-modules/api/interfaces/Customer/customer-api-interfaces';
import { MatDialog } from '@angular/material';
import { ContactHistoryDialogComponent } from '../../contact-history-dialog/contact-history-dialog.component';
import { UserTeamList } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';


@Component({
  selector: 'app-contact-details-edit',
  templateUrl: './contact-details-edit.component.html',
  styleUrls: ['./contact-details-edit.component.sass']
})
export class ContactDetailsEditComponent implements OnInit {
  customerTags = []
  selectedTags = [];
  selectedTeam = [];
  selectedTeams = [];
  customerTeamsEdit = this.selectedTeam;
  customerTagsEdit = this.selectedTags;
  customerAccount = undefined;
  from_account: number = undefined;
  userRole
  logger = new InAppLogger(ContactDetailsEditComponent.name)
  contactId: number;
  contactForm: FormGroup;
  contactList: Array<ContactRead>;
  contact: ContactRead;
  edit = true;
  dynamicForm = new DataDrivenForm([]);
  customerId: number[] = [];
  teams
  asyncResult: any;
  isEmailReadonly = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private formBuilder: FormBuilder,
    public contactHistoryMatDialog: MatDialog,
    //////private loader: LoaderService,
    private toaster: ToastrService,
    public context: AppContextService,
    public toggleService: CustomerListService
  ) { }

  ngOnInit() {
    const currentTenant = window.location.hostname.split('.')[0];
    if (currentTenant === "TGL1" || currentTenant === "tgl1") {
      this.isEmailReadonly = false;
    }

    this.context.currentUser$.subscribe((user) => {
      this.userRole = user
      console.log(this.userRole, "users")
    });

    this.route.params.subscribe(params => {
      this.contactId = Number(params.id);
      if (params.action === "edit") {
        this.edit = false;
      }
      if (params.account) {
        this.from_account = params.account
      }
      if (this.contactId) {
        this.loadContactDetails();
      }
    });
  }

  loadContactDetails() {
    // this.loader.start();
    this.api.contactDetails(this.contactId).subscribe(result => {
      console.log(result, "conatct result");
      // this.contactList = result;
      this.contact = result
      this.customerAccount = this.contact.account;
      this.contactForm = this.formBuilder.group({
        name: [this.contact.name, [Validators.required]],
        email: [this.contact.email, [Validators.required]],
        phone_number: [this.contact['phone_number']],
        facebook_id: [this.contact['facebook_id']],
        whatsapp_number: [this.contact['whatsapp_number']],
        twitter_id: [this.contact['twitter_id']]
      })
      this.selectedTags = this.contact.tags ? this.contact.tags : [];
      this.selectedTeam = this.contact.accessible_teams ? this.contact.accessible_teams : [];
      this.customerTagsEdit = this.selectedTags
      this.customerTeamsEdit = this.selectedTeam

      this.context.customerContactDynamicFields$.subscribe(fieldData => {
        this.logger.log('Loaded fields:', fieldData);
        if (fieldData.length) {
          this.dynamicForm = new DataDrivenForm(fieldData, this.contact.dynamic_fields || {});
        } else {
          this.dynamicForm = new DataDrivenForm([]);
        }
      });

      this.loadCustomerTags();
      this.loadUserTeamList();
      console.log('contact is', this.contact);
      // this.loader.stop();
    }, (err) => {
      // this.loader.stop();
      this.toaster.error("Failed to load contact details")
      this.toaster.error(ErrorToaster(err))
    });
  }

  loadCustomerTags() {
    this.api.CustomerTagList().subscribe((tags) => {
      this.customerTags = tags
      this.customerTagsEdit = this.edit ? this.selectedTags : this.customerTags;
    }, (err) => { console.log(err) })
  }

  // serchTeam =[];
  // loadUserTeamList() {
  //   this.api.ConatctTeamList()
  //     .subscribe((team) => {
  //       console.log(team, "Team")
  //       // this.team = team
  //       this.serchTeam=team
  //       // this.customerTeamsEdit = this.edit ? this.selectedTeam : this.serchTeam;
  //     })
  // }

  loadUserTeamList() {
    if (this.userRole.role == 'admin') {
      this.api.userTeamList()
        .subscribe((team: UserTeamList) => {
          console.log(team, "Team")
          this.teams = team
        })
    } else {
      this.api.userDetails(this.userRole.id)
        .subscribe((team) => {
          console.log(team, "Team")
          this.teams = team.teams
          console.log(this.teams, "login teams")
        })
    }
  }

  onEdit() {
    this.edit = !this.edit;
    this.customerTagsEdit = this.edit ? this.selectedTags : this.customerTags;
    // this.customerTeamsEdit=this.edit?this.selectedTeam:this.contact.account.accessible_teams;
    this.contactMapp();
  }

  onSave() {
    // this.loader.start();
    if (this.contactForm.valid && this.dynamicForm.formGroup.valid) {
      let request = <ContactCreateUpdate>this.contactForm.value;
      request.dynamic_fields = this.dynamicForm.formGroup.value
      request.tags = this.getTagIds()
      request.accessible_teams = this.getTeamIds();

      this.api.contactUpdate(request, this.contactId).subscribe(resp => {
        console.log('Contact Updated Successfully');
        this.loadContactDetails();
        // this.loadUserTeamList();
        // this.loader.stop();
        this.toaster.success('Contact Updated Successfully');
        this.onEdit();
      }, error => {
        // this.loader.stop();
        console.log('Contact Updation Failed');
        this.toaster.error('Please try again', 'Contact Update Failed');
      }
      );
    } else {
      // this.loader.stop();
      this.toaster.error('Please fill Required Fields');
      this.contactForm.markAllAsTouched();
      this.dynamicForm.formGroup.markAllAsTouched();
    }
  }

  getErrorMessage() {
    return ValidationMessages(this.contactForm.controls);
  }

  toggleTagSelection(tag: Tag) {
    if (!this.edit) {
      const index = this.selectedTags.map(obj => { return obj.id }).indexOf(tag.id);
      index > -1 ? this.selectedTags.splice(index, 1) : this.selectedTags.push(tag)
    }
  }

  isSelectedTag(tag: Tag) {
    return this.selectedTags.map((obj => { return obj.id })).includes(tag.id)
  }

  viewAccount(accountId: number) {
    console.log(accountId)
    this.router.navigate(['/app/customers/account', accountId])
  }

  backToCustomerList() {
    if (this.from_account)
      this.router.navigate(['/app/customers/account', this.from_account])
    else
      this.toggleService.toggle(true);
  }

  getTagIds() {
    let tags = [];
    this.selectedTags.forEach((tag) => {
      tags.push(tag.id)
    })
    return tags
  }

  // contact history dialog
  openContactHistoryDialog() {
    console.log(this.contactId);
    this.api.customerActionList(this.contactId)
      .subscribe((data) => {
        // console.log(data.results)
        const dialogRef = this.contactHistoryMatDialog.open(ContactHistoryDialogComponent, {
          panelClass: 'co-dialog-panel',
          data: { action: data }
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
      })
  }
  
  toggleTeamSelection(team: Tag) {
    if (!this.edit) {
      const index = this.selectedTeam.map(obj => { return obj.id }).indexOf(team.id);
      index > -1 ? this.selectedTeam.splice(index, 1) : this.selectedTeam.push(team)
    }
  }
  isSelectedTeam(team: Tag) {
    return this.selectedTeam.map((obj => { return obj.id })).includes(team.id)
  }
  getTeamIds() {
    let teams = [];
    this.selectedTeam.forEach((team) => {
      teams.push(team.id)
    })
    return teams
  }

  contactMapp() {
    if (this.contact.account == null) {
      this.customerTeamsEdit = this.teams;

    }
    else {
      if (this.contact.account.accessible_teams.length > 0) {
        this.customerTeamsEdit = this.contact.account.accessible_teams;
      }
      else {
        this.customerTeamsEdit = this.teams;
      }
    }
  }
  //   add =[]
  //   checked=false
  //  change(event){
  //    this.add=[]
  //    if(event.checked){
  //      this.checked=true

  //      this.customerTeamsEdit.forEach((team) => {
  //        this.add.push(team.id)
  //          })
  //    }else{
  //      this.add=[]
  //      this.checked=false
  //    }



  //  }
}
