import {Pipe, PipeTransform} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {AppContextService} from '../../app-context/app-context.service';
import {AuthService} from '../../authentication/auth.service';
import {first, map} from 'rxjs/operators';
import {ModuleIds, PlatformPermissionIds} from '../../saas/saas.enums';
import {UserProfileRead} from '../../api/interfaces/User/user-api-interfaces';

export const moduleViewPermissions = {
  [ModuleIds.USERS]: PlatformPermissionIds.USERS_ENABLE,
  // [ModuleIds.SUBSCRIPTION]: PlatformPermissionIds.SUBSCRIPTION_ENABLE,
  //[ModuleIds.MANAGE]: PlatformPermissionIds.MANAGE_ENABLE,
  [ModuleIds.BUSINESS_RULES]: PlatformPermissionIds.BUSINESS_RULES_ENABLE,
  [ModuleIds.EMAIL]: PlatformPermissionIds.EMAIL_ENABLE,
  [ModuleIds.TICKET]: PlatformPermissionIds.TICKET_ENABLE,
  // [ModuleIds.KNOWLEDGE_MANAGEMENT]: PlatformPermissionIds.KNOWLEDGE_MANAGEMENT_ENABLE,
  [ModuleIds.DASHBOARD]: PlatformPermissionIds.DASHBOARD_ENABLE,
  [ModuleIds.CUSTOMERS]: PlatformPermissionIds.CUSTOMERS_ENABLE,
  // [ModuleIds.THEME]: PlatformPermissionIds.THEME_ENABLE,
  [ModuleIds.REPORTS] : PlatformPermissionIds.REPORTS_ENABLE,
  // [ModuleIds.FEEDBACK] : PlatformPermissionIds.FEEDBACK_ENABLE,
  [ModuleIds.CAMPAIGNS]:PlatformPermissionIds.CAMPAIGNS_ENABLE
};

// export const myModules =['user','dashboard','email','customer','ticket']

@Pipe({
  name: 'hasModuleViewAccess'
})
export class HasModuleViewAccessPipe implements PipeTransform {
  constructor(
    private context: AppContextService,
    private auth: AuthService,
  ) {
  }

  transform(moduleId: ModuleIds)
  : Observable<boolean> {
    // alert(localStorage.getItem('currentTeam'))
    return combineLatest(this.context.currentUser$, this.auth.loginStatus$).pipe(
      map(([currentUser, loginStatus]) => {
       
        return loginStatus && currentUser 
        && Boolean(
          JSON.parse(localStorage.getItem("currentTeam"))[0].manager_module_permissions?
          JSON.parse(localStorage.getItem("currentTeam"))[0].manager_module_permissions
            .find(permission => moduleViewPermissions[moduleId] === permission):
            JSON.parse(localStorage.getItem("currentTeam"))[0].agent_module_permissions?
            JSON.parse(localStorage.getItem("currentTeam"))[0].agent_module_permissions
            .find(permission => moduleViewPermissions[moduleId] === permission):
            JSON.parse(localStorage.getItem("currentTeam"))[0].module_permissions
            .find(permission => moduleViewPermissions[moduleId] === permission)
        );
      })
    );
  }

}
