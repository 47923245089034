import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders,
} from '@angular/common/http';
import { Observable, timer, combineLatest } from 'rxjs';
import { AuthService } from '../../authentication/auth.service';
import { map, mergeMap, finalize, last, startWith, tap, filter } from 'rxjs/operators';
import { ApiReference } from '../api-end-points/api-reference';
import { LoaderService } from '../../utils/loader/loader.service';
import {apiCredentialsNames} from '../interfaces/Tenant/tenant-api-interfaces';

@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {
  private loggerName = '[ApiRequests] ';

  constructor(private auth: AuthService, private loader: LoaderService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let customHeaders = request.headers;

    // Set content type to json only if request body is not a FormData instance
    if (!(request.body instanceof FormData)) {
      customHeaders = customHeaders.set('Content-Type', 'application/json');
      // console.log(this.loggerName + 'Added Content-Type header as application/json');

    }
    //const refreshTokenUrl = ApiReference.getApiUrl('refreshAccessToken');
    if (request.url) {
      // token is being refreshed. accessing apiCredentials here would result in
      // an infinite loop.
      // console.log(`${this.loggerName} Token refresh request preventing access of apiCredentials..`);
      const updatedRequest = request.clone({
        headers: this.buildHeaderForTokenRefresh(customHeaders)
      });
      let apiComplete = false
      setTimeout(() => {
        if (!apiComplete) {
          this.loader.start()
        }
      }, 500)
      return next.handle(updatedRequest).pipe(
        finalize(() => {
          apiComplete = true
          this.loader.stop();
        }));
    } else {
      return this.auth.apiCredentials.pipe(
        mergeMap(apiCredentials => {
          if (apiCredentials) {
            // console.log(`${this.loggerName} Api credentials found. Adding appropriate auth headers to request..`);
            customHeaders = customHeaders.set('Authorization', `Token ${apiCredentials.token}`);
          } else {
            // console.log(`${this.loggerName} Api credentials not found. Skipping auth headers..`, localStorage);
          }
          const updatedRequest = request.clone({
            headers: customHeaders
          });
          let apiComplete = false
          setTimeout(() => {
            if (!apiComplete) {
              this.loader.start()
            }
          }, 500)

          return next.handle(updatedRequest).pipe(
            finalize(() => {
              apiComplete = true
              this.loader.stop();
            }));
        })
      );
    }

    // Set Authorization header if token is

  }

  private buildHeaderForTokenRefresh(headers: HttpHeaders): HttpHeaders {
    if (localStorage.getItem('token'))
      headers = headers.set('Authorization', `Token ${localStorage.getItem('token')}`);
    return headers;
  }
}
