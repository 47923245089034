import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChoicesConfiguratorForm, DdfConfiguratorForm} from '../../Configurators';
import {InAppLogger} from '../../../../general-utils/InAppLogger';
import {DynamicFieldTypes} from '../../../ddf.enums';
import {ChoiceFieldConfigurator, DynamicFieldConfigurator, SingleChoiceConfigurator} from '../../Configurators.interfaces';

export interface ChoiceFieldInfo {
  adder: SingleChoiceConfigurator;
}

@Component({
  selector: 'app-disposition-type-dialog',
  templateUrl: './add-choice-dialog.component.html',
  styleUrls: ['./add-choice-dialog.component.sass']
})
export class AddChoiceDialogComponent implements OnInit {
  logger = new InAppLogger(AddChoiceDialogComponent.name);
  fieldTypes = DynamicFieldTypes;
  ChoicesConfiguratorForm = ChoicesConfiguratorForm;
  // dispositionTypes: string[] = ['Text', 'Number', 'Dropdown', 'Multilevel'];
  adder: SingleChoiceConfigurator;

  constructor(
    public dialogRef: MatDialogRef<AddChoiceDialogComponent, ChoiceFieldInfo>,
    @Inject(MAT_DIALOG_DATA) public fieldInfo: ChoiceFieldInfo,
  ) {
  }

  ngOnInit() {
    this.adder = this.fieldInfo.adder || this.ChoicesConfiguratorForm.buildSingleChoiceConfigurator();
    this.logger.log(this.adder);
  }

  onNoClick(): void {
    this.cancel();
  }

  add() {
    this.dialogRef.close({
      adder: this.adder
    });
  }

  cancel() {

    this.dialogRef.close();
  }

}
