import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Phone } from 'src/app/circle-one/telephony/model/phone.model';
import { PhoneService } from 'src/app/circle-one/telephony/services/phone.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { UserProfileRead } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';

@Component({
  selector: 'app-telephony-disposition-dialog',
  templateUrl: './telephony-disposition-dialog.component.html',
  styleUrls: ['./telephony-disposition-dialog.component.sass']
})
export class TelephonyDispositionDialogComponent implements OnInit {

  disposCodeArr = [
    { id: '', disposition: 'Answered', 'sub_disposition': [] },
    { id: '', disposition: 'Busy', 'sub_disposition': [] },
    { id: '', disposition: 'Disconnected', 'sub_disposition': [] },
    { id: '', disposition: 'Not Intrested', 'sub_disposition': [] },
    { id: '', disposition: 'No Answer', 'sub_disposition': [] },
    { id: '', disposition: 'Call Transfer', 'sub_disposition': [] },
  ];

  disposCode: any;
  currentUser: UserProfileRead
  disposData: { lead: any; assigned_by: number; disposition: string; };
  disposCallData: { action: string; dispose_remark: any; sub_dispose_remark: any; remarks: any };
  teleSessionData: any;
  loginTeam: any;
  subDisposCodeArr: any = [];
  subDisposCode: any;
  subDispositionArr: any = [];
  disposId: any;
  disposValue: any;
  remark: any;

  constructor(
    private phoneApi: PhoneService,
    public phoneModel: Phone,
    public router: Router,
    public toaster: ToastrService,
    public dialogRef: MatDialogRef<TelephonyDispositionDialogComponent>,
    public context: AppContextService, // used in template,
    public api: ApiService,
  ) { }

  ngOnInit() {
    this.getPhoneDisposition()
    this.context.currentUser$.subscribe(user => {
      this.currentUser = user
    })
  }

  getPhoneDisposition() {
    let loginTeam = JSON.parse(localStorage.getItem("currentTeam"))[0]
    let processType = ((this.phoneModel.phone_loin_type == 'inbound') ? 'IB' : 'OB');

    this.api.getAllPhoneDisposition(loginTeam.id, processType).subscribe((disposition) => {
      if (disposition.results.length > 0) {
        this.disposCodeArr = disposition.results;
      }
    }, (err) => {
    })
  }

  getPhoneSubDisposition(event) {
    this.disposId = ((event.value) ? event.value.id : 0)
    this.disposValue = ((event.value) ? event.value.disposition : 0)
    this.subDisposCodeArr = this.disposCodeArr.find(x => x['id'] === this.disposId);
    if (this.subDisposCodeArr.sub_disposition.length == 0) {
      this.toaster.warning('There is no sub-dispositions')
    }
    this.subDispositionArr = ((this.subDisposCodeArr.sub_disposition.length > 0) ? this.subDisposCodeArr.sub_disposition : []);
  }

  disposObCall() {
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
    let leadId = this.phoneModel.leadId;

    if (this.disposCode) {
      this.phoneModel.disposCode = this.disposCode
      this.teleSessionData['disposRemark'] = this.phoneModel.disposCode
      localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
      let urlRoute = localStorage.getItem('urlRoute');
      localStorage.setItem('lastUrl', urlRoute);
      this.dialogRef.close()
      this.phoneModel.disposCode = this.disposCode
      this.phoneModel.callStatus = 'ACW'
      var splitPath = urlRoute.split('?')
      var path = ((splitPath[0]) ? splitPath[0] : null);
      this.toaster.success('Call disposed successfully');
      if (leadId) {
        this.addDispositionForLead()
      }
      this.disposCallData = { 'action': 'dispose-call', 'dispose_remark': this.disposValue, 'sub_dispose_remark': this.subDisposCode, 'remarks': this.remark }
      this.addDisposEntry(this.disposCallData)
    } else {
      this.toaster.warning('Please select disposition'); return;
    }

  }

  addDisposEntry(callData) {
    let callId = this.phoneModel.callId;
    this.api.addAgentCallDisposEntry(callData, callId).subscribe(
      data => {
        if (data.length > 0) {
        }
      });
  }

  addDispositionForLead() {
    let leadId = this.phoneModel.leadId;
    let url = localStorage.getItem('urlRoute');
    let campUrl = '/app/campaign/campaign-details/' + leadId
    var userid = ((this.currentUser['id']) ? this.currentUser['id'] : null);
    var disposVal = this.phoneModel.disposCode
    console.log(disposVal)
    // var data = this.disposCodeArr.filter(i => disposVal.includes(i.disposition));

    this.disposData = {
      lead: leadId,
      assigned_by: userid,
      disposition: this.disposValue
    };

    if (leadId) {
      this.api.addDispositionForLead(this.disposData).subscribe((success) => {
        let urlRoute = localStorage.getItem('lastUrl');
        let allData = { 'path': urlRoute };
        // this.router.navigate(['/phone-load'], { queryParams: allData });
      });
    }

  }

}
