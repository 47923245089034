import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-campaign-routing-dialog',
  templateUrl: './campaign-routing-dialog.component.html',
  styleUrls: ['./campaign-routing-dialog.component.sass']
})
export class CampaignRoutingDialogComponent implements OnInit {

  message = "Please confirm";
  
  constructor(public dialogRef: MatDialogRef<CampaignRoutingDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public confirmMessage: string) { }

  ngOnInit() {
    this.message = this.confirmMessage
    //console.log(this.message)
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  ok():void{
    this.dialogRef.close(true);
  }
}