import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { PhoneService } from 'src/app/circle-one/telephony/services/phone.service';
import { Phone } from 'src/app/circle-one/telephony/model/phone.model';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { UserProfileRead } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { PhoneLoginReport } from 'src/app/code-modules/api/interfaces/Reports/report-api-interfaces';


@Component({
  selector: 'app-telephony-login',
  templateUrl: './telephony-login.component.html',
  styleUrls: ['./telephony-login.component.sass']
})
export class TelephonyLoginComponent implements OnInit {
  currentUser: UserProfileRead;
  phoneLogin: boolean;
  phoneData: PhoneLoginReport;
  userId: string;
  selectedQueue: any = [];
  phoneLiveData: any;
  teleSessionData: any;
  allQueues: any = [];
  agentArr: any;
  selectedPause: any = 'Not Active';
  breakData: { user_id: number; break_remark: any; };


  constructor(
    private toaster: ToastrService,
    private phoneApi: PhoneService,
    private dialogRef: MatDialogRef<TelephonyLoginComponent>,
    public router: Router,
    public phoneModel: Phone,
    public context: AppContextService, // used in template
    public api: ApiService,
    public popupPadDialog: MatDialog,
  ) {
    this.context.currentUser$.subscribe(user => {
      this.currentUser = user
      console.log(this.currentUser)
    })
  }

  ngOnInit() {
    // this.allQueues = ['Payswiff_English', 'Axis_English', 'Payswiff_Hindi', 'Axis_Hindi', 'Payswiff_Other', 'Axis_Other']
    this.getAllQueue()
  }

  telephonyLogout() {
    let phoneLogin = ((this.currentUser['user_telephony_extension'][0]) ? this.currentUser['user_telephony_extension'][0] : null);

    let action = 'remove';
    if (this.allQueues.length > 0) {
      this.allQueues.forEach(element => {
        this.phoneApi.getRealTimeData().subscribe((result) => {
          var queue = ((element['name']) ? element['name'] : '');
          var isKey = result.hasOwnProperty(queue);
          if (isKey) {
            this.agentArr = ((result[queue]['Members']) ? result[queue]['Members'] : '');

            if (this.agentArr != '') {
              let phoneData = { 'extension': phoneLogin, 'action': action, 'queue': queue }

              this.phoneApi.phoneLoginLogout(phoneData).subscribe(
                data => {
                })
            }
          }
        })
      });
    }



  }

  phoneLoginInQueue() {
    localStorage.removeItem('phoneData')

    let urlRoute = localStorage.getItem('urlRoute');
    localStorage.setItem('lastUrl', urlRoute);

    var id = ((this.currentUser['id']) ? this.currentUser['id'] : null);
    var username = ((this.currentUser['username']) ? this.currentUser['username'] : null);
    let extension = ((this.currentUser['user_telephony_extension'][0]) ? this.currentUser['user_telephony_extension'][0] : null);

    if (extension == null) {
      this.toaster.warning('Extension not mapped to this user.');
      return;
    }
    if (this.selectedQueue.length > 0) {
      this.phoneData = {
        campaigns: '',
      };

      let queueLength = this.selectedQueue.length - 1

      this.selectedQueue.forEach(element => {
        let action = 'add';
        let queue = ((element['name']) ? element['name'] : null);
        let penalty = ((element['penalty']) ? element['penalty'] : null);

        this.phoneData.campaigns = `${this.phoneData.campaigns},${queue}`
        let phoneData = { 'extension': extension, 'action': action, 'queue': queue, 'username': username, 'penalty': penalty }
        this.phoneApi.phoneLoginLogout(phoneData).subscribe(
          data => {
            if (data) {
              this.dialogRef.close();
              let msg = data['Desc'].toString();
              if (msg != null || msg != '' || msg != undefined) {
                if (msg.indexOf('Success') !== -1) {

                  if (this.selectedQueue[queueLength]['name'] == element['name']) {
                    let urlRoute = localStorage.getItem('lastUrl');
                    var splitPath = urlRoute.split('?')

                    var path = ((splitPath[0]) ? splitPath[0] : null);
                    let allData = { 'path': path };
                    this.router.navigate(['/phone-load'], { queryParams: allData });

                    /*temporaray data store in the model file */
                    this.phoneModel.extension = ((phoneData['extension']) ? phoneData['extension'] : null);
                    this.phoneModel.isphoneLogin = 'YES';
                    this.phoneModel.loginCampaign = this.selectedQueue
                    this.phoneModel.agentStatus = 'unpause'
                    this.selectPauseCode(0)
                    /*data store in the session */

                    var telData = {
                      'extension': this.phoneModel.extension, 'isPhoneLogin': 'YES',
                      'queues': this.phoneModel.loginCampaign, 'agentStatus': this.phoneModel.agentStatus,
                      'sessionId': '', 'callId': '', 'mobileNo': '', 'breakId': '', 'holdId': '', 'secondNo': '',
                      'uniqueId': '', 'interactionId': ''
                    };

                    localStorage.setItem("phoneData", JSON.stringify(telData));

                    this.addPhoneLoginLogout(this.phoneData)
                  }
                } else {
                  this.toaster.error('Some thing went wrong in telephony login functionality.');
                  return;
                }
              } else {
                this.toaster.error('Telephony server not responding.');
                return;
              }
            } else {
              this.dialogRef.close();
              this.toaster.warning('Telephony login failed'); return;
            }

          },
          error => {
            this.toaster.error('Some thing went wrong in telephony login functionality.');
            return;
          });
      });

     

    } else {
      this.toaster.warning('Please select queue for telephony login.');
      return;
    }

  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    this.phoneLoginInQueue()
    this.close()
  }

  addPhoneLoginLogout(phoneData) {
    console.log(phoneData)
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back

    this.api.addPhoneLoginLogout(phoneData).subscribe((data) => {
      let phoneSessionId = ((data['id']) ? data['id'] : '');
      if (phoneSessionId != '') {
        let phoneSessionId = ((data['id']) ? data['id'] : '');
        this.toaster.success('Telephony login successfully');
        console.log(phoneSessionId)
        this.phoneModel.phoneSessionId = phoneSessionId
        this.phoneModel.selectedPauseCode = this.selectedPause
        this.teleSessionData['selectedPauseCode'] = this.phoneModel.selectedPauseCode
        this.teleSessionData['sessionId'] = phoneSessionId
        localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
      }
    });
  }

  selectPauseCode(flag) {
    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : '');
    let queue = ((this.phoneModel.loginCampaign) ? this.phoneModel.loginCampaign : []);
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
    var userId = ((this.currentUser['id']) ? this.currentUser['id'] : null);

    if (phoneLogin == 0 || phoneLogin == '' || phoneLogin == undefined) {
      this.toaster.error('Please login in telephony'); return;
    }

    var action = 'pause';

    let queueLength = queue.length - 1;

    if (queue.length > 0) {
      queue.forEach(element => {
        var qVal = ((element['name']) ? element['name'] : null);

        let phoneData = { 'extension': phoneLogin, 'action': action, 'queue': qVal, 'pauseCode': this.selectedPause }

        this.phoneApi.phonePauseUnpause(phoneData).subscribe(
          data => {
            let msg = data['Desc'].toString();
            if (msg != null || msg != '' || msg != undefined) {
              if (msg.indexOf("Success") !== -1) {
                if (queue[queueLength]['name'] == element['name']) {

                  if (flag == 0) {
                    this.breakData = {
                      user_id: userId,
                      break_remark: this.selectedPause,
                    };
                    this.addBreakTime(this.breakData)

                    this.phoneModel.isphoneLogin = 'PAUSE'
                    this.phoneModel.agentStatus = 'pause'
                    this.phoneModel.selectedPauseCode = this.selectedPause
                    this.teleSessionData['selectedPauseCode'] = this.phoneModel.selectedPauseCode
                    this.teleSessionData['isPhoneLogin'] = 'PAUSE'
                    this.teleSessionData['agentStatus'] = 'pause'
                    localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
                    this.phoneModel.inboundCall && this.phoneModel.inboundCall.unsubscribe();
                  }
                }
              } else {
                this.toaster.error('Telephony server not responding.');
                return;
              }

            }
            error => {
              this.toaster.error('Error in ' + action + ' functionality.');
              return;
            }
          });
      });


    } else {
      this.toaster.warning('Please login in the telephony');
      return;
    }

  }

  addBreakTime(breakData) {
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back

    this.api.addBreakTime(breakData).subscribe(
      data => {
        let breakId = ((data['id']) ? data['id'] : '');
        console.log(breakId)
        if (breakId != '') {
          let breakId = ((data['id']) ? data['id'] : '');
          console.log(breakId)
          this.phoneModel.breakId = breakId
          this.teleSessionData['breakId'] = breakId
          localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
        }
      });
  }

  getAllQueue() {
    const ExtList = this.api.getAllQueue().subscribe((result) => {
      console.log(result)
      this.allQueues = result;
      this.telephonyLogout()
    })
  }
}

