import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  expanded = true;
  showFilterBy = false;
  resetFilterBy: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dialInputValue : BehaviorSubject<string> = new BehaviorSubject('')

  open() {
    this.expanded = true;
  };

  close() {
    this.expanded = false;
  }

  toggle() {
    this.expanded = !this.expanded;
  }

}
