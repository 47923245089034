import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiReference } from '../api-end-points/api-reference';
import { Observable } from 'rxjs';
import { DynamicFieldConfigurationTypes } from '../../utils/data-driven-forms/ddf.enums';
import { DdfFieldData } from '../../utils/data-driven-forms/ddf.interfaces';
import { ApiServiceBase } from '../api-service-base/api.service.base';
import {
    DynamicChoiceDefinitionRead,
    DynamicDefinitionCreate,
    DynamicDefinitionUpdate,
    DynamicFieldDefinitionRead,
    DynamicFieldDefinitionsList
} from '../../utils/data-driven-forms/Configuration/Configurators.interfaces';
import {
    ActionMergeRequest,
    ActionRead,
    AgentMapCreate,
    AutoAllocationLimitCreate,
    AutoAllocationLimitRead,
    AutoAllocationLimitUpdate,
    CampaignCreate,
    CampaignNameUpdate,
    CampaignUpdate,
    CampInternalNoteCreate,
    CampLeadUpdate,
    CampNameCreate,
    CampNameList,
    CampNameRead,
    CampNameUpdate,
    CustomerNoteCreate,
    CustomersActionsList,
    DisposConfigCreate,
    DispositionCreate,
    DispositionRead,
    ExtCreate,
    IbCallStatusUpdate,
    InternalNoteCreate,
    LeadCreate,
    LeadMultipleAssignment,
    LeadMultipleDelete,
    NestedCampInProgressStatusCreate,
    NestedTicketInProgressStatusCreate,
    OrderListUpdate,
    OrderMultipleDelete,
    OrderMultipleStatus,
    PauseCodeCreate,
    PriCreate,
    QueueCreate,
    TelephonyServerCreate,
    TicketCreate,
    TicketFilterQueryParams,
    TicketInProgressStatusList,
    TicketInProgressStatusRead,
    TicketList,
    TicketMultipleAssignment,
    TicketNameCreate,
    TicketNameList,
    TicketNameRead,
    TicketNameUpdate,
    TicketRead,
    TicketRulesUpdate,
    TicketShareRequest,
    TicketUpdate,
    UpdateOrderData
} from '../interfaces/Ticket/ticket-api-interfaces';
import {
    CampaignTemplateCreate,
    EmailAllocationLimitCreate,
    EmailAllocationLimitRead,
    EmailAllocationLimitUpdate,
    EmailBinCount,
    EmailForwardSource,
    EmailMultipleAssignment,
    EmailRule,
    EmailsTemplatesList,
    EmailsThreadsList,
    EmailThreadCreate,
    EmailThreadDetailRead,
    EmailThreadUpdate,
    KeyWordAllocationRuleCreate,
    KeyWordAllocationRuleRead,
    KeyWordAllocationRuleUpdate,
    Note,
    TemplateCreate,
    TemplateRead,
    TemplateUpdate,
    ThreadReply
} from '../interfaces/Email/email-api-interfaces';
import { EmailStatusEnum } from '../../saas/saas.enums';
import {
    ApiChangePassword,
    ApiCredentials,
    ApiForgotPasswordRequest,
    ApiLoginRequest,
    ApiResetPassword,
    TenantUpdate
} from '../interfaces/Tenant/tenant-api-interfaces';
import { UserProfileCreate, UserProfileRead, UserProfileUpdate, UserTeamList } from '../interfaces/User/user-api-interfaces';
import {
    AccountBulkUpload,
    AccountCreateUpdate,
    AccountRead,
    ContactCreateUpdate,
    ContactRead,
    CustomersAccountsList,
    CustomersContactsList,
    EventData,
    Tag,
    TelephonycallHistory,
    TelephonycallHistoryPagenation
} from '../interfaces/Customer/customer-api-interfaces';
import {
    BaseNode,
    BusinessHoursReadUpdate,
    ContactBulkUpload,
    Group,
    LeadBulkUpload,
    NestedGroup,
    NestedTopic,
    Team,
    Topic,
    TopicEntryList,
    TopicEntryRead,
    TopicEntryUpdate,
    TopicsList,
    UserBulkUpload
} from '../interfaces/Business-Rule/business-rule-api-interfaces';
import { request } from 'http';
import { PhoneLoginReport } from '../interfaces/Reports/report-api-interfaces';


@Injectable({
    providedIn: 'root'
})

export class ApiService extends ApiServiceBase {

    loggerName = '[API/tenent]';
    apiUrlSource = ApiReference.tenantApiUrl;


    constructor(http: HttpClient) {
        super(http);
    }


    // // //tenant apis
    // // public signUpAdmin(signUpRequest: TenantSignUpRequest): Observable<any> {
    // //   return this._apiCreate('adminSignUp', signUpRequest);
    // // }\

    //auth apis
    public login(credentials: ApiLoginRequest): Observable<ApiCredentials> {
        console.log("credentials", credentials)
        return this._apiCreate('login', credentials);
    }

    public forgotPassword(username: ApiForgotPasswordRequest): Observable<any> {
        return this._apiCreate('forgotPassword', username);
    }

    public resetPass(credentials: ApiResetPassword) {
        return this._apiCreate('resetPassword', credentials);
    }

    public changePassword(credentials: ApiChangePassword) {
        return this._apiCreate('changePassword', credentials);
    }

    // User API
    public fetchCurrentUser(): Observable<UserProfileRead> {
        return this._apiRead('currentUser');
    }

    public userUpdate(user: UserProfileUpdate, userId: number): Observable<UserProfileRead> {
        return this._apiPartialUpdate('userUpdate', user, [userId]);
    }

    public listUsers(team?, role?, user_status?): Observable<UserProfileRead[]> {
        return this._apiRead('userList', null, {
            teams__id: team,
            role: role,
            user_status: user_status
        });
    }

    public inviteUser(details: UserProfileCreate) {
        return this._apiCreate('userInvite', details);
    }

    public userTeamList(): Observable<UserTeamList> {
        return this._apiRead('userTeamList');
    }
    public ConatctTeamList() {
        return this._apiRead('userTeamList');
    }
    public loadSingleUserTeamList(teamId: number) {
        return this._apiRead('loadSingleUserTeamList', [teamId]);
    }


    public userDetails(userId: number): Observable<UserProfileRead> {
        return this._apiRead('user', [userId]);
    }

    public removeUser(userId: number) {
        return this._apiDelete('userDelete', [userId]);
    }

    public loadTenantTeams(userName, tenantName) {
        return this._apiRead('loadTenantTeams', [userName, tenantName])
    }
    public logout() {
        return this._apiDelete('logout')
    }
    public releaseSession(id?: number, username?) {
        return this._apiRead('releaseSession', null, { id, username })
    }
    public clientDashboard(account) {
        return this._apiRead('clientDashboard', null, { account })
    }

    public clientDetails() {
        return this._apiRead('clientDetails');
    }

    public clientProfileUpdate(reqBody) {
        return this._apiPartialUpdate('clientProfileUpdate', reqBody)
    }

    public createCustomerTag(tag: Tag) {
        return this._apiCreate('customerTagCreate', tag);
    }

    public updateCustomerTag(tag: Tag) {
        return this._apiUpdate('customerTagUpdate', tag, [tag.id]);
    }

    public CustomerTagList(): Observable<Tag[]> {
        return this._apiRead('customerTagList');
    }

    public removeCustomerTag(tag: Tag) {
        return this._apiDelete('customerTagDelete', [tag.id]);
    }

    public customerTag(tagId: number) {
        return this._apiRead('customerTag', [tagId]);
    }
    public accountCreate(account: AccountCreateUpdate): Observable<AccountCreateUpdate> {
        return this._apiCreate('accountCreate', account);
    }
    public contactList(page?: number, account?: number, search?: string) {
        return this._apiRead('contactList', null, { account, page, search, is_lead_contact: false });
    }
    public contactListForCreateTicket(page?: number, account?: number, search?: string) {
        return this._apiRead('contactList', null, { account, page, search, is_lead_contact: false });
    }

    public contactSearchByEmail(email: string): Observable<CustomersContactsList> {
        return this._apiRead('contactSearch', null, { email });
    }
    public contactSearchByEmails(email: string, for_team: any): Observable<CustomersContactsList> {
        return this._apiRead('contactSearch', null, { email, for_team });
    }

    public contactDetails(contactId: number): Observable<ContactRead> {
        return this._apiRead('contactDetails', [contactId]);
    }

    public contactCreate(contact: ContactCreateUpdate): Observable<ContactCreateUpdate> {
        return this._apiCreate('contactCreate', contact);
    }

    public sendMail(email: EmailThreadCreate): Observable<EmailThreadCreate> {
        return this._apiCreate('composeMail', email);
    }

    public contactAvailability(email: string): Observable<ContactRead> {
        return this._apiRead('contactAvailability', null, { email, is_lead_contact: false });
    }

    public contactUpdate(contact: ContactCreateUpdate, customerId: number): Observable<ContactCreateUpdate> {
        return this._apiPartialUpdate('contactUpdate', contact, [customerId]);
    }

    public contactBulkCreate(contactList: ContactBulkUpload): Observable<any> {
        return this._apiCreate('contactBulkCreate', contactList);
    }

    public contactDelete(contactId: number): Observable<any> {
        return this._apiDelete('contactDelete', [contactId]);
    }
    public customerActionList(contact?: number, contact__account?: number, sortBy = '', page?: number) {
        return this._apiRead('customerHistory', null, { contact, contact__account, ordering: sortBy, page: page });
    }

    public actionMerge(duplicate: ActionMergeRequest, actionId: number) {
        return this._apiUpdate('actionMerge', duplicate, [actionId]);
    }

    public customerAction(actionId: number): Observable<ActionRead> {
        return this._apiRead('customerAction', [actionId]);

    }
    public accountDetails(accountId: number): Observable<AccountRead> {
        return this._apiRead('accountDetails', [accountId]);
    }

    public accountUpdate(account: AccountCreateUpdate, accountId: number): Observable<AccountCreateUpdate> {
        return this._apiUpdate('accountUpdate', account, [accountId]);
    }

    public accountPatch(account: AccountCreateUpdate, accountId: number): Observable<AccountCreateUpdate> {
        return this._apiPartialUpdate('accountUpdate', account, [accountId]);
    }
    public accountList(page: number, search: string, get_assignments__disposition__get_ticket_name__name?: string): Observable<CustomersAccountsList> {
        return this._apiRead('accountList', null, { page, search, get_assignments__disposition__get_ticket_name__name, is_lead_account: false });
    }
    public allAccountList() {
        return this._apiRead('accountList')
    }

    public accountDelete(accountId: number) {
        return this._apiDelete('accountDelete', [accountId]);
    }

    public accountSearch(search_value) {
        return this._apiRead('accountSearch', [search_value])
    }

    public contactSearchNew(search_value) {
        return this._apiRead('contactSearchNew', [search_value])
    }
    //disposition apis


    //business rules - business hours
    public setBusinessHours(businessHoursObj: BusinessHoursReadUpdate, team) {
        return this._apiUpdate('setBusinessHours', businessHoursObj, null, { team });
    }

    public businessHours(team): Observable<BusinessHoursReadUpdate> {
        return this._apiRead('businessHours', null, { team });
    }

    //Dispositions
    public getTeamWiseDisposition(teamId) {
        return this._apiRead('getTeamWiseDisposition', [teamId])
    }
    public editDisposition(request) {
        return this._apiPartialUpdate('editDisposition', request, [request[0].id]);
    }

    public ticketNameLookup(dispositionId: number, team_id?: number): Observable<TicketNameRead> {
        return this._apiRead('ticketNameLookup', [dispositionId], { team_id });
    }

    public dispositionCreate(disposition: Array<BaseNode>) {

        return this._apiCreate('createDisposition', disposition);
    }

    public setDispositionTree(dispositions: Array<BaseNode>, teamId): Observable<Array<BaseNode>> {
        return this._apiUpdate('updateDisposition', dispositions, [teamId]);
    }
    public setDispositionTree1(dispositions: Array<BaseNode>, teamId, hide?: any): Observable<Array<BaseNode>> {
        console.log(dispositions, teamId, hide, "api")
        return this._apiUpdate('updateDisposition', dispositions, [teamId], hide);
    }
    public removeDisposition(dispositionId: number): Observable<any> {
        return this._apiDelete('removeDisposition', [dispositionId]);
    }

    public getDispositionTree(team_id?: number): Observable<Array<BaseNode>> {
        return this._apiRead('dispositions', null, { team_id });
    }
    public dynamicFieldList(model_name: DynamicFieldConfigurationTypes) {
        console.log(model_name, "model")
        return this._apiRead('dynamicFieldList', null, { model_name: model_name });
    }

    public dynamicFieldCreate(dynamicField: DynamicDefinitionCreate): Observable<DynamicFieldDefinitionRead> {
        return this._apiCreate('dynamicFieldCreate', dynamicField);
    }

    public dynamicFieldUpdate(id: number, dymanicField: DynamicDefinitionUpdate): Observable<DynamicDefinitionUpdate> {
        return this._apiUpdate('dynamicFieldUpdate', dymanicField, [id]);
    }

    public dynamicField(id: number): Observable<DynamicFieldDefinitionRead> {
        return this._apiRead('dynamicField', [id]);
    }

    public dynamicFieldDelete(id: number): Observable<any> {
        return this._apiDelete('dynamicFieldDelete', [id]);
    }


    public dynamicFiledChoiceCreate(choice: DynamicChoiceDefinitionRead): Observable<DynamicChoiceDefinitionRead> {
        return this._apiCreate('dynamicFiledChoiceCreate', choice);
    }

    public dynamicFieldCheckBoxChoiceCreate(choice: DynamicChoiceDefinitionRead) {
        return this._apiCreate('dynamicFieldCheckBoxChoiceCreate', choice)
    }

    public dynamicFieldMultipleLevelCreate(choice: DynamicChoiceDefinitionRead, level) {
        return this._apiCreate('dynamicFieldMultipleLevelCreate', choice, [level])
    }
    public dynamicFiledChoiceUpdate(choice: DynamicChoiceDefinitionRead): Observable<DynamicChoiceDefinitionRead> {
        return this._apiUpdate('dynamicFiledChoiceUpdate', choice, [choice.id]);
    }

    public dynamicFiledChoiceDelete(choiceId: number): Observable<any> {
        return this._apiDelete('dynamicFiledChoiceDelete', [choiceId]);
    }

    // public userTeamUpdate(team) {
    //     return this._apiUpdate('userTeamUpdate', team, [team.id])
    // }
    public getNestedDynamicFields(fieldId, level) {
        return this._apiRead('getNestedDynamicFields', [fieldId, level])
    }
    public getSubLevelNestedDynamicFields(id, level) {
        return this._apiRead('getSubLevelNestedDynamicFields', [id, level])
    }
    public dynamicFieldCheckBoxChoiceDelete(choiceId: number): Observable<any> {
        return this._apiDelete('dynamicFieldCheckBoxChoiceDelete', [choiceId]);
    }

    public dynamicFieldMultipleLevelDelete(field_id, level) {
        return this._apiDelete('dynamicFieldMultipleLevelDelete', [field_id, level])
    }
    public userTeamUpdate(team) {
        return this._apiUpdate('userTeamUpdate', team, [team.id])
    }
    public archieveUserUpdate(userId, user_status?: any) {
        return this._apiUpdate('archieveUserUpdate', null, [userId], { user_status: user_status })
    }
    public adminResetPassword(username: string, body: any) {
        return this._apiUpdate('adminResetPassword', body, [username], null)
    }
    public getDefaultModulePermissions(role) {
        return this._apiRead('getDefaultModulePermissions', [role])
    }

    public userTeamCreate(request: Team) {
        return this._apiCreate('userTeamCreate', request)
    }

    public createImapConfiguration(request) {
        return this._apiCreate('createImapConfiguration', request)
    }

    public createCampaignConfiguration(request) {
        return this._apiCreate('createCampaignConfiguration', request)
    }

    public createSmptConfiguration(request) {
        return this._apiCreate('createSmptConfiguration', request)
    }

    public emailThreadList(page: number, status__contains?: EmailStatusEnum, action__assignee?: number, action__assignee__isnull?: boolean, is_new?: boolean, is_repeat?: boolean, ordering?: string, search?: string, action__priority__icontains?: string, action__contact__email__icontains
        ?: string, action__assignee__username__icontains?: string, original_subject__icontains
            ?: string, created?: Date, action__contact?: number, action__contact__phone_number__exact?: number, get_thread_for?: string): Observable<EmailsThreadsList> {
        return this._apiRead('emailListThread', null, {
            page,
            status__contains,
            action__assignee,
            action__assignee__isnull,
            is_new,
            is_repeat,
            ordering,
            search,
            action__priority__icontains,
            action__contact__email__icontains,
            action__assignee__username__icontains,
            original_subject__icontains
            ,
            created,
            action__contact,
            action__contact__phone_number__exact,
            get_thread_for
        });
    }
    public getEmailCount(): Observable<EmailBinCount> {
        return this._apiRead('getEmailCount');
    }
    public getIndividualEmailCount(id): Observable<EmailBinCount> {
        return this._apiRead('getIndividualEmailCount', [id]);
    }
    public getTicketCount() {
        return this._apiRead('getTicketCount')
    }
    public getIndividualTicketCount(id, workflowId): Observable<EmailBinCount> {
        return this._apiRead('getIndividualTicketCount', [id, workflowId]);
    }
    public getEmailThread(id: number): Observable<EmailThreadDetailRead> {
        return this._apiRead('getEmailThread', [id]);
    }
    public replyMail(email: ThreadReply, threadId: number): Observable<ThreadReply> {
        return this._apiUpdate('emailReply', email, [threadId]);
    }
    public listTickets(filterOption: TicketFilterQueryParams): Observable<TicketList> {
        return this._apiRead('listTickets', [], filterOption);
    }
    public createTicket(ticketRequestObject: TicketCreate): Observable<any> {
        return this._apiCreate('createTicket', ticketRequestObject);
    }
    public createSingleLead(leadRequestObject: LeadCreate): Observable<any> {
        return this._apiCreate('createSingleLead', leadRequestObject);
    }

    public updateTicket(ticketRequestObject: TicketUpdate, ticketId): Observable<TicketUpdate> {
        return this._apiPartialUpdate('updateTicket', ticketRequestObject, [`${ticketId}`]);
    }

    public getTciketDetails(ticketId): Observable<TicketRead> {
        return this._apiRead('getTciketDetails', [ticketId]);

    }

    public multipleTicketsAssign(request: TicketMultipleAssignment, userId: number): Observable<any> {
        return this._apiCreate('multipleTicketsAssign', request, [userId]);
    }

    public multipleThreadAssign(request: EmailMultipleAssignment, userId: number): Observable<any> {
        return this._apiCreate('multipleThreadAssign', request, [userId]);
    }

    public createCustomerNote(customerNote: CustomerNoteCreate): Observable<CustomerNoteCreate> {
        return this._apiCreate('createCustomerNote', customerNote);
    }

    public createInternalNote(internalNote: InternalNoteCreate): Observable<InternalNoteCreate> {
        return this._apiCreate('createInternalNote', internalNote);
    }

    public shareTicket(shareRequest: TicketShareRequest, ticketId: string): Observable<any> {
        return this._apiUpdate('shareTicket', shareRequest, [ticketId]);
    }

    public printTicket(ticketId: string, include_logs: boolean): Observable<Blob> {
        return this._apiRead('printTicket', [ticketId], { include_logs }, { responseType: 'blob' });
    }

    public emailTicketRequirementLookup(disposition: number): Observable<boolean> {
        return this._apiCreate('emailTicketRequirementLookup', disposition);
    }
    public getSmtpConfig(id: number) {
        return this._apiRead('getSmtpConfig', [id])
    }
    // public getEmailServerConfiguration() {
    //     return this._apiRead('getEmailServerConfiguration')
    // }
    public getEmailServerConfiguration(teamId) {
        return this._apiRead('getImapServerConfiguration', [teamId])
    }

    public getCampaignConfiguration(campaign?: any) {
        return this._apiRead('getCampaignConfiguration', null, { campaign })
    }

    public deleteSmtpConfiguration(id) {
        return this._apiDelete('deleteSmtpConfiguration', [id]);
    }
    public deleteImapConfiguration(id) {
        return this._apiDelete('deleteImapConfiguration', [id]);
    }

    public deleteCampConfiguration(id) {
        return this._apiDelete('deleteCampConfiguration', [id]);
    }

    public ticketWorkFlowList(team?: number): Observable<TicketNameList> {
        return this._apiRead('ticketWorkFlowList', null, { team });
    }
    public campWorkFlowList(team?: number, campaign?: number): Observable<CampNameList> {
        return this._apiRead('campWorkFlowList', null, { team, campaign });
    }
    public updateEmailThread(request: EmailThreadUpdate): Observable<EmailThreadUpdate> {
        return this._apiPartialUpdate('updateEmailThread', request, [request.id]);
    }
    public dashBoardStatistics(teamNo: number, period: string, startDateTime: string, endDateTime: string, workFlowId: number, agent_id) {
        return this._apiRead('dashBoardStatistics', null, { team: teamNo, group_by: period, start_date: startDateTime, end_date: endDateTime, workflow: workFlowId, user: agent_id });
    }

    //business rule - ticket work flow apis


    public createTicketWorkFlow(workFlow: TicketNameCreate): Observable<TicketNameCreate> {
        return this._apiCreate('createTicketWorkFlow', workFlow);
    }
    public createCampWorkFlow(workFlow: CampNameCreate): Observable<CampNameCreate> {
        return this._apiCreate('createCampWorkFlow', workFlow);
    }
    public updateTicketWorkFlow(workFlow: TicketNameUpdate): Observable<TicketNameUpdate> {
        return this._apiUpdate('updateTicketWorkFlow', workFlow, [workFlow.id]);
    }
    public updateCampWorkFlow(workFlow: CampNameUpdate): Observable<CampNameUpdate> {
        return this._apiUpdate('updateCampWorkFlow', workFlow, [workFlow.id]);
    }
    public ticketInprogressStatus(status_id) {
        return this._apiRead('ticketInprogressStatus', [status_id])
    }
    public getAllClosedStatus() {
        return this._apiRead('getAllClosedStatus')
    }
    public createClosedStatus(requestBody) {
        return this._apiCreate('createClosedStatus', requestBody)
    }
    public getClosedStatusById(closedStatusId) {
        return this._apiRead('getClosedStatusById', [closedStatusId])
    }
    public updateClosedStatusById(requestBody, closedStatusId) {
        return this._apiUpdate('updateClosedStatusById', requestBody, [closedStatusId])
    }
    public deleteClosedStatusById(closedStatusId) {
        return this._apiDelete('deleteClosedStatusById', [closedStatusId])
    }
    public getTicketWorkFlow(workflowId) {
        return this._apiRead('getTicketWorkFlow', [workflowId])
    }
    public ticketInprogressStatusUpdate(inprogessStatus: NestedTicketInProgressStatusCreate, workflowId): Observable<NestedTicketInProgressStatusCreate> {
        return this._apiUpdate('ticketInprogressStatusUpdate', inprogessStatus, [workflowId]);
    }
    public CampInprogressStatusUpdate(inprogessStatus: NestedCampInProgressStatusCreate, workflowId): Observable<NestedTicketInProgressStatusCreate> {
        return this._apiUpdate('CampInprogressStatusUpdate', inprogessStatus, [workflowId]);
    }
    public ticketInprogressStatusCreate(inprogessStatus: NestedTicketInProgressStatusCreate): Observable<NestedTicketInProgressStatusCreate> {
        return this._apiCreate('ticketInprogressStatusCreate', inprogessStatus);
    }
    public campInprogressStatusCreate(inprogessStatus: NestedCampInProgressStatusCreate): Observable<NestedCampInProgressStatusCreate> {
        return this._apiCreate('campInprogressStatusCreate', inprogessStatus);
    }
    public deleteTicketWorkFlow(workFlowId: number): Observable<string> {
        return this._apiDelete('deleteTicketWorkFlow', [workFlowId]);
    }
    public deleteCampWorkFlow(workFlowId: number): Observable<string> {
        return this._apiDelete('deleteCampWorkFlow', [workFlowId]);
    }
    public ticketInprogressStatusDelete(inprogessStatusId: number): Observable<any> {
        return this._apiDelete('ticketInprogressStatusDelete', [inprogessStatusId]);
    }
    public campInprogressStatusDelete(inprogessStatusId: number): Observable<any> {
        return this._apiDelete('campInprogressStatusDelete', [inprogessStatusId]);
    }
    public userDepartmentList(): Observable<any[]> {
        return this._apiRead('userDepartmentList');
    }
    public updateSmtpServer(reqBody, id) {
        return this._apiPartialUpdate('updateSmtpServer', reqBody, [id])
    }
    public updateImapServer(reqBody, id) {
        return this._apiPartialUpdate('updateImapServer', reqBody, [id])
    }
    public updateCampaignConfiguration(reqBody, id) {
        return this._apiPartialUpdate('updateCampaignConfiguration', reqBody, [id])
    }
    //Ticket  allocation

    public getTicketsSLAConfig(teamId): Observable<TicketRulesUpdate> {
        return this._apiRead('getTicketRules', [teamId]);
    }
    public updateTicketSLAConfig(slaConfig: TicketRulesUpdate, teamId): Observable<TicketRulesUpdate> {
        return this._apiPartialUpdate('updateTicketRules', slaConfig, [teamId]);
    }


    public editCustomer(contact: ContactCreateUpdate, customerId: number) {
        return this._apiPartialUpdate('contactUpdate', contact, [customerId]);
    }

    // public updateCustomerTag(tag: Tag) {
    //     return this._apiUpdate('customerTagUpdate', tag, [tag.id]);
    // }

    // public userTeamUpdate(team:Team){
    //     return this._apiUpdate('userTeamUpdate',team,[team.id])
    // }

    // public contactUpdate(contact: ContactCreateUpdate, customerId: number) {
    //     return this._apiPartialUpdate('contactUpdate', contact, [customerId]);
    // }

    public getAllTicketNameRule(teamId, dispoId, workFlowId: number): Observable<any> {
        return this._apiRead('getAllTicketNameRule', [teamId, dispoId, workFlowId]);
    }

    public updateTicketNameRule(ticketUpdateData, teamId, dispoId) {
        return this._apiUpdate('updateTicketNameRule', ticketUpdateData, [teamId, dispoId, ticketUpdateData.ticket_name])
    }

    public addLevelToTicketName(levelData, levelName, teamId, dispoId) {
        return this._apiCreate('addLevelToTicketName', levelData, [teamId, dispoId, levelName])
    }

    public updateTicketLevel(levelUpdateData, levelName, teamId, dispoId) {
        return this._apiUpdate('updateTicketLevel', levelUpdateData, [teamId, dispoId, levelName])
    }

    public getLevelsByTicketNameID(workFlowId: number, teamId, dispoId) {
        return this._apiRead('getLevelsByTicketNameID', [teamId, dispoId, workFlowId])
    }

    public getAllTicketLevels(workFlowId: number) {
        return this._apiRead(`getAllTicketLevels`, [workFlowId])
    }

    public deleteTicketLevel(request, teamId, dispoId) {
        return this._apiDelete('deleteTicketLevel', [teamId, dispoId, request.ticket_name_id, request.status, request.level])
    }

    public getLevelByStatus(request, teamId, dispoId) {
        return this._apiRead('getLevelByStatus', [teamId, dispoId, request.ticket_name_id, request.status])
    }

    // Tag Based SLA
    public getTagNameSLA(tagId, tagType) {
        return this._apiRead('getTagNameSLA', [tagId, tagType])
    }
    public updateTagNameSLA(request, tagId, tagType) {
        return this._apiUpdate('updateTagNameSLA', request, [tagId, tagType])
    }
    public getTagLevels(tagId, tagType) {
        return this._apiRead('getTagLevels', [tagId, tagType])
    }
    public addTagLevels(request, tagId, tagType, levelName) {
        return this._apiCreate('addTagLevels', request, [tagId, tagType, levelName])
    }
    public updateTagLevels(request, tagId, tagType, levelName) {
        return this._apiUpdate('updateTagLevels', request, [tagId, tagType, levelName])
    }
    public deleteTagLevel(tagId, tagType, levelName) {
        return this._apiDelete('deleteTagLevel', [tagId, tagType, levelName])
    }

    // email sla
    public addEmailSLALevel(request, action_type, level_name) {

        return this._apiCreate('addEmailSLALevel', request, [action_type], [level_name])
    }

    public getEmailSLALevel(action_type) {
        console.log(action_type, "getSLALevel")
        return this._apiRead('getEmailSLALevel', [action_type])
    }

    public updateEmailSLALevel(request, action_type, level_name) {
        return this._apiUpdate('updateEmailSLALevel', request, [action_type], [level_name])
    }

    public deleteEmailSLALevel(level_name) {
        return this._apiDelete('deleteEmailSLALevel', [level_name])
    }
    public getEmailRules(): Observable<EmailRule> {
        return this._apiRead('getEmailRules');
    }

    public updateEmailRules(emailRule: EmailRule): Observable<any> {
        return this._apiPartialUpdate('updateEmailRules', emailRule);
    }

    public getTicketCountWorkflowWise(workflowId: string, filterOptions) {
        return this._apiRead('getTicketCountWorkflowWise', [workflowId], filterOptions)
    }

    public listTeamWiseUsers(teamId: number, user_status?): Observable<UserProfileRead[]> {
        return this._apiRead('userTeamWiseList', [teamId], { user_status: user_status });
    }

    public getTeamDataByName(name: string): Observable<CustomersContactsList> {
        return this._apiRead('getTeamDataByName', null, { name });
    }

    public createEmailTemplate(emailTemplate: TemplateCreate): Observable<TemplateCreate> {
        return this._apiCreate('createEmailTemplate', emailTemplate);
    }

    public createCampaignTemplate(campTemplate: CampaignTemplateCreate): Observable<CampaignTemplateCreate> {
        return this._apiCreate('createCampaignTemplate', campTemplate);
    }

    public updateEmailTemplate(emailTemplate: TemplateUpdate): Observable<TemplateUpdate> {
        return this._apiUpdate('updateEmailTemplate', emailTemplate, [emailTemplate.id]);
    }
    public updateCampaignTemplate(campTemplate: TemplateUpdate): Observable<TemplateUpdate> {
        return this._apiUpdate('updateCampaignTemplate', campTemplate, [campTemplate.id]);
    }

    public deleteEmailTemplate(templateId: number): Observable<any> {
        return this._apiDelete('deleteEmailTemplate', [templateId]);
    }

    public deleteCampaignTemplate(templateId: number): Observable<any> {
        return this._apiDelete('deleteCampaignTemplate', [templateId]);
    }

    public templateAttchmentDelete(attchmentId: number): Observable<any> {
        return this._apiDelete('templateAttchmentDelete', [attchmentId]);
    }

    public campTemplateAttchmentDelete(attchmentId: number): Observable<any> {
        return this._apiDelete('campTemplateAttchmentDelete', [attchmentId]);
    }

    public getEmailTemplate(templateId: number): Observable<TemplateRead> {
        return this._apiRead('getEmailTemplate', [templateId]);
    }

    public emailTemplateList(page: number, search?: string): Observable<EmailsTemplatesList> {
        return this._apiRead('emailTemplate', null, { page, search });
    }
    public getTeamWiseEmailTemplate(page: number, team_id, search?: string) {
        console.log(team_id)
        return this._apiRead('getTeamWiseEmailTemplate', null, { page, team_id, search })
    }
    public getCampaignWiseTemplate(page: number, campaign) {
        console.log(campaign)
        return this._apiRead('getCampaignWiseTemplate', null, { page, campaign })
    }
    public userDepartmentDelete(department): Observable<any> {
        return this._apiDelete('userDepartmentDelete', [department.id]);
    }
    public getCyeretDynamicFieldValue(ticketId, recordDate) {
        return this._apiRead('getCyeretDynamicFieldValue', [ticketId, recordDate])
    }
    // public getAccountContact(account?: number): Observable<CustomersContactsList> {
    //     return this._apiRead('contactList', null, { account });
    // }
    public getAccountContact(account__name__in?: any): Observable<CustomersContactsList> {
        return this._apiRead('contactList', null, { account__name__in });
    }
    public addEventData(event: EventData): Observable<EventData> {
        return this._apiCreate('addEventData', event);
    }
    public getSmtpServer(teamId) {
        return this._apiRead('getSmtpServer', [teamId])
    }
    public updateAutoResponseSmtp(body, id) {
        return this._apiPartialUpdate('updateAutoResponseSmtp', body, [id])
    }
    public listEventData(event_date?: any, team?: any, ticket?: any, created_by?: any) {
        return this._apiRead('listEventData', null, { event_date, team, ticket, created_by });
    }

    public singleEventDetails(eventId) {
        return this._apiRead('singleEventDetails', [eventId]);
    }


    public updateEvent(id: number, data) {
        return this._apiUpdate('updateEvent', data, [id])
    }

    public deleteEvent(id: number) {
        return this._apiDelete('deleteEvent', [id]);
    }


    public createCampaign(campaignRequestObject: CampaignCreate): Observable<any> {
        return this._apiCreate('createCampaign', campaignRequestObject);
    }

    public createExtension(extensionRequestObject: ExtCreate): Observable<any> {
        return this._apiCreate('createExtension', extensionRequestObject);
    }

    public createQueue(queueRequestObject: QueueCreate): Observable<any> {
        return this._apiCreate('createQueue', queueRequestObject);
    }

    public createPauseCode(pauseCodeRequestObject: PauseCodeCreate): Observable<any> {
        return this._apiCreate('createPauseCode', pauseCodeRequestObject);
    }

    public createAgentMapData(agentMapRequestObject: AgentMapCreate): Observable<any> {
        return this._apiCreate('createAgentMapData', agentMapRequestObject);
    }
    public createPri(priRequestObject: PriCreate): Observable<any> {
        return this._apiCreate('createPri', priRequestObject);
    }

    public getAllCampaign(page?:any,team?: number) {
        return this._apiRead('getAllCampaign', null, {page,team });
    }

    public getAllCampaignForDropDown(team?: number) {
        return this._apiRead('getAllCampaign', null, { team });
    }

    public getAgentMapList() {
        return this._apiRead('getAgentMapList', null)
    }

    public getAllExtension(ordering?: any) {
        return this._apiRead('getAllExtension', null, { ordering });
    }
    public getAllQueue() {
        return this._apiRead('getAllQueue', null);
    }
    public getAllPauseCode() {
        return this._apiRead('getAllPauseCode', null);
    }
    public getAllPriList() {
        return this._apiRead('getAllPriList', null);
    }
    public deleteCampaign(id: number) {
        return this._apiDelete('deleteCampaign', [id]);
    }

    public deleteExtension(id: number) {
        return this._apiDelete('deleteExtension', [id]);
    }
    public deleteQueue(id: number) {
        return this._apiDelete('deleteQueue', [id]);
    }
    public deletePauseCode(id: number) {
        return this._apiDelete('deletePauseCode', [id]);
    }
    public deletePri(id: number) {
        return this._apiDelete('deletePri', [id]);
    }
    public deleteMapExtension(id: number) {
        return this._apiDelete('deleteMapExtension', [id]);
    }

    public getSingleCampaign(campId) {
        return this._apiRead('getSingleCampaign', [campId]);
    }

    public getSingleExtension(extId) {
        return this._apiRead('getSingleExtension', [extId]);
    }
    public getSingleQueue(queueId) {
        return this._apiRead('getSingleQueue', [queueId]);
    }
    public getSinglePauseCode(pauseCodeId) {
        return this._apiRead('getSinglePauseCode', [pauseCodeId]);
    }
    public getSinglePri(priId) {
        return this._apiRead('getSinglePri', [priId]);
    }

    public getSingleMapExtension(extId) {
        return this._apiRead('getSingleMapExtension', [extId]);
    }

    public updateCampaign(id: number, data) {
        return this._apiUpdate('updateCampaign', data, [id])
    }

    public updateExtension(id: number, data) {
        return this._apiUpdate('updateExtension', data, [id])
    }
    public updateQueue(id: number, data) {
        return this._apiUpdate('updateQueue', data, [id])
    }
    public updatePauseCode(id: number, data) {
        return this._apiUpdate('updatePauseCode', data, [id])
    }
    public updatePri(id: number, data) {
        return this._apiUpdate('updatePri', data, [id])
    }
    public updateMapExtension(id: number, data) {
        return this._apiUpdate('updateMapExtension', data, [id])
    }

    public userBulkCreate(userList: UserBulkUpload): Observable<any> {
        return this._apiCreate('userBulkCreate', userList);
    }

    public getInboundCall(extension?: any) {
        return this._apiRead('getInboundCall', null, { extension })
    }

    public updateTelephonyLogoutEvent(username) {
        console.log(username)
        return this._apiUpdate('updateTelephonyLogoutEvent', '', [username])
    }

    public campLeadBulkCreate(leadList: LeadBulkUpload): Observable<any> {
        return this._apiCreate('campLeadBulkCreate', leadList);
    }

    public replyAllEmailIds(threadId, emailId) {
        return this._apiRead('replyAllEmailIds', [threadId, emailId])
    }

    public replyEmailIds(threadId, emailId) {
        return this._apiRead('replyEmailIds', [threadId, emailId])
    }

    public campWorkflowUpdate(campId: number, camp: CampaignUpdate): Observable<CampaignUpdate> {
        return this._apiUpdate('campWorkflowUpdate', camp, [campId]);
    }

    public addPhoneLoginLogout(phoneRequestObject: PhoneLoginReport): Observable<any> {
        return this._apiCreate('addPhoneLoginLogout', phoneRequestObject);
    }

    public getCampaignLeadData(page: number, campaign?: number, campaign__name__icontains?: string,
        first_name__icontains?: string,
        last_name__icontains?: string,
        email__icontains?: string,
        created_by?: string,
        phone_number__icontains?: any,
        status_type__icontains?: string,
        status__status__contains?: string, id__icontains
            ?: any, assignee__username__icontains?: string, created?: any, ordering?: any, closed_status__status__contains?: any,account_name__icontains?: string): Observable<EmailsThreadsList> {
        return this._apiRead('getCampaignLeadData', null, {
            page,
            campaign,
            campaign__name__icontains,
            first_name__icontains,
            last_name__icontains,
            email__icontains,
            created_by,
            phone_number__icontains,
            status_type__icontains,
            status__status__contains,
            id__icontains,
            assignee__username__icontains,
            created,
            ordering, closed_status__status__contains,
            account_name__icontains

        });
    }

    public getSingleLeadDetails(workFlowId: any) {
        return this._apiRead('getSingleLeadDetails', [workFlowId])
    }

    public updateCampLead(leadRequestObject: CampLeadUpdate, leadId): Observable<TicketUpdate> {
        return this._apiPartialUpdate('updateCampLead', leadRequestObject, [`${leadId}`]);
    }

    public campaignStatus(campaign_workflow?: number): Observable<CampNameRead> {
        return this._apiRead('campaignStatus', null, { campaign_workflow });
    }

    public updateLeadCampaign(campaignRequestObject: CampaignNameUpdate, leadId): Observable<CampaignNameUpdate> {
        return this._apiPartialUpdate('updateLeadCampaign', campaignRequestObject, [`${leadId}`]);
    }

    public updateIbCallStatus(data: IbCallStatusUpdate, callId): Observable<IbCallStatusUpdate> {
        return this._apiPartialUpdate('updateIbCallStatus', data, [`${callId}`]);
    }

    public campCreateInternalNote(internalNote: CampInternalNoteCreate): Observable<CampInternalNoteCreate> {
        return this._apiCreate('campCreateInternalNote', internalNote);
    }

    public multipleLeadsAssign(request: LeadMultipleAssignment, userId: number): Observable<any> {
        return this._apiCreate('multipleLeadsAssign', request, [userId]);
    }
    public getStatusCount(campaign?: number, workflow_name?: number,
        campaign__name__icontains?: any,
        first_name__icontains?: any,
        last_name__icontains?: any,
        email__icontains?: any,
        created_by?: any,
        phone_number__icontains?: any,
        status_type__icontains?: any,
        status__status__contains?: any,
        id__icontains?: any,
        assignee__username__icontains?: any,
        created?: any, closed_status__status__contains?: any,account_name__icontains?: string): Observable<CampNameRead> {
        return this._apiRead('getCampaignCount', null, {
            campaign, workflow_name,
            campaign__name__icontains,
            first_name__icontains,
            last_name__icontains,
            email__icontains,
            created_by,
            phone_number__icontains,
            status_type__icontains,
            status__status__contains,
            id__icontains,
            assignee__username__icontains,
            created,
            closed_status__status__contains,
            account_name__icontains
        });
    }

    public addDispositionForLead(request: DispositionCreate): Observable<any> {
        return this._apiCreate('addDispositionForLead', request);
    }
    public getNotifications(notification_type, status, team_id) {
        return this._apiRead('getNotifications', [team_id, notification_type, status])
    }

    public createNotifications(requestBody) {
        return this._apiCreate('createNotifications', requestBody)
    }
    public dashboardApi(start_date?, end_date?, user?, team?, workflow?, account?, contact?, graph_start_date?, graph_end_date?,
        prev_graph_start_date?, prev_graph_end_date?, graph_email_status?, graph_ticket_status?,
        ticket_status_type?) {
        return this._apiRead('dashboardApi', null, {
            start_date,
            end_date,
            user,
            team,
            workflow,
            account,
            contact,
            graph_start_date: graph_start_date,
            graph_end_date: graph_end_date,
            prev_graph_start_date: prev_graph_start_date,
            prev_graph_end_date: prev_graph_end_date,
            graph_email_status,
            graph_ticket_status,
            ticket_status_type
        })
    }

    public dashboardChannelCount(type?, user?, team?, workflow?, start_date?, end_date?, account?, contact?) {
        return this._apiRead('dashboardChannelCount', null, {
            type, user, team, workflow, start_date, end_date, account, contact
        })
    }

    public getWordCloudCount(start_date, end_date) {
        return this._apiRead('getWordCloudCount', null, { start_date, end_date });
    }
    public updateNotification(requestBody) {
        return this._apiUpdate('updateNotification', requestBody, [requestBody.id])
    }

    public getEmailStatusCount(type, user, team, start_date, end_date, account, contact) {
        return this._apiRead('getEmailStatusCount', null, {
            type, user, team, start_date, end_date, account, contact
        })
    }

    public getLeadDisposition(lead?: any): Observable<DispositionRead> {
        return this._apiRead('getLeadDisposition', null, {
            lead,
        });
    }

    public getMapUserCampaigns(userId) {
        return this._apiRead('getMapUserCampaigns', [userId])
    }

    public checkUserRealTimeStatus() {
        return this._apiRead('checkUserRealTimeStatus')
    }

    public getAgentRtmDashboardCnt() {
        return this._apiRead('getAgentRtmDashboardCnt')
    }

    public getIbObPhoneSummary(comments?: any) {
        return this._apiRead('getIbObPhoneSummary', null, { comments })
    }

    public getPhoneEvent(userId) {
        return this._apiRead('getPhoneEvent', [userId])
    }

    public removePhoneEvent(userId: any) {
        return this._apiDelete('removePhoneEvent', [userId]);
    }

    public getTicketAuditTrailList(ticket_id) {
        return this._apiRead('getTicketAuditTrailList', [ticket_id]);
    }

    public getTicketAuditTrailDetails(trail_id, activity) {
        return this._apiRead('getTicketAuditTrailDetails', [trail_id], { activity });
    }

    public createTicketScheduler(requestBody) {
        return this._apiCreate('createTicketScheduler', requestBody)
    }
    public getTicketScheduler(teamId) {
        return this._apiRead('getTicketScheduler', [teamId]);
    }
    public deleteTicketScheduler(schedulerId) {
        return this._apiDelete('deleteTicketScheduler', [schedulerId])
    }
    public getSingleTicketScheduler(schedulerId) {
        return this._apiRead('getSingleTicketScheduler', [schedulerId])
    }

    public editSingleTicketScheduler(reqBody, schedulerId) {
        return this._apiPartialUpdate('editSingleTicketScheduler', reqBody, [schedulerId])
    }
    public addCampignEvent(event: EventData): Observable<EventData> {
        // alert('addCampignEvent')
        return this._apiCreate('addCampignEvent', event);
    }

    public listCampaignEvent(event_date?: any, team?: any, lead?: any, created_by?: any) {
        // alert('listCampaignEvent')
        return this._apiRead('listCampaignEvent', null, { event_date, team, lead, created_by });
    }

    public countCampaignEvent() {
        return this._apiRead('countCampaignEvent');
    }

    public listCampaignEventQuery(queryParams:any) {
        // alert('listCampaignEvent')
        return this._apiRead('listCampaignEvent', null,queryParams);
    }

    public updateCampaignEvent(id: number, data) {
        // alert('updateCampaignEvent')
        return this._apiUpdate('updateCampaignEvent', data, [id])
    }

    public singleCampaignEvent(eventId) {
        // alert('updateCampaignEvent')

        return this._apiRead('singleCampaignEvent', [eventId]);
    }


    public createClientLogin(reqBody) {
        return this._apiCreate('createClientLogin', reqBody)
    }
    public getAllClientLogin() {
        return this._apiRead('getAllClientLogin');
    }
    public getSingleClientLogin(id) {
        return this._apiRead('getSingleClientLogin', [id]);
    }
    public updateSingleClientLogin(reqBody, id) {
        return this._apiUpdate('updateSingleClientLogin', reqBody, [id]);
    }
    public deleteSingleClientLogin(id) {
        return this._apiDelete('deleteSingleClientLogin', [id])
    }


    // ticket access rights APIs
    public createTicketAccessRight(reqBody) {
        return this._apiCreate('createTicketAccessRight', reqBody)
    }

    public updateTicketAccessRight(id, reqBody) {
        return this._apiUpdate('updateTicketAccessRight', reqBody, [id])
    }

    public getTicketAccessRightList(teamId) {
        return this._apiRead('getTicketAccessRightList', [], { team_from: teamId })
    }

    public getSettingsTicketAccessRightList(teamId) {
        return this._apiRead('getSettingsTicketAccessRightList', [], { team_from: teamId })
    }

    public searchCustomer(phone_number__endswith?: number) {
        return this._apiRead('searchCustomer', null, { phone_number__endswith })
    }

    public checkExtensionState(extension?: number) {
        return this._apiRead('checkExtensionState', null, { extension })
    }

    public addAgentCallEntry(callData) {
        return this._apiCreate('addAgentCallEntry', callData)
    }

    public addAgentExtState(extStateData) {
        return this._apiCreate('addAgentExtState', extStateData)
    }

    public addBreakTime(breakData) {
        return this._apiCreate('addBreakTime', breakData)
    }

    public addHoldRetrieveEntry(holdData, callId) {
        return this._apiCreate('addHoldRetrieveEntry', holdData, [callId])
    }

    public addAgentCallHangupEntry(callData, callId) {
        return this._apiCreate('addAgentCallHangupEntry', callData, [callId])
    }

    public addAgentResumeEntry(breakData, breakId) {
        return this._apiCreate('addAgentResumeEntry', breakData, [breakId])
    }

    public addAgentLogoutEntry(logoutData, sessionId) {
        return this._apiCreate('addAgentLogoutEntry', logoutData, [sessionId])
    }

    public addAgentCallDisposEntry(callData, callId) {
        return this._apiCreate('addAgentCallDisposEntry', callData, [callId])
    }

    public getCallHistory(user_id: any, call_type: any, gte: any, lte: any) {
        return this._apiRead('getCallHistory', null, { user_id, call_type, gte, lte })
    }

    //fetch telephony configuration

    public getTelephonyConfiguration() {
        return this._apiRead('getTelephonyConfiguration')
    }
    public deleteCampLead(request: LeadMultipleDelete, id?: any) {
        return this._apiDelete1('deleteCampLead', request, [id]);
    }
    //invoiceWorkflow api

    public createOrderInvoiceWorkflow(workFlow: CampNameCreate): Observable<CampNameCreate> {
        return this._apiCreate('createOrderInvoiceWorkflow', workFlow);
    }
    public orderWorkflowList(team?: number, campaign?: number): Observable<CampNameList> {
        return this._apiRead('orderWorkflowList', null, { team, campaign });
    }
    public orderInprogressStatusCreate(inprogessStatus: NestedCampInProgressStatusCreate): Observable<NestedCampInProgressStatusCreate> {
        return this._apiCreate('orderInprogressStatusCreate', inprogessStatus);
    }
    public orderInprogressStatusUpdate(inprogessStatus: NestedCampInProgressStatusCreate, workflowId): Observable<NestedTicketInProgressStatusCreate> {
        return this._apiUpdate('orderInprogressStatusUpdate', inprogessStatus, [workflowId]);
    }
    public orderInprogressStatusDelete(inprogessStatusId: number): Observable<any> {
        return this._apiDelete('orderInprogressStatusDelete', [inprogessStatusId]);
    }
    public updateOrderWorkflow(workFlow: CampNameUpdate): Observable<CampNameUpdate> {
        return this._apiUpdate('updateOrderWorkflow', workFlow, [workFlow.id]);
    }
    public deleteOrderWorkflow(workFlowId: number): Observable<string> {
        return this._apiDelete('deleteOrderWorkflow', [workFlowId]);
    }
    public updateOrderWorkflowMapping(campId: number, camp: CampaignUpdate): Observable<CampaignUpdate> {
        return this._apiUpdate('updateOrderWorkflow', camp, [campId]);
    }
    public createOrderLead(leadRequestObject: LeadCreate): Observable<any> {
        return this._apiCreate('createOrderLead', leadRequestObject);
    }
    public getOrderDynamicList(page: number, lead?: number) {
        return this._apiRead('getOrderDynamicList', null, { page, lead });
    }

    public getOrderLeadBulkCreate(leadList: LeadBulkUpload): Observable<any> {
        return this._apiCreate('getOrderLeadBulkCreate', leadList);
    }
    public orderWorkflowListStatus(order_workflow?: number): Observable<CampNameRead> {
        return this._apiRead('orderWorkflowListStatus', null, { order_workflow });
    }
    public updateOrderChangeStatus(campaignRequestObject: OrderListUpdate, orderId): Observable<OrderListUpdate> {
        return this._apiPartialUpdate('updateOrderChangeStatus', campaignRequestObject, [`${orderId}`]);
    }
    public deleteOrderInvoiceLead(request) {
        return this._apiMultipleDelete('deleteOrderInvoiceLead', request);
    }
    public multiOrderStatusChange(request: OrderMultipleStatus): Observable<OrderMultipleStatus> {
        return this._apiCreate('multiOrderStatusChange', request);
    }
    public getOrderIdList(id?: number) {
        return this._apiRead('getOrderDynamicList', null, { id });
    }
    public updateOrderData(orderId, orderData: UpdateOrderData): Observable<UpdateOrderData> {
        return this._apiUpdate('updateOrderListfield', orderData, [orderId]);
    }

    public getManagerMailIds(role, teams) {
        return this._apiRead('getManagerMailIds', null,{role, teams});
    }

    public getOrderAmount(lead?: number) {
        return this._apiRead('getOrderAmount', null, { lead });
    }
    public deleteOrderAttachment(request) {
        return this._apiMultipleDelete('deleteOrderAttachment', request);
    }
    public getLeadOrderDataInExcel(page: boolean, lead, type) {
        return this._apiRead('getLeadOrderDataInExcel', null, { page, lead, type })
    }
    public getLoginAgentPhoneData(user_id:number) {
        return this._apiRead('getLoginAgentPhoneData', null,{user_id})
    }
    public getAgentMapLists(call_type?:any,team?:any,user_id?:any) {
        return this._apiRead('getAgentMapList', null,{ call_type, team ,user_id })
    }

    //Telephony disposition
    public getAllPhoneDisposition(team?:any,type?:any) {
        return this._apiRead('getAllPhoneDisposition', null,{team,type});
    }
     
    public getSinglePhoneDisposition(id) {
        return this._apiRead('getSinglePhoneDisposition', [id]);
    }

    public createPhoneDisposition(data: DisposConfigCreate): Observable<any> {
        return this._apiCreate('createPhoneDisposition', data);
    }

    public deleteDisposition(id: number) {
        return this._apiDelete('deleteDisposition', [id]);
    }

    public updateConfigDisposition(id,dispositionData: DisposConfigCreate) {
        return this._apiUpdate('updateConfigDisposition', dispositionData, [id]);
    }

     //Telephony Server Configration
     public createTelephonyServerConfigration(telephonyServerRequestObject:TelephonyServerCreate): Observable<any> {
        return this._apiCreate('createTelephonyServerConfigration', telephonyServerRequestObject);
    }

    public getAllTelePhonyServerList() {
        return this._apiRead('getAllTelePhonyServerList', null);
    }
    public updateTelephonyServerConfigration(id: number, data) {
        return this._apiUpdate('updateTelephonyServerConfigration', data, [id])
    }
    public getSingleServerConfigration(serverId) {
        return this._apiRead('getSingleServerConfigration', [serverId]);
    }
    public deleteTelephonyServerConfigration(id: number) {
        return this._apiDelete('deleteTelephonyServerConfigration', [id]);
    }
}


