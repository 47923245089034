import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DdfFieldData, systemFields } from '../../ddf.interfaces';
import {
  DdfConfiguratorForm
} from '../Configurators';
import { InAppLogger } from '../../../general-utils/InAppLogger';


import { MatDialog, MatPaginator } from '@angular/material';
import { AddChoiceDialogComponent, ChoiceFieldInfo } from './add-choice-dialog/add-choice-dialog.component';
import { AddFieldDialogComponent } from './add-field-dialog/add-field-dialog.component';
import {
  ChoiceFieldConfigurator,
  ChoicesConfigurator,
  DynamicFieldConfigurator,
  SingleChoiceConfigurator,
  DynamicFieldDefinitionRead,
  DynamicDefinitionCreate,
  DynamicChoiceDefinitionRead
} from '../Configurators.interfaces';
import { DynamicFieldTypes, DynamicFieldValidators, DynamicFieldConfigurationTypes, DynamicFieldConfigurationHeader } from '../../ddf.enums';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { LoaderService } from 'src/app/code-modules/utils/loader/loader.service';
import { FormControl } from '@angular/forms';
import { DataDrivenForm } from '../../DataDrivenForm';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { DeleteFieldDialogComponent } from './delete-field-dialog/delete-field-dialog.component';
import { findIndex } from 'rxjs/operators';
import { ErrorToaster } from '../../../validations/genral-validation';
import { L, T } from '@angular/cdk/keycodes';

export interface FieldInfo {
  adder: DynamicFieldConfigurator;
}
// const AccountSystemFields: Array<systemFields> = [
//   { type: "text", label: "Name" }
// ];



@Component({
  selector: 'app-ddf-configurator',
  templateUrl: './ddf-configurator.component.html',
  styleUrls: ['./ddf-configurator.sass']
})
export class DdfConfiguratorComponent implements OnInit {

  configurator: DdfConfiguratorForm;
  dynamicFieldDefinitions: any;

  @Input() ddfType: DynamicFieldConfigurationTypes;
  @Input() systemFields: Array<systemFields>
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  ///@ViewChildren("dynamicFields") dynamicFields: QueryList<any>;
  fieldTypes = DynamicFieldTypes;
  levelOneFields ;
  levelTwoFields ;
  levelThreeFields;
  levelFourFields;
  selectTicketId : number = null;
  dynamicFields:Array<DynamicFieldDefinitionRead>;
  labelOne
  labelTwo
  labelThree
  labelFour
  constructor(
    public matDialog: MatDialog,
    private toaster: ToastrService,
    private api: ApiService,
   // ////private loader: LoaderService,
    private context: AppContextService
  ) {
  }

  ngOnInit() {
    this.dynamicFieldDefinitions = {
      [DynamicFieldConfigurationTypes.USER_PROFILE]: {
        fetch$: this.context.userDynamicFields$,
        refresh: () => this.context.fetchUserDynamicFields(),
      },
      [DynamicFieldConfigurationTypes.CUSTOMER_ACCOUNT]: {
        fetch$: this.context.customerAccountDynamicFields$,
        refresh: () => this.context.fetchCustomerAccountDynamicFields()
      },
      [DynamicFieldConfigurationTypes.CUSTOMER_CONTACT]: {
        fetch$: this.context.customerContactDynamicFields$,
        refresh: () => this.context.fetchCustomerContactDynamicFields()
      },

      // [DynamicFieldConfigurationTypes.TICKET]: {
      //   fetch$: this.context.ticketDynamicFields$,
      //   refresh: () => this.context.fetchTicketDynamicFields()
      // },

      [DynamicFieldConfigurationTypes.TICKET_NAME]: {
        fetch$: this.context.ticketNameDynamicFields$,
        refresh: () => this.context.fetchTicketNameDynamicFields()
      },
      [DynamicFieldConfigurationTypes.CAMPAIGN]: {
        fetch$: this.context.campaignDynamicFields$,
        refresh: () => this.context.fetchCampaignDynamicFields()
      },
      
    }
    console.log(this.ddfType)
    
    this.loadDyanamicFeilds();
   

  }
 
  createConfigurator(feilds: DynamicFieldDefinitionRead[]) {

    const ddfFormHeader = DynamicFieldConfigurationHeader[this.ddfType]
    const fieldData = DataDrivenForm.buildFieldData(feilds);
    this.configurator = new DdfConfiguratorForm(ddfFormHeader, fieldData, 0, this.systemFields);
    console.log(this.configurator,")))))");
    // this.getLevelWiseFields(this.configurator.focusedField.fieldData.identifier,1)
    //this.dynamicFieldCount = this.customerAccoutConfigurator.fieldData.length
  }

  buildFieldData(feilds: DynamicFieldDefinitionRead[]): DdfFieldData[] {
    let fieldData: DdfFieldData[] = []
    feilds.forEach((field) => {
      let obj = <DdfFieldData>{
        identifier: field.id,
        label: field.label,
        type: field.field_type,
        validators: field.is_required ? [DynamicFieldValidators.REQUIRED] : [],
        choices: field.choices && field.choices.length ? field.choices : undefined
      }
      fieldData.push(obj)
    })
    return fieldData
  }

  selectChangeHandler(value){
    console.log("selected ticket name")
    this.selectTicketId = value.value
  }
  
  loadDyanamicFeilds() { 
    this.dynamicFieldDefinitions[this.ddfType].fetch$.subscribe((dynamicFields:Array<DynamicFieldDefinitionRead>) => {
      console.log("dynamic fields",dynamicFields)     
      this.createConfigurator(dynamicFields)
      this.dynamicFields = dynamicFields
    })
    
    this.api.dynamicFieldList(this.ddfType).subscribe((dynamicFields) => {
      console.log(dynamicFields)
      this.createConfigurator(dynamicFields)
    },(err)=>{
      this.toaster.error(ErrorToaster(err))
    }) 
  }

  loadTicketDynamicField(){
    console.log("loadTck")
    if(this.ddfType == 'ticket_name'){
      this.dynamicFieldDefinitions[this.ddfType].fetch$.subscribe((dynamicFields:Array<DynamicFieldDefinitionRead>)=> {
        console.log("dynamic fields",dynamicFields)
        
        dynamicFields.filter((dfield)=> {
          if(dfield.ticket_name === null){
            console.log(dfield , dynamicFields.indexOf(dfield))
            this.removeDynamicField(dfield.id,dynamicFields.indexOf(dfield))
          }
          // else{
          //   this.loadDyanamicFeilds()
          // }
        })
        // this.createConfigurator(this.dynamicFields)
        // this.dynamicFields = dynamicFields
        
      })
    }
  }

  removeDynamicField(identifier: number, index: number) {
    console.log("remove")
   // //this.loader.start();
    this.api.dynamicFieldDelete(identifier).subscribe((success) => {
     // //this.loader.stop();
      this.configurator.removeFieldOnIndex(index)
      // this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
      this.dynamicFields = success
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    ////this.loader.stop()
  })
  }

  addDispositionFieldDialog(fieldConfigurator?: DynamicFieldConfigurator): void {
    
    let data: FieldInfo;
    if (fieldConfigurator) {
      data = {
        adder: fieldConfigurator
      };
    }
    const dialogRef = this.matDialog.open(AddFieldDialogComponent, {
      panelClass: 'co-dialog-panel',
      data,
      // width:'400px'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Add field dialog was closed:', result);
      if (result) {
        if (!this.isExistingField(result.adder.label.value)) {

          // this.logger.log('Received field:', result.adder);
          let dynamicField = <DynamicDefinitionCreate>{
            field_type: result.adder.type.value,
            label: result.adder.label.value,
            is_required: result.required,
            is_unique: result.unique,
            model_name: this.ddfType,
            ticket_name:this.selectTicketId,
            field_length:result.field_length
          }
          this.api.dynamicFieldCreate(dynamicField).subscribe((ddf) => {
            console.log(ddf);
            this.configurator.addField({
              label: result.adder.label.value,  
              type: result.adder.type.value,
              identifier: ddf.id,
              validators: []
            });
            this.loadDyanamicFeilds()
            // window.location.reload();
            // this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
          },(err) => {
            this.toaster.error(ErrorToaster(err))
            console.log(err)
          })


        } else {
          this.toaster.info("Field already exist")
        }
      }
    });
   
  }

  addChoiceDialog(choiceConfigurator?: SingleChoiceConfigurator): void {
    let data: ChoiceFieldInfo;
    if (choiceConfigurator) {
      data = {
        adder: choiceConfigurator
      };
    }
    const dialogRef = this.matDialog.open(AddChoiceDialogComponent, {
      panelClass: 'co-dialog-panel',
      data,
    });

    dialogRef.afterClosed().subscribe((result?: ChoiceFieldInfo) => {
      console.log('Add choice dialog was closed:', result);
      if (result) {
        let request = <DynamicChoiceDefinitionRead>{
          field: this.configurator.focusedField.fieldData.identifier,
          choice: result.adder.value.value
        }
        this.api.dynamicFiledChoiceCreate(request).subscribe((ddfChoice) => {
          (this.configurator.focusedField as ChoiceFieldConfigurator)
            .choices.addChoice(result.adder.value.value, ddfChoice.id);
            this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
        },(err)=>{
          this.toaster.error(ErrorToaster(err))
        })
        //this.logger.log('Received choice:', result.adder);

        // .choices.addChoice(result.adder.value.value, result.adder.displayValue.value);
      }
    });
  }

  addCheckboxChoiceDialog(choiceMultipleConfigurator?: SingleChoiceConfigurator){
    let data: ChoiceFieldInfo;
    if (choiceMultipleConfigurator) {
      data = {
        adder: choiceMultipleConfigurator
      };
    }
    const dialogRef = this.matDialog.open(AddChoiceDialogComponent, {
      panelClass: 'co-dialog-panel',
      data,
    });
    dialogRef.afterClosed().subscribe((result?: ChoiceFieldInfo) => {
      console.log('Add choice dialog was closed:', result);
      if (result) {
        let request = <DynamicChoiceDefinitionRead>{
          field: this.configurator.focusedField.fieldData.identifier,
          choice: result.adder.value.value
        }
        this.api.dynamicFieldCheckBoxChoiceCreate(request).subscribe((ddfChoice) => {
          (this.configurator.focusedField as ChoiceFieldConfigurator)
            .multiple_choices.addChoice(result.adder.value.value, ddfChoice.id);
            this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
            console.log(this.configurator.choiceMultipleConfigurator ,"%%%%%%%%%%%" )
        },(err)=>{
          this.toaster.error(ErrorToaster(err))
        })
        //this.logger.log('Received choice:', result.adder);

        // .choices.addChoice(result.adder.value.value, result.adder.displayValue.value);
      }
    });
  }
  addNestedChoiceDialog(level,fieldId,choiceMultiLevelConfigurator?: SingleChoiceConfigurator){
    console.log(choiceMultiLevelConfigurator);
    let data: ChoiceFieldInfo;
    if (choiceMultiLevelConfigurator) {
      data = {
        adder: choiceMultiLevelConfigurator
      };
    }
    const dialogRef = this.matDialog.open(AddChoiceDialogComponent, {
      panelClass: 'co-dialog-panel',
      data,
    });
    
    dialogRef.afterClosed().subscribe((result?: ChoiceFieldInfo) => {
      console.log('Add choice dialog was closed:', result);
      // let level = 1
      if (result) {
        let request = <DynamicChoiceDefinitionRead>{
          field: fieldId,
          choice: result.adder.value.value,
          field_type:DynamicFieldTypes.NESTED
        }
    
        this.api.dynamicFieldMultipleLevelCreate(request,level).subscribe((ddfChoice) => {
          (this.configurator.focusedField as ChoiceFieldConfigurator)
            .multi_level.addChoice(result.adder.value.value, ddfChoice.id);
            // this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
            let field_id = {
              id:fieldId
            }
            // let field_id_main = {
            //   identifier:fieldId
            // }
            if(level == 1){
             
              this.getLevelOneFields(this.configurator.focusedField.fieldData , level)
            }
            if(level == 2){
              console.log(level ,request.field, fieldId, "wwwwww")
              this.getLevelTwoFields(field_id, level)
            }
            if(level == 3){
              this.getLevelThreeFields(field_id,level)
            }
            if(level == 4){
                this.getLevelFourFields(field_id ,level)
            }
            console.log(this.configurator.multiLevelConfigurator ,"%%%%%%%%%%%" )
        },(err)=>{
          this.toaster.error(ErrorToaster(err))
        })
       
        //this.logger.log('Received choice:', result.adder);

        // .choices.addChoice(result.adder.value.value, result.adder.displayValue.value);
      }
    });
  }
  
  checked(field: any) {
    const fieldData: DdfFieldData = field.fieldData;
    return (fieldData.validators.indexOf(DynamicFieldValidators.REQUIRED) != -1) ? true : false
  }
  checkedUnique(field: any) {
    const fieldData: DdfFieldData = field.fieldData;
    return (fieldData.validators.indexOf(DynamicFieldValidators.UNIQUE) != -1) ? true : false

  }
  isExistingField(value: string) {
    return this.configurator.fields.find((field) => field.label.value.toLowerCase() === value.toLowerCase())
  }
  removeChoice(choice: FormControl, index: number) {
    ////this.loader.start();
    this.api.dynamicFiledChoiceDelete(choice.value).subscribe((succes) => {
     // //this.loader.stop();
      this.configurator.choiceConfigurator.removeChoiceOnIndex(index);
      this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
    }, (err) => {
    ////this.loader.stop()
    this.toaster.error(ErrorToaster(err))
  })
    console.log(choice, index)
  }
  removeMultipleChoice(choice: FormControl, index: number) {
    ////this.loader.start();
    this.api.dynamicFieldCheckBoxChoiceDelete(choice.value).subscribe((succes) => {
     // //this.loader.stop();
      this.configurator.choiceMultipleConfigurator.removeChoiceOnIndex(index);
      this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
    }, (err) => {
    ////this.loader.stop()
      this.toaster.error(ErrorToaster(err))
    })
    console.log(choice, index)
  }
  removemultiLevelConfigurator(choice: FormControl,level, index: number,fieldId){
    this.api.dynamicFieldMultipleLevelDelete(choice,level).subscribe((succes) => {
      //  this.configurator.choiceMultipleConfigurator.removeChoiceOnIndex(index);
      //  this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
      console.log(choice , )
      // this.getLevelWiseFields(this.configurator.focusedField.fieldData.identifier , 1)
      // this.getLevelWiseFields(fieldId,level)
      let field_id = {
        id:fieldId
      }
      let field_id_main = {
        identifier:fieldId
      }
      if(level == 1){
        this.getLevelOneFields(this.configurator.focusedField.fieldData, level)
      }
      if(level == 2){
        this.getLevelTwoFields(field_id , level)
      }
      if(level == 3){
        this.getLevelThreeFields(field_id,level)
      }
      if(level == 4){
          this.getLevelFourFields(field_id ,level)
      }
     }, (err) => {
      this.toaster.error(ErrorToaster(err))
     })
  }

  openDeleteFieldDialog(identifier: number, index: number){
    const dialogRef = this.matDialog.open(DeleteFieldDialogComponent, {
      panelClass: 'co-dialog-panel',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('Add field dialog was closed:', result);
      if (result) {
        this.removeDynamicField(identifier,index)
      }
    })
  }
  openDeleteChoiceDialog(identifier, index: number){
    const dialogRef = this.matDialog.open(DeleteFieldDialogComponent, {
      panelClass: 'co-dialog-panel',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('Add field dialog was closed:', result);
      if (result) {
        this.removeChoice(identifier,index)
      }
    })
  }
  openDeleteMultipleChoiceDialog(identifier, index: number){
    const dialogRef = this.matDialog.open(DeleteFieldDialogComponent, {
      panelClass: 'co-dialog-panel',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('Add field dialog was closed:', result);
      if (result) {
        this.removeMultipleChoice(identifier,index)
      }
    })
  }
  openDeleteMultiLevelDialog(identifier,level, index: number ,field_id){
    const dialogRef = this.matDialog.open(DeleteFieldDialogComponent, {
      panelClass: 'co-dialog-panel',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('Add field dialog was closed:', result);
      if (result) {
        this.removemultiLevelConfigurator(identifier,level,index,field_id)
      }
    })
  }

//  getLevelWiseFields(field_id , level){
//     console.log(this.configurator.focusedField ,"000");
//     // let field_id = this.fields[this.focusedFieldIndex].fieldData.identifier
//     this.api.getNestedDynamicFields(field_id ,level)
//     .subscribe((data)=>{
//       console.log(data,"ppppppp")
//       this.configurator.multiLevelConfigurator.fields = data
//       this.levelOneFields = data
//       this.createConfigurator(this.levelOneFields)
//     },(err)=>{
//       this.toaster.error(ErrorToaster(err))
//     })
    
//   }
  getLevelOneFields(field,level){
    console.log(field , "^^^^^^^")
    
    if(field.type == "multi-level"){
      this.labelOne = field
      console.log(this.labelOne ,"labelOne")
      // this.getLevelWiseFields(field.identifier,level)
      this.api.getNestedDynamicFields(field.identifier,level)
      .subscribe((data)=>{
        console.log(data,"ppppppp")
        this.levelOneFields = data
        // this.configurator.multiLevelConfigurator.fields = data
        this.levelTwoFields = undefined
        this.levelThreeFields = undefined
        this.levelFourFields = undefined
        this.labelTwo = undefined
        this.labelThree = undefined
        this.labelFour = undefined
        // this.levelOneFields = data
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
    }
    // else{
    //   // this.labelOne = field
    //   console.log(this.labelOne ,"labelOne")
    //   // this.getLevelWiseFields(field.identifier,level)
    //   this.api.getNestedDynamicFields(field,level)
    //   .subscribe((data)=>{
    //     console.log(data,"ppppppp")
    //     this.configurator.multiLevelConfigurator.fields = data
    //     this.levelTwoFields = undefined
    //     this.levelThreeFields = undefined
    //     this.levelFourFields = undefined
    //     this.labelTwo = undefined
    //     this.labelThree = undefined
    //     this.labelFour = undefined
    //     // this.levelOneFields = data
    //   },(err)=>{
    //     this.toaster.error(ErrorToaster(err))
    //   })
    // }
    // this.getLevelWiseFields(field,1)
  }
  getLevelTwoFields(field,level){
    console.log("%%%%%%%%%%%%%%%%%%%%",field,level)
    this.labelTwo = field
    console.log(this.labelTwo ,"labelTwo")
    // this.labelOne = field
    // if(field.focusedField.fieldData.type == "multi-level"){
      // this.getLevelWiseFields(field.focusedField.fieldData.identifier,level)
      this.api.getNestedDynamicFields(field.id,level)
      .subscribe((data)=>{
        console.log(data,"ppppppp")
        // this.configurator.multiLevelConfigurator.fields = data
        this.levelTwoFields = data
        this.levelThreeFields = undefined
        this.levelFourFields = undefined
        this.labelThree = undefined
        this.labelFour = undefined
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
    // }
    
  }
  getLevelThreeFields(field,level){
    console.log("%%%%%%%%%%%%%%%%%%%%",field,level)
    this.labelThree = field
      this.api.getNestedDynamicFields(field.id,level)
      .subscribe((data)=>{
        console.log(data,"ppppppp")
        this.levelThreeFields = data
        this.levelFourFields = undefined
        this.labelFour = undefined
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
    
  }
  getLevelFourFields(field,level){
    console.log("%%%%%%%%%%%%%%%%%%%%",field,level)
    this.labelFour = field
      this.api.getNestedDynamicFields(field.id,level)
      .subscribe((data)=>{
        console.log(data,"ppppppp")
        this.levelFourFields = data
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
    
  }
  getLevelFiveFields(field,level){
    this.api.getNestedDynamicFields(field.id,level)
      .subscribe((data)=>{
        console.log(data,"ppppppp")
        // this.levelFourFields = data
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
  }


  
}
