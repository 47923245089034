import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DdfConfiguratorForm } from '../../Configurators';
import { InAppLogger } from '../../../../general-utils/InAppLogger';
import { DynamicFieldTypes } from '../../../ddf.enums';
import { DynamicFieldConfigurator } from '../../Configurators.interfaces';
import { UserTeamList } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';

export interface FieldInfo {
  adder: DynamicFieldConfigurator;
}

@Component({
  selector: 'app-disposition-type-dialog',
  templateUrl: './add-field-dialog.component.html',
  styleUrls: ['./add-field-dialog.component.sass']
})
export class AddFieldDialogComponent implements OnInit {
  logger = new InAppLogger(AddFieldDialogComponent.name);
  fieldTypes = DynamicFieldTypes;
  adder: DynamicFieldConfigurator;
  required: boolean = false;
  unique: boolean = false;
  both: boolean = false;
  isRedioButtonNumber: boolean;
  field_length: number;
  teams: UserTeamList[] = [];
  selectedTeam = [];
  edit:boolean=true;
  constructor(
    public dialogRef: MatDialogRef<AddFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public fieldInfo: FieldInfo,
    private api: ApiService
  ) {
  }

  ngOnInit() {
    this.adder = this.fieldInfo.adder || DdfConfiguratorForm.buildSingleFieldConfgurator();
    this.logger.log(this.adder, ';;;;;;;;');
    this.getTeams();

  }
  getTeams() {
    this.api.userTeamList().subscribe((team: any) => {
      console.log(team, 'team+++++++++++++')
      this.teams = team

    })
  }

   toggleAllSelection(event) {
    // this.teams.id
    // console.log(event);
    if (this.selectedTeam.length == this.teams.length + 1 && this.selectedTeam.includes('All')) {
      this.selectedTeam = [];

    } else if (this.selectedTeam.includes('All')) {
      this.selectedTeam = ['All'];
      for (let i = 0; i < this.teams.length; i++) {
        console.log(this.teams[i].id);
        this.selectedTeam.push((this.teams[i].id).toString())
      }

      this.selectedTeam = [...this.selectedTeam]
      console.log(this.selectedTeam);

    } else {
      this.selectedTeam = [];
    }
    // console.log(this.teams.id);



  }
  onNoClick(): void {
    this.cancel();
  }

  add() {
    if (this.selectedTeam.includes('All')) {
      const index = this.selectedTeam.findIndex(x => x == 'All')
      this.selectedTeam.splice(index, 1)
      this.selectedTeam = [...this.selectedTeam]
    }
    // console.log(this.adder)
    if (this.adder.label.value && this.adder.type.value) {
      this.dialogRef.close({
        
        adder: this.adder,
        required: this.required,
        unique: this.unique,
        both: this.both,
        field_length: this.field_length,
        team: this.selectedTeam,
        edit:this.edit,

      });
      console.log(this.dialogRef.close,"optipoooo")
    }
  }

  cancel() {

    this.dialogRef.close();
  }
  markAsMandatory(checked: boolean) {
    this.required = checked

  }
  markAsUnique(checked: boolean) {
    this.unique = checked
  }
  markAsBoth(checked: boolean) {
    this.both = checked
  }
  markAsEdit(checked: boolean) {
    if(this.edit==false){
      this.edit = checked
    }else {
      this.edit = !checked
    }
    
  }


  radioChange(type) {
    console.log(type);
    if (type == 'number' || type == 'text') {
      this.isRedioButtonNumber = true
    } else {
      this.isRedioButtonNumber = false
    }

  }

  abc() {

    {
      console.log(this.selectedTeam);
      if (this.selectedTeam.length == this.teams.length && this.selectedTeam.includes('All')) {
        console.log('aala re');
        const index = this.selectedTeam.findIndex(x => x == 'All')

        this.selectedTeam.splice(index, 1)
        this.selectedTeam = [...this.selectedTeam]
      } else if (this.selectedTeam.length == this.teams.length && !this.selectedTeam.includes('All')) {
        console.log('aala re');
        this.selectedTeam.push('All')
        this.selectedTeam = [...this.selectedTeam]
      }
    }
  }
}
