import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircleOneRoutingModule } from './circle-one-routing.module';
import { SharedModule } from '../shared/shared.module';
import { UtilsModule } from '../code-modules/utils/utils.module';
import { CircleOneComponent } from './circle-one.component';
import { NavbarModule } from './navbar/navbar.module';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './app/components/dashboard/dashboard.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { OwlNativeDateTimeModule } from 'ng-pick-datetime/date-time/adapter/native-date-time.module';
import { OwlDateTimeModule } from 'ng-pick-datetime/date-time/date-time.module';
import { UserProfileComponent } from './app/components/user-profile/user-profile.component';
import { UsersComponent } from './app/components/users/users.component';
import { InviteDialogComponent } from './app/components/users/invite-dialog/invite-dialog.component';
import { UserDetailComponent } from './app/components/users/user-detail/user-detail.component';
import { CustomersComponent } from './app/components/customers/customers.component';
import { AccountDetailsComponent } from './app/components/customers/account-details/account-details.component';
import { ContactDetailsEditComponent } from './app/components/customers/contact-details-edit/contact-details-edit.component';
import { AddCustomerAccountDialogComponent } from './app/components/add-customer-account-dialog/add-customer-account-dialog.component';
import { AddCustomerContactDialogComponent } from './app/components/add-customer-contact-dialog/add-customer-contact-dialog.component';
import { CreateContactDialogComponent } from './app/components/create-contact-dialog/create-contact-dialog.component';
import { ContactAccountMappingDialogComponent } from './app/components/contact-account-mapping-dialog/contact-account-mapping-dialog.component';
import { ContactHistoryDialogComponent } from './app/components/contact-history-dialog/contact-history-dialog.component';
import { PhoneLoaderComponent } from './telephony/phone-loader/phone-loader.component';
import { DispositionDialogComponent } from './app/components/disposition-dialog/disposition-dialog.component';
import { WorkflowDialogComponent } from './app/components/workflow-dialog/workflow-dialog.component';
import { UpdateCustomerFormComponent } from './app/components/update-customer-form/update-customer-form.component';
import { GenerateLevelDialogComponent } from './app/components/generate-level-dialog/generate-level-dialog.component';
import { UpdateaccountformComponent } from './app/components/updateaccountform/updateaccountform.component';
import { EventNotificationComponent } from './app/components/event-notification/event-notification.component';
import { DatePipe } from '@angular/common';
import { UserNotificationComponent } from './app/components/user-notification/user-notification.component';
import { ChangeAssigneeDialogComponent } from './tickets/components/change-assignee-dialog/change-assignee-dialog.component';
import { FunnelComponent } from './app/components/dashboard/funnel/funnel.component';
import { BaseChartDirective } from 'ng2-charts';
import * as funnelPlugin from 'chartjs-funnel';
import { LineChartComponent } from './app/components/dashboard/line-chart/line-chart.component';
import { WordCloudComponent } from './app/components/dashboard/word-cloud/word-cloud.component';
import { HighchartsChartModule } from 'highcharts-angular';
// import { SentimentComponent } from './app/components/dashboard/sentiment/sentiment.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatDatepickerModule, MatFormFieldModule, MatNativeDateModule } from '@angular/material';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TicketLineGraphComponent } from './app/components/dashboard/ticket-line-graph/ticket-line-graph.component';
import { CampaignEventNotificationComponent } from './app/components/campaign-event-notification/campaign-event-notification.component';
import { CKEditorModule } from 'ng2-ckeditor';

import { ClientLoginPageComponent } from './app/components/client-login-page/client-login-page.component';
import { BreakPointRegistry, FlexLayoutModule, FlexStyleBuilder, LayoutAlignStyleBuilder, LayoutStyleBuilder, MediaMarshaller, PrintHook, StylesheetMap, StyleUtils, ɵMatchMedia } from '@angular/flex-layout';
import { CdkTableModule } from '@angular/cdk/table';
import { CampaignTemplateDialogComponent } from './app/campaign-template-dialog/campaign-template-dialog.component';
import { ClientProfileComponent } from './app/components/client-login-page/client-profile/client-profile.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HelpComponent } from './app/components/help/help.component';
import { OrderModule } from 'ngx-order-pipe';
import { TelephonyCallHistoryComponent } from './app/components/telephony-call-history/telephony-call-history.component';
import { ResetPasswordDialogComponent } from './app/components/users/reset-password-dialog/reset-password-dialog.component';
import { TicketDetailsAssigneeDialogComponent } from './tickets/components/ticket-details-assignee-dialog/ticket-details-assignee-dialog.component';
import { UpdatesCustomersFormComponent } from './app/components/updates-customers-form/updates-customers-form.component';
import { TaskReminderNotificationComponent } from './app/components/task-reminder-notification/task-reminder-notification.component';
import { MobileNumberMaskPipe } from '../code-modules/utils/pipes/mobile-num-masking.pipe';
// import { OrderModule } from 'ngx-order-pipe';

@NgModule({
    declarations: [
        CircleOneComponent,
        UserProfileComponent,
        UserDetailComponent,
        UsersComponent,
        InviteDialogComponent,
        CustomersComponent,
        AccountDetailsComponent,
        ContactDetailsEditComponent,
        AddCustomerContactDialogComponent,
        AddCustomerAccountDialogComponent,
        CreateContactDialogComponent,
        ContactAccountMappingDialogComponent,
        ContactHistoryDialogComponent,
        DashboardComponent,
        PhoneLoaderComponent,
        DispositionDialogComponent,
        WorkflowDialogComponent,
        UpdateCustomerFormComponent,
        GenerateLevelDialogComponent,
        UpdateaccountformComponent,
        EventNotificationComponent,
        UserNotificationComponent,
        ChangeAssigneeDialogComponent,
        FunnelComponent,
        LineChartComponent,
        WordCloudComponent,
        TicketLineGraphComponent,
        CampaignEventNotificationComponent,
        TaskReminderNotificationComponent,
        ClientLoginPageComponent,
        CampaignTemplateDialogComponent,
        ClientProfileComponent,
        HelpComponent,
        TelephonyCallHistoryComponent,
        ResetPasswordDialogComponent,
        TicketDetailsAssigneeDialogComponent,
        UpdatesCustomersFormComponent,

        MobileNumberMaskPipe
        // SentimentComponent
    ],

    imports: [
        CdkTableModule,
        UtilsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        CircleOneRoutingModule,
        SharedModule,
        NavbarModule,
        RouterModule,
        CommonModule,
        HighchartsChartModule,
        NgApexchartsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        NgxDaterangepickerMd.forRoot(),
        //infinte scroll
        InfiniteScrollModule,
        ChartsModule,
        Ng2GoogleChartsModule,
        FlexLayoutModule,
        CKEditorModule,

        CKEditorModule,

        FlexLayoutModule,
        PdfViewerModule,
        OrderModule,
    ],
    entryComponents: [
        InviteDialogComponent,
        AddCustomerContactDialogComponent,
        AddCustomerAccountDialogComponent,
        CreateContactDialogComponent,
        ContactAccountMappingDialogComponent,
        ContactHistoryDialogComponent,
        DispositionDialogComponent,
        WorkflowDialogComponent,
        UpdateCustomerFormComponent,
        UpdatesCustomersFormComponent,
        GenerateLevelDialogComponent,
        UpdateaccountformComponent,
        EventNotificationComponent,
        UserNotificationComponent,
        ChangeAssigneeDialogComponent,
        CampaignEventNotificationComponent, 
        TaskReminderNotificationComponent,
        CampaignTemplateDialogComponent,
        ResetPasswordDialogComponent,
        TicketDetailsAssigneeDialogComponent
    ],
    providers: [
        DatePipe,
        PrintHook,
        StyleUtils,
        StyleSheet,
        StylesheetMap,
        LayoutAlignStyleBuilder,
        LayoutStyleBuilder,
        FlexStyleBuilder,
        MediaMarshaller,
        ɵMatchMedia,
        BreakPointRegistry        
    ],
    exports: [
        MobileNumberMaskPipe
    ]
})


export class CircleOneModule {
    constructor() {
        BaseChartDirective.registerPlugin(funnelPlugin);
    }
}
