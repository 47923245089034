import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { ContactRead, TelephonycallHistory } from 'src/app/code-modules/api/interfaces/Customer/customer-api-interfaces';
import { UserProfileRead } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';
import { CustomerListService } from '../customers/customers.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-telephony-call-history',
  templateUrl: './telephony-call-history.component.html',
  styleUrls: ['./telephony-call-history.component.sass']
})


export class TelephonyCallHistoryComponent implements OnInit {
  userId: any;
   dataSource = new MatTableDataSource([]);
  subscribers: Array<Subscription> = []
  reportData: Array<TelephonycallHistory> = []
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  callType: any = ''
  contactsDisplayedColumns = ['name', 'campign_name','starttime','endtime', 'email', 'created', 'last_interaction', 'actions'];
  currentUser: UserProfileRead
  report_start_date: Date = new Date();
  report_end_date: Date = new Date();
  constructor(
    public confirmDialog: MatDialog,
    private api: ApiService,
    public context: AppContextService,
    private router: Router,
    private toaster: ToastrService,
  ) { }

  ngOnInit() {
    this.context.currentUser$.subscribe(user => {
      this.currentUser = user
    })
    this.dataSource = new MatTableDataSource([]);
    this.userId = ((this.currentUser['id']) ? this.currentUser['id'] : null);
    this.getCallhistory();
  }

  getCallhistory() {
   
      const start_date = this.getDateString(this.report_start_date)
      const end_date = this.getDateString(this.report_end_date)
      if (start_date && end_date) {
    const callList = this.api.getCallHistory(this.userId, this.callType,start_date, end_date).subscribe((report) => {
      this.dataSource = new MatTableDataSource(report);
      this.reportData = report
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    });
    this.subscribers.push(callList)
    console.log(callList, "contact");
   } else {
      this.toaster.info("Please select date")
    }
  }

  typeHandler() {
    this.getCallhistory();
  }

  dialCall(mobileNo) {
    $('#dialPadValue').val(mobileNo);
  }

  dateChanged() {
    if (this.report_start_date && this.report_end_date) {
      if (this.report_start_date <= this.report_end_date) {
        this.getCallhistory()
      } else {
        this.toaster.info("Invalid date range")
      }
    }
  }

  getDateString(date: Date) {
    const year = date.getFullYear();
    let month: number | string = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month
    let day: number | string = date.getDate();
    day = day < 10 ? "0" + day : day
    return year + "-" + month + "-" + day
  }
}
