import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../code-modules/authentication/auth.service';
import { ApiService } from '../../../code-modules/api/api-services/api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { loginSuccessUrl, clientLoginUrl } from '../../../code-modules/authentication/auth.urls';
import { WebSocketService } from '../../../code-modules/websocket/web-socket.service';
import { AppContextService } from '../../../code-modules/app-context/app-context.service';
import { Subscription } from 'rxjs';
import { ApiLoginRequest } from '../../../code-modules/api/interfaces/Tenant/tenant-api-interfaces';
import { ApiTenantService } from 'src/app/code-modules/api/api-services/api-tenant-service';
import { Phone } from 'src/app/circle-one/telephony/model/phone.model';
import { PhoneService } from 'src/app/circle-one/telephony/services/phone.service';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';

const enum ApiType {
  TENANT_SPECIFIC = 'tenenat specific',
  COMMON = 'common'
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit, OnDestroy {
  loggerName = '[/login]';
  hidePassword = true;
  loginForm: FormGroup;
  redirectSubscription: Subscription;
  redirectingToLogin = false;
  teams = []
  tenantCheck: boolean;
  login_type; setdata
  currentTenant = window.location.hostname.split('.')[0]
  // currentTenant ='meta'

  errorData: any;
  displayReleaseButton: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private api: ApiService,
    private toaster: ToastrService,
    public context: AppContextService,
    public tenantAPI: ApiTenantService,
    public phoneModel: Phone,
    public phoneApi: PhoneService,
    private socketConnection: WebSocketService
  ) {
  }

  browserName = (function () {
    var test = function (regexp) { return regexp.test(window.navigator.userAgent) }
    switch (true) {
      case test(/edg/i): return "Microsoft Edge";
      case test(/trident/i): return "Microsoft Internet Explorer";
      case test(/firefox|fxios/i): return "Mozilla Firefox";
      case test(/opr\//i): return "Opera";
      case test(/ucbrowser/i): return "UC Browser";
      case test(/samsungbrowser/i): return "Samsung Browser";
      case test(/chrome|chromium|crios/i): return "Google Chrome";
      case test(/safari/i): return "Apple Safari";
      default: return "Other";
    }
  })();

  ngOnInit() {
    // this.tenantCheck = window.location.hostname.split('.').includes('circleone')
    if (this.browserName.indexOf("Chrome") === -1) {
      alert("For better application experince please use chrome browser.");
    }

    console.log("currentTenant", this.currentTenant)
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      team: ['']
    });
  }

  loaddata() {
    const credentialsname: ApiLoginRequest = {
      username: this.loginForm.value.email,
      password: this.loginForm.value.password,
      tenant: this.currentTenant,
    };

    this.tenantAPI.authuser(credentialsname).subscribe((data) => {

      this.setdata = data.message;

      if (this.setdata = "Success") {
        this.tenantAPI.loadTenantTeams(this.loginForm.value.email, this.currentTenant)
          .subscribe((datateam) => {
            if (datateam.team.length) {
              this.login_type = "Team";
              this.teams = datateam.team;

            }
            if (datateam.accounts) {
              this.login_type = "Account";
              this.teams = datateam.accounts;
            }

          })
      }

    }, (err) => {

      console.log(err.status, "err.status");
      if (err.status == "401") {
        this.toaster.error('invalid_password');
        this.teams = null;


      }
      else {
        this.toaster.error('invalid_username');

      }


    })



    // this.tenantAPI.loadTenantTeams(this.loginForm.value.email, this.currentTenant)
    // .subscribe((data) => {
    //   if(data.team.length){
    //     this.login_type = "Team";
    //     this.teams = data.team;

    //   }
    //   if(data.accounts){
    //     this.login_type = "Account";
    //     this.teams = data.accounts;
    //   }

    // }) 


  }
  resetfuntion() {
    this.tenantAPI.loadTenantTeams(this.loginForm.value.email, this.currentTenant)
      .subscribe((data) => {
        if (data.team.length) {
          this.login_type = "Team";
          this.teams = data.team;

        }
        if (data.accounts) {
          this.login_type = "Account";
          this.teams = data.accounts;
        }

      })
  }

  submit() {
    localStorage.clear()
    if (this.loginForm.valid) {
      const credentials: ApiLoginRequest = {
        username: this.loginForm.value.email,
        password: this.loginForm.value.password,
        tenant: this.currentTenant,
        team: this.loginForm.value.team
      };
      localStorage.setItem('username', this.loginForm.value.email);
      localStorage.setItem('password', this.loginForm.value.password);
      localStorage.setItem('team_account', this.loginForm.value.team)
      this.auth.login(credentials).subscribe((data) => {
        if (data.login_type == "customer") {
          this.router.navigate([clientLoginUrl])
        }
        if (data.login_type == "user") {
          this.redirectSubscription = this.context.contextReady.subscribe(ready => {
            if (ready === true) {
              this.router.navigate([loginSuccessUrl]);
            }
          })
        }
      }, (err) => {
        
        if (err.status === 400) {
          console.log(err, 'error');
          if (err.error.team[0] == "Already logged in") {
            this.displayReleaseButton = true;
          }
          this.errorData = err;
          console.log( this.errorData,"this.errorData")

          // this.toaster.error('Already logged in');
        } else {
          this.toaster.error(ErrorToaster(err))

        }




      });
    }
  }

  // loadTeams is use to populate teams options
  // loadTeams() {

  //   console.clear()
  //   if (this.loginForm.value.email && this.loginForm.value.password) {
  //     this.tenantAPI.loadTenantTeams(this.loginForm.value.email, this.currentTenant)
  //       .subscribe((data) => {
  //         if(data.team.length){
  //           this.login_type = "Team";
  //           this.teams = data.team;

  //         }
  //         if(data.accounts){
  //           this.login_type = "Account";
  //           this.teams = data.accounts;
  //         }

  //       },(err)=>{

  //       })
  //   } else {

  //   }
  // }
  
  getAgentRelaseSession() {
    if (this.errorData && this.errorData.error.user_id[0]) {
      console.log(this.errorData, this.errorData.error.user_id[0],"this.errorData.error.id[0]")
      this.api.releaseSession(this.errorData.error.user_id[0]).subscribe(
        (data) => {
          console.log(data);
          // Display success message if needed
          this.displayReleaseButton = false;
          this.toaster.success('Session released successfully.Please Login again');

          // Perform necessary actions after releasing session
          // For example, re-login or any other operation
          // this.auth.logout();
          // Perform login logic again
          this.loginForm.reset()

          this.submit(); // Be cautious about recursion; ensure it fits your use case

        },
        (err) => {
          console.error('Error releasing session:', err);
          // this.toaster.error(ErrorToaster(err));
        }
      );
    } else {
      console.error('No error data or ID available');
    }
  }





  ngOnDestroy() {
    if (this.redirectSubscription) {
      this.redirectSubscription.unsubscribe()
    }
  }
}
