import {enumAsList} from '../general-utils/enum-utils';

export enum DynamicFieldTypes {
  TEXT = 'text',
  EMAIL = 'email',
  NUMBER = 'number',
  TELEPHONE = 'tel-phone',
  TEXT_AREA = 'text-area',
  DROP_DOWN = 'drop-down',
  CHECKBOX = 'check-box',
  NESTED = 'multi-level',
  DATETIME = 'date-time',
  DATE = 'date',
  TIME = 'time'
  // RADIOBUTTON = 'radio-button',


  
}

const FieldNames = {
  [DynamicFieldTypes.NESTED]: 'Nested',
  [DynamicFieldTypes.DROP_DOWN]: 'Drop Down',
  [DynamicFieldTypes.TEXT]: 'Text',
  [DynamicFieldTypes.NUMBER]: 'Number',
  [DynamicFieldTypes.TEXT_AREA]: 'Text Area',
  [DynamicFieldTypes.EMAIL]: 'Email',
  [DynamicFieldTypes.DATE]: 'Date',
  [DynamicFieldTypes.CHECKBOX]: 'Check Box',
  // [DynamicFieldTypes.RADIOBUTTON]:'Radio button',
  [DynamicFieldTypes.TELEPHONE]:'Telephone',
  [DynamicFieldTypes.TIME]:'Time',
  [DynamicFieldTypes.DATETIME]:'Date-Time'

};
export namespace DynamicFieldTypes {
  export function all() {
    return enumAsList<DynamicFieldTypes>(DynamicFieldTypes);
  }

  export function displayName(fieldType: DynamicFieldTypes): string {
    return FieldNames[fieldType];
  }
}

export enum DynamicFieldConfigurationTypes {
  DISPOSITION = 'disposition',
  USER_PROFILE = 'userprofile',
  CUSTOMER_ACCOUNT = 'account',
  CUSTOMER_CONTACT = 'contact',
  TICKET = 'ticket',
  TICKET_NAME = 'ticket_name',
  LEAD_ACCOUNT = 'lead-account',
  LEAD_CONTACT = 'lead-contact',
  OPPORTUNITY = 'opportunity',
  CAMPAIGN = 'campaign',
  ORDER = 'order',

}

export const DynamicFieldConfigurationHeader = {
  [DynamicFieldConfigurationTypes.CUSTOMER_ACCOUNT] : "Customer Account Fields",
  [DynamicFieldConfigurationTypes.CUSTOMER_CONTACT] : "Customer Contact Fields",
  [DynamicFieldConfigurationTypes.USER_PROFILE] : "User Profile Fields",
  [DynamicFieldConfigurationTypes.TICKET] : "Ticket Fields",
  [DynamicFieldConfigurationTypes.TICKET_NAME] : "Ticket Name Fields",
  [DynamicFieldConfigurationTypes.LEAD_ACCOUNT] : "Lead Account Fields",
  [DynamicFieldConfigurationTypes.LEAD_CONTACT] : "Lead Contact Fields",
  [DynamicFieldConfigurationTypes.OPPORTUNITY] : "Opportunity Fields",
  [DynamicFieldConfigurationTypes.CAMPAIGN] : "Campaign Fields",
  [DynamicFieldConfigurationTypes.ORDER] : "Order/Invoice Fields"


}

export namespace DynamicFieldConfigurationTypes {
  export function all() {
    return enumAsList<DynamicFieldConfigurationTypes>(DynamicFieldConfigurationTypes);
  }
}


export enum DynamicFieldValidators {
  REQUIRED = 'required',
  UNIQUE='unique',
  EDITTABLE='edittable'
  
}
