import { Component, Input, OnInit } from '@angular/core';
import { DataDrivenField, DdfFieldData } from '../ddf.interfaces';

@Component({
  selector: 'app-dd-label',
  templateUrl: './dd-label.component.html',
  styleUrls: ['./dd-label.component.sass']
})
export class DdLabelComponent implements OnInit {
  @Input() field: DataDrivenField;

  constructor() {
  }

  ngOnInit() {

  }

}
