



import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {AuthService} from '../../../code-modules/authentication/auth.service';
import {ApiService} from '../../../code-modules/api/api-services/api.service';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ToastrService} from 'ngx-toastr';
import {loginSuccessUrl,changePasswordUrl,loginUrl,logoutUrl} from '../../../code-modules/authentication/auth.urls';
import {WebSocketService} from '../../../code-modules/websocket/web-socket.service';
import { AppContextService } from '../../../code-modules/app-context/app-context.service';
import { ErrorStateMatcher } from '@angular/material';
import { LoaderService } from 'src/app/code-modules/utils/loader/loader.service';
import {ApiChangePassword, ApiLoginRequest} from '../../../code-modules/api/interfaces/Tenant/tenant-api-interfaces';
import { ApiTenantService } from 'src/app/code-modules/api/api-services/api-tenant-service';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})

export class ChangePasswordComponent implements OnInit {
  loggerName = '[/login]';
  hidePassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;
  loginForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    // private api : ApiService,
    private api: ApiService,

    private router: Router,
    ////private loader: LoaderService,
    private toaster: ToastrService,
    private socket: WebSocketService,
    public context: AppContextService
  ) {
  }

  ngOnInit() {
    //this.loader.start();
    this.loginForm = this.formBuilder.group({
      newPassword: ['', [Validators.required,Validators.minLength(6)]],
      currentPassword: ['', [Validators.required]],
      confirmPassword : ['']}, {validator: [this.checkPasswords,this.checkOldPassword]
    });
    //this.loader.stop();
  }

  submit() {
    if(this.loginForm.valid){
    //this.loader.start();
    const credentials: ApiChangePassword = {
      old_password: this.loginForm.value.currentPassword,
      new_password: this.loginForm.value.newPassword
    };
    console.log(credentials)
    this.api.changePassword(credentials).subscribe((date) => {
      this.toaster.success("Password updated");
      this.api.logout().subscribe((data) => {
        console.log(data)
        this.router.navigate([loginUrl]);
        // this.auth.logout()
      })
      // this.socket.disconnect();
      // this.auth.logout();
      //this.loader.stop();

    },(err) => {
      this.toaster.error(ErrorToaster(err))
      // this.toaster.error("Something went wrong");
      //this.loader.stop();
    })
  }
  }

  checkOldPassword(group: FormGroup) { // here we have the 'passwords' group
  let pass = group.get('newPassword').value;
  let currentPass = group.get('currentPassword').value;

  return pass !== currentPass ? null : { oldPass: true }     
}
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  let pass = group.get('newPassword').value;
  let confirmPass = group.get('confirmPassword').value;

  return pass === confirmPass ? null : { notSame: true }     
}

}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty && control.touched);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty && control.touched);

    return (invalidCtrl || invalidParent);
  }
}
