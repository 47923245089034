import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../../authentication/auth.service';
import { tap, catchError } from 'rxjs/operators';
import { WebSocketService } from '../../websocket/web-socket.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ApiResponseInterceptor implements HttpInterceptor {
  private loggerName = '[ApiResponses] ';
  constructor(private auth: AuthService, private socketConnection: WebSocketService,
    public toaster:ToastrService) {
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // console.log(`${this.loggerName} Api response from ${request.url}: `, event.body);
        }
      }),
      catchError(event => {
        // console.log('checking for auth status', event)
        if (event instanceof HttpErrorResponse) {
          if (event.status === 401) {
            // console.log('Unauthorized: logging out')
            
            this.socketConnection.disconnect();
            this.auth.logout();
          }
        }
        return throwError(event)
      })
    );
  }
}
