import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullNamePipe } from './pipes/full-name.pipe';
import { DdInputComponent, NumberTextDirective } from './data-driven-forms/dd-input/dd-input.component';
import { DdLabelComponent } from './data-driven-forms/dd-label/dd-label.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DdfConfiguratorComponent } from './data-driven-forms/Configuration/ddf-configurator/ddf-configurator.component';
import { AddChoiceDialogComponent } from './data-driven-forms/Configuration/ddf-configurator/add-choice-dialog/add-choice-dialog.component';
import {
  MAT_DIALOG_DATA,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule, MatIconModule,
  MatInputModule,
  MatOptionModule, MatRadioModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  MatDividerModule,
  MatBadgeModule

} from '@angular/material';
import { AddFieldDialogComponent } from './data-driven-forms/Configuration/ddf-configurator/add-field-dialog/add-field-dialog.component';
import { RouterModule } from '@angular/router';
import { DispositionTreeComponent } from './disposition-tree/disposition-tree.component';
import { EmailFilterPipe } from './pipes/email-filter';
import { TimeZoneFilterPipe } from './pipes/timeZone-filter';
import { FocusDirective } from './directives/focus.directive';
import { HasModuleViewAccessPipe } from './pipes/has-module-view-access.pipe';
import { AdminPrivilegeDirective } from './directives/admin-privilege.directive';
import { AdminOnlyDirective } from './directives/admin-only';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DeleteFieldDialogComponent } from './data-driven-forms/Configuration/ddf-configurator/delete-field-dialog/delete-field-dialog.component';
import { TicketDdfConfiguratorComponent } from './data-driven-forms/Configuration/ticket-ddf-configurator/ticket-ddf-configurator.component';
import { CampaignDdfConfiguratorComponent } from './data-driven-forms/Configuration/campaign-ddf-configurator/campaign-ddf-configurator.component';
import { OwlNativeDateTimeModule } from 'ng-pick-datetime/date-time/adapter/native-date-time.module';
import { OwlDateTimeModule } from 'ng-pick-datetime/date-time/date-time.module';

import { Ng2TelInputModule } from 'ng2-tel-input';
import { MarkAsteriskDirective } from './directives/mark-asterisk.directive';
import { ReleaseSessionComponent } from './components/release-session/release-session.component';
import { AddCampaignFieldDialogComponent } from './data-driven-forms/Configuration/ddf-configurator/add-campaign-field-dialog/add-campaign-field-dialog.component';
import { InvoiceOrderDdfConfiguratorComponent } from './data-driven-forms/Configuration/invoice-order-ddf-configurator/invoice-order-ddf-configurator.component';
import { SpecialCharPipe } from './pipes/special-char.pipe';
import { DddInputComponent } from './data-driven-forms/ddd-input/ddd-input.component';

@NgModule({
  declarations: [
    FullNamePipe,
    DdInputComponent,
    DdfConfiguratorComponent,
    TicketDdfConfiguratorComponent,
    AddChoiceDialogComponent,
    DeleteFieldDialogComponent,
    AddFieldDialogComponent,
    DdLabelComponent,
    NumberTextDirective,
    DispositionTreeComponent,
    EmailFilterPipe,
    TimeZoneFilterPipe,
    HasModuleViewAccessPipe,
    //directive
    FocusDirective,
    AdminPrivilegeDirective,
    AdminOnlyDirective,
    SafeHtmlPipe,
    DeleteFieldDialogComponent,
    CampaignDdfConfiguratorComponent,
    MarkAsteriskDirective,
    ReleaseSessionComponent,
    AddCampaignFieldDialogComponent,
    InvoiceOrderDdfConfiguratorComponent,
    SpecialCharPipe,
    DddInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatIconModule,
    MatDialogModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatBadgeModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,

    Ng2TelInputModule,

  ],
  exports: [
    FullNamePipe,
    EmailFilterPipe,
    DdInputComponent,
    DdLabelComponent,
    DdfConfiguratorComponent,
    TicketDdfConfiguratorComponent,
    CampaignDdfConfiguratorComponent,
    DispositionTreeComponent,
    TimeZoneFilterPipe,
    HasModuleViewAccessPipe,
    SafeHtmlPipe,
    ReleaseSessionComponent,
    //directives
    FocusDirective,
    AdminPrivilegeDirective,
    AdminOnlyDirective,
    InvoiceOrderDdfConfiguratorComponent,
    SpecialCharPipe,
    DddInputComponent,
  ],
  entryComponents: [
    AddChoiceDialogComponent,
    AddFieldDialogComponent,
    DeleteFieldDialogComponent,
    ReleaseSessionComponent,
    AddCampaignFieldDialogComponent
  ],
  providers: [
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    }, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class UtilsModule {
}
