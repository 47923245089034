import { Pipe, PipeTransform } from '@angular/core';
import {UserProfileRead} from '../../../code-modules/api/interfaces/User/user-api-interfaces';

@Pipe({
  name: 'filterUsers'
})

export class UserFilterPipe implements PipeTransform {

  transform(userList: Array<UserProfileRead>, depId: number, groupId: number): Array<object> {

   

    if (!userList || (!groupId && !depId)) {
      // console.log('result:', userList)
      return userList;
    } else if (userList.length > 0) {
     
      if (groupId) {
        userList = userList.filter(user => (user.teams || []).find(grp => grp.id === Number(groupId)));
      }
      else if (depId) {
        // userList = userList.filter(usr =>
          // (usr.teams  || []).find(grp => (grp && grp === Number(depId)))
          // );
      }
      // console.log('result:', userList)
      return userList;
    } else {
      // console.log('result:', userList)
      return userList;
    }


  }
}
