import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.sass']
})
export class HelpComponent implements OnInit {

  constructor() { }

  pdfSrc

  ngOnInit() {

    this.pdfSrc = "";

    if (window.location.hostname.split('.')[1] == 'localhost') {

      this.pdfSrc = "http://localhost:8000/media/support.pdf"

    }else if (window.location.hostname.split('.')[2] == 'org') {

      this.pdfSrc = "https://bluewhirl.org/media/support.pdf"

    }else if (window.location.hostname.split('.')[2] == 'io') {

      this.pdfSrc = "https://bluewhirl.io:8000/media/support.pdf"
    }
  }


  openUrl() {
    window.open("src/assets/pdf/support.pdf")

  }





}
