import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AutoUnsubscribeComponentBase } from '../../../code-modules/utils/components/auto-unsubscribe.component';
//import {ApiService} from '../../../code-modules/api/api.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/code-modules/utils/loader/loader.service';
import { signUpSuccessUrl } from '../../../code-modules/authentication/auth.urls';
import { FormGroup, FormControl, Validators } from '@angular/forms';
//import { SignupFormService } from '../../services/api/store/api/signup/signup.service';
//import { ApiService } from '../../services/api/controllers/Api';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material';
import { TermsConditionsDialogComponent } from './terms-conditions-dialog/terms-conditions-dialog.component';
import { ApiTenantService } from 'src/app/code-modules/api/api-services/api-tenant-service';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.sass']
})
export class SignupComponent extends AutoUnsubscribeComponentBase implements OnInit {
  loggerName = '[/signup]';
  signupFormData: FormGroup
  environmentName: string = "";
  saasDomain = environment.saasDomain
  agreed = false;

  constructor(
    private router: Router,
    private api: ApiTenantService,
    private toaster: ToastrService,
    ////private loader: LoaderService,
    public addKnowledgeDialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    if (environment.mode != ".prod")
      this.environmentName = environment.mode;
    this.signupFormData = new FormGroup({
      sub_domain: new FormControl(undefined, [Validators.maxLength(256), Validators.minLength(1), Validators.required]),
      admin_username: new FormControl(undefined, [Validators.email, Validators.maxLength(254), Validators.minLength(1), Validators.required]),
      agreed: new FormControl(false, [Validators.required]),
      admin_phone: new FormControl(undefined,[Validators.required,
        Validators.pattern('^-?[0-9]*$'),
        Validators.minLength(10),
        Validators.maxLength(10)])
    })
  }

  get fControls() {
    return this.signupFormData.controls
  }


  signup() {
    if (this.signupFormData.valid && this.signupFormData.controls.agreed.value) {
      //this.loader.start();
      const sub_domain:string=this.signupFormData.controls.sub_domain.value;
      this.signupFormData.controls.sub_domain.setValue(sub_domain.toLocaleLowerCase());
      console.log(this.signupFormData.value,"requestData")
      this.api.signUpAdmin(this.signupFormData.value).subscribe((success) => {
        //this.loader.stop();
        this.router.navigate(['/verificationSuccess', { action: "verify" }], { skipLocationChange: true });
      }, (err) => {
        //this.loader.stop();
        this.toaster.error(ErrorToaster(err))
        if (err && err.status && err.status === 400) {
          if (err.error.admin_username) {
            let email: string = this.signupFormData.value.admin_username;
            email = email.split('@')[1];
            this.toaster.error(`Domain ${email} is not available ,Please choose another domain`);
          }
          else if (err.error.sub_domain && !this.signupFormData.controls.agreed.value) {
            this.toaster.error('Please choose another name', ` Team name '${this.signupFormData.value.sub_domain}' is already taken`);
          }
        }
        else{
          this.toaster.error(ErrorToaster(err))
        }

      })
    } else {
      if (this.signupFormData.valid )
        this.toaster.info("Please read and accept the terms and conditions")
    }



  }

  getErrorMessage(): { admin_username: string; sub_domain: string;admin_phone:string } {
    if (this.signupFormData.invalid) {
      return {
        admin_username: (this.fControls.admin_username.errors) ?
          (this.fControls.admin_username.errors.email) ?
            'Invalid email' : (this.fControls.admin_username.errors.required) ?
              'This field is required' : '' : '',
        sub_domain: (this.fControls.sub_domain.errors) && (this.fControls.sub_domain.errors.required) ?
          'This field is required' : '',
          admin_phone : (this.fControls.admin_phone.errors) ? '10 digit phone number required' : ''
      }
        ;
    } else {
      return {
        admin_username: '',
        sub_domain: '',
        admin_phone : ''
      };
    }
  }

  TermsConditions(): void {
    const dialogRef = this.addKnowledgeDialog.open(TermsConditionsDialogComponent, {
      panelClass: 'co-dialog-panel'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  
}
