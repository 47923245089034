import { formatIndexedString } from '../../utils/general-utils/string-manip';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../authentication/auth.service';
import { AppContextService } from '../../app-context/app-context.service';
import { IfLoggedIn } from '../../authentication/auth.guard.ifLoggedIn';
import { LoginComponent } from 'src/app/common-modules/components/login/login.component';

const enum ApiType {
  TENANT_SPECIFIC = 'tenenat specific',
  COMMON = 'common'
}

export class ApiReference {
  static apiReference: { [apiName: string]: string } = {
    adminSignUp: '/v1/signup/',

    // tenantAvailability: '/username-availability?username={1}',
    // // tenantDetails: '/tenant/details',
    // domainAvailability: '/sub-domain-availability?subDomain={1}',

    login: '/v1/auth/',
    refreshAccessToken: '/refresh-tokens',
    forgotPassword: '/v1/users/forgot-password/',
    resetPassword: '/v1/users/reset-password/',
    changePassword: '/v1/users/change-password/',

    currentUser: '/v1/users/me/',

    profileUpdate: '/v1/users/users/{1}/',
    loadTenantTeams: '/v1/auth/teams/{1}/{2}/',
    //User API
    userUpdate: '/v1/users/users/{1}/',
    userList: '/v1/users/users/',
    userInvite: '/v1/users/users/',
    userTeamList: '/v1/users/teams/',
    getDefaultModulePermissions: '/v1/users/teams/team_default_permission/{1}/',
    user: '/v1/users/users/{1}/',
    userDelete: '/v1/users/users/{1}/',
    userPermissionsUpdate: '/users/{1}',
    userDepartmentList: '/v1/users/teams/',
    userDepartment: '/v1/users/departments/{1}/',
    userTeamCreate: '/v1/users/teams/',
    userTeamUpdate: '/v1/users/teams/{1}/',
    userDepartmentDelete: '/v1/users/teams/{1}/',
    userGroupList: '/v1/users/groups/{1}/',
    userAllGroupList: '/v1/users/groups/',
    userGroupCreate: '/v1/users/groups/',
    userGroupUpdate: '/v1/users/groups/{1}/',
    userGroupDelete: '/v1/users/groups/{1}/',
    userTeamWiseList: '/v1/users/user-list/{1}/',
    loadSingleUserTeamList: '/v1/users/teams/{1}/',
    getTeamDataByName: '/v1/users/teams/',
    archieveUserUpdate: '/v1/users/user-enable-disable/{1}/',
    adminResetPassword:'/v1/users/reset-password/{1}/',

    //Customer API
    clientDashboard: '/v1/customers/client-dashboard/',
    clientDetails: '/v1/customers/client-profile/',
    clientProfileUpdate: '/v1/customers/client-profile/',
    customerTagCreate: '/v1/customers/tags/',
    customerTagList: '/v1/customers/tags/',
    customerTag: '/v1/customers/tags/{1}/',
    customerTagUpdate: '/v1/customers/tags/{1}/',
    customerTagDelete: '/v1/customers/tags/{1}/',
    customerHistory: '/v1/customers/actions/',
    actionMerge: '/v1/customers/actions/{1}/merge/',
    customerAction: '/v1/customers/actions/{1}/',
    accountDetails: '/v1/customers/accounts/{1}/',
    accountCreate: '/v1/customers/accounts/',
    accountUpdate: '/v1/customers/accounts/{1}/',
    accountDelete: '/v1/customers/accounts/{1}/',
    accountSearch: '/v1/customers/account-search/{1}',
    contactSearchNew: '/v1/customers/contact-search/{1}',
    contactUnderAccount: '/Customer-accounts/{1}/contacts',
    contactList: '/v1/customers/contacts/',
    contactSearch: '/v1/customers/contacts/search-by-email/',

    // contactCreate: '',
    contactDetails: '/v1/customers/contacts/{1}/',
    contactCreate: '/v1/customers/contacts/', // this is used for both single create

    contactAvailability: '/v1/customers/contacts/lookup-by-email/',

    contactBulkCreate: '/v1/customers/contacts/bulk-upload/',
    userBulkCreate: '/v1/users/users/bulk-upload/',

    contactUpdate: '/v1/customers/contacts/{1}/',
    contactDelete: '/v1/customers/contacts/{1}/',

    accountList: '/v1/customers/accounts/',


    accountBulkCreate: '/v1/customers/accounts/bulk-upload/',

    dispositions: '/v1/common/disposition/',
    updateDisposition: '/v1/common/disposition/{1}',
    // getTeamWiseDisposition:'/v1/common/disposition/{1}',
    createDisposition: '/v1/common/disposition/{1}',
    editDisposition: '/v1/common/disposition/{1}',
    removeDisposition: '/v1/common/disposition/{1}/',
    ticketNameLookup: '/v1/common/disposition/{1}/lookup_by_disposition/',
    dynamicFieldList: '/v1/common/dynamic-field-definitions/',
    dynamicFieldCreate: '/v1/common/dynamic-field-definitions/',
    dynamicField: '/v1/common/dynamic-field-definitions/{1}/',
    dynamicFieldUpdate: '/v1/common/dynamic-field-definitions/{1}/',
    dynamicFieldDelete: '/v1/common/dynamic-field-definitions/{1}/',
    dashBoardStatistics: '/v1/common/dashboard/workflow/',
    dynamicFiledChoiceUpdate: '/v1/common/dynamic-field-choices/{1}/',
    dynamicFiledChoiceDelete: '/v1/common/dynamic-field-choices/{1}/',
    getNestedDynamicFields: '/v1/common/dynamic-field-get-multi_level/{1}/{2}',
    getSubLevelNestedDynamicFields: '/v1/common/dynamic-field-multi_level/{1}/{2}',
    dynamicFiledChoiceCreate: '/v1/common/dynamic-field-choices/',
    dynamicFieldCheckBoxChoiceCreate: '/v1/common/dynamic-field-checkbox_multiple_choices/',
    dynamicFieldMultipleLevelCreate: '/v1/common/dynamic-field-multi_level/{1}',
    dynamicFieldCheckBoxChoiceDelete: '/v1/common/dynamic-field-checkbox_multiple_choices/{1}/',
    dynamicFieldMultipleLevelDelete: '/v1/common/dynamic-field-multi_level/{1}/{2}',
    businessHours: '/v1/common/business-hours/',
    setBusinessHours: '/v1/common/business-hours/',
    createImapConfiguration: '/v1/common/email-server-configuration/',
    createSmptConfiguration: '/v1/common/smtp-server-configuration/',
    getSmtpConfig: '/v1/common/smtp-server-configuration/{1}/',
    getEmailServerConfiguration: '/v1/common/email-server-configuration/team/',
    getImapServerConfiguration: '/v1/common/email-server-configuration/team-config/{1}',
    updateAutoResponseSmtp: '/v1/common/smtp-server-configuration/{1}/',
    deleteSmtpConfiguration: '/v1/common/smtp-server-configuration/{1}/',
    deleteImapConfiguration: '/v1/common/email-server-configuration/{1}/',
    updateSmtpServer: '/v1/common/smtp-server-configuration/{1}/',
    updateImapServer: '/v1/common/email-server-configuration/{1}/',
    getNotifications: '/v1/common/notification/get_notification/{1}/{2}/{3}/',
    updateNotification: '/v1/common/notification/{1}/',
    createNotifications: '/v1/common/notification/',
    dashboardApi: '/v1/common/dashboard/',
    dashboardChannelCount: '/v1/common/ticket-channel-count/',
    getWordCloudCount: '/v1/common/wordslist/',
    getEmailStatusCount: '/v1/common/email-status/',

    // Client Login
    createClientLogin: '/v1/customers/account_login/',
    getAllClientLogin: '/v1/customers/account_login/',
    getSingleClientLogin: '/v1/customers/account_login/{1}/',
    updateSingleClientLogin: '/v1/customers/account_login/{1}/',
    deleteSingleClientLogin: '/v1/customers/account_login/{1}/',
    //Email API
    composeMail: '/v1/emails/threads/',
    emailListThread: '/v1/emails/threads/',
    getEmailCount: '/v1/emails/bin-count/',
    getIndividualEmailCount: '/v1/emails/bin-count/{1}',
    getTicketCount: '/v1/tickets/bin-count/',
    getTicketCountWorkflowWise: '/v1/tickets/bin-count/workflow/{1}/',
    getIndividualTicketCount: '/v1/tickets/bin-count/{1}/{2}',
    getEmailThread: '/v1/emails/threads/{1}/',
    emailReply: '/v1/emails/threads/{1}/reply/',
    multipleThreadAssign: '/v1/emails/bulk-assignment/',

    deleteEvent: '/v1/tickets/ticket-event/{1}/',
    replyAllEmailIds: '/v1/emails/reply-all-email-list/{1}/{2}',
    replyEmailIds: '/v1/emails/reply-email-list/{1}/{2}',
    // Ticket API
    listTickets: '/v1/tickets/ticket/',
    createTicket: '/v1/tickets/ticket/',
    getTciketDetails: '/v1/tickets/ticket/{1}/',
    ticketWorkFlowList: '/v1/tickets/ticket-name/',
    campWorkFlowList: '/v1/common/campaign-name/',
    orderWorkflowList:'/v1/common/order-name/',
    orderWorkflowListStatus:'/v1/common/order-name/',
    getLeadOrderDataInExcel:'/v1/campaign/campaign-order/',

    teamWiseTicketWorkFlowList: '/v1/tickets/ticket-name/{1}/',
    createCustomerNote: '/v1/tickets/customer-note/',
    createInternalNote: '/v1/tickets/internal-note/',
    updateTicket: '/v1/tickets/ticket/{1}/',
    addEventData: '/v1/tickets/ticket-event/',
    listEventData: '/v1/tickets/ticket-event/',
    singleEventDetails: '/v1/tickets/ticket-event/{1}/',
    updateEvent: '/v1/tickets/ticket-event/{1}/',
    multipleTicketsAssign:'/v1/tickets/bulk-assignment/',
    shareTicket: '/v1/tickets/ticket/{1}/share/',
    printTicket: '/v1/tickets/ticket/{1}/print_ticket/',
    getTicketAuditTrailList: '/v1/tickets/ticket-audit-trail/{1}/',
    getTicketAuditTrailDetails: '/v1/tickets/ticket-audit-trail/get_details/{1}/',
    createTicketScheduler: '/v1/tickets/auto-ticket-scheduler/',
    getTicketScheduler: '/v1/tickets/auto-ticket-scheduler/scheduler_list/{1}/',
    deleteTicketScheduler: '/v1/tickets/auto-ticket-scheduler/{1}',
    getSingleTicketScheduler: '/v1/tickets/auto-ticket-scheduler/{1}',
    editSingleTicketScheduler: '/v1/tickets/auto-ticket-scheduler/{1}/',
    getTicketRules: '/v1/tickets/ticket-rules/{1}/',
    updateTicketRules: '/v1/tickets/ticket-rules/{1}/',
    ticketAllocationLimit: '/v1/tickets/auto-allocation-rules/',
    setTicketAllocationLimit: '/v1/tickets/auto-allocation-rules/',
    updateTicketAllocationLimit: '/v1/tickets/auto-allocation-rules/{1}/',
    deleteTicketAllocationLimit: '/v1/tickets/auto-allocation-rules/{1}/',
    updateEmailThread: '/v1/emails/threads/{1}/',

    updateTicketPriorityRule: '/v1/ticket-priority-rule/',
    getCyeretDynamicFieldValue: '/v1/tickets/get-cyret-ticket-dynamic-fields/{1}/{2}/',

    // Ticket access rights
    createTicketAccessRight: '/v1/tickets/ticket-access-control/',
    updateTicketAccessRight: '/v1/tickets/ticket-access-control/{1}/',
    getTicketAccessRightList: '/v1/tickets/ticket-access-control/',
    getSettingsTicketAccessRightList: '/v1/tickets/ticket-access-control-get/',

    // workflow / ticket name
    createTicketWorkFlow: '/v1/tickets/ticket-name/',
    createCampWorkFlow: '/v1/common/campaign-name/',
    createOrderInvoiceWorkflow:'/v1/common/order-name/',
    // ticketWorkFlowList: '/v1/tickets/ticket-name/',
    updateTicketWorkFlow: '/v1/tickets/ticket-name/{1}/',
    updateCampWorkFlow: '/v1/common/campaign-name/{1}/',
    updateOrderWorkflow:'/v1/common/order-name/{1}/',
    deleteTicketWorkFlow: '/v1/tickets/ticket-name/{1}/',
    deleteCampWorkFlow: '/v1/common/campaign-name/{1}/',
    deleteOrderWorkflow:'/v1/common/order-name/{1}/',
    getTicketWorkFlow: '/v1/tickets/ticket-name/{1}/',
    ticketInprogressStatusList: '/v1/tickets/ticket-in-progress-status/',
    ticketInprogressStatusCreate: '/v1/tickets/ticket-in-progress-status/',
    campInprogressStatusCreate: '/v1/common/campaign-progress-status/',
    orderInprogressStatusCreate:'/v1/campaign/order-progress-status/',
    ticketInprogressStatus: '/v1/tickets/ticket-in-progress-status/{1}/',
    ticketInprogressStatusUpdate: '/v1/tickets/ticket-in-progress-status/{1}/',
    CampInprogressStatusUpdate: '/v1/common/campaign-progress-status/{1}/',
    orderInprogressStatusUpdate:'/v1/campaign/order-progress-status/{1}/',
    ticketInprogressStatusDelete: '/v1/tickets/ticket-in-progress-status/{1}/',
    campInprogressStatusDelete: '/v1/common/campaign-progress-status/{1}/',
    orderInprogressStatusDelete:'/v1/campaign/order-progress-status/{1}/',
    getAllClosedStatus: '/v1/tickets/ticket-closed-status/',
    createClosedStatus: '/v1/tickets/ticket-closed-status/',
    getClosedStatusById: '/v1/tickets/ticket-closed-status/{1}/',
    updateClosedStatusById: '/v1/tickets/ticket-closed-status/{1}/',
    deleteClosedStatusById: '/v1/tickets/ticket-closed-status/{1}/',

    //ticket sla
    getAllTicketNameRule: "/v1/tickets/ticket-name-rules-all/{1}/{2}/{3}/",
    updateTicketNameRule: "/v1/tickets/ticket-name-rules/{1}/{2}/{3}/",
    addLevelToTicketName: "/v1/tickets/ticket-level/{1}/{2}/{3}/",
    updateTicketLevel: "/v1/tickets/ticket-level/{1}/{2}/{3}/",
    getLevelsByTicketNameID: "/v1/tickets/ticket-level/{1}/{2}/{3}/",
    deleteTicketLevel: "/v1/tickets/ticket-level/{1}/{2}/{3}/{4}/{5}/",
    getLevelByStatus: "/v1/tickets/ticket-level/{1}/{2}/{3}/{4}/",

    // Tag based SLA
    getTagNameSLA: '/v1/common/tag-name/{1}/{2}',
    updateTagNameSLA: '/v1/common/tag-name/{1}/{2}',
    getTagLevels: '/v1/common/tag-level/{1}/{2}/',
    addTagLevels: '/v1/common/tag-level/{1}/{2}/{3}',
    updateTagLevels: '/v1/common/tag-level/{1}/{2}/{3}',
    deleteTagLevel: '/v1/common/tag-level/{1}/{2}/{3}',

    // email rules

    updateEmailRules: '/v1/emails/email-rules/',
    getEmailRules: '/v1/emails/email-rules/',


    // email sla
    addEmailSLALevel: '/v1/emails/email-level/{1}/{2}',
    getEmailSLALevel: '/v1/emails/email-level/{1}/',
    updateEmailSLALevel: '/v1/emails/email-level/{1}/{2}',
    deleteEmailSLALevel: '/v1/emails/email-level/{1}',


    logout: '/v1/logout/',
    releaseSession: '/v1/users/release-session/',

    // reports
    ticketsDailyReport: '/v1/reports/tickets-daily-report/',
    ticketsAgentPerformanceReport: '/v1/reports/tickets-agent-performance-report/',
    ticketDetailsReport: '/v1/reports/tickets-detail-report/',
    emailAgentPerfomanceReport: '/v1/reports/email-agent-performance-report/',
    emailSLAReport: '/v1/reports/email-sla-report/',
    dispositionReport: '/v1/reports/disposition-report/',
    ticketLifeCycleReport: '/v1/reports/ticket-lifecycle-report/',
    ticketIssueReport: '/v1/reports/tickets-issue-report/',
    cncTeamWorkLoadReport: '/v1/reports/workload-report/',
    ticketSlaReport: '/v1/reports/tickets-sla-report/',
    downloadPdf: '/v1/reports/json_to_pdf/',

    createEmailTemplate: '/v1/emails/templates/',
    updateEmailTemplate: '/v1/emails/templates/{1}/',
    deleteEmailTemplate: '/v1/emails/templates/{1}/',
    emailTemplate: '/v1/emails/templates/',
    getTeamWiseEmailTemplate: '/v1/emails/templates/team/',
    getEmailTemplate: '/v1/emails/templates/{1}/',
    templateAttchmentDelete: '/v1/emails/template-attachments/{1}/',
    getSmtpServer: '/v1/common/smtp-server-configuration/team-config/{1}/',
    getIssueType:'/v1/reports/sla-reports/',
    getTeamType:'/v1/reports/sla-reports/',
    getAuditTicket:'/v1/reports/sla-reports/',
    getLatestAuditTicket:'/v1/reports/sla-reports/',



    //campaign
    createCampaign: '/v1/common/campaigns/',
    getAllCampaign: '/v1/common/campaigns/',
    deleteCampaign: '/v1/common/campaigns/{1}/',
    getSingleCampaign: '/v1/common/campaigns/{1}/',
    updateCampaign: '/v1/common/campaigns/{1}/',
    createSingleLead: '/v1/common/lead/',
    createOrderLead: '/v1/campaign/campaign-order/',
    getOrderDynamicList:'/v1/campaign/campaign-order/',
    campLeadBulkCreate: '/v1/common/lead/bulk-upload/',
    getOrderLeadBulkCreate:'/v1/campaign/campaign-order/bulk-upload/',
    campWorkflowUpdate: '/v1/common/campaigns/{1}/',
    getCampaignLeadData: '/v1/common/lead/',
    getSingleLeadDetails: '/v1/common/lead/{1}/',
    updateCampLead: '/v1/common/lead/{1}/',
    campaignStatus: '/v1/common/campaign-name/',
    updateLeadCampaign: '/v1/common/lead/{1}/',
    updateOrderChangeStatus:'/v1/campaign/campaign-order/{1}/',
    campCreateInternalNote: '/v1/common/lead-internal-note/',
    multipleLeadsAssign: '/v1/users/users/{1}/assign_multiple_lead/',
    getCampaignCount: '/v1/common/bin-count/workflow/',
    addDispositionForLead: '/v1/common/lead-status-change-log/',
    getLeadDisposition: '/v1/common/lead-status-change-log/',
    removePhoneEvent: '/v1/telephony/destroy-event-log/{1}/',
    addCampignEvent: '/v1/common/lead-event/',
    listCampaignEvent: '/v1/common/lead-event/',
    countCampaignEvent: '/v1/common/lead-event-bin-count/',
    updateCampaignEvent: '/v1/common/lead-event/{1}/',
    singleCampaignEvent: '/v1/common/lead-event/{1}/',
    createCampaignTemplate: '/v1/campaign/campaign-templates/',
    getCampaignWiseTemplate: '/v1/campaign/campaign-templates/',
    updateCampaignTemplate: '/v1/campaign/campaign-templates/{1}/',
    deleteCampaignTemplate: '/v1/campaign/campaign-templates/{1}/',
    campTemplateAttchmentDelete: '/v1/campaign/campaign-template-attachments/{1}/',
    createCampaignConfiguration: '/v1/campaign/campaign-configuration/',
    getCampaignConfiguration: '/v1/campaign/campaign-configuration/',
    deleteCampConfiguration: '/v1/campaign/campaign-configuration/{1}/',
    updateCampaignConfiguration: '/v1/campaign/campaign-configuration/{1}/',
    deleteCampLead:'/v1/common/lead/delete/',
    deleteOrderInvoiceLead:'/v1/campaign/campaign-order/delete/',
    multiOrderStatusChange:'/v1/campaign/campaign-order/bulk-update/',
    updateOrderListfield:'/v1/campaign/campaign-order/{1}/',
    getOrderAmount:'/v1/campaign/campaign-order/order-summary/',
    deleteOrderAttachment:'/v1/campaign/campaign-order/delete/',


    //extension
    createExtension: '/v1/telephony/extensions/',
    getAllExtension: '/v1/telephony/extensions/',
    getSingleExtension: '/v1/telephony/extensions/{1}/',
    updateExtension: '/v1/telephony/extensions/{1}/',
    deleteExtension: '/v1/telephony/extensions/{1}/',
    createAgentMapData: '/v1/users/user-telephony-extension/',
    getAgentMapList: '/v1/users/user-telephony-extension/',
    getLoginAgentPhoneData: '/v1/users/user-telephony-extension/',

    getSingleMapExtension: '/v1/users/user-telephony-extension/{1}/',
    updateMapExtension: '/v1/users/user-telephony-extension/{1}/',
    deleteMapExtension: '/v1/users/user-telephony-extension/{1}/',
    addAgentCallEntry: '/v1/users/call-history/',
    addAgentCallHangupEntry: '/v1/users/call-history/{1}/',
    addAgentCallDisposEntry: '/v1/users/call-history/{1}/',
    createPri:'/v1/telephony/telephony-pri/',
    getAllPriList:'/v1/telephony/telephony-pri/',
    updatePri:'/v1/telephony/telephony-pri/{1}/',
    deletePri:'/v1/telephony/telephony-pri/{1}/',
    getSinglePri:'/v1/telephony/telephony-pri/{1}/',
    createQueue: '/v1/telephony/queues/',
    getSingleQueue: '/v1/telephony/queues/{1}/',
    updateQueue: '/v1/telephony/queues/{1}/',
    deleteQueue: '/v1/telephony/queues/{1}/',
    getAllQueue: '/v1/telephony/queues/',
    getAllPauseCode: '/v1/telephony/pause-codes/',
    deletePauseCode: '/v1/telephony/pause-codes/{1}/',
    createPauseCode: '/v1/telephony/pause-codes/',
    getSinglePauseCode: '/v1/telephony/pause-codes/{1}/',
    updatePauseCode: '/v1/telephony/pause-codes/{1}/',
    createTelephonyServerConfigration: '/v1/telephony/telephony-server-config/',
    getAllTelePhonyServerList:'/v1/telephony/telephony-server-config/',
    updateTelephonyServerConfigration:'/v1/telephony/telephony-server-config/{1}/',
    getSingleServerConfigration:'/v1/telephony/telephony-server-config/{1}/',
    deleteTelephonyServerConfigration: '/v1/telephony/telephony-server-config/{1}/',

    //inbound
    getInboundCall: '/v1/telephony/inbound-call/',
    addPhoneLoginLogout: '/v1/users/telephony-login/',
    getMapUserCampaigns: '/v1/telephony/user-dial-method/{1}',
    getPhoneEvent: '/v1/telephony/get-event-log/{1}/',
    checkUserRealTimeStatus: '/v1/telephony/live-agent-details/',
    getAgentRtmDashboardCnt: '/v1/telephony/live-agent-dashboard/',
    getIbObPhoneSummary: '/v1/telephony/rtm-summary/',
    updateIbCallStatus: '/v1/telephony/vicidial-list-data/{1}/',
    searchCustomer: '/v1/customers/contacts/',
    getCallHistory:'/v1/users/call-history/',
    checkExtensionState: '/v1/telephony/get-extension-state/',
    addAgentExtState: '/v1/telephony/extension-states/',
    addBreakTime: '/v1/users/call-breaks/',
    addAgentResumeEntry: '/v1/users/call-breaks/{1}/',
    addHoldRetrieveEntry: '/v1/users/call-history/{1}/',
    addAgentLogoutEntry: '/v1/users/telephony-login/{1}/',
    updateTelephonyLogoutEvent: '/v1/users/force-logout/{1}/',

    //Fetch telephony configuration

    getTelephonyConfiguration:'/v1/telephony/telephony-server-config/',



    // ttreport
    getSubIssueType:'/v1/reports/sla-reports/',
    getStatusAuditReport:'/v1/reports/sla-reports/',



    authenticateuser: '/v1/authenticate-user/',

    //telephony report
    phoneLoginReport: '/v1/users/telephony-login/',
    // telephonyAgentPerformanceReport: '/v1/reports/telephony-agent-performance-report/',
    telephonyAgentPerformanceReport:'/v1/reports/telephony-agent-performance/',
    // telephonyContactReport: '/v1/reports/telephony-contact-details-report/',
    telephonyContactReport:'/v1/users/call-history/',
    telephonyCampaignReport: '/v1/reports/campaign-detail-report/',
    telephonyDispositionReport: '/v1/reports/campaign-disposition-report/',
    ibCdrReport: '/v1/telephony/vicidial-closer-log/',
    telephonyBreakReport:'/v1/users/call-breaks/',
    telephonyInboundCallReport:'/v1/reports/telephony-inbound-call-report/',
    telephonyAbandonedReport:'/v1/reports/telephony-abandoned-report/',
    //circleone report
    circleonticketDetailsReport: '/v1/reports/circleone-tickets-detail-report/',

    signupVerification: '/v1/signup-verification/',
    getManagerMailIds :'/v1/users/users/',
    campignDetailsReport:'/v1/common/lead/leadfields/',

    //Telephony disposition
    getAllPhoneDisposition: '/v1/telephony/disposition/',
    createPhoneDisposition: '/v1/telephony/disposition/',
    getSinglePhoneDisposition: '/v1/telephony/disposition/{1}/',
    deleteDisposition:'/v1/telephony/disposition/{1}/',
    updateConfigDisposition: '/v1/telephony/disposition/{1}/',


    
  };

  //   public static getCommonApiUrl(apiName, urlParams?: Array<any>, queryParams?: object): string {
  //     console.log("commono",ApiReference.getApiUrl(ApiType.COMMON, apiName, urlParams, queryParams))
  //     return ApiReference.getApiUrl(ApiType.COMMON, apiName, urlParams, queryParams);
  //   }

  //   public static tenantApiUrl(apiName, urlParams?: Array<any>, queryParams?: object): string {
  //     console.log("tenantt",ApiReference.getApiUrl(ApiType.TENANT_SPECIFIC, apiName, urlParams, queryParams))
  //     return ApiReference.getApiUrl(ApiType.TENANT_SPECIFIC, apiName, urlParams, queryParams);
  //   }

  //   private static getApiUrl(apiType: ApiType, apiName: any, urlParams?: Array<any>, queryParams?: object): string {
  //     // let auth = new AuthService()

  //     let apiUrl = this.apiReference[apiName];

  //     let endPoint: string;

  //     if (urlParams && urlParams.length) {
  //       apiUrl = formatIndexedString(apiUrl, urlParams);
  //       console.log(apiUrl,'urlParams')
  //     }
  //     if (queryParams) {
  //       const allQueries = Object.keys(queryParams);
  //       if (allQueries.length) {
  //         apiUrl += '?';
  //         allQueries.forEach(param => {
  //           const queryValue = queryParams[param];
  //           if (!['', undefined, null].includes(queryValue)) {
  //             apiUrl = `${apiUrl}${param}=${queryValue}&`;
  //             console.log(apiUrl,'queryParams')
  //           }
  //         });
  //       }
  //     }
  //     if (window.location.hostname.endsWith('localhost')) {

  //       // localhost
  //       if (environment.localTestDomain) {
  //         if (apiType === ApiType.TENANT_SPECIFIC) {
  //           endPoint = 'https://' + environment.localTestDomain + '.' + environment.apiBaseUrl + apiUrl;

  //         } else {
  //           endPoint = 'https://' + environment.apiBaseUrl + apiUrl;

  //         }
  //       } else {
  //         // local testing environment
  //         if (apiType === ApiType.TENANT_SPECIFIC) {
  //           if(window.location.host.split(`.${environment.saasDomain}`)[0].includes(`${environment.saasDomain}`)){
  //             endPoint = `http://${environment.apiBaseUrl}${apiUrl}`;
  //           }else{
  //             const subDomain = window.location.host.split(`.${environment.saasDomain}`)[0]
  //             endPoint = `http://${subDomain}.${environment.apiBaseUrl}${apiUrl}`;
  //           }
  //         } else {
  //           endPoint = `http://${environment.apiBaseUrl}${apiUrl}`;

  //         }
  //       }
  //     } else {
  //       if (apiType === ApiType.TENANT_SPECIFIC) {
  //         const domainName = window.location.hostname.split(environment.saasDomain)[0];
  //         endPoint = 'https://' + domainName + environment.apiBaseUrl + apiUrl;
  //       } else {
  //         endPoint = 'https://' + environment.apiBaseUrl + apiUrl;
  //       }
  //     }
  //     return endPoint;
  //   }
  // }
  public static getCommonApiUrl(apiName, urlParams?: Array<any>, queryParams?: object): string {
    return ApiReference.getApiUrl(ApiType.COMMON, apiName, urlParams, queryParams);
  }

  public static tenantApiUrl(apiName, urlParams?: Array<any>, queryParams?: object): string {
    return ApiReference.getApiUrl(ApiType.TENANT_SPECIFIC, apiName, urlParams, queryParams);
  }

  private static getApiUrl(apiType: ApiType, apiName: any, urlParams?: Array<any>, queryParams?: object): string {
    let apiUrl = this.apiReference[apiName];
    let endPoint: string;
    if (urlParams && urlParams.length) {
      apiUrl = formatIndexedString(apiUrl, urlParams);
    }
    if (queryParams) {
      const allQueries = Object.keys(queryParams);
      if (allQueries.length) {
        apiUrl += '?';
        allQueries.forEach(param => {
          const queryValue = queryParams[param];
          if (!['', undefined, null].includes(queryValue)) {
            apiUrl = `${apiUrl}${param}=${queryValue}&`;
          }
        });
      }
    }
    if (window.location.hostname.endsWith('localhost')) {
      // localhost
      if (environment.localTestDomain) {
        if (apiType === ApiType.TENANT_SPECIFIC) {
          endPoint = 'https://' + environment.localTestDomain + '.' + environment.apiBaseUrl + apiUrl;
        } else {
          endPoint = 'https://' + environment.apiBaseUrl + apiUrl;
        }
      } else {
        // local testing environment
        if (apiType === ApiType.TENANT_SPECIFIC) {
          const subDomain = window.location.hostname.split(`.${environment.saasDomain}`)[0];
          endPoint = `http://${subDomain}.${environment.apiBaseUrl}${apiUrl}`;
        } else {
          endPoint = `http://${environment.apiBaseUrl}${apiUrl}`;
        }
      }
    } else {
      if (apiType === ApiType.TENANT_SPECIFIC) {
        const domainName = window.location.hostname.split(environment.saasDomain)[0];
        const liveAgent = '/v1/telephony/live-agent-details/'
        const liveAgentDashboardCnt = '/v1/telephony/live-agent-dashboard/'
        const callSumary = '/v1/telephony/rtm-summary/'
        const contactReport = '/v1/reports/telephony-contact-details-report/'
        const agentPerformanceReport = '/v1/reports/telephony-agent-performance-report/'
        if (domainName == 'biosash.') {
          let url = apiUrl.toString();
          if ((url.indexOf(liveAgent) !== -1) || (url.indexOf(liveAgentDashboardCnt) !== -1) || (url.indexOf(callSumary) !== -1) || (url.indexOf(contactReport) !== -1) || (url.indexOf(agentPerformanceReport) !== -1)) {
            let baseUrl = 'api.bluewhirl.org/api'
            endPoint = 'https://' + domainName + baseUrl + apiUrl;
          } else {
            endPoint = 'https://' + domainName + environment.apiBaseUrl + apiUrl;
          }
        } else {
          endPoint = 'https://' + domainName + environment.apiBaseUrl + apiUrl;
        }
      } else {
        endPoint = 'https://' + environment.apiBaseUrl + apiUrl;
      }
    }
    return endPoint;
  }
}
