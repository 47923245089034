import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public loading = false;
  constructor() { }

  start() {
    setTimeout(() => this.loading = true)
  }
  stop() {
    setTimeout(() => this.loading = false)
  }

}
