import { Component, OnInit } from '@angular/core';
import { AuthService } from './code-modules/authentication/auth.service';
import { AppContextService } from './code-modules/app-context/app-context.service';
import { ApiService } from './code-modules/api/api-services/api.service';
import { combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import { InAppLogger } from './code-modules/utils/general-utils/InAppLogger';
import { LoaderService } from './code-modules/utils/loader/loader.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { loginUrl } from './code-modules/authentication/auth.urls';
import { PhoneService } from './circle-one/telephony/services/phone.service';
import { Phone } from './circle-one/telephony/model/phone.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  loggerName = '[AppRoot]';
  logger = new InAppLogger(AppComponent.name);
  title = 'circleone-frontend';
  currentUser: any;
  phoneData: any;
  userId: any;
  resumeData: { action: string; };
  selectedPause: any;
  teleSessionData: any;
  sessionId: any;
  breakId: any;

  constructor(
    private auth: AuthService,
    private context: AppContextService,
    private router: Router,
    public loader: LoaderService,
    private bnIdle: BnNgIdleService,
    private phoneModel: Phone,
    private phoneApi: PhoneService,
    private api: ApiService,
    private toaster: ToastrService,
  ) {}

  ngOnInit() {
    // //this.loader.start();
    // window.addEventListener("beforeunload", (e)=> {
    //   let authToken = JSON.parse(localStorage.getItem('loginToken'));
    //   if(authToken.token){
    //       // localStorage.removeItem('loginToken')
    //       // this.auth.logout()
    //       this.api.logout().subscribe((data) => {
    //         console.log(data)
    //         // this.auth.logout()
    //       })
    //   }
    // });

    this.bnIdle.startWatching(3600).subscribe((isTimedOut: boolean) => {
      this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
      console.log(this.teleSessionData)

      var mobileNo = ((this.teleSessionData) ? this.teleSessionData['mobileNo'] : '');

      if (isTimedOut) {
        console.log('session expired');
        console.log(mobileNo + 'mobileNo')

        if (mobileNo == '' || mobileNo == undefined) {
          console.log('enter for telephonylogout')
          if (this.teleSessionData != null) {
            this.telephonyLogout()
          }

          setTimeout(() => {
            this.api.logout().subscribe((data) => {
              console.log(data)
              this.router.navigate([loginUrl]);
              this.refreshPage()
              localStorage.removeItem('phoneData')
              // this.auth.logout()
            })
            // this.auth.logout()
          }, 5000);
        }

      }
    });

    combineLatest(this.context.initialize(), this.context.contextReady).subscribe(([initialized, contextDataReady]) => {
      if (initialized && contextDataReady) {
        console.log(`${this.loggerName} Context initialisation complete.`);
        // //this.loader.stop();
      } else {
        console.log(`${this.loggerName} Waiting for context data initialization..`);
        // //this.loader.start();
      }
    }, error => {
      console.error(`${this.loggerName} Context initialization failed.`, error);
    });

  }

  telephonyLogout() {
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
    console.log(this.teleSessionData)

    var phoneLogin = ((this.teleSessionData) ? this.teleSessionData['extension'] : '');
    var queue = ((this.teleSessionData) ? this.teleSessionData['queues'] : '');


    let action = 'remove';
    if (queue) {
      let logoutData = { 'action': 'logout', 'logout_reason': 'System Logout' }

      let queueLength = queue.length - 1;

      queue.forEach(element => {

        let phoneData = { 'extension': phoneLogin, 'action': action, 'queue': element }

        this.phoneApi.phoneLoginLogout(phoneData).subscribe(
          data => {
            let msg = data['Desc'].toString();

            if (msg != null || msg != '' || msg != undefined) {
              if (msg.indexOf("Success") !== -1) {
                this.phoneModel.isphoneLogin = 'NO';
                this.phoneModel.callStatus = ''
                if (queue[queueLength] == element) {
                  this.addLogoutEntry(logoutData)
                }
                var isphoneLogin = ((this.teleSessionData) ? this.teleSessionData['isPhoneLogin'] : '');
                if (isphoneLogin == 'PAUSE') {
                  this.selectPauseCode(1)
                }

                this.phoneModel.inboundCall && this.phoneModel.inboundCall.unsubscribe();

              }
            } else {
              this.toaster.error('Telephony server not responding.');
              return;
            }
          },
          error => {
            this.toaster.error('Error in telephony logout function.');
            return;
          });
      });
      this.toaster.success('Telephony logout successfully');
    }
  }

  selectPauseCode(flag) {
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
    console.log(this.teleSessionData)

    var phoneLogin = ((this.teleSessionData) ? this.teleSessionData['extension'] : '');
    var queue = ((this.teleSessionData) ? this.teleSessionData['queues'] : '');


    if (phoneLogin == 0 || phoneLogin == '' || phoneLogin == undefined) {
      this.toaster.error('Please login in telephony'); return;
    }

    var action = 'unpause';
    let queueLength = queue.length - 1;

    if (queue) {
      queue.forEach(element => {
        let phoneData = { 'extension': phoneLogin, 'action': action, 'queue': element, 'pauseCode': '' }

        this.phoneApi.phonePauseUnpause(phoneData).subscribe(
          data => {

            let msg = data['Desc'].toString();
            if (msg != null || msg != '' || msg != undefined) {
              if (msg.indexOf("Success") !== -1) {
                if (queue[queueLength] == element) {
                  this.resumeData = {
                    action: 'resume',
                  };
                  this.selectedPause = null

                  this.addResumeEntry(this.resumeData)

                }
              } else {
                this.toaster.error('Telephony server not responding.');
                return;
              }

            }
            error => {
              this.toaster.error('Error in ' + action + ' functionality.');
              return;
            }
          });
      });


    } else {
      this.toaster.warning('Please login in the telephony');
      return;
    }

  }

  addLogoutEntry(logoutData) {
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
    console.log(this.teleSessionData)

    var sessionId = ((this.teleSessionData) ? this.teleSessionData['sessionId'] : '');
    this.api.addAgentLogoutEntry(logoutData, sessionId).subscribe(
      data => {
        if (data.length > 0) {
        }
      });
  }

  addResumeEntry(breakData) {
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
    console.log(this.teleSessionData)

    var breakId = ((this.teleSessionData) ? this.teleSessionData['breakId'] : '');
    console.log(breakId + 'breakId')

    this.api.addAgentResumeEntry(breakData, breakId).subscribe(
      data => {
        if (data.length > 0) {
        }
      });
  }

  refreshPage() {
    location.reload();
  }





  // get getRandomColor() {
  //   setTimeout(() => {
  //     var letters = '0123456789ABCDEF';
  //     var color = '#';
  //     for (var i = 0; i < 6; i++) {
  //       color += letters[Math.floor(Math.random() * 16)];
  //     }
  //     return color;
  //   })
  //   return '#006ddd'
  // }
}
