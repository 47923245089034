import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../code-modules/api/api-services/api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import { LoaderService } from 'src/app/code-modules/utils/loader/loader.service';
import {ApiForgotPasswordRequest} from '../../../../code-modules/api/interfaces/Tenant/tenant-api-interfaces';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';

@Component({
  selector: 'app-enter-username',
  templateUrl: './enter-username.component.html',
  styleUrls: ['./enter-username.component.sass']
})
export class EnterUsernameComponent implements OnInit {

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    private toaster: ToastrService,
    ////private loader: LoaderService,
  ) {
  }

  loggerName = '[/forgotPassword]';
  forgotForm: FormGroup;
  @Output() gotoNext = new EventEmitter();

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]]
    });
  }

  onSubmitCheck() {
    if(this.forgotForm.valid){
      this.submit();
    }
  }
  submit() {

    ////this.loader.start();
    const forgotRequest: ApiForgotPasswordRequest = {
      email: this.forgotForm.value.email
    };
    console.log(forgotRequest);
    this.api.forgotPassword(forgotRequest).subscribe(response => {
      console.log(`${this.loggerName} Topic creation success: `, response);
   //   //this.loader.stop();
      this.emitFormValue();
    }, error1 => {
     // //this.loader.stop();
     this.toaster.error(ErrorToaster(error1))
      // this.toaster.error('Something went wrong');
      console.error(`${this.loggerName} Topic creation failed: `, error1);
    });
  }

  emitFormValue() {
    this.gotoNext.emit(true);
  }
}

