import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { delay, retry } from 'rxjs/operators';
import { ApiReference } from 'src/app/code-modules/api/api-end-points/api-reference';

const SIGNUP_API = 'signupVerification';

enum VerificationStatusEnum {
  pending = 'pending',
  success = 'success',
  failure = 'failure'
}

class SignupRespModel {
  redirect_url: Location;
}

class SignupQueryParamModel {
  username: string;
  verification_token: string;
}

@Component({
  selector: 'app-signup-verification',
  templateUrl: './signup-verification.component.html',
  styleUrls: ['./signup-verification.component.sass']
})
export class SignupVerificationComponent {

  isVerificationComplete = false;
  verificationStatus = VerificationStatusEnum.pending; // pending, success, failure

  getCommonApiUrl = ApiReference.getCommonApiUrl;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((param: SignupQueryParamModel)=>{
      if(param.username && param.verification_token) {
        const url = this.getCommonApiUrl(SIGNUP_API, [], {
          username: param.username,
          verification_token: param.verification_token
        });
        this.verifySignup(url);
      }
    })
  }

  verifySignup(url) {
    this.http.get(url)
    .pipe(retry(5),delay(1000))
    .subscribe((resp: SignupRespModel)=>{
      setTimeout(() => {
        this.verificationStatus = VerificationStatusEnum.success;
        setTimeout(() => {
          window.location = resp.redirect_url;
        }, 1000);
      }, 2000);
    }, (err)=>{
      this.verificationStatus = VerificationStatusEnum.failure;
    });

    
  }
}

// http://localhost:4200/verification?username=siddemo1@rhyta.com&verification_token=792045a6-d4f5-426c-9869-3928e42205b4