import {
  Component,
  ViewChild,
  AfterViewInit,
  ElementRef,
  OnInit,
  Input
} from "@angular/core";
import { FormGroup, FormControl, Validators, ValidatorFn } from "@angular/forms";
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Moment } from "moment";
import { Color, Label } from 'ng2-charts';
import { IDefinedDateRange } from "ngx-daterange";
import { type } from "os";
import { ApiService } from "src/app/code-modules/api/api-services/api.service";
//import 'chartjs-plugin-annotation';


@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.sass']
})
export class LineChartComponent implements OnInit{
 
  selectedPrevious: {startDate: Moment, endDate: Moment};
  selectedCurrent:  {startDate: Moment, endDate: Moment};
// lineChart
public lineChartData:Array<any> = [
  {
    data: [], 
    label: 'Interval 1', 
    backgroundColor:'#4791FF',
    pointBackgroundColor:'#4791FF',
    pointRadius:4
    // lineTension: 1
  },
  {
    data: [], 
    label: 'Interval 2', 
    backgroundColor:'#02BC77',
    pointBackgroundColor:'#02BC77',
    pointRadius:4
    // lineTension: 0
  },
];
isContact
public lineChartLabels:Array<any> = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00',
'08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00',
'20:00','21:00','22:00','23:00'];

public lineChartOptions = {
  responsive: true,
  legend: {
    position: 'bottom',
    fill:{
      type:'solid',
      
    },
    labels: {
      fontSize: 10,
     
      // usePointStyle: true
    }
  },
  scales: {
    xAxes: [{
      VISIBLE:true,
         gridLines: {
          display:true,
          color : 'transparent',
          zeroLineWidth : 1,
          zeroLineColor : "blue"
         },
         scaleLabel: {
          display: true,
          labelString: "Time(Hours)",
         },
      }],
    yAxes: [{
      VISIBLE: true,

         gridLines: {
            display:true,
            color : 'transparent',
            zeroLineWidth : 1,
            zeroLineColor : "black"
         },
         scaleLabel: {
          display: true,
          labelString: "Count",
         },
         ticks: {
          beginAtZero: true,
          userCallback: function(label, index, labels) {
              // when the floored value is the same as the value we have a whole number
              if (Math.floor(label) === label) {
                  return label;
              }

          },
      }
      }]
    }
};

public lineChartColors: Color[] = [
  {
    borderColor: '#4791FF',
    backgroundColor: 'rgb(255 255 255)'
  },
  {
    borderColor: '#02BC77',
    backgroundColor: 'rgb(255 255 255)',
  }
];
public lineChartLegend = {
      horizontalAlign: "left", // "center" , "right"
      verticalAlign: "center",  // "top" , "bottom"
      fontSize: 150
}
public lineChartType:string = 'line';
previous_start_Date
previous_end_Date
current_start_Date
current_end_Date
sendBox = false
emailStatus = 'active'
@Input() dashboardData
@Input() selectedTeam
constructor(public api:ApiService){
  
}
ngOnInit(): void {
  console.log(this.selectedTeam)
  this.onTypeChange()
  // this.setLineGraphData()
}
ngOnChanges() {
  this.selectedTeam = this.selectedTeam
}
dateChangePrevious(e){
  if(this.selectedPrevious){
    if(this.selectedPrevious.startDate && this.selectedPrevious.endDate){
      console.log(e,this.selectedPrevious.startDate.format('YYYY-MM-DD') ,this.selectedPrevious.endDate)
      this.previous_start_Date = this.selectedPrevious.startDate.format('YYYY-MM-DD')
      this.previous_end_Date = this.selectedPrevious.endDate.format('YYYY-MM-DD')
    }
  }
  
  
}
dateChangeCurrent(e){
  if(this.selectedCurrent){
    if(this.selectedCurrent.startDate && this.selectedCurrent.endDate){
      this.current_start_Date = this.selectedCurrent.startDate.format('YYYY-MM-DD')
      this.current_end_Date = this.selectedCurrent.endDate.format('YYYY-MM-DD')
      if(this.previous_start_Date && this.previous_end_Date && this.current_start_Date,this.current_end_Date){
        this.api.dashboardApi(undefined,undefined , undefined ,this.selectedTeam , undefined,
          undefined , undefined , this.current_start_Date , this.current_end_Date,this.previous_start_Date,this.previous_end_Date,this.emailStatus).subscribe((data)=>{
          
          this.lineChartData[1].data =[]
          this.lineChartData[0].data =[]
          Object.keys(data.email_line_graph[0].current_email_count_hourwise).forEach((key)=>{
            this.lineChartData[1].data.push(data.email_line_graph[0].current_email_count_hourwise[key])
            // this.lineChartLabels.push(key)
          })
          Object.keys(data.email_line_graph[0].prev_email_count_hourwise).forEach((key)=>{
            this.lineChartData[0].data.push(data.email_line_graph[0].prev_email_count_hourwise[key])
            // this.lineChartLabels.push(key)
          })
        })
      }
      console.log(this.lineChartData[1].data , "$#$#$")
  }
  
  }
}
// dateChanged(e: any) {
//   console.log(e);
// }
setLineGraphData(){
  this.lineChartData[1].data =[]
  this.lineChartData[0].data =[]
  Object.keys(this.dashboardData.email_line_graph[0].current_email_count_hourwise).forEach((key)=>{
    this.lineChartData[1].data.push(this.dashboardData.email_line_graph[0].current_email_count_hourwise[key])
    // this.lineChartLabels.push(key)
  })
  Object.keys(this.dashboardData.email_line_graph[0].prev_email_count_hourwise).forEach((key)=>{
    this.lineChartData[0].data.push(this.dashboardData.email_line_graph[0].prev_email_count_hourwise[key])
    // this.lineChartLabels.push(key)
  })
}

public chartHovered(e:any):void {
  console.log(e);
}
onTypeChange(event?){
  if(event){
    this.sendBox = event.checked;
  }
  
  console.log(event ,"event",this.sendBox ,this.selectedTeam)
  // if(this.previous_start_Date && this.previous_end_Date && this.current_start_Date,this.current_end_Date){
    if(this.sendBox){
      this.emailStatus = 'closed'
      this.api.dashboardApi(undefined,undefined , undefined ,this.selectedTeam , undefined,
        undefined , undefined , this.current_start_Date , this.current_end_Date,this.previous_start_Date,this.previous_end_Date,this.emailStatus)
        .subscribe((data)=>{
          this.lineChartData[1].data =[]
          this.lineChartData[0].data =[]
            Object.keys(data.email_line_graph[0].current_email_count_hourwise).forEach((key)=>{
              this.lineChartData[1].data.push(data.email_line_graph[0].current_email_count_hourwise[key])
              // this.lineChartLabels.push(key)
            })
            Object.keys(data.email_line_graph[0].prev_email_count_hourwise).forEach((key)=>{
              this.lineChartData[0].data.push(data.email_line_graph[0].prev_email_count_hourwise[key])
              // this.lineChartLabels.push(key)
            })
            console.log(this.lineChartData , "$$$$$$$")
        })
    }else{
      this.emailStatus = 'active'
      this.api.dashboardApi(undefined,undefined , undefined ,undefined , undefined,
        undefined , undefined , this.current_start_Date , this.current_end_Date,this.previous_start_Date,this.previous_end_Date,this.emailStatus)
        .subscribe((data)=>{
          this.lineChartData[1].data =[]
          this.lineChartData[0].data = []
            Object.keys(data.email_line_graph[0].current_email_count_hourwise).forEach((key)=>{
              this.lineChartData[1].data.push(data.email_line_graph[0].current_email_count_hourwise[key])
              // this.lineChartLabels.push(key)
            })
            Object.keys(data.email_line_graph[0].prev_email_count_hourwise).forEach((key)=>{
              this.lineChartData[0].data.push(data.email_line_graph[0].prev_email_count_hourwise[key])
              // this.lineChartLabels.push(key)
            })
        })
      }
  // }
}

}
