import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedRoutingModule } from './shared-routing.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CdkTreeModule } from '@angular/cdk/tree';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxPaginationModule } from 'ngx-pagination';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {
  MatSidenavModule,
  MatMenuModule,
  MatCheckboxModule,
  MatButtonModule,
  MatTabsModule,
  MatFormFieldModule,
  MatDialogModule,
  MatInputModule,
  MatSelectModule,
  MatTableModule,
  MatDividerModule,
  MatExpansionModule,
  MatSortModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatChipsModule,
  MatIconModule,
  MatCardModule,
  MatTooltipModule,
  MatListModule,
  MatToolbarModule,
  MatBadgeModule,
  MatProgressBarModule,
  MatRippleModule,
  MatTreeModule,
  MatGridListModule,
  MatAutocompleteModule,
  MatStepperModule,
  MatOptionModule,
  MAT_DIALOG_DATA

} from '@angular/material';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DateStringPipe } from './utils/pipes/date-string.pipe';
import { ObjectfilterPipe } from './utils/pipes/objectfilter.pipe';
import { UserFilterPipe } from './utils/pipes/user-filter.pipe';
import { passwordMatchValidator } from './utils/validators/password-match-validator';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { QuillModule } from 'ngx-quill'
import { NgKnifeModule } from 'ng-knife';
import { ArchieveDialogComponent } from './components/archieve-dialog/archieve-dialog.component';
import { ChangeStatusComponent } from './components/change-status/change-status.component';
import { EmailConfigartionDialogComponent } from './components/email-configartion-dialog/email-configartion-dialog.component';
import { CampaignRoutingDialogComponent } from './components/campaign-routing-dialog/campaign-routing-dialog.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,

    // pipes
    DateStringPipe,
    ObjectfilterPipe,
    UserFilterPipe,
    ArchieveDialogComponent,
    ChangeStatusComponent,
    EmailConfigartionDialogComponent,
    CampaignRoutingDialogComponent


  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    //material modules
    NgxUiLoaderModule,
    ToastrModule,
    QuillModule.forRoot(),
    CommonModule,
    MatSidenavModule,
    MatMenuModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatDividerModule,
    MatExpansionModule,
    MatSortModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatChipsModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatTooltipModule,
    MatListModule,
    MatToolbarModule,
    MatBadgeModule,
    NgxMatSelectSearchModule,
    MatProgressBarModule,
    ColorPickerModule,
    MatRippleModule,
    MatTreeModule,
    CdkTreeModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatOptionModule,
    NgxPaginationModule,
    DragDropModule,
  

//time picker
  NgxMaterialTimepickerModule,
  NgKnifeModule




  ],
  exports: [

    //form module
    FormsModule,
    ReactiveFormsModule,
    ToastrModule,
    QuillModule,
    NgxUiLoaderModule,

    //material modules

    MatSidenavModule,
    MatMenuModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatDividerModule,
    MatExpansionModule,
    MatSortModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatChipsModule,
    MatIconModule,
    MatCardModule,
    MatTooltipModule,
    MatListModule,
    MatToolbarModule,
    MatBadgeModule,
    NgxMatSelectSearchModule,
    MatProgressBarModule,
    ColorPickerModule,
    MatRippleModule,
    MatTreeModule,
    CdkTreeModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatOptionModule,
    DragDropModule,
    NgxPaginationModule,

    //components
    ConfirmDialogComponent,
    ArchieveDialogComponent,

    //time picker
    NgxMaterialTimepickerModule,
    NgKnifeModule,

    //pipes
    DateStringPipe,
    ObjectfilterPipe,
    UserFilterPipe,
   
  ],
  entryComponents:[
    ConfirmDialogComponent,
    ArchieveDialogComponent,
    ChangeStatusComponent,
    EmailConfigartionDialogComponent,
    CampaignRoutingDialogComponent
  ],
  providers: [
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    }
  ]
})
export class SharedModule { }
