import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SidenavService } from 'src/app/circle-one/navbar/components/side-nav/sidenav.service';
import { ReportApiService } from 'src/app/code-modules/api/api-services/api-report-service';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { AllStatus, StatusDisplay } from 'src/app/code-modules/api/interfaces/Ticket/ticket-api-interfaces';
import { UserProfileRead } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { AuthService } from 'src/app/code-modules/authentication/auth.service';
import { loginUrl } from 'src/app/code-modules/authentication/auth.urls';
import { UserRoles } from 'src/app/code-modules/saas/saas.enums';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';

@Component({
  selector: 'app-client-login-page',
  templateUrl: './client-login-page.component.html',
  styleUrls: ['./client-login-page.component.sass']
})
export class ClientLoginPageComponent implements OnInit {
  displayedCyretColumns: string[] = ["client_name", "srno", "created_date", "module", "assignee", "Ticket_title", "status", "last_update", "resolution_time", "remarks"];
  displayedColumns = ["created_date", "ticket_id", "last_update", "status", "assignee", "customer", "Ticket_title", "Ticket_Name", "resolution_time"]
  dataSourceCyret = []
  dataSource = []
  dashboardData 
  companyLogo = "/assets/new-circleone.png"
  footerLogo = "/assets/botgo.png"
  sideExpanded = true;
  avatar = 'url(https://www.w3schools.com/howto/img_avatar.png)';
  today = new Date()
  expanded:boolean
  allWorkflow
  selectedWorkflow
  selectedStatus
  ticketNameId :number
  currentUser = JSON.parse(localStorage.getItem('session'))
  currentAccount = JSON.parse(localStorage.getItem('team_account'))
  ticketStatusDisplay = []
  allContacts
  report_end_date: Date = new Date();
  report_start_date: Date = new Date(this.report_end_date.getFullYear(), this.report_end_date.getMonth(), 1);;
  clientDetails
  startDate
  endDate 
  loginAccountDetails
  selectedContact
  currentTenant = window.location.hostname.split('.')[0]
  
  // 'test'
  // window.location.hostname.split('.')[0]
  constructor(
    public sideNavController: SidenavService,
    public context: AppContextService, // used in template
    public auth: AuthService, // used in template
    public api: ApiService,
    private toaster: ToastrService,
    private reportApi:ReportApiService,
    public router: Router
  ) { 
    // this.currentUser = JSON.parse(localStorage.getItem('session'))
    // this.currentAccount = JSON.parse(localStorage.getItem('team_account'))
  }

  ngOnInit() {
    // this.loadWorkFlows()
    this.loadClientDetatils()
    this.endDate =  this.getDateString(this.report_end_date)
    this.startDate = this.getDateString(this.report_start_date)
    if(this.endDate && this.startDate){
      this.loadWorkFlows()
    }
    console.log(this.currentTenant)
    if(this.currentAccount){
      this.loadAccount()
    }
    console.log(this.currentUser,this.currentAccount ,"**")
    this.sideNavController.close();
    // this.loadWorkFlows();
    this.loadCustomers();
    this.loadDashboardData();
    if(this.startDate && this.endDate && this.loginAccountDetails && this.selectedWorkflow){
      if(this.currentTenant == 'cyret'){
        this.loadCyretReport()
      }else{
        this.loadOtherTenantReport()
      }
    }
  }
  onItemSelected(item) {
    this.expanded = !this.expanded;
  }
  close() {
    this.sideNavController.close();
  }
  toggleMobileSidebar() {
    this.sideNavController.toggle();
  }

  toggleSidebar() {
    console.log('Sidenav toggle');
    this.sideNavController.toggle();
  }
  loadWorkFlows() {
    this.api.ticketWorkFlowList().subscribe((workFlows) => {
      console.log(workFlows,"Work")
        if(workFlows.results.length){
          this.allWorkflow = workFlows.results
          this.selectedWorkflow =this.allWorkflow[0]
          if(this.selectedWorkflow){
            this.showWorkflowWiseData()
          }
          if(this.currentTenant == 'cyret'){
            this.loadCyretReport()
          }else{
            this.loadOtherTenantReport()
          }
          
        }
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
    
    }
    loadCustomers(){
      this.api.contactList(undefined,this.currentAccount)
      .subscribe((data)=>{
        console.log(data,"customer")
        this.allContacts = data
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
    }
    loadAccount(){
      this.api.accountDetails(this.currentAccount)
      .subscribe((acc)=>{
        this.loginAccountDetails = acc
        console.log(this.loginAccountDetails)
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
    }
    loadDashboardData(){
      this.api.clientDashboard(this.currentAccount)
      .subscribe((dashData)=>{
        console.log(dashData , "dashboard")
        this.dashboardData = dashData
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
    }
    showWorkflowWiseData(){
      this.ticketStatusDisplay = []
      this.selectedWorkflow.all_statuses
      .forEach(element => {
          console.log(element ,"...")
          if(Object.prototype.toString.call(element.status) == "[object Object]"){
            this.ticketStatusDisplay.push(element.status)
          }else{
            this.ticketStatusDisplay.push(element)
          }
      });
      console.log(this.ticketStatusDisplay ,"..")
      if(this.currentTenant == 'cyret'){
        this.loadCyretReport()
      }else{
        this.loadOtherTenantReport()
      }
      
    }
    showStatusWiseData(event){
      if(this.currentTenant == 'cyret'){
        this.loadCyretReport()
      }else{
        this.loadOtherTenantReport()
      }
    }
    showContactWiseData(event){
      if(this.currentTenant == 'cyret'){
        this.loadCyretReport()
      }else{
        this.loadOtherTenantReport()
      }
    }
    loadCyretReport(){
      // ticketIssueReport
      if(this.selectedWorkflow && this.loginAccountDetails){
        // this.reportApi.ticketIssueReport(this.startDate,this.endDate,this.selectedWorkflow.name,
        //   this.selectedStatus,this.loginAccountDetails.name,this.selectedContact)
        //   .subscribe((reportData)=>{
        //     console.log(reportData ,"MMMM")
        //     this.dataSourceCyret = reportData
        //   },(err)=>{
        //     this.toaster.error(ErrorToaster(err));
        //   })
        let status 
        if(this.selectedStatus){
          status = this.selectedStatus.status
        }
        this.reportApi.ticketDetailsReport(this.startDate,this.endDate,status,
          this.selectedWorkflow.name,undefined,this.loginAccountDetails.name,undefined,this.selectedContact)
          .subscribe((reportData)=>{
            console.log(reportData ,"MMMM")
            this.dataSourceCyret = reportData
          },(err)=>{
            this.toaster.error(ErrorToaster(err));
          })
      }
      
    }
    loadOtherTenantReport(){
      if(this.selectedWorkflow){
        console.log(this.selectedStatus , "status")
        let status__type = null ;
        let status = null;
        if(this.selectedStatus){
          if(this.selectedStatus.status_type){
            status__type = this.selectedStatus.status_type
          }else{
            status = this.selectedStatus.status
          }
        }
       
        this.reportApi.circleonticketDetailsReport(this.startDate,this.endDate,undefined,
          this.selectedWorkflow.name,status__type,status,undefined,undefined,this.selectedContact)
          .subscribe((data)=>{
            console.log(data , ".......")
            this.dataSource = data
          },(err)=>{
            this.toaster.error(ErrorToaster(err))
          })
      }
    }
    dateChanged() {
      if(this.report_start_date && this.report_end_date){
        this.startDate = this.getDateString(this.report_start_date)
        this.endDate = this.getDateString(this.report_end_date)
        this.loadWorkFlows()
      }
      
    }
    getDateString(date: Date) {
      const year = date.getFullYear();
      let month: number | string = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month
      let day: number | string = date.getDate();
      day = day < 10 ? "0" + day : day
      return year + "-" + month + "-" + day
    }
    logout() {
      
      this.api.logout().subscribe((data) => {
        console.log(data)
        this.router.navigate([loginUrl]);
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
      // this.socketConnection.disconnect();
      this.auth.logout();
    }
    clearFilter(){
      this.report_start_date = undefined
      this.report_end_date = undefined
      this.selectedWorkflow = undefined
      this.selectedStatus = undefined
      this.selectedContact = undefined

    }
    loadClientDetatils(){
      this.api.clientDetails()
      .subscribe((data)=>{
        console.log(data , ".....")
        this.clientDetails = data
        if(this.clientDetails){
          if(this.clientDetails.avatar){
            this.avatar = `url(${this.clientDetails.avatar})`
          }else{
            this.avatar = 'url(https://www.w3schools.com/howto/img_avatar.png)';
          }
            
        }
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
    }
}
