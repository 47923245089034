import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { currentId } from 'async_hooks';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { ContactCreateUpdate, ContactRead } from 'src/app/code-modules/api/interfaces/Customer/customer-api-interfaces';
import { EmailThreadDetailRead } from 'src/app/code-modules/api/interfaces/Email/email-api-interfaces';
import { UserTeamList } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { DataDrivenForm } from 'src/app/code-modules/utils/data-driven-forms/DataDrivenForm';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';
import { Tag } from '../../../../code-modules/api/interfaces/Customer/customer-api-interfaces';

@Component({
  selector: 'app-updates-customers-form',
  templateUrl: './updates-customers-form.component.html',
  styleUrls: ['./updates-customers-form.component.sass']
})
export class UpdatesCustomersFormComponent implements OnInit {
  contactId: number;
  // selectedTags =[];
  dynamicForm = new DataDrivenForm([]);
  customerTags: Array<Tag> = [];
  selectedTags = [];
  isSelected = false
  accountDynamicForm = new DataDrivenForm([]);
  customerDetails: any
  selectedTeams = [];
  customerTeamsEdit = []
  userRole
  teams
  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<UpdatesCustomersFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toaster: ToastrService,
    public context: AppContextService,private router: Router,
  ) {


  }

  dynamic_fields_arr: any;

  ngOnInit() {

    this.context.currentUser$.subscribe((user) => {
      this.userRole = user
      console.log(this.userRole, "users")
    })
    this.getConatct();
    this.customerDetails = this.data;

    console.log(this.customerDetails);
    this.selectedTags = this.data.tags;
    // if(this.data.account!=null){
    // this.selectedTeams=this.data.account.accessible_teams;

    // }else{
    //   this.selectedTeams=this.data.accessible_teams;

    // }
    this.selectedTeams = this.data.accessible_teams;
    this.dynamic_fields_arr = this.customerDetails.dynamic_fields;

    console.log("this.dynamic_fields_arr :---", this.dynamic_fields_arr);
    this.loadUserTeamList();
    // this.contactMapp();
    this.getAllCustomerTagsList();
    this.context.customerContactDynamicFields$.subscribe(fieldData => {
      // this.logger.log('Loaded fields:', fieldData);
      if (fieldData.length) {
        // this.dynamicForm = new DataDrivenForm(fieldData, {});
        this.dynamicForm = new DataDrivenForm(fieldData, this.customerDetails.dynamic_fields || {});

        console.log("this.dynamicForm :--------------", this.dynamicForm);


      }
      else {

        this.dynamicForm = new DataDrivenForm([]);

      }

    });
    this.api.CustomerTagList().subscribe((tags) => {
      this.customerTags = tags
    }, (err) => {
      //  //this.loader.stop();
    })
    this.context.customerAccountDynamicFields$.subscribe((fieldData) => {
      if (fieldData.length) {
        this.accountDynamicForm = new DataDrivenForm(fieldData, {});
      }
    })

  }
  customerTeam = []
  // serchTeam =[];
  // loadUserTeamList() {
  //   this.api.ConatctTeamList()
  //     .subscribe((team) => {
  //       console.log(team, "Team")
  //        this.customerTeam = team
  //       this.serchTeam=team
  //       // this.customerTeamsEdit = this.edit ? this.selectedTeams : this.serchTeam;
  //       this.contactMapp()




  //     })
  // }

  loadUserTeamList() {
    if (this.userRole.role == 'admin') {
      this.api.userTeamList()
        .subscribe((team: UserTeamList) => {
          console.log(team, "Team")
          this.teams = team
          this.contactMapp()
        })
    } else {
      this.api.userDetails(this.userRole.id)
        .subscribe((team) => {
          console.log(team, "Team")
         this.teams = team.teams
          this.contactMapp()
          console.log(this.teams, "login teams")
        })
    }

  }

  getAllCustomerTagsList() {
    this.api.CustomerTagList().subscribe((tags) => {
      this.customerTags = tags
      // //this.loader.stop();
    }, (err) => {
      //  //this.loader.stop();
    })
  }


  editInfo() {

    this.data.tags = this.selectedTags;
    // if(this.selectedTeams==null){
    //   this.data.account.accessible_teams=[];

    // }else{
    //   this.data.account.accessible_teams=this.selectedTeams;

    // }


    const data = {
      'name': this.customerDetails.name,
      'email': this.customerDetails.email,
      'phone_number': this.customerDetails.phone_number,
    }


    let request = <ContactCreateUpdate>data;
    request.dynamic_fields = this.dynamicForm.formGroup.value
    request.tags = this.getTagIds()
    request.accessible_teams = this.getTeamIds();

    this.api.editCustomer(request, this.data.id).subscribe(res => {

      console.log(res)
      // this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => { this.router.navigate(['/app/email/emails']); }); 
      this.toaster.success('Customer Updated Successfully');
      const result = this.data;
      this.dialogRef.close({ result });

    }, err => {
      this.toaster.error(ErrorToaster(err))
      // this.toaster.error('Something went wrong');
    });
  }
  toggleTagSelection(tag: Tag) {

    const index = this.selectedTags.map(obj => { return obj.id }).indexOf(tag.id);
    index > -1 ? this.selectedTags.splice(index, 1) : this.selectedTags.push(tag)

  }

  isSelectedTag(tag: Tag) {
    return this.selectedTags.map((obj => { return obj.id })).includes(tag.id)

  }
  getTagIds() {
    let tags = [];
    this.selectedTags.forEach((tag) => {
      console.log(tag)
      tags.push(tag.id)
    })
    return tags
  }
  toggleTeamSelection(team: Tag) {

    const index = this.selectedTeams.map(obj => { return obj.id }).indexOf(team.id);
    index > -1 ? this.selectedTeams.splice(index, 1) : this.selectedTeams.push(team)
    console.log(this.selectedTeams, "select teams")
    console.log(this.customerTeam, "cust teams")

  }
  // toggleTeamSelection(team: Tag) {
  //   if (!this.edit) {
  //     const index = this.selectedTeam.map(obj => { return obj.id }).indexOf(team.id);
  //     index > -1 ? this.selectedTeam.splice(index, 1) : this.selectedTeam.push(team)
  //   }
  // }
  // isSelectedTeam(team: Tag) {
  //   return this.selectedTeam.map((obj => { return obj.id })).includes(team.id)
  // }
  getTeamIds() {
    let teams = [];
    this.selectedTeams.forEach((team) => {
      teams.push(team.id)
    })
    return teams
  }
  isSelectedTeam(team: Tag) {
    return this.selectedTeams.map((obj => { return obj.id })).includes(team.id)
  }

  contactMapp() {
    this.customerTeam = []
    if (this.data.account === null) {
      this.customerTeam = this.teams;
      console.log("cond1")

    }
    else {

      if (this.data.account != null) {
        this.customerTeam = this.data.account.accessible_teams;
        console.log("cond2")

      }
      // else {
      //   this.customerTeam = this.teams;

      // }
    }

    
  }
  getConatct() {
    this.api.contactDetails(this.data.id).subscribe(res => {
      this.data = res

    }

    )
  }
}
