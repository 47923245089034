import { Component, OnInit, OnDestroy, ViewChild, ElementRef, VERSION } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { Dashboard } from 'src/app/code-modules/api/interfaces/Business-Rule/business-rule-api-interfaces';
import { UserProfileRead, UserTeamList, QueueData } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { UserNotificationComponent } from '../user-notification/user-notification.component';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
// import { element, element } from 'protractor';
import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart,
  ApexFill,
  ChartComponent,
  ApexStroke,
  ApexLegend
} from "ng-apexcharts";
import * as Highcharts from 'highcharts';
import { I, K, U } from '@angular/cdk/keycodes';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PhoneLiveAgentRead } from 'src/app/code-modules/api/interfaces/Customer/customer-api-interfaces';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';
import { PhoneService } from 'src/app/circle-one/telephony/services/phone.service';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  legend: ApexLegend;
  colors: string[];
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})

export class DashboardComponent {
  labels = ['Active', 'WIP', 'Resolved'];
  selectedModule = 'email'
  selectedTeam: number;
  selectedPeriod: string = null;
  fromDate;
  toDate;
  myControl = new FormControl();
  teamData: any;
  teamId: number = null;
  singleTeamArr: any = [];
  allTeam: boolean = true;
  singleTeam: boolean = false;
  selectedSingleTeam: any;
  role: any;
  teamNo: number = null;
  subscription: Subscription;
  summaryInterval: Subscription;
  dashboardData
  userRole
  teams
  report_start_date: Date;
  report_end_date: Date;
  selectedUser
  selectedWorkflow
  selectedAccount
  selectedContact
  allWorkflow
  ticketData = []
  sentiments
  users: UserProfileRead[] = [];
  highChartData = []
  @ViewChild("chart", { static: true }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartChannelOptions: Partial<ChartOptions>;
  name = `Angular! v${VERSION.full}`;
  titleName = 'Email Status'
  titleDivTwo = 'Ticket By Email'
  isHidden = true
  isDisable = true
  @ViewChild("container", { read: ElementRef, static: true }) container: ElementRef;
  @ViewChild("container1", { read: ElementRef, static: false }) container1: ElementRef;
  selectedTicketWorkflow
  emailToTicketData = []
  ticketToChannelData
  totalAccountsForFilter
  today = new Date()
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  reportHeaders = ["queue_name", "user", "status", "pause_code", "duration", "callduration", "logout"]
  Headers = ["offered", "answered", "abandoned", "serlevel", "callwaiting"]
  Headers2 = ["sr.no", "callerid", "duration_str", "waittime"]
  phoneLoggedIn: any = 0;
  phoneOnCall: any = 0;
  phoneReady: any = 0;
  phonePause: any = 0;
  phoneAcw: any = 0;
  phoneWait: any = 0;
  dataSource = new MatTableDataSource([]);
  dataSource1 = new MatTableDataSource([]);
  dataSource2 = new MatTableDataSource([]);
  selectPhoneProcess: any = 'INBOUND'
  selectCampaign: any = 'blended'
  offered: any = 'Offered'
  answered: any = 'Answered'
  abandoned: any = 'Abandoned'
  abandonedPer: any = 'Abandoned %'
  avg_wait_time: any = 'Avg Waiting Time'
  sl: any = 'Service Level %'
  aht: any = 'AHT'
  occupancy: any = 'Occupancy %'
  agentArr: any = [];
  agentList: any;
  ibData: any = [];
  //queueArr: any = [];
  nCalls: any;
  Answered: number = 0;
  Abandoned: number = 0;
  ServiceLevel: any = 0;
  abandoned_per: number = 0;
  readyCnt: any;
  pauseCnt: any;
  loggedInCnt: any;
  selectedQueue: string = 'all';
  showQueues: boolean = false;
  agentQueueCall: any;
  showCallWaitTable: boolean = false;
  allQueues: any;
  allLiveAgentData: any;
  allArrQueues: any;
  agentVal: any;
  onCallPauseCnt: any;
  callWaitArr: any = [];


  constructor(public api: ApiService,
    private phoneApi: PhoneService,
    private toaster: ToastrService,
    private context: AppContextService) {

    this.chartOptions = {
      series: [0, 0, 0],

      chart: {
        height: 350,
        type: 'radialBar',
      },
      colors: ['#FF2366', '#01BC77', '#4791FF'],
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Noto Sans JP',
        fontWeight: 400,
      },
      plotOptions: {

        radialBar: {
          dataLabels: {
            name: {
              fontSize: '22px',
            },
            value: {
              fontSize: '16px',
            },
            // total: {
            //   show: true,
            //   label: 'TOTAL',
            //   // formatter: return '10'
            // }
          }
        }
      },
      labels: ['Inbox Email', 'Sent Email', 'Exception Email'],

    };
    this.chartChannelOptions = {
      series: [0, 0, 0, 0, 0],

      chart: {
        height: 350,
        type: 'radialBar',
      },
      colors: ['#546E7A', '#E91E63'],
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Noto Sans JP',
        fontWeight: 400,

      },
      plotOptions: {

        radialBar: {
          dataLabels: {
            name: {
              fontSize: '22px',
            },
            value: {
              fontSize: '14px',
            },
            // total: {
            //   show: true,
            //   label: 'TOTAL'
            // }
          }
        }
      },
      labels: ['Chat', 'Email', 'Facebook', 'Phone', 'Whatsapp'],

    };
  }
  chartEmailData = [
    {
      label: 'Ticket By Email',
      data: [],
    }
  ];

  ticketToChannelChartData = [
    {
      label: 'Ticket From Channel',
      data: []
    },
    // {
    //   label: 'Email',
    //   data:[]
    // },
    // {
    //   label: 'Facebook',
    //   data:[]
    // },
    // {
    //   label: 'Phone',
    //   data:[]
    // },
    // {
    //   label: 'Whatsapp',
    //   data:[]
    // }
  ]
  ticketToChannelChartLabel = ['Chat', 'Email', 'Facebook', 'Phone', 'Whatsapp']
  ticketToChannelChartOptions = {
    responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
    scales: {
      xAxes: [{
        barPercentage: 0.7,
        visible: true,
        gridLines: {
          display: true,
          color: 'transparent',
          zeroLineWidth: 1,
          zeroLineColor: "blue"
        }
      }],
      yAxes: [{
        gridLines: {
          display: true,
          color: 'transparent',
          zeroLineWidth: 1,
          zeroLineColor: "blue"
        },
        ticks: {
          beginAtZero: true,
          userCallback: function (label, index, labels) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label;
            }

          },
        }
      }]
    }
  }
  labelsBarChart = []
  barChartOptions = {
    responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
    scales: {
      xAxes: [{
        barPercentage: 0.7,
        visible: true,
        gridLines: {
          display: true,
          color: 'transparent',
          zeroLineWidth: 1,
          zeroLineColor: "blue"
        }
      }],
      yAxes: [{
        gridLines: {
          display: true,
          color: 'transparent',
          zeroLineWidth: 1,
          zeroLineColor: "blue"
        },
        ticks: {
          beginAtZero: true,
          userCallback: function (label, index, labels) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label;
            }

          },
        }
      }]
    }
  }
  colors = [
    {
      backgroundColor: '#4791FF'
    }
  ]
  loginTeam
  ngOnInit() {
    this.getAllQueue()
    this.context.currentUser$.subscribe((user) => {
      console.log(user, "user")
      this.userRole = user

    })

    // this.allQueues = ['Payswiff_English', 'Axis_English', 'Payswiff_Hindi', 'Axis_Hindi', 'Payswiff_Other', 'Axis_Other']

    if (this.userRole.role == 'admin') {
      if (JSON.parse(localStorage.getItem('currentTeam'))) {
        this.loadUserList(JSON.parse(localStorage.getItem('currentTeam'))[0].id)
      } else {
        this.loadUserList()
      }
    } if (this.userRole.role == 'manager') {

      if (JSON.parse(localStorage.getItem('currentTeam'))) {
        this.loadUserList(JSON.parse(localStorage.getItem('currentTeam'))[0].id)
      }
    }
    if (this.userRole.role == "agent") {
      this.users = []
      this.users.push(this.userRole)
    }


    this.loadAllAccounts()
    this.loadUserTeamList()
  }

  getAllQueue() {
    const ExtList = this.api.getAllQueue().subscribe((result) => {
      console.log(result)
      this.allQueues = result;
    })
  }


  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
    this.summaryInterval && this.summaryInterval.unsubscribe();
  }
  loadAllAccounts() {
    this.api.allAccountList()
      .subscribe((data) => {
        this.totalAccountsForFilter = data;
        if (this.totalAccountsForFilter.length) {
          this.selectedAccount = this.totalAccountsForFilter[0].id
        }

        // console.log(this.totalAccountsForFilter ,"************");
        this.loadEmailStatusCount()
      }, (err) => {
        this.toaster.error(ErrorToaster(err))
      })
  }


  loadEmailStatusCount() {
    // alert(this.selectedModule)
    if (this.report_start_date && this.report_end_date) {
      if (this.report_start_date <= this.report_end_date) {
        let start_date = this.getDateString(this.report_start_date)
        let end_date = this.getDateString(this.report_end_date)
        console.log("loadEmailStatusCount", this.selectedSingleTeam)
        this.api.getEmailStatusCount('email', this.selectedUser, this.selectedSingleTeam, start_date, end_date, this.selectedAccount, this.selectedContact).subscribe((data) => {
          // console.log(data, "abcd")
          this.chartOptions.series = [data.bin_count.active_percentage, data.bin_count.closed_percentage,
          data.bin_count.exception_percentage]
        }, (err) => {
          this.toaster.error(ErrorToaster(err))
        })

      } else {
        this.toaster.info("Invalid date range")
      }
    } else {
      if (this.selectedAccount && this.selectedSingleTeam) {
        console.log("selectedAccount", this.selectedSingleTeam)
        this.api.getEmailStatusCount('email', this.selectedUser, this.selectedSingleTeam, this.report_start_date, this.report_end_date
          , this.selectedAccount, this.selectedContact).subscribe((data) => {
            // console.log(data, "abcd")
            this.chartOptions.series = [data.bin_count.active_percentage, data.bin_count.closed_percentage,
            data.bin_count.exception_percentage]
          }, (err) => {
            this.toaster.error(ErrorToaster(err))
          })
      }

    }

  }

  showSelectedAccountInfo(event) {
    // this.loadDashboardData(this.report_start_date,this.report_end_date,this.selectedUser,
    //   this.selectedSingleTeam,this.selectedWorkflow)
    this.loadEmailStatusCount()

  }

  loadDashboardChannelCount(type, user?, team?, workflow?, start_date?, end_date?, account?, contact?) {
    if (start_date && end_date) {
      if (start_date <= end_date) {
        let startDate = this.getDateString(start_date)
        let endDate = this.getDateString(end_date)
        this.emailToTicketData = []
        this.api.dashboardChannelCount(type, user, team, workflow, startDate, endDate, account, contact)
          .subscribe((data) => {
            if (this.selectedModule == 'email') {
              this.ticketData = []
              for (const key in data.ticket.bin_count) {
                if (Object.prototype.hasOwnProperty.call(data.ticket.bin_count, key)) {
                  const element = data.ticket.bin_count[key];

                  if (Object.prototype.toString.call(data.ticket.bin_count[key]) == "[object Array]") {
                    data.ticket.bin_count[key].forEach(obj => {
                      this.ticketData.push(obj)
                      // console.log(obj , "obj")
                    });
                  } else {
                    this.ticketData.push(element)
                  }
                }
              }
              this.emailToTicketData = []
              this.chartEmailData[0].data = []
              this.labelsBarChart = []
              this.ticketData.forEach(element => {
                for (const key in element) {
                  if (Object.prototype.hasOwnProperty.call(element, key)) {
                    this.labelsBarChart.push(`${key}`)
                    this.chartEmailData[0].data.push(element[key])
                  }
                }
              });
            }
            if (this.selectedModule == 'tickets' && this.selectedWorkflow) {
              this.ticketToChannelData = data.ticket.bin_count
              this.ticketToChannelChartData[0].data = []
              this.ticketToChannelChartData[0].data.push(this.ticketToChannelData.Chat)
              this.ticketToChannelChartData[0].data.push(this.ticketToChannelData.Email)
              this.ticketToChannelChartData[0].data.push(this.ticketToChannelData.Facebook)
              this.ticketToChannelChartData[0].data.push(this.ticketToChannelData.Phone)
              this.ticketToChannelChartData[0].data.push(this.ticketToChannelData.Whatsapp)
            }

          }, (err) => {
            this.toaster.error(ErrorToaster(err))
          })
      } else {
        this.toaster.info("Invalid date range")
      }
    } else {
      this.emailToTicketData = []
      // alert(this.selectedWorkflow)
      this.api.dashboardChannelCount(type, user, team, workflow, start_date, end_date, account, contact)
        .subscribe((data) => {
          if (this.selectedModule == 'email') {
            this.ticketData = []
            for (const key in data.ticket.bin_count) {
              if (Object.prototype.hasOwnProperty.call(data.ticket.bin_count, key)) {
                const element = data.ticket.bin_count[key];

                if (Object.prototype.toString.call(data.ticket.bin_count[key]) == "[object Array]") {
                  data.ticket.bin_count[key].forEach(obj => {
                    this.ticketData.push(obj)
                    // console.log(obj , "obj")
                  });
                } else {
                  this.ticketData.push(element)
                }
              }
            }
            this.emailToTicketData = []
            this.chartEmailData[0].data = []
            this.labelsBarChart = []
            this.ticketData.forEach(element => {
              for (const key in element) {
                if (Object.prototype.hasOwnProperty.call(element, key)) {
                  this.labelsBarChart.push(`${key}`)
                  this.chartEmailData[0].data.push(element[key])
                }
              }
            });
          }
          if (this.selectedModule == 'tickets' && this.selectedWorkflow) {
            this.ticketToChannelData = data.ticket.bin_count
            this.ticketToChannelChartData[0].data = []
            this.ticketToChannelChartData[0].data.push(this.ticketToChannelData.Chat)
            this.ticketToChannelChartData[0].data.push(this.ticketToChannelData.Email)
            this.ticketToChannelChartData[0].data.push(this.ticketToChannelData.Facebook)
            this.ticketToChannelChartData[0].data.push(this.ticketToChannelData.Phone)
            this.ticketToChannelChartData[0].data.push(this.ticketToChannelData.Whatsapp)
          }

        }, (err) => {
          this.toaster.error(ErrorToaster(err))
        })
    }

  }

  loadDashboardData(startDate?, endDate?, user?, team?, workflow?) {
    if (startDate && endDate) {
      if (startDate <= endDate) {
        let start_date = this.getDateString(startDate)
        let end_date = this.getDateString(endDate)
        this.api.dashboardApi(start_date, end_date, user, team, workflow).subscribe((data) => {
          this.dashboardData = data
          this.sentiments = this.dashboardData.sentiment
          if (this.selectedModule == 'email') {
            this.titleName = 'Email Status'
            this.titleDivTwo = 'Ticket By Channel'
            this.isHidden = true
            this.isDisable = false
          }
          if (workflow) {
            this.titleName = 'Ticket Status'
            this.titleDivTwo = 'Ticket From Channel'
            this.isHidden = false
            this.isDisable = false

            if (this.titleDivTwo == 'Ticket From Channel') {
              if (this.selectedModule == 'email') {
                this.loadDashboardChannelCount('ticket_to_channel', this.selectedUser, this.selectedSingleTeam,
                  this.selectedTicketWorkflow, this.report_start_date, this.report_end_date)
              } else if (this.selectedModule == 'tickets') {
                this.loadDashboardChannelCount('ticket_to_channel', this.selectedUser, this.selectedSingleTeam,
                  this.selectedWorkflow, this.report_start_date, this.report_end_date)
              }

            }

            this.ticketData = []
            if (this.dashboardData.ticket.bin_count.active) {
              this.ticketData.push(this.dashboardData.ticket.bin_count.active)
            }
            if (this.dashboardData.ticket.bin_count.wip) {
              this.dashboardData.ticket.bin_count.wip.forEach(element => {
                for (const key in element) {
                  if (Object.prototype.hasOwnProperty.call(element, key)) {
                    const ele = element[key];
                    this.ticketData.push(element)
                  }
                }
              });
            }
            if (this.dashboardData.ticket.bin_count.closed) {
              // this.ticketData.push(this.dashboardData.ticket.bin_count.closed)
              this.dashboardData.ticket.bin_count.closed.forEach(element => {
                for (const key in element) {
                  if (Object.prototype.hasOwnProperty.call(element, key)) {
                    const ele = element[key];
                    this.ticketData.push(element)
                  }
                }
              });
            }

            this.highChartData = []

            this.ticketData.forEach(element => {
              for (const key in element) {
                if (Object.prototype.hasOwnProperty.call(element, key)) {
                  this.highChartData.push([`${key}`, element[key]])
                }
              }
            });
            console.log(this.highChartData, this.ticketData, "test")

            if (this.highChartData) {
              Highcharts.chart({
                chart: {
                  renderTo: 'chartFunnel',
                  type: 'funnel'
                },
                title: {
                  text: ''
                },
                exporting: {
                  enabled: false
                },
                credits: {
                  enabled: false
                },
                plotOptions: {
                  funnel: {
                    showInLegend: true
                  },
                },
                colors: ['#02BC77', '#4791FF', '#FFD950', '#FF2366', '#1BCEDF', '#FC8701', '#9de8f5', '#6551ad', '#f196f2',
                  '#e3b956', '#64e356'],
                series: [{
                  type: 'funnel',
                  dataLabels: {
                    enabled: false,
                    format: '<b>{point.name}</b> ({point.y:,.0f})',
                    softConnector: false,

                  },
                  center: ['40%', '50%'],
                  neckWidth: '20%',
                  neckHeight: '25%',
                  width: '80%',
                  data: this.highChartData
                }],
                responsive: {
                  rules: [{
                    condition: {
                      maxWidth: 500
                    },
                    chartOptions: {
                      plotOptions: {
                        series: {
                          dataLabels: {
                            inside: true,
                          },

                        }
                      }
                    }
                  }]
                }
              });
            }
          }
        }, (err) => {
          this.toaster.error(ErrorToaster(err))
        })
      } else {
        this.toaster.info("Invalid date range")
      }
    } else {
      this.api.dashboardApi(startDate, endDate, user, team, workflow).subscribe((data) => {
        this.dashboardData = data
        this.sentiments = this.dashboardData.sentiment
        if (this.selectedModule == 'email') {
          this.titleName = 'Email Status'
          this.titleDivTwo = 'Ticket By Email'
          this.isHidden = true
          this.isDisable = false
        }
        if (workflow) {
          this.titleName = 'Ticket Status'
          this.titleDivTwo = 'Ticket From Channel'
          this.isHidden = false
          this.isDisable = false

          if (this.titleDivTwo == 'Ticket From Channel') {
            console.log("mydata", this.selectedWorkflow, this.selectedTicketWorkflow)
            if (this.selectedModule == 'email') {
              this.loadDashboardChannelCount('ticket_to_channel', this.selectedUser, this.selectedSingleTeam,
                this.selectedTicketWorkflow, this.report_start_date, this.report_end_date)
            } else if (this.selectedModule == 'tickets') {
              this.loadDashboardChannelCount('ticket_to_channel', this.selectedUser, this.selectedSingleTeam,
                this.selectedWorkflow, this.report_start_date, this.report_end_date)
            }
          }

          this.ticketData = []
          if (this.dashboardData.ticket.bin_count.active) {
            this.ticketData.push(this.dashboardData.ticket.bin_count.active)
          }
          if (this.dashboardData.ticket.bin_count.wip) {
            this.dashboardData.ticket.bin_count.wip.forEach(element => {
              for (const key in element) {
                if (Object.prototype.hasOwnProperty.call(element, key)) {
                  const ele = element[key];
                  this.ticketData.push(element)
                }
              }
            });
          }
          if (this.dashboardData.ticket.bin_count.closed) {
            // this.ticketData.push(this.dashboardData.ticket.bin_count.closed)
            this.dashboardData.ticket.bin_count.closed.forEach(element => {
              for (const key in element) {
                if (Object.prototype.hasOwnProperty.call(element, key)) {
                  const ele = element[key];
                  this.ticketData.push(element)
                }
              }
            });
          }

          this.highChartData = []
          this.ticketData.forEach(element => {

            for (const key in element) {
              if (Object.prototype.hasOwnProperty.call(element, key)) {
                this.highChartData.push([`${key}`, element[key]])
              }
            }
          });
          console.log(this.highChartData, this.ticketData, "test")

          if (this.highChartData) {
            Highcharts.chart({
              chart: {
                renderTo: 'chartFunnel',
                type: 'funnel'
              },
              title: {
                text: ''
              },
              exporting: {
                enabled: false
              },
              credits: {
                enabled: false
              },
              plotOptions: {
                funnel: {
                  showInLegend: true
                },
              },
              colors: ['#02BC77', '#4791FF', '#FFD950', '#FF2366', '#1BCEDF', '#FC8701', '#9de8f5', '#6551ad', '#f196f2',
                '#e3b956', '#64e356'],
              series: [{
                type: 'funnel',
                dataLabels: {
                  enabled: false,
                  format: '<b>{point.name}</b> ({point.y:,.0f})',
                  softConnector: false,

                },
                center: ['40%', '50%'],
                neckWidth: '20%',
                neckHeight: '25%',
                width: '80%',
                data: this.highChartData
              }],
              responsive: {
                rules: [{
                  condition: {
                    maxWidth: 500
                  },
                  chartOptions: {
                    plotOptions: {
                      series: {
                        dataLabels: {
                          inside: true,
                        },

                      }
                    }
                  }
                }]
              }
            });
          }
        }
      }, (err) => {
        this.toaster.error(ErrorToaster(err))
      })
    }

  }

  loadUserTeamList() {
    if (this.userRole.role == 'admin') {
      this.api.userTeamList()
        .subscribe((team: UserTeamList) => {
          // console.log(team,"Team")
          this.teams = team
          console.log(JSON.parse(localStorage.getItem("currentTeam")), "myteamm")
          if (JSON.parse(localStorage.getItem("currentTeam"))) {
            this.loginTeam = JSON.parse(localStorage.getItem("currentTeam"))[0]
            this.selectedSingleTeam = this.loginTeam.id
          } else
            if (this.teams.length) {
              this.selectedSingleTeam = this.teams[0].id
            }

          this.loadWorkFlows(this.selectedSingleTeam)
          if (this.selectedModule == 'email') {
            this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser,
              this.selectedSingleTeam)
            this.loadDashboardChannelCount('email_to_ticket', this.selectedUser, this.selectedSingleTeam,
              this.selectedTicketWorkflow, this.report_start_date, this.report_end_date)
            this.loadEmailStatusCount()
          } else if (this.selectedModule == 'tickets') {
            this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser,
              this.selectedSingleTeam, this.selectedWorkflow)
            this.loadDashboardChannelCount('ticket_to_channel', this.selectedUser, this.selectedSingleTeam,
              this.selectedTicketWorkflow, this.report_start_date, this.report_end_date)
          }


        }, (err) => {
          this.toaster.error(ErrorToaster(err))
        })
    } else {
      this.api.userDetails(this.userRole.id)
        .subscribe((team) => {
          // console.log(team,"Team")
          this.teams = team.teams
          this.selectedSingleTeam = this.teams[0].id
          this.loadWorkFlows(this.selectedSingleTeam)
          if (this.selectedModule == 'email') {
            this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser,
              this.selectedSingleTeam)
          } else if (this.selectedModule == 'tickets') {

            this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser,
              this.selectedSingleTeam, this.selectedWorkflow)
          }
        }, (err) => {
          this.toaster.error(ErrorToaster(err))
        })
    }

  }
  loadUserList(currentTeam?) {
    this.api.listUsers(currentTeam).subscribe((usersList) => {
      this.users = usersList
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    })
  }

  loadWorkFlows(team) {
    // //this.loader.start();
    // alert("Hi")
    this.api.ticketWorkFlowList(team).subscribe((workFlows) => {
      console.log(workFlows, "Work")
      if (workFlows.results.length) {
        this.allWorkflow = workFlows.results
        console.log(this.selectedTicketWorkflow, this.selectedWorkflow, this.allWorkflow, "....");
        this.selectedTicketWorkflow = this.allWorkflow[0].id
        this.selectedWorkflow = this.allWorkflow[0].id
        console.log(this.selectedWorkflow, this.selectedTicketWorkflow, this.allWorkflow, "++++");
        if (this.selectedModule == 'email') {
          this.loadDashboardChannelCount('email_to_ticket', this.selectedUser, this.selectedSingleTeam,
            this.selectedTicketWorkflow, this.report_start_date, this.report_end_date)
          this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser, this.selectedSingleTeam)
          this.loadEmailStatusCount()
        } else if (this.selectedModule == 'tickets') {
          this.loadDashboardChannelCount('ticket_to_channel', this.selectedUser, this.selectedSingleTeam,
            this.selectedWorkflow, this.report_start_date, this.report_end_date)
          this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser, this.selectedSingleTeam, this.selectedWorkflow)
        }

      } else {
        this.allWorkflow = []
        this.toaster.info("Please Create Workflow For Team.")
      }
      console.log(this.allWorkflow, this.selectedTicketWorkflow, "****")

    }, (err) => {

      this.toaster.error(ErrorToaster(err))

    })
  }

  showTeamWiseData(event) {
    this.loadWorkFlows(this.selectedSingleTeam)
    // if(this.selectedWorkflow){
    //   if(this.selectedModule == 'email'){
    //     this.loadDashboardData(this.report_start_date,this.report_end_date,this.selectedUser,this.selectedSingleTeam)
    //     this.loadEmailStatusCount()
    //     this.loadDashboardChannelCount('email_to_ticket',this.selectedUser,this.selectedTeam,this.selectedTicketWorkflow,
    //     this.report_start_date,this.report_end_date)
    //   }else if(this.selectedModule == 'tickets'){
    //     console.log(this.report_start_date,this.report_end_date,this.selectedUser,this.selectedSingleTeam,this.selectedWorkflow,"123");
    //     this.loadDashboardData(this.report_start_date,this.report_end_date,this.selectedUser,this.selectedSingleTeam,this.selectedWorkflow)
    //     this.loadDashboardChannelCount('ticket_to_channel',this.selectedUser,this.selectedTeam,this.selectedWorkflow,
    //     this.report_start_date,this.report_end_date)
    //   }
    // }

  }
  showIndividualData(event) {
    if (this.selectedModule == 'email') {
      this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser, this.selectedSingleTeam)
      this.loadDashboardChannelCount('email_to_ticket', this.selectedUser, this.selectedSingleTeam,
        this.selectedTicketWorkflow, this.report_start_date, this.report_end_date)
      this.loadEmailStatusCount()
    } else if (this.selectedModule == 'tickets') {
      this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser, this.selectedSingleTeam, this.selectedWorkflow)
      this.loadDashboardChannelCount('ticket_to_channel', this.selectedUser, this.selectedSingleTeam,
        this.selectedTicketWorkflow, this.report_start_date, this.report_end_date)
    }

  }
  showWorkflowWiseData(event) {
    console.log(this.selectedWorkflow, "sele")
    this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser, this.selectedSingleTeam, this.selectedWorkflow)
    // this.loadDashboardChannelCount('ticket_to_channel',this.selectedUser,this.selectedSingleTeam,
    // this.selectedWorkflow,this.report_start_date,this.report_end_date)
    // this.loadEmailStatusCount()
  }
  showTicketWorkflowWiseData(event) {
    this.loadDashboardChannelCount('email_to_ticket', this.selectedUser, this.selectedSingleTeam,
      this.selectedTicketWorkflow, this.report_start_date, this.report_end_date)
  }
  dateChanged() {


    // alert(this.report_start_date);


    console.log("this.report_start_date:----", this.report_start_date);

    if (this.report_start_date && this.report_end_date) {
      console.log(this.report_start_date, this.report_end_date, this.selectedUser, this.selectedSingleTeam, this.selectedWorkflow)
      if (this.selectedModule == 'email') {
        this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser, this.selectedSingleTeam)
        this.loadDashboardChannelCount('email_to_ticket', this.selectedUser, this.selectedSingleTeam,
          this.selectedTicketWorkflow, this.report_start_date, this.report_end_date)
        this.loadEmailStatusCount()
        this.loadWorkcloud();
      } else if (this.selectedModule == 'tickets') {
        this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser, this.selectedSingleTeam, this.selectedWorkflow)
        this.loadDashboardChannelCount('ticket_to_channel', this.selectedUser, this.selectedSingleTeam,
          this.selectedTicketWorkflow, this.report_start_date, this.report_end_date)
      }
    }


  }
  getDateString(date: Date) {
    const year = date.getFullYear();
    let month: number | string = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month
    let day: number | string = date.getDate();
    day = day < 10 ? "0" + day : day
    return year + "-" + month + "-" + day
  }

  moduleChangeHandler(event) {

    console.clear
    console.log("selectedModule :------------", this.selectedModule)
    this.loadWorkFlows(this.selectedSingleTeam)
    // this.selectedModule = event.value;
    if (this.selectedModule == 'email') {
      // this.selectedWorkflow = undefined
      this.titleName = 'Email Status'
      this.titleDivTwo = 'Ticket By Email'
      this.isHidden = true
      this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser,
        this.selectedSingleTeam);
      this.loadEmailStatusCount()
      // this.loadDashboardChannelCount('email_to_ticket',this.selectedUser,this.selectedSingleTeam,
      //     this.selectedTicketWorkflow,this.report_start_date,this.report_end_date)
      this.selectedWorkflow = undefined
      this.subscription && this.subscription.unsubscribe();
      this.summaryInterval && this.summaryInterval.unsubscribe();
    }
    if (this.selectedModule == 'tickets') {
      this.titleName = 'Ticket Status'
      this.titleDivTwo = 'Ticket From Channel'

      if (this.allWorkflow.length) {
        this.selectedWorkflow = this.allWorkflow[0].id
        this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser,
          this.selectedSingleTeam, this.selectedWorkflow);
        // this.loadDashboardChannelCount('ticket_to_channel',this.selectedUser,this.selectedSingleTeam,
        // this.selectedWorkflow,this.report_start_date,this.report_end_date)
      } else {
        // this.allWorkflow = []
        this.toaster.info("Please Create Workflow For Team.")
      }
      this.subscription && this.subscription.unsubscribe();
      this.summaryInterval && this.summaryInterval.unsubscribe();
    }
    if (this.selectedModule == 'rtm') {
      this.rtmBlock()
      this.showQueues = true
    }

  }
  clearFilter() {
    this.selectedUser = undefined
    // this.selectedWorkflow = undefined
    this.report_start_date = undefined
    this.report_end_date = undefined
    this.loadDashboardData(this.report_start_date, this.report_end_date, this.selectedUser,
      this.selectedSingleTeam, this.selectedWorkflow)
    this.loadEmailStatusCount()

  }

  rtmBlock() {
    this.subscription && this.subscription.unsubscribe();
    const source = interval(7000);
    this.subscription = source.subscribe(val => this.rtmBlock());
    this.getRealTimeData()
  }

  phoneLogoutActivity(phoneData) {
    this.telephonyLogout(phoneData)
    this.crmTelephonyLogoutAction(phoneData)
  }

  telephonyLogout(phoneData) {
    let action = 'remove';

    let phoneLogin = ((phoneData['Exten']) ? phoneData['Exten'] : '');

    this.allQueues.forEach(element => {
      this.phoneApi.getRealTimeData().subscribe((result) => {
        var queue = ((element['name']) ? element['name'] : '');
        var isKey = result.hasOwnProperty(queue);
        if (isKey) {
          this.agentArr = ((result[queue]['Members']) ? result[queue]['Members'] : '');
          if (this.agentArr != '') {
            let phoneData = { 'extension': phoneLogin, 'action': action, 'queue': queue }

            this.phoneApi.phoneLoginLogout(phoneData).subscribe(
              data => {
                this.toaster.success('Forcefull logout successfully.')
              })
          }
        }

      })

    });
  }

  crmTelephonyLogoutAction(phoneData) {
    let username = ((phoneData['AgentName']) ? phoneData['AgentName'] : '');

    this.api.updateTelephonyLogoutEvent(username).subscribe((result) => {
    })
  }

  callSummaryBlock() {
    this.summaryInterval && this.summaryInterval.unsubscribe();
    const source = interval(300000);
    this.summaryInterval = source.subscribe(val => this.callSummaryBlock());
  }

  getRealTimeData() {

    this.allArrQueues = []
    this.allLiveAgentData = []
    this.agentVal = []
    this.agentArr = []

    var queue = String(this.selectedQueue)
    this.phoneApi.getRealTimeData().subscribe((result) => {
      this.getIbObPhoneSummary(result)
      if (queue == 'all') {
        this.callWaitArr = []
        this.agentQueueCall = []
        this.phoneWait = 0
        this.dataSource2 = new MatTableDataSource();
        this.showCallWaitTable = false
        this.allArrQueues = Object.keys(result)
        this.allArrQueues.forEach(queue => {
          this.allLiveAgentData = ((result[queue]['Members']) ? result[queue]['Members'] : '');
          this.agentVal = Object.values(this.allLiveAgentData)
          this.agentVal.forEach(element => {
            this.agentArr.push({
              'AgentName': element['AgentName'],
              'Exten': element['Exten'],
              'LastCall': element['LastCall'],
              'PauseCode': element['PauseCode'],
              'Status': element['Status'],
              'type': element['type'],
              'Duration': element['Duration'],
              'queueName': queue
            })
          });
          this.agentQueueCall = ((result[queue]) ? result[queue]['calls'] : '');


          if (this.agentQueueCall != undefined) {
            this.showCallWaitTable = true
            this.agentQueueCall.forEach(element => {
              var channel = ((element.chaninfo) ? element.chaninfo['channel'] : '');
              console.log(element.chaninfo)
              var callerid = ((element.chaninfo) ? element.chaninfo['callerid'] : '');
              var duration_str = ((element.chaninfo) ? element.chaninfo['duration_str'] : '');
              var waittime = ((element) ? element['waittime'] : '');

              this.callWaitArr.push({
                'channel': channel, 'callerid': callerid, 'duration_str': duration_str,
                'waittime': waittime
              })
            });

            this.dataSource2 = new MatTableDataSource(this.callWaitArr);

            this.phoneWait = this.phoneWait + this.agentQueueCall.length

          }

        });
      } else {
        this.callWaitArr = []
        this.agentQueueCall = []
        this.phoneWait = 0
        this.dataSource2 = new MatTableDataSource();
        this.showCallWaitTable = false
        var isKey = result.hasOwnProperty(queue);
        if (isKey) {
          this.allLiveAgentData = ((result[queue]['Members']) ? result[queue]['Members'] : '');
          this.agentVal = Object.values(this.allLiveAgentData)
          this.agentVal.forEach(element => {
            this.agentArr.push({
              'AgentName': element['AgentName'],
              'Exten': element['Exten'],
              'LastCall': element['LastCall'],
              'PauseCode': element['PauseCode'],
              'Status': element['Status'],
              'type': element['type'],
              'Duration': element['Duration'],
              'queueName': queue
            })
          });
        }
      }
      console.log(this.agentArr)
      this.agentQueueCall = ((result[queue]) ? result[queue]['calls'] : '');
      console.log(this.agentQueueCall)

      if (this.agentQueueCall != undefined && this.agentQueueCall != '') {
        this.showCallWaitTable = true
        this.agentQueueCall.forEach(element => {
          var channel = ((element.chaninfo) ? element.chaninfo['channel'] : '');
          console.log(element.chaninfo)
          var callerid = ((element.chaninfo) ? element.chaninfo['callerid'] : '');
          var duration_str = ((element.chaninfo) ? element.chaninfo['duration_str'] : '');
          var waittime = ((element) ? element['waittime'] : '');

          this.callWaitArr.push({
            'channel': channel, 'callerid': callerid, 'duration_str': duration_str,
            'waittime': waittime
          })
        });

        this.dataSource2 = new MatTableDataSource(this.callWaitArr);

        this.phoneWait = this.phoneWait + this.agentQueueCall.length

      }

      this.agentList = Object.values(this.agentArr)
      this.loggedInCnt = this.agentList.length
      console.log(this.agentQueueCall)
      this.readyCnt = this.agentList.filter(function (result) {
        return (result.type == "not in use") && result.Status == '';
      });
      this.readyCnt = this.readyCnt.length

      this.pauseCnt = this.agentList.filter(function (result) {
        return result.Status == "paused" && result.PauseCode != '' && result.type == 'not in use';
      });

      this.onCallPauseCnt = this.agentList.filter(function (result) {
        return result.Status == "" && result.type == 'unknown';
      });


      this.phoneAcw = this.agentList.filter(function (result) {
        return result.Status == "paused" && result.PauseCode == '';
      });

      this.phoneAcw = this.phoneAcw.length


      this.phoneOnCall = this.agentList.filter(function (result) {
        return result.type == 'busy';
      });
      this.phoneOnCall = this.phoneOnCall.length


      this.pauseCnt = this.pauseCnt.length + this.onCallPauseCnt.length

      this.dataSource = new MatTableDataSource(this.agentList);
      this.dataSource.paginator = this.paginator;
    })
  }


  getAgentRtmDashboardCnt() {
    this.api.getAgentRtmDashboardCnt().subscribe((result) => {
      console.log('dashboard cnt' + result[0])
      this.phoneLoggedIn = ((result[0]['logged_in_count']) ? result[0]['logged_in_count'] : 0);
      console.log('phone login' + this.phoneLoggedIn)
      this.phoneOnCall = ((result[0]['on_call_count']) ? result[0]['on_call_count'] : 0);
      this.phoneReady = ((result[0]['ready_count']) ? result[0]['ready_count'] : 0);
      this.phonePause = ((result[0]['paused_count']) ? result[0]['paused_count'] : 0);
      this.phoneAcw = ((result[0]['acw_count']) ? result[0]['acw_count'] : 0);
      this.phoneWait = ((result[0]['call_waiting_count']) ? result[0]['call_waiting_count'] : 0);
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    });
  }

  getAllLivePhoneAgents() {
    this.dataSource = new MatTableDataSource([]);
    const accountList = this.api.checkUserRealTimeStatus().subscribe(result => {
      console.log('live agent' + result)

      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    });
  }

  getIbObPhoneSummary(result) {
    this.Answered = 0
    this.Abandoned = 0
    this.ServiceLevel = 0
    this.abandoned_per = 0
    this.allArrQueues = []
    var queue = String(this.selectedQueue)
    const queueArr = []
    console.log(this.selectPhoneProcess)
    if (this.selectPhoneProcess == 'MANUAL') {
      this.offered = 'Attempted'
      this.answered = 'Answered'
      this.abandoned = 'Unanswered'
      this.abandonedPer = 'Unanswered %'
      this.avg_wait_time = 'Avg Ringing'
      this.sl = 'Contacts Connect %'
      this.aht = 'AHT'
      this.occupancy = 'Occupancy'
    } else {
      this.offered = 'Offered'
      this.answered = 'Answered'
      this.abandoned = 'Abandoned'
      this.abandonedPer = 'Abandoned %'
      this.avg_wait_time = 'Avg Waiting Time'
      this.sl = 'Service Level %'
      this.aht = 'AHT'
      this.occupancy = 'Occupancy %'
    }
    if (queue == 'all') {
      this.allArrQueues = Object.keys(result)
      this.allArrQueues.forEach(queue => {
        this.ibData = ((result[queue]) ? result[queue] : '');
        this.Answered = this.Answered + Number((this.ibData.Answered) ? this.ibData.Answered : 0);
        this.Abandoned = this.Abandoned + Number((this.ibData.Abandoned) ? this.ibData.Abandoned : 0);
        this.nCalls = (this.Answered + this.Abandoned);
        this.ServiceLevel = this.ServiceLevel + Number((this.ibData.ServiceLevel) ? this.ibData.ServiceLevel : 0);
        this.abandoned_per = ((this.Abandoned / this.nCalls) * 100)
      })
    } else {
      this.ibData = ((result[queue]) ? result[queue] : '');
      this.Answered = Number((this.ibData.Answered) ? this.ibData.Answered : 0);
      this.Abandoned = Number((this.ibData.Abandoned) ? this.ibData.Abandoned : 0);
      this.nCalls = (this.Answered + this.Abandoned);
      this.ServiceLevel = ((this.ibData.ServiceLevel) ? this.ibData.ServiceLevel : 0);
      this.abandoned_per = ((this.Abandoned / this.nCalls) * 100)
    }


    queueArr.push({
      'nCalls': this.nCalls, 'Answered': this.Answered, 'Abandoned': this.Abandoned,
      'ServiceLevel': this.ServiceLevel, 'abandonedPer': this.abandoned_per
    })
    this.dataSource1 = new MatTableDataSource(queueArr);
    this.dataSource1.paginator = this.paginator;
  }
  loadWorkcloud() {
    if (this.report_start_date && this.report_end_date) {
      if (this.report_start_date <= this.report_end_date) {
        let start_date = this.getDateString(this.report_start_date)
        let end_date = this.getDateString(this.report_end_date)
        this.api.getWordCloudCount(start_date, end_date).subscribe((count) => {
          var lines = [];
          count.forEach(element => {

            element.intent.forEach(element1 => {
              lines.push(element1);
            })
          });
          // console.log(count,"count111111111")var lines=[];
          // count.forEach(element => {

          //   element.intent.forEach(element1 => {
          //     lines.push(element1);
          //   })
          // });
        // count.forEach(element => {
          var obj = { name: '', weight: 0 }
          // var abc = element.intent 
          var data = Highcharts.reduce(lines, function (arr, word) {
            // var  data = abc.reduce((arr, word) => {
            obj = Highcharts.find(arr, obj => obj.name === word);
            if (obj) {
              obj.weight += 1;
            } else {
              obj = {
                name: word,
                weight: 1
              };
              arr.push(obj);
            }
            return arr;
          }, []);
          Highcharts.chart('container', {
            accessibility: {
              screenReaderSection: {
                beforeChartFormat: '<h5>{chartTitle1}</h5>' +
                  '<div>{chartSubtitle}</div>' +
                  '<div>{chartLongdesc}</div>' +
                  '<div>{viewTableButton}</div>'
              }
            },
            series: [{
              type: 'wordcloud',
              data: data,
              name: 'Occurrences',
            }],
            title: {
              text: 'Word Cloud',
            },
            // subtitle: {
            //   text: 'An excerpt from chapter 1: Down the Rabbit-Hole',
            //   align: 'left'
            // },
            tooltip: {
              headerFormat: '<span style="font-size: 16px"><b>{point.key}</b></span><br>'
            },
            credits: {
              enabled: false
            },
          });
        })
      }
    }
  }

}
