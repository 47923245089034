import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Phone } from 'src/app/circle-one/telephony/model/phone.model';
import { PhoneService } from 'src/app/circle-one/telephony/services/phone.service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MatDialogRef } from '@angular/material';
import { interval } from 'rxjs';


@Component({
  selector: 'app-dialpad',
  templateUrl: './dialpad.component.html',
  styleUrls: ['./dialpad.component.sass']
})
export class DialpadComponent implements OnInit {
  callTimerEvent: any;
  hangupObCall: boolean;
  parkCustomer: string;

  constructor(
    public phoneModel : Phone,
    public toaster : ToastrService,
    public phoneApi : PhoneService,
    public router : Router,
    public dialogRef: MatDialogRef<DialpadComponent>,
  ) { }

  ngOnInit() {
  }
 
  timer() {
    this.phoneModel.callTimerEvent && this.phoneModel.callTimerEvent.unsubscribe();
   
    $('#callTimer').val('00:00:00');

    var i = 0;

    this.phoneModel.callTimerEvent && this.phoneModel.callTimerEvent.unsubscribe();
    const source = interval(1000);
    this.phoneModel.callTimerEvent = source.subscribe(val => {
      const newTime = ['00:00:0' + i];
      const sum = newTime.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());

      const sum1 = Math.floor(sum.asHours());
      const sum2 = Math.floor(sum.minutes());
      const sum3 = Math.floor(sum.seconds());

      let ms = sum1 + ':' + sum2 + ':' + sum3;
      $('#callTimer').val(ms);
    i++;
    } )
  }

  dial() {
   
      this.dialogRef.close()
      this.phoneModel.dialPadCall = 'active'
      let urlRoute = localStorage.getItem('lastUrl');
      var splitPath = urlRoute.split('?')
      var path = ((splitPath[0]) ? splitPath[0] : null);
      let allData = { 'path': path };
      this.router.navigate(['/phone-load'], { queryParams: allData });

  }
  

  hangupCall() {
    this.dialogRef.close()
    this.phoneModel.dialPadCall = 'deactive'
    let urlRoute = localStorage.getItem('lastUrl');
    var splitPath = urlRoute.split('?')
    var path = ((splitPath[0]) ? splitPath[0] : null);
    let allData = { 'path': path };
    this.router.navigate(['/phone-load'], { queryParams: allData });
  }

 


}
