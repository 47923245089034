import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../../code-modules/api/api-services/api.service';
import { AddCustomerContactDialogComponent } from '../../add-customer-contact-dialog/add-customer-contact-dialog.component';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// import { ContactHistoryDialogComponent } from '../../contact-history-dialog/contact-history-dialog.component';
import { DataDrivenForm } from 'src/app/code-modules/utils/data-driven-forms/DataDrivenForm';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { InAppLogger } from 'src/app/code-modules/utils/general-utils/InAppLogger';
import { ValidationMessages, ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';
import { CustomerListService } from '../customers.service';
import { ContactAccountMappingDialogComponent } from '../../contact-account-mapping-dialog/contact-account-mapping-dialog.component';
import { Observable, Subscription } from 'rxjs';
import {
  AccountCreateUpdate,
  AccountRead,
  ContactRead,
  Tag
} from '../../../../../code-modules/api/interfaces/Customer/customer-api-interfaces';
import { ContactHistoryDialogComponent } from '../../contact-history-dialog/contact-history-dialog.component';
import { UserTeamList } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.sass']
})
export class AccountDetailsComponent implements OnInit, OnDestroy {
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  contactsCount = undefined
  cards_opened: number[] = [];
  userRole
  progressiveLoad = false;

  pageNumber = 1;
  next: boolean = false;
teams
  customerTags = []
  selectedTags = [];
  accessible_teams=[];
  selectedTeams =[];
  customerTeamsEdit=this.selectedTeams;
  customerTagsEdit = this.selectedTags;
  subscribers: Array<Subscription> = []

  logger = new InAppLogger(AccountDetailsComponent.name)
  accountId: number;
  accounts: AccountRead;
  accountContactList: Array<ContactRead> = [];
  customerAccountForm: FormGroup;
  edit = true;
  dynamicForm = new DataDrivenForm([]);
  public userDepartment$: Observable<UserTeamList>;


  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    public addCustomerContactDialog: MatDialog,
    public mapContacctDialog: MatDialog,
    public fromBuilder: FormBuilder,
    private toaster: ToastrService,
    public context: AppContextService,
    public toggleService: CustomerListService,
    private router:Router,
    public contactHistoryMatDialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.context.currentUser$.subscribe((user)=>{
      this.userRole = user
      console.log( this.userRole ,"users")
    })
    this.route.params.subscribe(params => {
      this.accountId = Number(params.id);
      this.fetchAccountDatails();

      if (params.action == "edit") {
        this.edit = false;
      }
      const tagList = this.api.CustomerTagList().subscribe((tags) => {
        this.customerTags = tags
        this.customerTagsEdit = this.edit ? this.selectedTags : this.customerTags;
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })

      this.subscribers.push(tagList)
      // const teamlist= this.api.userTeamList().subscribe((team)=>{

      // }

      // console.log('the Customer account id is', this.accountId);
    });

   this. loadUserTeamList() 


  }

  // serchTeam =[];
  // loadUserTeamList() {
  //   this.api.ConatctTeamList()
  //     .subscribe((team) => {
  //       console.log(team, "Team")
  //       // this.team = team
  //       this.serchTeam=team
  //       this.customerTeamsEdit = this.edit ? this.selectedTeams : this.serchTeam;

  //     })
  // }

  loadUserTeamList(){
    if(this.userRole.role == 'admin'){
      this.api.userTeamList()
      .subscribe((team:UserTeamList)=>{
        console.log(team,"Team")
        this.teams = team
      })
    }else{
      this.api.userDetails(this.userRole.id)
      .subscribe((team)=>{
        console.log(team,"Team")
        this.teams = team.teams
        console.log(this.teams,"login teams" )
      })
    }
    
  }


  fetchContactsUnderAcccount() {
    const contactApi = this.api.contactList(this.pageNumber, this.accountId).subscribe((contacts) => {
      if (this.progressiveLoad)
        this.accountContactList = [...this.accountContactList, ...contacts.results];
      else
        this.accountContactList = [...contacts.results]
      this.contactsCount = contacts.count;
      this.next = contacts.next && contacts.next !== null ? true : false
    },(err)=>{
      this.toaster.error(ErrorToaster(err))
    })
    this.subscribers.push(contactApi)
  }

  fetchAccountDatails() {
    ////this.loader.start();
    const accountApi = this.api.accountDetails(this.accountId).subscribe(result => {
      console.log(result,"result account")
      this.customerAccountForm = this.fromBuilder.group({
        name: [result.name, [Validators.required]]
      });
      this.accounts = result;
      this.selectedTags = result.tags ? result.tags : [];
      this.customerTagsEdit = this.selectedTags;
      this.selectedTeams = result.accessible_teams? result.accessible_teams: [];
      this.customerTeamsEdit=this.selectedTeams
      

      this.context.customerAccountDynamicFields$.subscribe(fieldData => {
        this.logger.log('Loaded fields:', fieldData ,this.accounts.dynamic_fields);
        if (fieldData.length) {
          this.dynamicForm = new DataDrivenForm(fieldData, this.accounts.dynamic_fields || {});
          console.log(this.dynamicForm.fields,"...................")
        }

      });
      this.fetchContactsUnderAcccount();
      // //this.loader.stop();
    }, error => {
      console.log('account list fetch operation fails', error);
      // //this.loader.stop();
      this.toaster.error(ErrorToaster(error))
      // this.toaster.error('Something Went Wrong');
    });
    this.subscribers.push(accountApi)

  }


  openAddContactDialog(): void {
    const dialogRef = this.addCustomerContactDialog.open(AddCustomerContactDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: { accountId: this.accountId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetContact();
        this.fetchAccountDatails();
      }
      console.log('The dialog was closed');
    });
  }

  openMapContactDialog() {
    const dialogRef = this.mapContacctDialog.open(ContactAccountMappingDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: { account: this.accounts }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetContact();
        this.fetchAccountDatails();
      }
      console.log('The dialog was closed');
    });
  }

  onEdit() {
    this.edit = !this.edit;
    this.customerTagsEdit = this.edit ? this.selectedTags : this.customerTags;
    this.customerTeamsEdit=this.edit?this.selectedTeams:this.teams;
  }


  getTagIds() {
    let tags = [];
    this.selectedTags.forEach((tag) => {
      tags.push(tag.id)
    })
    return tags
  }
  getTeamIds() {
    let teams = [];
    this.selectedTeams.forEach((team) => {
      teams.push(team.id)
    })
    return teams
  }

  onSave() {
    // //this.loader.start();
    if (this.customerAccountForm.valid && this.dynamicForm.formGroup.valid) {
      let request = <AccountCreateUpdate>this.customerAccountForm.value
      request.dynamic_fields = this.dynamicForm.formGroup.value
      request.tags = this.getTagIds()
      request.accessible_teams=this.getTeamIds();

      const updateApi = this.api.accountUpdate(request, this.accountId).subscribe(resp => {
        console.log('account updated successfully', resp);
        //  //this.loader.stop();
        this.toaster.success('Account Updated Successfully');
        this.onEdit();
      }, error => {
        console.log('account update failed', error);
        //  //this.loader.stop();
        this.toaster.error(ErrorToaster(error));
      });

      this.subscribers.push(updateApi)

    } else {
      this.toaster.error('Please fill required fields.');
      this.customerAccountForm.markAllAsTouched();
      this.dynamicForm.formGroup.markAllAsTouched();
      ////this.loader.stop();
    }
  }

  toggleTagSelection(tag: Tag) {
    if (!this.edit) {
      const index = this.selectedTags.map(obj => { return obj.id }).indexOf(tag.id);
      index > -1 ? this.selectedTags.splice(index, 1) : this.selectedTags.push(tag)
    }
  }
  toggleTeamSelection(team:Tag) {
    if (!this.edit) {
      const index = this.selectedTeams.map(obj => { return obj.id }).indexOf(team.id);
      index > -1 ? this.selectedTeams.splice(index, 1) : this.selectedTeams.push(team)
    }
  }
  isSelectedTag(tag: Tag) {
    return this.selectedTags.map((obj => { return obj.id })).includes(tag.id)
  }
  isSelectedTeam(team:Tag) {
    return this.selectedTeams.map((obj => { return obj.id })).includes(team.id)
  }
  getErrorMessage() {
    return ValidationMessages(this.customerAccountForm.controls);
  }

  contactDynamicFields(contact: any) {
    return Object.keys(contact);
  }
  backToCustomerList() {
    this.toggleService.toggle(false);
  }

  resetContact() {
    this.progressiveLoad = false;
    this.pageNumber = 1;
  }
  onScrollDown() {
    if (this.next) {
      this.pageNumber++;
      this.progressiveLoad = true;
      this.fetchContactsUnderAcccount()
    }
  }
  ngOnDestroy() {
    this.subscribers.forEach((sub) => sub.unsubscribe())
  }

  viewMore_viewLess(id: number) {
    // const idx = this.cards_opened.indexOf(id);
    // if (idx !== -1)
    //   this.cards_opened = [ ]
    // else
    //   this.cards_opened = [id]
    this.router.navigate(['/app/customers/contact', id, 'edit', { account: this.accountId }])
  }

  is_card_opened(id:number){
    return this.cards_opened.includes(id)
  }
  dynamic_fields_values(dynamic_fields:any){
    const values = Object.values(dynamic_fields).filter(Boolean);
    return values.length ? true : false
  }

    // contact history dialog
    openContactHistoryDialog() {
      console.log(this.accountId);
      this.api.customerActionList(undefined,this.accountId)
      .subscribe((data)=>{
        // console.log(data.results)
        const dialogRef = this.contactHistoryMatDialog.open(ContactHistoryDialogComponent, {
          panelClass: 'co-dialog-panel',
          data: { action: data }
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        });
      })
  
    }
}
