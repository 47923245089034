import { Component, Input, OnInit } from '@angular/core';
import { Moment } from 'moment';
import { Color } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { AllStatus, StatusDisplay } from 'src/app/code-modules/api/interfaces/Ticket/ticket-api-interfaces';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { UserRoles } from 'src/app/code-modules/saas/saas.enums';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';

@Component({
  selector: 'app-ticket-line-graph',
  templateUrl: './ticket-line-graph.component.html',
  styleUrls: ['./ticket-line-graph.component.sass']
})
export class TicketLineGraphComponent implements OnInit {
  selectedPrevious: {startDate: Moment, endDate: Moment};
  selectedCurrent:  {startDate: Moment, endDate: Moment};
// lineChart
public lineChartData:Array<any> = [
  {
    data: [], 
    label: 'Interval 1', 
    backgroundColor:'#4791FF',
    pointBackgroundColor:'#4791FF',
    pointRadius:4
    // lineTension: 0
  },
  {
    data: [], 
    label: 'Interval 2', 
    backgroundColor:'#02BC77',
    pointBackgroundColor:'#02BC77',
    pointRadius:4
    // lineTension: 0
  },
];
public lineChartLabels:Array<any> = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00',
'08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00',
'20:00','21:00','22:00','23:00'];
public lineChartOptions = {
  responsive: true,
  legend: {
    position: 'bottom',
    fill:{
      type:'solid',
    },
    labels: {
      fontSize: 10,
     
      // usePointStyle: true
    }
  },
  scales: {
    xAxes: [{
      VISIBLE:true,
         gridLines: {
          display:true,
          color : 'transparent',
          zeroLineWidth : 1,
          zeroLineColor : "blue"
         },
         scaleLabel: {
          display: true,
          labelString: "Time(Hours)",
         },
      }],
    yAxes: [{
      VISIBLE: true,

         gridLines: {
            display:true,
            color : 'transparent',
            zeroLineWidth : 1,
            zeroLineColor : "blue"
         },
         scaleLabel: {
          display: true,
          labelString: "Count",
         },
         ticks: {
          beginAtZero: true,
          userCallback: function(label, index, labels) {
              // when the floored value is the same as the value we have a whole number
              if (Math.floor(label) === label) {
                  return label;
              }

          },
      }
      }]
    }
};

public lineChartColors: Color[] = [
  {
    borderColor: '#4791FF',
    backgroundColor: 'rgb(255 255 255)'
  },
  {
    borderColor: '#02BC77',
    backgroundColor: 'rgb(255 255 255)',
  }
];
public lineChartLegend = {
      horizontalAlign: "left", // "center" , "right"
      verticalAlign: "center",  // "top" , "bottom"
      fontSize: 150
}
public lineChartType:string = 'line';
previous_start_Date
previous_end_Date
current_start_Date
current_end_Date
@Input() dashboard
@Input() selectedTeam
selectedWorkflow
allWorkflow
ticketStatusDisplay = []
ticketStatusObject: Array<AllStatus> = [];
selectedStatus
currentUser
selectedStatusType
  constructor(public api:ApiService,
    public context:AppContextService,
    public toaster:ToastrService) { }

  ngOnInit() {
    this.context.currentUser$.subscribe((user) => {
      this.currentUser = user;
    });
    this.loadWorkFlows()
    console.log(this.selectedTeam)
  }
  loadWorkFlows() {
    // //this.loader.start();
    this.api.ticketWorkFlowList().subscribe((workFlows) => {
      console.log(workFlows);
      this.allWorkflow = workFlows.results
      if(this.allWorkflow.length){
        this.selectedWorkflow = this.allWorkflow[0].id
        this.loadWordFlowsWiseStatus(this.allWorkflow[0])
      }
      
      
      console.log(this.selectedWorkflow , "workflow")
      this.showWorkflowWiseData()
    },(err)=>{
      this.toaster.error(ErrorToaster(err))
    })
  }

  loadWordFlowsWiseStatus(workflow){
    console.log(workflow ,"1@!3$$%")
    workflow.all_statuses.forEach(element => {
      console.log(element ,"...")
      // if(Object.prototype.toString.call(element.status) == "[object Object]"){
        // this.ticketStatusDisplay.push(element.status.status)
        this.ticketStatusObject = workflow.all_statuses || [];
        this.ticketStatusDisplay = this.ticketStatus;
        // }else{
        //   this.ticketStatusDisplay.push(element.status)
        // }
    });
  }
  get ticketStatus() {
    const currentUserGroups = this.currentUser.teams.map(group => group.id);
    return this.ticketStatusObject.map((statusObj, index) => {
      console.log(statusObj)
      
        if(statusObj.status!=null)
        if (typeof statusObj.status === 'string') {
            return this.visible_status(statusObj, statusObj.status, currentUserGroups, index);
            } else {
                return this.visible_status(statusObj, statusObj.status.status, currentUserGroups, index);
            }
        }).filter(value => value !== undefined);
    }

    visible_status(status_object: AllStatus, status: string, currentUserGroups: Array<number>, index: number): StatusDisplay | undefined {
        if (status_object.visibility_group && status_object.visibility_group !== null) {
            if (currentUserGroups.includes(status_object.visibility_group) || this.currentUser.role === UserRoles.ADMIN) {
                return {name: status, index: index ,status_type:status_object.status_type};
            } else {
                return undefined;
            }
        } else {
            return {name: status, index: index ,status_type:status_object.status_type};
        }
    }
  showStatusWiseData(){
    console.log(this.selectedStatus ,"MZMZZM")
    this.selectedStatusType = this.selectedStatus.status_type
    this.showWorkflowWiseData()
  }

  dateChangePrevious(e){
    if(this.selectedPrevious){
      if(this.selectedPrevious.startDate && this.selectedPrevious.endDate){
        this.previous_start_Date = this.selectedPrevious.startDate.format('YYYY-MM-DD')
        this.previous_end_Date = this.selectedPrevious.endDate.format('YYYY-MM-DD')
      }
    }
    
    
  }
  dateChangeCurrent(e){
    if(this.selectedCurrent){
      if(this.selectedCurrent.startDate && this.selectedCurrent.endDate){
        this.current_start_Date = this.selectedCurrent.startDate.format('YYYY-MM-DD')
        this.current_end_Date = this.selectedCurrent.endDate.format('YYYY-MM-DD')
        let status = this.selectedStatus?this.selectedStatus.name:undefined
        if(this.previous_start_Date && this.previous_end_Date && this.current_start_Date,this.current_end_Date){
          this.api.dashboardApi(undefined,undefined , undefined ,this.selectedTeam , this.selectedWorkflow,
            undefined , undefined , this.current_start_Date , this.current_end_Date,this.previous_start_Date,this.previous_end_Date,
            undefined,status,this.selectedStatusType).subscribe((data)=>{
            this.lineChartData[1].data =[]
            this.lineChartData[0].data =[]
            Object.keys(data.ticket_line_graph[0].current_ticket_count_hourwise).forEach((key)=>{
              this.lineChartData[1].data.push(data.ticket_line_graph[0].current_ticket_count_hourwise[key])
              // this.lineChartLabels.push(key)
            })
            Object.keys(data.ticket_line_graph[0].prev_ticket_count_hourwise).forEach((key)=>{
              this.lineChartData[0].data.push(data.ticket_line_graph[0].prev_ticket_count_hourwise[key])
              // this.lineChartLabels.push(key)
            })
          },(err)=>{
            this.toaster.error(ErrorToaster(err))
          })
        }
    }
    
    }
  }

  // setLineGraphData(){
  //   this.lineChartData[1].data =[]
  //   this.lineChartData[0].data =[]
  //   Object.keys(this.dashboard.ticket_line_graph[0].current_ticket_count_hourwise).forEach((key)=>{
  //     this.lineChartData[1].data.push(this.dashboard.ticket_line_graph[0].current_ticket_count_hourwise[key])
  //     // this.lineChartLabels.push(key)
  //   })
  //   Object.keys(this.dashboard.ticket_line_graph[0].prev_ticket_count_hourwise).forEach((key)=>{
  //     this.lineChartData[0].data.push(this.dashboard.ticket_line_graph[0].prev_ticket_count_hourwise[key])
  //     // this.lineChartLabels.push(key)
  //   })
  // }
  
  public chartHovered(e:any):void {
    console.log(e);
  }
  showWorkflowWiseData(){
    if(this.selectedWorkflow == '0'){
      this.selectedWorkflow = undefined
    }
    let status = this.selectedStatus?this.selectedStatus.name:undefined
    this.api.dashboardApi(undefined,undefined , undefined ,this.selectedTeam , this.selectedWorkflow,
      undefined , undefined , this.current_start_Date , this.current_end_Date,this.previous_start_Date,this.previous_end_Date,undefined,status,
      this.selectedStatusType)
      .subscribe((data)=>{
        this.lineChartData[1].data =[]
        this.lineChartData[0].data =[]
          Object.keys(data.ticket_line_graph[0].current_ticket_count_hourwise).forEach((key)=>{
            this.lineChartData[1].data.push(data.ticket_line_graph[0].current_ticket_count_hourwise[key])
            // this.lineChartLabels.push(key)
          })
          Object.keys(data.ticket_line_graph[0].prev_ticket_count_hourwise).forEach((key)=>{
            this.lineChartData[0].data.push(data.ticket_line_graph[0].prev_ticket_count_hourwise[key])
            // this.lineChartLabels.push(key)
          })
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
  }
}
