import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import 'rxjs/add/operator/map'
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Phone } from '../model/phone.model';

@Injectable({
  providedIn: 'root'
})
export class PhoneService {
  currentUser: any;
  tenant: string = '';
  ext: any;
  apiPassword: string;
  url: string;

  constructor(
    private http: HttpClient,
    public context: AppContextService, // used in template
    public phoneModel: Phone,


  ) {

   this.apiPassword = '6601pwd'
  }

  commoonApiFunc(data) {

    if (this.tenant != '') {
      return this.http.request("POST", "https://" + this.tenant + "telephony.bluewhirl." + this.ext + "/agc/api_json.php", {

        params: {
          source: 'test',
          user: '6601',
          pass: this.apiPassword,
          agent_user: data['phoneLogin'],
          function: data['apiFunc'],
          value: data['apiVal'],
        },
      })
        .pipe(map(res => {
          return res;
        }));
    }

  }


  dial(phoneLogin, mobileNo, priNo, interactionid) {

    this.url = JSON.parse(localStorage.getItem('phoneUrl'))

    if (mobileNo > 10) {
      var mobileNo = mobileNo.substr(mobileNo.length - 10);
    }

    if (this.url != null) {

      return this.http.request("POST", this.url + "click2call/calls.php", {

        params: {
          CustPhone: '91' + mobileNo,
          AgentExten: phoneLogin,
          SecKey: '2c41c5e95e052e9d03f423e9f578b9b3',
          CallerID: priNo,
          interactionid: interactionid
        },
      })
        .map(res => { return res });

    }
  }


  phoneLoginLogout(data) {
      let phoneUrl = JSON.parse(localStorage.getItem('phoneUrl'))
      this.url  = ((phoneUrl) ? phoneUrl : this.phoneModel.phoneDomainUrl);
      console.log(this.url)
      return this.http.request("POST", this.url + "click2call/acd.php", {

        params: {
          AgentExten: data['extension'],
          Operation: data['action'],
          Queue: data['queue'],
          SecKey: '2c41c5e95e052e9d03f423e9f578b9b3',
          AgentName: data['username'],
          Penalty: data['penalty']
        },
      })
        .pipe(map(res => {
          return res;
        }));
  }

  getRealTimeData() {

    this.url = JSON.parse(localStorage.getItem('phoneUrl'))

    if (this.url != null) {
      return this.http.request("GET", this.url + "click2call/QueueRealTimeReport.php", {
      })
        .pipe(map(res => {
          return res;
        }));
    }
  }

  phonePauseUnpause(data) {

    this.url = JSON.parse(localStorage.getItem('phoneUrl'))

    if (this.url != null) {
      return this.http.request("POST", this.url + "click2call/acd.php", {

        params: {
          AgentExten: data['extension'],
          Operation: data['action'],
          Queue: data['queue'],
          SecKey: '2c41c5e95e052e9d03f423e9f578b9b3',
          PauseCode: data['pauseCode'],
          break_id: data['breakId']
        },
      })
        .pipe(map(res => {
          return res;
        }));
    }
  }

  phoneHangup(data) {

    let phoneUrl = JSON.parse(localStorage.getItem('phoneUrl'))
    this.url  = ((phoneUrl) ? phoneUrl : this.phoneModel.phoneDomainUrl);

    if (this.url != null) {
      return this.http.request("POST", this.url + "click2call/hangup.php", {

        params: {
          AgentExten: data['extension'],
          SecKey: '2c41c5e95e052e9d03f423e9f578b9b3',
        },
      })
        .pipe(map(res => {
          return res;
        }));
    }
  }

  phoneHoldRetrieve(data) {

    this.url = JSON.parse(localStorage.getItem('phoneUrl'))

    if (this.url != null) {
      return this.http.request("POST", this.url + "click2call/Transfer.php", {

        params: {
          Number: data['extension'],
          TransferNumber: '91' + data['mobileNo'],
          Type: data['action'],
          SecKey: '2c41c5e95e052e9d03f423e9f578b9b3',
        },
      })
        .pipe(map(res => {
          return res;
        }));
    }
  }


  transferACall(data, secondCallNo, type) {

    this.url = JSON.parse(localStorage.getItem('phoneUrl'))

    if (secondCallNo > 10) {
      var secondCallNo = secondCallNo.substr(secondCallNo.length - 10);
    }

    if (this.url != null) {
      return this.http.request("POST", this.url + "click2call/Transfer.php", {

        params: {
          Number: data['extension'],
          TransferNumber: '91' + secondCallNo,
          Type: type,
          SecKey: '2c41c5e95e052e9d03f423e9f578b9b3',
        },
      })
        .pipe(map(res => {
          return res;
        }));
    }


  }

  hangupThirdParty(data) {
    this.url = JSON.parse(localStorage.getItem('phoneUrl'))

    if (data['secondCallNo'] > 10) {
      var secondCallNo = data['secondCallNo'].substr(data['secondCallNo'].length - 10);
    }
    if (this.url != null) {
      return this.http.request("POST", this.url + "click2call/Transfer.php", {

        params: {
          Number: data['extension'],
          TransferNumber: '91' + secondCallNo,
          Type: data['type'],
          SecKey: '2c41c5e95e052e9d03f423e9f578b9b3',
        },
      })
        .pipe(map(res => {
          return res;
        }));
    }


  }

  hangupConference(data) {

    this.url = JSON.parse(localStorage.getItem('phoneUrl'))

    if (this.url != null) {
      return this.http.request("POST", this.url + "click2call/Transfer.php", {

        params: {
          Number: data['extension'],
          Type: data['type'],
          SecKey: '2c41c5e95e052e9d03f423e9f578b9b3',
        },
      })
        .pipe(map(res => {
          return res;
        }));
    }
  }




  getInboundCall(extension) {

    if (this.tenant != '') {
      return this.http.request("GET", 'https://meta.api.bluewhirl.org/api/v1/telephony/inbound-call/?extension=' + extension)
        .pipe(map(res => {
          return res;
        }));
    }
  }

  getTelephonyConfiguration() {

    return this.http.request("GET", 'https://sales.api.bluewhirl.org/api/v1/telephony/telephony-server-config/')
      .pipe(map(res => {
        return res;
      }));
  }

  addUserInPhoneDb(userId, username) {

    if (this.tenant == 'pinc') {
      var agentGroup = 'Inbound'
    } else if (this.tenant == 'biosash') {
      var agentGroup = 'INBgroup'
    } else if (this.tenant == 'sales') {
      var agentGroup = 'agent'
    }
    else {
      var agentGroup = 'AgentGroup'
    }

    if (this.tenant != '') {
      return this.http.request("POST", "https://" + this.tenant + "telephony.bluewhirl." + this.ext + "/vicidial/non_agent_api_json.php", {

        params: {
          source: 'test',
          user: '6601',
          pass: this.apiPassword,
          function: 'add_user',
          agent_user: userId,
          agent_pass: userId,
          agent_user_level: '1',
          agent_full_name: username,
          agent_user_group: agentGroup,
        },
      })
        .pipe(map(res => {
          return res;
        }));
    }


  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
}

