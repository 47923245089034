import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserRoles } from 'src/app/code-modules/saas/saas.enums';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { MatchTenantDomain } from 'src/app/shared/utils/validators/tenantDomain.validator';
import { UserTeamList } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
import { DataDrivenForm } from 'src/app/code-modules/utils/data-driven-forms/DataDrivenForm';
import { PhoneService } from 'src/app/circle-one/telephony/services/phone.service';
import { ContactBulkUpload, UserBulkUpload } from 'src/app/code-modules/api/interfaces/Business-Rule/business-rule-api-interfaces';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';



@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.sass']
})
export class InviteDialogComponent implements OnInit {
  loggerName = '[/app/users/invite]';
  inviteForm: FormGroup;
  formInitialised = false;
  userRoles = UserRoles;
  csvFile: File;
  // userGender = UserGender;
  tenantDomain: string;
  isBulk = false;
  teams: UserTeamList
  dynamicForm = new DataDrivenForm([]);
  currentUser: any;
  tenant: string = '';
  submitted = false
  constructor(
    public dialogRef: MatDialogRef<InviteDialogComponent>,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private context: AppContextService,
    public phoneApi: PhoneService,
    private toaster: ToastrService,
  ) {
    this.context.currentUser$.subscribe(user => {
      this.currentUser = user
      this.tenant = ((this.currentUser) ? this.currentUser.tenant.sub_domain : null);
      if (this.tenant == 'pinc') {
        this.tenant = 'pinc'
      } else if (this.tenant == 'biosash') {
        this.tenant = 'biosash'
      } else if (this.tenant == 'sales') {
        this.tenant = 'sales'
      } else {
        this.tenant = ''
      }
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onTypeChange(e: any) {
    this.isBulk = e.checked;
  }

  ngOnInit() {
    //set time to handle expression changed event
    this.context.userDynamicFields$.subscribe(fieldData => {
      // this.logger.log('Loaded fields:', fieldData);
      if (fieldData.length) {
        this.dynamicForm = new DataDrivenForm(fieldData, {});
        console.log(this.dynamicForm, "Form")
      } else {
        this.dynamicForm = new DataDrivenForm([]);
        console.log(this.dynamicForm, "Form....")
      }

    });
    this.loadUserTeamList()
    this.buildInviteForm()
  }

  loadUserTeamList() {
    this.api.userTeamList()
      .subscribe((team: UserTeamList) => {
        console.log(team, "Team")
        this.teams = team
      })
  }

  buildInviteForm() {
    ////this.loader.start();
    this.context.currentTenant$.subscribe(tenant => {
      this.tenantDomain = tenant.tenant_domain;

      this.inviteForm = new FormGroup({
        // id: new FormControl(undefined, []),
        role: new FormControl(undefined, [Validators.required]),
        username: new FormControl(undefined, [Validators.required]),
        dynamic_fields: this.dynamicForm.formGroup,
        avatar: new FormControl(undefined, []),
        phone: new FormControl(undefined, []),
        teams: new FormControl(undefined, [Validators.required])
      })


      // this.formBuilder.group({
      //   email: ['', [
      //     Validators.required,
      //     Validators.email,
      //     MatchTenantDomain(this.tenantDomain)
      //   ]],
      //   userRole: ['', [Validators.required]],
      //   // gender: ['', [Validators.required]],
      //   firstName: ['', [Validators.required]],
      //   lastName: ['', [Validators.required]],
      //   phone: ['', [Validators.required,
      //   Validators.pattern('^-?[0-9]*$'), // TODO update pattern to match real phone number only,
      //   Validators.minLength(10),
      //   Validators.maxLength(10),
      //   ]]
      // });
      // this.formInitialised = true;

      ////this.loader.stop();
    });


  }

  onSubmitCheck() {
    // console.log(this.inviteForm , "myform");return
    this.submitted = true;
    if (this.isBulk) {
      this.bulkUplaod()
    }
    else if (this.inviteForm.valid && this.dynamicForm.formGroup.valid) {
      this.submit();

    } else {
      this.toaster.info("Please fill mandatory fields.")
      this.inviteForm.markAllAsTouched()
      this.dynamicForm.formGroup.markAllAsTouched();
    }
  }

  submit() {
    // //this.loader.start();

    this.api.inviteUser(this.inviteForm.value).subscribe(success => {
      // this.toaster.success(`${this.inviteForm.value.username} has been invited`, 'Invitation Sent');
      // console.log(`${this.loggerName} Successfully added user: `, this.inviteForm.value, success);
      // //this.loader.stop();
      this.toaster.success( 'User created successfully');
      if (this.tenant == 'pinc' || this.tenant == 'biosash' || this.tenant == 'sales') {
        this.addUserInPhoneDb(success)
      }
      this.dialogRef.close();
    }, error => {

      if (error.status == 400 && error.error.username) {
        this.toaster.error('User already exist..!');
      } else {
        this.toaster.error('Server Error', 'Please try again');
      }
      //  //this.loader.stop();
      console.error(`${this.loggerName} Failed to add user: `, this.inviteForm.value, error);
    });
  }

  bulkUplaod() {
    if (this.csvFile) {
      const reader = new FileReader();
      //this.loader.start();
      reader.readAsDataURL(this.csvFile);
      reader.onload = (event: any) => {
        const fileContent = reader.result as string;
        const request: UserBulkUpload = {
          file_name: this.csvFile.name,
          csv_file: fileContent
        }
        console.log(request, "user upload req")
        this.api.userBulkCreate(request).subscribe((resp) => {
          this.toaster.success('Uploaded succesfully');
          this.dialogRef.close(true);
        }, (err) => {
          this.toaster.error(ErrorToaster(err))
        })
      }
    }


  }
  handleFileSelect(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.csvFile = fileList[0];
    }
  }
  download() {

    let header: string = "user_role,user_username,user_teams";

    if (this.dynamicForm && this.dynamicForm.fields && this.dynamicForm.fields.length) {
      this.dynamicForm.fields.forEach((field) => {
        let csvKey = field.controlName.replace(/ /g, "_");
        header = header + ",user_" + csvKey

      })

    }

    let blob = new Blob([header], { type: 'text/csv;charset=utf-8;' });
    let url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, 'UserInviteSample.csv');
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = 'UserInviteSample.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  addUserInPhoneDb(userData) {
    var id = ((userData['id']) ? userData['id'] : 0);
    var username = ((userData['username']) ? userData['username'] : null);

    let isCheck = username.includes('@')
    if (isCheck == true) {
      var splitUserId = username.split('@')
      var username = ((splitUserId[0]) ? splitUserId[0] : null);
      var userId = username + id
    } else {
      var userId = username + id
    }
    this.phoneApi.addUserInPhoneDb(userId, username).subscribe(
      data => {
        let msg = data.toString();
        if (msg.indexOf("SUCCESS") !== -1) {

        } else if (msg.indexOf("ERROR") !== -1) {
          this.toaster.error('Phone error : ' + msg);
        }
      });
  }

}
