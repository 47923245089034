import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-landing-pages',
  templateUrl: './landing-pages.component.html',
  styleUrls: ['./landing-pages.component.sass']
})
export class LandingPagesComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
  verifyAccount = false;
  ngOnInit() {
    console.log(this.route.snapshot.routeConfig.path )
    this.route.params.subscribe(params => {
      if(params.action == "verify"){
        this.verifyAccount = true;
      }
    });
  }

}
