import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ChangePasswordComponent } from './common-modules/components/change-password/change-password.component';
import { LoginComponent } from './common-modules/components/login/login.component';
import { SignupComponent } from './common-modules/components/signup/signup.component';
import { ForgotPasswordComponent } from './common-modules/components/forgot-password/forgot-password.component';
import { IfLoggedIn } from './code-modules/authentication/auth.guard.ifLoggedIn';
//import {CanDeactivateLogin} from './code-modules/authentication/route-deactivater';
import { NewPasswordComponent } from './common-modules/components/forgot-password/new-password/new-password.component';
import { DdfConfiguratorComponent } from './code-modules/utils/data-driven-forms/Configuration/ddf-configurator/ddf-configurator.component';
import { LandingPagesComponent } from './common-modules/components/landing-pages/landing-pages.component';
import { PageNotFoundComponent } from './common-modules/components/landing-pages/page-not-found/page-not-found.component';
import { DashboardComponent } from './circle-one/app/components/dashboard/dashboard.component';
import { RoleGuard } from './code-modules/authentication/role.guard';
import { ModuleIds } from './code-modules/saas/saas.enums';
import { ClientLoginPageComponent } from './circle-one/app/components/client-login-page/client-login-page.component';
import { ClientProfileComponent } from './circle-one/app/components/client-login-page/client-profile/client-profile.component';
import { SignupVerificationComponent } from './common-modules/components/signup-verification/signup-verification.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/app',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
    //canDeactivate: ['CanDeactivateLogin']
  }, {
    path: 'changePassword',
    component: ChangePasswordComponent
   // canDeactivate:['CanDeactivateChangePassword']
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'ticket-tracker',
    component: ClientLoginPageComponent,
  },
  {
    path: 'client-profile',
    component:ClientProfileComponent
  },
  {
    path: 'app',
    loadChildren: () => import('./circle-one/circle-one.module').then(m => m.CircleOneModule) ,
    //loadChildren: './circle-one/circle-one.module#CircleOneModule',
    // canActivate: [IfLoggedIn]
  },
  {
    path: 'super-admin',
    loadChildren: './super-admin/super-admin.module#SuperAdminModule'
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent
  }, {
    path: 'verificationSuccess',
    component: LandingPagesComponent
  },
  {
    path: 'resetPassword',
    component: NewPasswordComponent
  },
  {
    path: 'verification',
    component: SignupVerificationComponent
  },
  {
    path:'**',
    component:PageNotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [
    {
      provide: 'CanDeactivateLogin',
      useValue: (component: LoginComponent, currentRoute: ActivatedRouteSnapshot, currentState:
        RouterStateSnapshot, nextState: RouterStateSnapshot) => true
    },{
      provide: 'CanDeactivateChangePassword',
      useValue: (component: ChangePasswordComponent, currentRoute: ActivatedRouteSnapshot, currentState:
        RouterStateSnapshot, nextState: RouterStateSnapshot) => true
    }
  ]
})
export class AppRoutingModule {
}
