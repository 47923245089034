import { Component, OnInit, ViewChild, ViewChildren, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

import { AddCustomerContactDialogComponent } from '../add-customer-contact-dialog/add-customer-contact-dialog.component';
import { AddCustomerAccountDialogComponent } from '../add-customer-account-dialog/add-customer-account-dialog.component';
import { ApiService } from '../../../../code-modules/api/api-services/api.service';
import { ObserveOnSubscriber } from 'rxjs/internal/operators/observeOn';
import { BehaviorSubject, Observable, of, Subscriber, forkJoin, Subscription } from 'rxjs';
import { filter, map, mergeMap, skip, debounceTime, switchMap } from 'rxjs/operators';
import { async } from 'q';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { CustomerListService } from './customers.service';
import { PageEvent, MatPaginator } from '@angular/material';
import { FormControl } from '@angular/forms';
import {AccountRead, ContactRead} from '../../../../code-modules/api/interfaces/Customer/customer-api-interfaces';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';
import { saveAs } from 'file-saver'
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { Phone } from 'src/app/circle-one/telephony/model/phone.model';

enum PageType {
  CONTACTS = "contacts",
  ACCOUNTS = "accounts"
}

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.sass']
})
export class CustomersComponent implements OnInit, OnDestroy {
  subscribers: Array<Subscription> = []
  pageType = PageType
  loggerName = '[/app/customers]';
  listPerPage = 20;
  totalAccounts: number
  totalContacts: number
  pageEvent: PageEvent;
  isContact = false;
  accountsDisplayedColumns: string[] = ['name','created' ,'tag','actions'];
  contactsDisplayedColumns: string[] = ['name', 'email','created','last_interaction','tag','account','actions'];
  conatctPageNumber = 1;
  accountPageNumber = 1;
  accountNext = false;
  accountPrevious = false;
  contactNext = false;
  contactPrevious = false;
  search = new FormControl('')
  searchTerm: string = "";

  totalCount: number;
  // dataSource = new MatTableDataSource<ApiAccount>(ACCOUNTS_DATA);
  // contactsDataSource = new MatTableDataSource<ApiContact>(CONTACTS_DATA);
  accounts: MatTableDataSource<AccountRead>;
  contacts: MatTableDataSource<''>;
  allTagList;
  allContact;
  allAccount;
  //@ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('sortCol1', { static: true }) sortCol1: MatSort;
  @ViewChild('sortCol2', { static: true }) sortCol2: MatSort;
  @ViewChild('accountsPaginator', { static: false, read: MatPaginator }) accountsPaginator: MatPaginator;
  @ViewChild('contactsPaginator', { static: false, read: MatPaginator }) contactsPaginator: MatPaginator;
  selectedTagId: any;
  currentRole : string;
  emailInterval:any;
  ticketInterval: any;
  totalAccountsForFilter
  // @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  //@ViewChildren(MatSort) todoComponents: QueryList<TodoComponent>;

  constructor(
    public addCustomerContactDialog: MatDialog,
    public addCustomerAccountDialog: MatDialog,
    public confirmDialog: MatDialog,
    private api: ApiService,
    private customerService:CustomerListService,
    public context:AppContextService,
    //  ////private loader: LoaderService,
    private router: Router,
    private toaster: ToastrService,
    public toggleService: CustomerListService,
    public phoneModel : Phone
  ) {
    if(this.phoneModel.contactData!='' || this.phoneModel.contactData!=undefined){
      console.log('in if cond')
      this.contacts = new MatTableDataSource<''>(this.phoneModel.contactData);
      this.isContact = true
    }
    console.log(this.phoneModel.contactData)

  }



  ngOnInit() {

    let urlRoute = localStorage.getItem('urlRoute');
    this.phoneModel.allBackUrls.unshift(urlRoute);
    this.phoneModel.lastUrl = urlRoute

     /*stop autorefresh interval of email & ticket */
     this.emailInterval = localStorage.getItem('emailInterval');
     if (this.emailInterval) {
       clearInterval(this.emailInterval);
     }
 
     this.ticketInterval = localStorage.getItem('ticketInterval');
     if (this.ticketInterval) {
       clearInterval(this.ticketInterval);
     }
     this.context.fetchCustomerAccountDynamicFields().subscribe(fieldData => {
       console.log(fieldData,"account dynamic fields")
     })
     this.context.fetchCustomerContactDynamicFields().subscribe(fieldData => {
       console.log(fieldData , "customer dynamic fields")
     })
     /*end of function*/
     this.loadAllAccounts()
     if(this.phoneModel.contactData=='' || this.phoneModel.contactData==undefined){
      this.isContact = this.toggleService.isContact;
     }
    this.loadAccounts()
    this.loadAllAccounts()
    this.loadContacts();
    this.loadAllTagList();
    
    this.getCurrentUser();
    const searchHit = this.search.valueChanges
      .pipe(
        debounceTime(500)
      ).subscribe((value) => {
        if (this.isContact){
          this.resetContactPage()
          // this.loadContacts()
          if(this.search.value == ''){
            this.loadContacts()
          }else{
            this.searchContact()
          }
          
        }
        else{
          this.resetAccountPage()
          if(this.search.value == ''){
            this.loadAccounts();
          }else{
            this.searchAccount()
          }

          
          // this.loadAccounts();
        }
         
      })
    this.subscribers.push(searchHit)
  }

  searchContact(){
    this.api.contactSearchNew(this.search.value)
    .subscribe((data)=>{
      this.totalContacts = data.count;
      this.contactNext = data.next && data.next !== null ? true : false
      this.contactPrevious = data.previous && data.previous !== null ? true : false
      this.contacts = new MatTableDataSource<''>(data.results);
      this.contacts.sort = this.sortCol2;
      //  this.contacts.paginator = this.contactsPaginator;
      console.log(data,"contactList");
      this.allContact = data
    },(err)=>{
      this.toaster.error(ErrorToaster(err))
    })
  }

  searchAccount(){
    // console.log(this.search ,"var")
    this.api.accountSearch(this.search.value)
    .subscribe((data)=>{
      console.log(data ,"search")
      this.totalAccounts = data.count;
      this.accountNext = data.next && data.next !== null ? true : false;
      this.accountPrevious = data.previous && data.previous !== null ? true : false
      this.accounts = new MatTableDataSource<AccountRead>(data.results);
      this.accounts.sort = this.sortCol1;
    },(err)=>{
      this.toaster.error(ErrorToaster(err))
    })
  }

  onTypeChange(e: any) {
    this.isContact = e.checked;
    this.selectedTagId = undefined
    this.search.setValue('')
    if (this.isContact)
      this.loadContacts()
    else
      this.loadAccounts()
  }

  // add contact dialog
  openAddCustomerContactDialog() {
    const addContactDialog = this.addCustomerContactDialog.open(AddCustomerContactDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: { bulkUpload: true }
    });

    addContactDialog.afterClosed().subscribe(result => {
      console.log(`${this.loggerName} Contact dialog closed`);
      this.loadContacts();
    });
  }

  loadContacts() {
   const contactList =  this.api.contactList(this.conatctPageNumber,this.selectedTagId , this.search.value).subscribe(contacts => {
   console.log(this.phoneModel.contactData)
    if(this.phoneModel.contactData=='' || this.phoneModel.contactData==undefined){
      console.log('in load contacts')
      this.totalContacts = contacts.count;
      this.contactNext = contacts.next && contacts.next !== null ? true : false
      this.contactPrevious = contacts.previous && contacts.previous !== null ? true : false
      this.contacts = new MatTableDataSource<''>(contacts.results);
      this.contacts.sort = this.sortCol2;
      //  this.contacts.paginator = this.contactsPaginator;
      console.log(contacts,"contactList");
      this.allContact = contacts
    }
    },(err)=>{
      this.toaster.error(ErrorToaster(err))
    });
    this.subscribers.push(contactList)
    // console.log(contactList,"contact" );
  }

  // add account dialog
  openAddCustomerAccountDialog() {
    const addAccountDialog = this.addCustomerAccountDialog.open(AddCustomerAccountDialogComponent, {
      panelClass: 'co-dialog-panel'
    });

    addAccountDialog.afterClosed().subscribe(result => {
      console.log(`${this.loggerName} Account dialog closed`);
      this.loadAccounts();
    });
  }

  loadAccounts() {
   const accountList= this.api.accountList(this.accountPageNumber, this.search.value,undefined ).subscribe(accounts => {
      console.log(accounts);
      this.totalAccounts = accounts.count;
      this.accountNext = accounts.next && accounts.next !== null ? true : false;
      this.accountPrevious = accounts.previous && accounts.previous !== null ? true : false
      this.accounts = new MatTableDataSource<AccountRead>(accounts.results);
      this.accounts.sort = this.sortCol1;
      
      // this.accounts.paginator = this.accountsPaginator;

    },(err)=>{
      this.toaster.error(ErrorToaster(err))
    });
    this.subscribers.push(accountList)

  }
  loadAllAccounts(){
    this.api.allAccountList()
    .subscribe((data)=>{
      this.totalAccountsForFilter = data;
      console.log(this.totalAccountsForFilter ,"************");
    },(err)=>{
      this.toaster.error(ErrorToaster(err))
    })
  }
  applyFilter(filterValue: string) {
    this.accounts.filter = filterValue.trim().toLowerCase();
    this.contacts.filter = filterValue.trim().toLowerCase();
  }

  editAccount(accountId: string) {
    this.router.navigate(['/app/customers/account', accountId, { action: "edit" }])
  }
  editContact(contactId: string) {
    this.router.navigate(['/app/customers/contact', contactId, 'edit', { action: "account" }])
  }
  openConfirmDialog() {
    const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: "Do you really want to delete these records? This process cannot be undone."
    });
    return dialogRef;
  }

  deleteAccount(event: any, accountId: number) {
    console.log("accountid", accountId)
    event.stopPropagation();
    const dialogRef = this.openConfirmDialog()
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        ////this.loader.start();
       const deleteAccount = this.api.accountDelete(accountId).subscribe((success) => {
          this.resetAccountPage()
          this.loadAccounts();
          this.loadContacts();
          /////this.loader.stop();
          this.toaster.success("Account deleted")
        }, (err) => {
          ////this.loader.stop();
          this.toaster.error(ErrorToaster(err))
          // this.toaster.error("Something went wrong")
        })
        this.subscribers.push(deleteAccount)
      }
    })
  }
  deleteContact(event, contactId: number) {
    event.stopPropagation();
    const dialogRef = this.openConfirmDialog()
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // //this.loader.start();
      const deleteConatct =  this.api.contactDelete(contactId).subscribe((success) => {
          this.resetContactPage()
          this.loadContacts();
          // //this.loader.stop();
          this.toaster.success("Contact deleted")
        }, (err) => {
          ////this.loader.stop();
          this.toaster.error(ErrorToaster(err))
          // this.toaster.error("Something went wrong")
        })
      this.subscribers.push(deleteConatct)
      }
    })
  }
  onBackButton() {

  }
  resetAccountPage() {
    this.accountPageNumber = 1;
    this.accountsPaginator.pageIndex = 0

  }
  resetContactPage() {
    this.conatctPageNumber = 1;
    this.contactsPaginator.pageIndex = 0
  }

  pageChange($event: PageEvent, page: PageType) {
    if ($event.pageIndex > $event.previousPageIndex) {
      if (this.accountNext && page === this.pageType.ACCOUNTS) {
        this.accountPageNumber++;
        this.loadAccounts();
      } else if (this.contactNext && page === this.pageType.CONTACTS) {
        this.conatctPageNumber++;
        this.loadContacts();
      }
    } else {
      if (this.accountPrevious && page === this.pageType.ACCOUNTS) {
        this.accountPageNumber--;
        this.loadAccounts();
      }
      else if (this.contactPrevious && page === this.pageType.CONTACTS) {
        this.conatctPageNumber--;
        this.loadContacts();
      }
    }
  }

  showSelectedTagInfo(event){
    console.log(event)
    // this.selectedTag = event.value.name
    this.selectedTagId = event.value.id
    this.loadContacts();
    this.loadAccounts();
  }

  loadAllTagList(){
    this.api.CustomerTagList()
    .subscribe((tags)=>{
      console.log(tags,"tags")
      this.allTagList = tags
    },(err)=>{
      this.toaster.error(ErrorToaster(err))
    })
  }

  downloadCSV(type){
    
    
    if(type == 'contact'){
      let data;
      data = this.allContact.results.map((item) => {
        let obj = {
          name: item.name,
          email: item.email,
          created: item.created,
          last_interaction_date: item.last_interaction_date,
          tag:[], 
          // team:item.team,     
          account:item.account?item.account.name:""     
        };
        item.tags.filter((db)=>{
          obj.tag.push(db.name)
        }) 
        console.log(obj,"csv")
         Object.keys(item.dynamic_fields).forEach((key) => {
          let csvKey = key.replace(/ /g, "_");
          obj[csvKey] = item.dynamic_fields[key]
        })
        return obj
      })
      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(data[0]);
      let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      let csvArray = csv.join('\r\n');

      var blob = new Blob([csvArray], {type: 'text/csv' })
      saveAs(blob, 'Contact.csv');    
      // this.customerService.download("Contacts",data)
    }
    if(type == 'account'){
      let data;
      data = this.totalAccountsForFilter.map((item) => {
        let obj = {
          name: item.name,
          created: item.created ,
          tag:[] ,
          // team:item.team,

        };
        item.tags.filter((db)=>{
          obj.tag.push(db.name)
        }) 
         Object.keys(item.dynamic_fields).forEach((key) => {
          let csvKey = key.replace(/ /g, "_");
          obj[csvKey] = item.dynamic_fields[key]
        })
        console.log(obj , ">>>>>>>>>>>>>>>")
        return obj
        
      })
      
      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(data[0]);
      let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      let csvArray = csv.join('\r\n');

      var blob = new Blob([csvArray], {type: 'text/csv' })
      saveAs(blob, 'Account.csv');    
        // this.customerService.download("Accounts",data)

    }
  }

  getCurrentUser(){
    this.api.fetchCurrentUser()
    .subscribe((user)=>{
      this.currentRole = user.role;
    },(err)=>{
      this.toaster.error(ErrorToaster(err))
    })
  }

  ngOnDestroy() {
    this.subscribers.forEach((sub) => sub.unsubscribe())
  }

  backToLastPanel() {
    let leadId = ((this.phoneModel.leadId) ? this.phoneModel.leadId : '');
    let mobileNo = ((this.phoneModel.mobileNo) ? this.phoneModel.mobileNo : '');

    let backUrlArr = ((this.phoneModel.allBackUrls) ? this.phoneModel.allBackUrls : []);
    console.log(backUrlArr , 'bac')

    if (backUrlArr.length > 0) {

        var lastUrl =   ((backUrlArr[1]) ? backUrlArr[1] : backUrlArr[0])

        let checkIsThereSym = lastUrl.indexOf('?') !== -1;

        if (checkIsThereSym == true) {
            lastUrl = lastUrl.split("?");
            lastUrl = lastUrl[0]
        }
        if (leadId != '' && mobileNo != '') {
            this.router.navigate(['/app/campaign/campaign-details/' + leadId]);
        } else {
            this.router.navigate([lastUrl]);
        }
    }else{
        this.router.navigate(['/app/customers/']);
    }

}

}
