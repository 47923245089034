import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getErrorText, ValidationMessages, ErrorToaster } from '../../../../code-modules/utils/validations/genral-validation';
import { ApiService } from '../../../../code-modules/api/api-services/api.service';
import { AppContextService } from '../../../../code-modules/app-context/app-context.service';
import { ToastrService } from 'ngx-toastr';
import { InAppLogger } from 'src/app/code-modules/utils/general-utils/InAppLogger';
import { DataDrivenForm } from 'src/app/code-modules/utils/data-driven-forms/DataDrivenForm';
import {AccountCreateUpdate, Tag} from '../../../../code-modules/api/interfaces/Customer/customer-api-interfaces';
import { UserTeamList } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';


@Component({
  selector: 'app-add-customer-account-dialog',
  templateUrl: './add-customer-account-dialog.component.html',
  styleUrls: ['./add-customer-account-dialog.component.sass']
})
export class AddCustomerAccountDialogComponent implements OnInit {
  userRole
  customerTags :Tag[]=[]
  selectedTags : number[]=[];

  teams
  logger = new InAppLogger(AddCustomerAccountDialogComponent.name)
  loggerName = '[/app/Customer/create account/single]';
  //isBulk = false;
  createAccountForm: FormGroup;
  createAccountFormSubmitted = false;
  csvFile: File;
  dynamicForm = new DataDrivenForm([]);

  

  constructor(
    public dialogRef: MatDialogRef<AddCustomerAccountDialogComponent>,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private context: AppContextService,
    private toaster: ToastrService
    
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    // console.log(this.selectedTeam ,",.,")
    this.context.currentUser$.subscribe((user)=>{
      this.userRole = user
    })
  // //this.loader.start();
    this.context.customerAccountDynamicFields$.subscribe(fieldData => {
      this.logger.log('Loaded fields:', fieldData);
      if (fieldData.length) {
        this.dynamicForm = new DataDrivenForm(fieldData, {});
        console.log(this.dynamicForm.fields ,">>>>>>>>>>>>")
      } 
      this.api.CustomerTagList().subscribe((tags) => {
        //this.customerTagData = tags;
        this.customerTags = tags
       // //this.loader.stop();
      }, (err) => {
        this.toaster.error(ErrorToaster(err))
        ////this.loader.stop();
      })

    });
    this.createAccountForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      accessible_teams: [[]],
    });
    this.loadUserTeamList();
  }
  // loadUserTeamList() {
  //   this.api.userTeamList()
  //     .subscribe((team: UserTeamList) => {
  //       console.log(team, "Team")
  //       this.teams = team
        
  //     })
  // }

  loadUserTeamList(){
    if(this.userRole.role == 'admin'){
      this.api.userTeamList()
      .subscribe((team:UserTeamList)=>{
        console.log(team,"Team")
        this.teams = team
      })
    }else{
      this.api.userDetails(this.userRole.id)
      .subscribe((team)=>{
        console.log(team,"Team")
        this.teams = team.teams
      })
    }
    
  }

  get singleControls() {
    return this.createAccountForm.controls;
  }

  get singleErrors() {
    const errors: any = {};
    Object.keys(this.singleControls).forEach(controlName => {
      errors[controlName] = getErrorText(this.singleControls[controlName]);
    });
    return errors;
  }

  submit() {
    console.log('submit clicked')
    this.fromValidityCheck();
    // if (this.isBulk) {
    //   console.log('bulk upload')
    //   this.submitBulkAccounts();
    // } else {
    //   this.fromValidityCheck();
    // }
  }

  fromValidityCheck() {
    console.log('formValidityCheck selected')
    if (this.createAccountForm.valid && this.dynamicForm.formGroup.valid) {
      this.submitSingleAccount();
      console.log('form validity passed');
    } else {
      this.toaster.info('Please Fill Required Fields');
      this.createAccountForm.markAllAsTouched();
      this.dynamicForm.formGroup.markAllAsTouched();
    }
  }

  submitSingleAccount() {
    ////this.loader.start();
    let request  = <AccountCreateUpdate> this.createAccountForm.value
    request.dynamic_fields =   this.dynamicForm.formGroup.value
    request.tags = this.selectedTags;
    this.api.accountCreate(request).subscribe(result => {
      console.log('accountCreate success', result);
      this.toaster.success('Account Created');
      this.dialogRef.close(result);
     // //this.loader.stop();
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
     // //this.loader.stop();
    });

  }

  // onTypeChange(e) {
  //   this.isBulk = e.checked;
  // }

  handleFileSelect(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.csvFile = fileList[0];
    }
  }

 

  toggleTagSelection(tag: Tag) {
    const index = this.selectedTags.indexOf(tag.id)
    if (index > -1) {
      this.selectedTags.splice(index, 1)
    } else {
      this.selectedTags.push(tag.id)
    }
  }

  isSelectedTag(tag: Tag) {
    return this.selectedTags.includes(tag.id)
  }


  getErrorMessage() {
    return ValidationMessages(this.createAccountForm.controls);
  }

  checkboxvalue =false;

  changeTeam(event){
  if(event.checked) {
      this.checkboxvalue=true
       var allteam =[]
       this.teams.forEach(element => {
      allteam.push(element.id.toString()) ;
      }); 
       this.createAccountForm.get('accessible_teams').setValue(allteam);
      }else{
        this.checkboxvalue=false
      var allteam =[]
      this.createAccountForm.get('accessible_teams').setValue([]);
    }
    
   }
    teamchange(event){
        console.log(event)
        let abc=[];
        abc= this.createAccountForm.get('accessible_teams').value;
      //  if(abc.length==0){
        if(this.teams.length>abc.length){
    
        this.checkboxvalue =false;
    
      }else{
         if(this.teams.length==abc.length)
        this.checkboxvalue =true;
      }
    }
}
