import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mask' })
export class MobileNumberMaskPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      const numberArr = value.toString().split('')
      numberArr.splice(2, 4, '*', '*', '*', '*')
      return numberArr.join("");
    }
  }

}