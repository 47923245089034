import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {ApiService} from 'src/app/code-modules/api/api-services/api.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {ErrorToaster} from 'src/app/code-modules/utils/validations/genral-validation';
import {Subscription, Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {debounceTime, switchMap, tap} from 'rxjs/operators';
import {AccountRead, ContactCreateUpdate, ContactRead} from '../../../../code-modules/api/interfaces/Customer/customer-api-interfaces';


interface dialogData {
    account: AccountRead,
    contacts: ContactRead[]
}


@Component({
    selector: 'app-contact-account-mapping-dialog',
    templateUrl: './contact-account-mapping-dialog.component.html',
    styleUrls: ['./contact-account-mapping-dialog.component.sass']
})
export class ContactAccountMappingDialogComponent implements OnInit, OnDestroy {
    filteredContacts: Observable<any>;
    subscribers: Array<Subscription> = [];
    contacts: Array<ContactRead>;
    contactEmail: FormControl;
    customerEmailId: '';
    selectedEmailId: string;
    selectedContact: ContactRead;
    typing = true;
    account: AccountRead;

    constructor(
        public dialogRef: MatDialogRef<ContactAccountMappingDialogComponent>,
        private api: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: dialogData,
        public toaster: ToastrService
    ) {
    }

    ngOnInit() {
        this.account = this.data.account;
        // console.log(this.account)
        this.contactEmail = new FormControl('');
        //this.loadContactList();
        this.filteredContacts = this.contactEmail.valueChanges
            .pipe(
                tap(() => this.typing = true),
                debounceTime(300),
                switchMap(value => {
                    if (typeof value === 'string') {
                        return this.api.contactSearchByEmail(value);
                    } else {
                        this.typing = false;
                        return this.api.contactSearchByEmail(this.selectedEmailId);
                    }
                })
            );

    }


    loadContactList() {
        const listApi = this.api.contactList().subscribe((contacts) => {
            this.contacts = contacts.results;
        },(err)=>{
            this.toaster.error(ErrorToaster(err))
        });
        this.subscribers.push(listApi);
    }

    setContact(contact: ContactRead) {
        // if (!this.isContactAlreadExist(contact)) {
        this.selectedContact = contact;
        this.selectedEmailId = contact.email;
        // this.typing = false
        // } else
        //   this.toaster.info("Contact already exists")
    }

    // isContactAlreadExist(contact: ContactRead) {
    //   if (this.data.contacts.length) {
    //     return this.data.contacts.find((c) => c.id === contact.id)
    //   } else {
    //     return undefined
    //   }

    // }
    addContact() {
        //  if (this.selectedEmailId && this.selectedEmailId === this.contactEmail.value && this.account && this.account.id) {

        if (this.selectedContact && this.selectedContact.id) {
            const request = <ContactCreateUpdate> {
                account: this.account.id
            };
            const updateApi = this.api.contactUpdate(request, this.selectedContact.id).subscribe((success) => {
                this.toaster.success('Success');
                this.dialogRef.close(true);
            }, (err) => {
                this.toaster.error(ErrorToaster(err));
            });
            this.subscribers.push(updateApi);
        }
        // }
    }

    // selectedContact() {
    //   return this.contacts.find((contact) => contact.email === this.selectedEmailId)
    // }


    ngOnDestroy() {
        this.subscribers.forEach((sub) => sub.unsubscribe());
    }

    mapDisplayValue(contact: ContactRead) {
        return contact && contact.email || '';
    }

}
