export class InAppLogger {
  static serverLessThreadId?: string;

  constructor(private loggerName: string) {
  }

  log = (...args: any[]) => console.log(`:${InAppLogger.serverLessThreadId || ''}[${this.loggerName}]`, ...args);
  error = (...args: any[]) => console.error(`:${InAppLogger.serverLessThreadId || ''}[${this.loggerName}]`, ...args);
  warn = (...args: any[]) => console.log(`:${InAppLogger.serverLessThreadId || ''}[${this.loggerName}]`, ...args);
}
