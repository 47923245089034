import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterBy'
})

export class ObjectfilterPipe implements PipeTransform {

  transform(objectList: Array<object>, searchTerm: string): Array<object> {
    // console.log('pipe:', objectList, searchTerm);
    searchTerm = searchTerm && searchTerm.toLowerCase();
    if (!objectList || !searchTerm) {
      return objectList;
    } else if (objectList.length > 0) {
      return objectList.filter(singleObject => {
        const allObjectProperties = Object.keys(singleObject);
        return allObjectProperties
          .filter(objectProperty => typeof singleObject[objectProperty] === 'string')
          .some(objectProperty => (singleObject[objectProperty] as string).toLowerCase().includes(searchTerm));
      });
    } else {
      return objectList;
    }


  }
}
