import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent, MatChipList, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { ContactRead, EventData } from 'src/app/code-modules/api/interfaces/Customer/customer-api-interfaces';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { ErrorToaster, getErrorText, ValidationMessages } from 'src/app/code-modules/utils/validations/genral-validation';
import { WebSocketService } from 'src/app/code-modules/websocket/web-socket.service';

@Component({
  selector: 'app-event-notification',
  templateUrl: './event-notification.component.html',
  styleUrls: ['./event-notification.component.sass']
})
export class EventNotificationComponent implements OnInit {

  today = new Date()
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public createEventForm: FormGroup;
  data = [

  ]



  userVal = new FormControl();
  filteredContacts: Observable<any>;
  ticketId: any;
  teamData: any;
  teamId: any = '';
  filteredFruits: Observable<string[]>;
  fruitCtrl = new FormControl();
  userId: number;
  eventId: any;
  saveBtn: boolean = false;
  updateBtn: boolean = false;
  userRole: string;
  userEmail: any;
  routePath: any;


  fruits = [
    'Lemon',
  ];

  allFruits = [
    'Apple',
    'Lemon',
    'Lime',
    'Orange',
    'Strawberry'
  ];
  type: any;
  notification: any;




  constructor(
    public dialogRef: MatDialogRef<EventNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: EventNotificationComponent,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private api: ApiService,
    public datepipe: DatePipe,
    private context: AppContextService,
    private route: ActivatedRoute,
    private router: Router,
    private socketConnection: WebSocketService,


  ) {

    this.context.currentUser$.subscribe((currentuser) => {
      this.userRole = ((currentuser['role']) ? currentuser['role'] : null);
      this.userId = ((currentuser['id']) ? currentuser['id'] : 0);
      this.userEmail = ((currentuser['username']) ? currentuser['username'] : '');
    })
    this.ticketId = ((dialogData['ticket_id']) ? dialogData['ticket_id'] : 0);
    this.eventId = ((dialogData['event_id']) ? dialogData['event_id'] : 0);
    this.type = ((dialogData['type']) ? dialogData['type'] : '');
    this.notification = ((dialogData['notification']) ? dialogData['notification'] : '');


    this.createForm()

    this.filteredContacts = this.userVal.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          if (typeof value === 'string') {
            return this.api.contactSearchByEmail(value);
          }
        })
      );

  }

  ngOnInit() {
    if (this.eventId == 0) {
      //this.createForm()
      this.saveBtn = true;
    } else {
      this.singleEventDetails()
      if (this.type == 'view') {
      } else {
        this.updateBtn = true;
      }
    }
  }

  singleEventDetails() {
    this.api.singleEventDetails(this.eventId).subscribe(result => {
      this.ticketId = ((result.ticket['id']) ? result.ticket['id'] : 0);
      this.editForm(result)
    });
  }

  createForm() {
    let isCheck = this.userEmail.includes('@')
    if (isCheck == true) {
      this.data.push(this.userEmail)
    }
    this.createEventForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      event_date: ['', [Validators.required]],
      start_time: ['', [Validators.required]],
      guests: this.formBuilder.array(this.data, [Validators.required]),
      event_description: ['', [Validators.required]],
    });
  }

  editForm(result) {
    this.createEventForm = this.formBuilder.group({
      title: [result['title'], Validators.required],
      event_date: [result['event_date'], Validators.required],
      start_time: [result['start_time'], Validators.required],
      guests: this.formBuilder.array(result['guests'], Validators.required),
      event_description: [result['event_description'], Validators.required],
    });
  }
  validateArrayNotEmpty(email) {
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (!EMAIL_REGEXP.test(email)) {
      return { "Please provide a valid email": true };
    }

    return null;
  }

  add(event: MatChipInputEvent, form: FormGroup): void {

    const input = event.input;
    const value = event.value;

    // Add name
    if ((value || '').trim()) {
      const control = <FormArray>form.get('guests');
      control.push(this.initName(value.trim()));
      console.log(control);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(form, index) {
    form.get('guests').removeAt(index);
  }

  initName(name: string): FormControl {
    let isEmail = this.validateArrayNotEmpty(name)
    if (isEmail != null) {
      this.toaster.warning('Please enter valid email'); return;
    } else {
      return this.formBuilder.control(name);
    }
  }


  submitEventData() {
    if (this.createEventForm.invalid === true) {
      this.toaster.warning('Please enter all fields'); return;
    }
    let request = <EventData>this.createEventForm.value
    let date = new Date(this.createEventForm.value['event_date']);
    let latest_date = this.datepipe.transform(date, 'yyyy-MM-dd');
    request.event_date = latest_date
    request.ticket = this.ticketId
    request.created_by = this.userId
    if (this.userRole != 'admin') {
      this.teamData = JSON.parse(localStorage.getItem('currentTeam'));
      this.teamId = ((this.teamData[0]['id']) ? this.teamData[0]['id'] : null);
      if (this.teamId == null) {
        this.teamId = ''
      }
    }

    request.team = this.teamId
    this.api.addEventData(request).subscribe(result => {
      this.router.navigate(['/app/tickets/']);
      this.toaster.success('Task created successully');
      this.dialogRef.close(result);

    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    });
  }

  updateEventData() {
    let request = <EventData>this.createEventForm.value
    let date = new Date(this.createEventForm.value['event_date']);
    let latest_date = this.datepipe.transform(date, 'yyyy-MM-dd');
    request.event_date = latest_date
    request.created_by = this.userId
    request.ticket = this.ticketId
    this.api.updateEvent(this.eventId, request).subscribe(result => {
      this.toaster.success('Task updated successully');
      this.dialogRef.close(result);
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const control = <FormArray>this.createEventForm.get('guests');
    control.push(this.initName(event.option.viewValue.trim()));
    this.userVal.setValue(null);
  }

  getErrorMessage() {
    return ValidationMessages(this.createEventForm.controls);
  }

  get singleControls() {
    return this.createEventForm.controls;
  }

  get singleErrors() {
    const errors: any = {};
    Object.keys(this.singleControls).forEach(controlName => {
      errors[controlName] = getErrorText(this.singleControls[controlName]);
    });
    return errors;
  }

  close() {
    this.dialogRef.close()
    this.router.navigate(['/phone-load']);
  }

}
