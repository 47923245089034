import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getErrorText, ValidationMessages, ErrorToaster } from '../../../../code-modules/utils/validations/genral-validation';
import { ApiService } from '../../../../code-modules/api/api-services/api.service';
import { Papa } from 'ngx-papaparse';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { InAppLogger } from 'src/app/code-modules/utils/general-utils/InAppLogger';
import { AddCustomerAccountDialogComponent } from '../add-customer-account-dialog/add-customer-account-dialog.component';
import { DataDrivenForm } from 'src/app/code-modules/utils/data-driven-forms/DataDrivenForm';
import { HttpErrorResponse } from '@angular/common/http';
import { ContactCreateUpdate, Tag } from '../../../../code-modules/api/interfaces/Customer/customer-api-interfaces';
import { ContactBulkUpload } from '../../../../code-modules/api/interfaces/Business-Rule/business-rule-api-interfaces';
import { ContactHistoryDialogComponent } from '../contact-history-dialog/contact-history-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserTeamList } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
import { Phone } from 'src/app/circle-one/telephony/model/phone.model';


@Component({
  selector: 'app-add-customer-contact-dialog',
  templateUrl: './add-customer-contact-dialog.component.html',
  styleUrls: ['./add-customer-contact-dialog.component.sass']
})
export class AddCustomerContactDialogComponent implements OnInit {

  customerTags: Array<Tag> = [];
  selectedTags: number[] = [];
  userRole
  logger = new InAppLogger(AddCustomerAccountDialogComponent.name)
  loggerName = '[Customers/Add contact/single]';
  isBulk = false;
  singleContactForm: FormGroup;
  // singleFormSubmitted = false;

  customerAccountId = this.data && this.data.accountId ? this.data.accountId : null;
  bulkOption = this.data && this.data.bulkUpload ? true : false;
  csvFile: File;
  dynamicForm = new DataDrivenForm([]);
  accountDynamicForm = new DataDrivenForm([]);
  email;
  allAccountList;
  selectedAccount;
  newCustMbNo: any = '';
  leadData: any = [];
  name: any;
  // teams=[];
  teams;
  exTeams;

  serchTeam = [];
  constructor(
    public dialogRef: MatDialogRef<AddCustomerContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: AddCustomerContactDialogComponent,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private csvParser: Papa,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toaster: ToastrService,
    public context: AppContextService,
    public addCustomerAccountDialog: MatDialog,
    public phoneModel: Phone,
  ) {
    // this.name = ((dialogData['name']) ? dialogData['name'] : '')
    this.newCustMbNo = ((dialogData['mobileNo']) ? dialogData['mobileNo'] : '');
    this.leadData = ((dialogData['leadData']) ? dialogData['leadData'] : []);
    let firstName = ((this.leadData) ? this.leadData['first_name'] : '')
    let lastName = ((this.leadData) ? this.leadData['last_name'] : '')
    // this.name = firstName + ' ' + lastName;
    this.email = ((this.leadData) ? this.leadData['email'] : '')
    if (this.leadData != '') {
      this.newCustMbNo = ((this.leadData) ? this.leadData['phone_number'] : dialogData['mobileNo']);
    }else{
      this.newCustMbNo = this.phoneModel.mobileNo
    }


  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
    this.context.currentUser$.subscribe((user) => {
      this.userRole = user
    })
    // //this.loader.start();
    this.api.accountList(undefined, undefined, undefined)
      .subscribe((accounts) => {
        this.allAccountList = accounts;
      }, (err) => {
        this.toaster.error(ErrorToaster(err))
      })

    this.context.customerContactDynamicFields$.subscribe(fieldData => {
      this.logger.log('Loaded fields:', fieldData);
      if (fieldData.length) {
        this.dynamicForm = new DataDrivenForm(fieldData, {});
      }
      this.api.CustomerTagList().subscribe((tags) => {
        this.customerTags = tags
        // //this.loader.stop();
      }, (err) => {
        this.toaster.error(ErrorToaster(err))
        //  //this.loader.stop();
      })
    });

    this.context.customerAccountDynamicFields$.subscribe((fieldData) => {
      if (fieldData.length) {
        this.accountDynamicForm = new DataDrivenForm(fieldData, {});
      }
    })
    console.log(this.data, "$%^&*()")
    if (this.data.email) {
      // this.name = this.data.email
      console.log("hi")
      this.singleContactForm = this.formBuilder.group({
        account: ['', []],
        accessible_teams: [[]],
        name: ['', [
          Validators.required,
        ]],
        email: [this.data.email, [
          Validators.required,
          Validators.email
        ]],
        phone_number: ['', []],
        facebook_id: ['', []],
        whatsapp_number: ['', []],
        twitter_id: ['', []]
      });
    } else {
      this.name = ""
      this.singleContactForm = this.formBuilder.group({
        account: ['', []],
        accessible_teams: [[]],
        name: ['', [
          Validators.required,
        ]],
        email: ['', [
          Validators.required,
          Validators.email
        ]],
        phone_number: ['', []],
        facebook_id: ['', []],
        whatsapp_number: ['', []],
        twitter_id: ['', []]
      });
    }

    console.log("data from dialog", this.data);
    this.loadUserTeamList();
  }

  get singleControls() {
    return this.singleContactForm.controls;
  }

  get singleErrors() {
    const errors: any = {};
    Object.keys(this.singleControls).forEach(controlName => {
      errors[controlName] = getErrorText(this.singleControls[controlName]);
    });
    return errors;
  }

  onTypeChange(e) {
    this.isBulk = e.checked;
  }

  submit() {
    if (this.isBulk) {
      this.submitBulkContacts();
    } else {
      this.fromValidityCheck();
    }
    console.log('log after submit', this.singleContactForm.value);
  }

  fromValidityCheck() {
    console.log('log at validity check', this.singleContactForm.value);
    if (this.singleContactForm.valid && this.dynamicForm.formGroup.valid) {
      this.submitSingleContact();
    } else {
      this.toaster.info('Please Fill Required Fields');
      this.singleContactForm.markAllAsTouched();
      this.dynamicForm.formGroup.markAllAsTouched();
    }
  }
  submitSingleContact() {
    let request = <ContactCreateUpdate>this.singleContactForm.value
    console.log(request.account)
    if (request.account == 'noaccount') {
      console.log('in if conditon')
      request.account = ''
    }
    console.log(request, "...............................")
    request.dynamic_fields = this.dynamicForm.formGroup.value
    request.tags = this.selectedTags;
    // request.account = this.customerAccountId
    console.log(request, "...,,.......")
    this.api.contactCreate(request).subscribe(result => {

      this.toaster.success('Contact Created');
      this.dialogRef.close(result);
    }, err => {
      this.toaster.error(ErrorToaster(err));
    });

  }

  handleFileSelect(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.csvFile = fileList[0];
    }


  }

  submitBulkContacts() {

    if (this.csvFile) {
      const reader = new FileReader();
      //this.loader.start();
      reader.readAsDataURL(this.csvFile);
      reader.onload = (event: any) => {
        const fileContent = reader.result as string;
        const request: ContactBulkUpload = {
          file_name: this.csvFile.name,
          csv_file: fileContent
        }
        this.api.contactBulkCreate(request).subscribe((response) => {
          //this.loader.stop();
          console.log(response, "1234445")
          if (response.success_count !== 0) {
            this.toaster.success(`${response.success_count} contact uploaded succesfully`);
          }
          if (response.fail_count !== 0) {
            this.toaster.error(`${response.fail_count} contact failed to upload`);
          }

          this.dialogRef.close(true);
        },
          (err: HttpErrorResponse) => {
            //this.loader.stop();
            let errorMessage = "Unable to Upload the file"
            if (err.status && err.status === 400) {
              const error = err.error;
              errorMessage = this.getFirstKeyObject(error)

            }

            //console.log("error",errorMessage)
            this.toaster.error(errorMessage);
            this.dialogRef.close(false);
          }
        )
      }
    }

  }

  getFirstKeyObject(data: any) {
    // console.log(data)
    if (typeof data === 'object')
      return this.getFirstKeyObject(data[Object.keys(data)[0]])
    else {
      return data
    }
  }

  getErrorMessage() {
    return ValidationMessages(this.singleContactForm.controls);
  }

  toggleTagSelection(tag: Tag) {
    const index = this.selectedTags.indexOf(tag.id)
    if (index > -1) {
      this.selectedTags.splice(index, 1)
    } else {
      this.selectedTags.push(tag.id)
    }
  }

  isSelectedTag(tag: Tag) {
    return this.selectedTags.includes(tag.id)
  }

  download() {
    //contact system fields
    let header: string = `contact_name,contact_email,contact_mobile_no,contact_whatsapp_no,contact_twitter_id,contact_facebook_id`;
    // Object.keys(this.singleContactForm.controls).forEach((key) => {
    //   if (header)
    //     header = header + "," + key
    //   else
    //     header = key
    // });


    //contact dynamic fields
    if (this.dynamicForm && this.dynamicForm.fields && this.dynamicForm.fields.length) {
      this.dynamicForm.fields.forEach((field) => {

        header = header + ",contact_" + field.controlName.replace(/ /g, '_')
        // console.log(field.controlName , "dynamic field1");
        // if (field.fieldData.type !== DynamicFieldTypes.DROP_DOWN)
        //   header = header + ",contact_" + field.controlName
      })
      console.log(header, "dynamic field1");
    }

    //account system fields
    header = header + ",account_name"

    //account dynamic fields
    // if (this.accountDynamicForm && this.accountDynamicForm.fields && this.accountDynamicForm.fields.length) {
    //   this.accountDynamicForm.fields.forEach((field) => {
    //     header = header + ",account_" + field.controlName.replace(/ /g,'_')
    //     // if (field.fieldData.type !== DynamicFieldTypes.DROP_DOWN)
    //     //   header = header + ",account_" + field.controlName
    //   })
    // }
    // console.log(header)
    let blob = new Blob([header], { type: 'text/csv;charset=utf-8;' });
    let url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, 'CustomerContactSample.csv');
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = 'CustomerContactSample.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
  openAddCustomerAccountDialog() {
    const addAccountDialog = this.addCustomerAccountDialog.open(AddCustomerAccountDialogComponent, {
      panelClass: 'co-dialog-panel'
    });

    addAccountDialog.afterClosed().subscribe(result => {
      console.log(`${this.loggerName} Account dialog closed`);
      this.api.accountList(undefined, undefined, undefined)
        .subscribe((accounts) => {
          this.allAccountList = accounts;
        }, (err) => {
          this.toaster.error(ErrorToaster(err))
        })
    });
  }
  changeAccountHandler(event) {
    console.log(event.value)
    // if (event.value == 'other') {
    //   this.openAddCustomerAccountDialog()
    // }
  }

  // loadUserTeamList() {
  //   this.api.ConatctTeamList()
  //     .subscribe((team) => {
  //       console.log(team, "Team")
  //       this.teams = team
  //       this.serchTeam=team
  //     })
  // }

  loadUserTeamList() {
    if (this.userRole.role == 'admin') {
      this.api.userTeamList()
        .subscribe((team: UserTeamList) => {
          console.log(team, "Team")
          this.teams = team
          this.exTeams = team
        })
    } else {
      this.api.userDetails(this.userRole.id)
        .subscribe((team) => {
          console.log(team, "Team")
          this.teams = team.teams
          this.exTeams = team.teams
        })
    }

  }

  // loadUserTeamList(){
  //   this.api.userDetails(this.userRole.id)
  //     .subscribe((team)=>{
  //       console.log(team,"Team")
  //       this.teams = team.teams

  //     })
  // }
  filterTeamUsingAccountId(event) {
    // xxxxxxxxxxxxxxxxxxxxx
    console.log(event, "event");
    this.exTeams = this.teams;

    if (event.value != 'noaccount') {
      let accountObj = this.allAccountList.filter(e => e.id === +event.value);

      let fliteredTeams = [];
      accountObj[0].accessible_teams.forEach(element => {
        var teamsObj = this.teams.filter(_ => _.id === element.id);
        console.log(teamsObj, "teams")
        if (teamsObj[0] != undefined) {
          fliteredTeams.push(teamsObj[0]);
          console.log(teamsObj[0], "teams object")
        }
      });
      console.log(fliteredTeams)

      this.exTeams = fliteredTeams;
      console.log(this.teams, "this team")
    } else {
      this.exTeams = this.teams;
    }
    var allteam = []
    this.exTeams.forEach(element => {
      allteam.push(element.id.toString());
    });

    if (event.value != 'noaccount') {
      if (allteam.length == 0) {
        this.checkboxvalue = false
      } else {
        this.checkboxvalue = true
      }
      this.singleContactForm.get('accessible_teams').setValue(allteam);
    }else{
      var allteam = []

      this.singleContactForm.get('accessible_teams').setValue(allteam);

      this.checkboxvalue = false
    }



  }
  // filterTeamUsingAccountId(event)
  // {

  // console.log(event,"event");
  // let accountObj=this.allAccountList.filter(e=>e.id===+event.value);

  // let  fliteredTeams=[];
  // accountObj[0].accessible_teams.forEach(element => {
  // var teamsObj=  this.serchTeam.filter(_=>_.id===element.id);
  //   fliteredTeams.push(teamsObj[0]);
  // });

  // this.teams=fliteredTeams;
  // var allteam =[]
  // this.teams.forEach(element => {
  // allteam.push(element.id.toString()) ;
  // }); 
  // if(allteam.length==0){
  // this.checkboxvalue=false
  // }else{
  // this.checkboxvalue=true
  // }

  // this.singleContactForm.get('accessible_teams').setValue(allteam);

  // }

  checkboxvalue = false;
  changeTeam(event) {

    if (event.checked) {
      this.checkboxvalue = true
      var allteam = []
      this.teams.forEach(element => {
        allteam.push(element.id.toString());
      });
      this.singleContactForm.get('accessible_teams').setValue(allteam);
    } else {
      this.checkboxvalue = false
      var allteam = []
      this.singleContactForm.get('accessible_teams').setValue([]);
    }

  }
  teamchange(event) {
    console.log(event)
    let abc = [];
    abc = this.singleContactForm.get('accessible_teams').value;
    //  if(abc.length==0){
    if (this.teams.length > abc.length) {

      this.checkboxvalue = false;

    } else {
      if (this.teams.length == abc.length)
        this.checkboxvalue = true;
    }
  }
}


