import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../code-modules/api/api-services/api.service';
import {MatchTenantDomain} from '../../../../shared/utils/validators/tenantDomain.validator';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AppContextService} from '../../../../code-modules/app-context/app-context.service';
import {ToastrService} from 'ngx-toastr';
import { DataDrivenForm } from 'src/app/code-modules/utils/data-driven-forms/DataDrivenForm';
import { InAppLogger } from 'src/app/code-modules/utils/general-utils/InAppLogger';
import {UserProfileRead, UserProfileUpdate} from '../../../../code-modules/api/interfaces/User/user-api-interfaces';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent implements OnInit {
  loggerName = '[/UserProfile]';
  logger = new InAppLogger(UserProfileComponent.name)
  userProfileForm: FormGroup;
  avatarSrc = 'https://www.w3schools.com/howto/img_avatar.png';
  avatarChanged = false;
  uploadedAvatar:any;
  userId: string;

  formInitialised = false;
  edit = true;
  dynamicForm = new DataDrivenForm([]);
  user : UserProfileRead;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    //////private loader: LoaderService,
    private context: AppContextService,
    private toaster: ToastrService
  ) {
  }

  pdfSrc:any;

  ngOnInit() {

console.clear();


    this.pdfSrc = "";

    // this.pdfSrc = "";


    // console.log( "value is :--", this.pdfSrc)

    this.pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";


   // //this.loader.start();
    this.context.currentUser$.subscribe(user => {
      this.avatarSrc = user.avatar ? user.avatar + '?random=' + String(Number(new Date())) : this.avatarSrc
      this.userProfileForm = this.formBuilder.group({
        first_name: [user.first_name, [Validators.required]],
        last_name: [user.last_name, [Validators.required]],
      });
      this.user = user;


      this.context.userDynamicFields$.subscribe(fieldData => {
        this.logger.log('Loaded fields:', fieldData);
        if (fieldData.length) {
          this.dynamicForm = new DataDrivenForm(fieldData,this.user.dynamic_fields || {});
          console.log(this.dynamicForm ,"Form")
        } else {
          this.dynamicForm = new DataDrivenForm([]);
          console.log(this.dynamicForm ,"Form....")
        }
        
      });

      this.formInitialised = true;
    //  //this.loader.stop();
      
      console.log(this.userProfileForm);
    });
  }


  onFileChanged(event) {
    this.uploadedAvatar = event.target.files[0];
    if (this.uploadedAvatar) {
      const reader = new FileReader(); // HTML5 FileReader API

      reader.onload = () => {
        this.avatarSrc = reader.result as string;
        this.avatarChanged = true;
        console.log(this.avatarSrc);
      };

      reader.readAsDataURL(this.uploadedAvatar);
    }
  }

  onEdit() {
    this.edit = !this.edit;
  }




  submit() {
    if (this.userProfileForm.valid && this.dynamicForm.formGroup.valid) {
      //console.log(this.dynamicForm.value,this.dynamicForm.fields,this.dynamicForm.formGroup.value)
      const updateRequest = <UserProfileUpdate>{
        first_name : this.userProfileForm.value.first_name,
        last_name : this.userProfileForm.value.last_name,
        avatar : this.avatarChanged ? this.avatarSrc : undefined,
       dynamic_fields : this.dynamicForm.formGroup.value
      }
      
      this.api.userUpdate(updateRequest,this.user.id).subscribe(response => {
        console.log(`${this.loggerName} Profile Updation success: `, response);
        this.edit = !this.edit;
        this.toaster.success('Profile updated');
       this.context.fetchCurrentUser().subscribe();
        ////this.loader.stop();
      }, error1 => {
        this.toaster.error(ErrorToaster(error1))
        // this.toaster.error("Something went wrong","Sorry");
        console.error(`${this.loggerName} Profile Updation failed: `, error1);
       // //this.loader.stop();
      });
    }else{
      this.userProfileForm.markAllAsTouched();
      this.dynamicForm.formGroup.markAllAsTouched();
    }


  }

}
