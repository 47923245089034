import { AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

export function getErrorText(control: AbstractControl): string {
  return (control.invalid && control.errors) ?
    (control.errors.email) ?
      'Invalid email' : (control.errors.required) ?
        'This field is required' : '' : '';
}

export function ValidationMessages(formControls: any) {
  let validationObject = {}
  Object.keys(formControls).forEach((control) => {
    validationObject[control] = ""
  })
  for (let key in validationObject) {
    let control: AbstractControl = formControls[key];
    if (control.status == "INVALID") {
      if (control.errors.required) {
        validationObject[key] = "Required field";
      } else if (control.errors.pattern) {
        validationObject[key] = "Invalid input";
      }
      else if (control.errors.maxlength) {
        validationObject[key] = key + " should be " + control.errors.maxlength.requiredLength + " characters";
      } else if (control.errors.minlength) {
        validationObject[key] = key + " should be " + control.errors.minlength.requiredLength + " characters";
      } else if (control.errors.email) {
        validationObject[key] = "Invalid mail id";
      }
    }
  }
  return validationObject;
}
export function DynamicFieldValidation(formControls: any, controlName: string) {
  let validationObject = {
    [controlName]: ""
  }

  let control: AbstractControl = formControls;
  if (control.status == "INVALID") {
    if (control.errors.required) {
      validationObject[controlName] = "Required field";
    } else if (control.errors.pattern) {
      validationObject[controlName] = "Invalid input";
    }
    else if (control.errors.maxlength) {
      validationObject[controlName] = controlName + " should be " + control.errors.maxlength.requiredLength + " characters";
    } else if (control.errors.minlength) {
      validationObject[controlName] = controlName + " should be " + control.errors.minlength.requiredLength + " characters";
    } else if (control.errors.email) {
      validationObject[controlName] = "Invalid mail id";
    }
  }

  return validationObject;
}

export function ErrorToaster(errorResponse: HttpErrorResponse) {
 
  let errorMessage = "Something went wrong"
  if (errorResponse.status && errorResponse.status === 400) {
    const error = errorResponse.error
    const keys = Object.keys(errorResponse.error);
    if (keys && keys.length) {
      const fieldError = error[keys[0]]
      if (fieldError && fieldError.length){
        errorMessage = fieldError[0]
        if(errorMessage == 'Session expired.Please login again'){
          window.location.reload()
          // localStorage.clear()
        }
      }
       
    }
  }
  if (errorResponse.status && errorResponse.status === 404) {
    const error = errorResponse.error
    const keys = Object.keys(errorResponse.error);
    if (keys && keys.length) {
      const fieldError = error[keys[0]]
      if (fieldError && fieldError.length)
        errorMessage = fieldError[0]
    }
  }
  return errorMessage
}