import { Pipe, PipeTransform } from '@angular/core';
//import { ALL_TIME_ZONES } from 'src/app/code-modules/utils/time-zone';

@Pipe({
    name: 'timeZonePipe'
})

export class TimeZoneFilterPipe implements PipeTransform {

    transform(AllTimeZone:Array<any>,timezone: string): Array<any> {

        if (!timezone) {
             //console.log('hii')
            return AllTimeZone;
        } else {
            let filteredTimeZone = AllTimeZone;
            filteredTimeZone = AllTimeZone.filter(time => (time.displayValue.toString().match(new RegExp(timezone, 'gi'))));
            //console.log('hii',filteredTimeZone,timezone)
            return filteredTimeZone
        }
    }
}
