import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './common-modules/components/login/login.component';
import { SignupComponent } from './common-modules/components/signup/signup.component';

import { ForgotPasswordComponent } from './common-modules/components/forgot-password/forgot-password.component';
import { CircleOneModule } from './circle-one/circle-one.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiRequestInterceptor } from './code-modules/api/interceptors/api-requests.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from './code-modules/utils/utils.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import { PapaParseModule } from 'ngx-papaparse';
import { ApiResponseInterceptor } from './code-modules/api/interceptors/api-responses.interceptor';
import { EnterUsernameComponent } from './common-modules/components/forgot-password/enter-username/enter-username.component';
import { ResetPasswordComponent } from './common-modules/components/forgot-password/new-password/reset-password/reset-password.component';
import { NewPasswordComponent } from './common-modules/components/forgot-password/new-password/new-password.component';
import { TermsConditionsDialogComponent } from './common-modules/components/signup/terms-conditions-dialog/terms-conditions-dialog.component';
//import {DispositionDialogComponent} from './circle-one/general/components/disposition-dialog/disposition-dialog.component';

import { ChangePasswordComponent } from './common-modules/components/change-password/change-password.component';
import { LandingPagesComponent } from './common-modules/components/landing-pages/landing-pages.component';
import { DecimalPipe } from '@angular/common';
import { PageNotFoundComponent } from './common-modules/components/landing-pages/page-not-found/page-not-found.component';
import { SharedModule } from './shared/shared.module';
import { NgxLoadingModule } from 'ngx-loading';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BnNgIdleService } from 'bn-ng-idle';
import { BreakPointRegistry, FlexLayoutModule, FlexStyleBuilder, LayoutAlignStyleBuilder, LayoutStyleBuilder, MediaMarshaller, PrintHook, StylesheetMap, StyleUtils, ɵMatchMedia } from '@angular/flex-layout';
// import { HelpComponent } from './components/help/help.component';

import { OrderModule } from 'ngx-order-pipe';
import { SignupVerificationComponent } from './common-modules/components/signup-verification/signup-verification.component';
import { MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    EnterUsernameComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
    TermsConditionsDialogComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    LandingPagesComponent,
    PageNotFoundComponent,
    SignupVerificationComponent,

    // HelpComponent
  ],
  imports: [
    // from project:
    CircleOneModule,
    UtilsModule,
    SharedModule,
    FlexLayoutModule,
    // Other:
    PapaParseModule,
    NgxUiLoaderModule,
    ToastrModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({}),
    OrderModule,
    MatProgressSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    PrintHook,
    StyleUtils,
    StyleSheet,
    StylesheetMap,
    LayoutAlignStyleBuilder,
    LayoutStyleBuilder,
    FlexStyleBuilder,
    MediaMarshaller,
    ɵMatchMedia,
    BreakPointRegistry,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResponseInterceptor,
      multi: true
    },
    BnNgIdleService
    // {
    //   provide: MAT_DIALOG_DATA,
    //   useValue: {} // Add any data you wish to test if it is passed/used correctly
    // }
  ],

  exports: [
  ],
  entryComponents: [
    TermsConditionsDialogComponent,
    // DispositionDialogComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
