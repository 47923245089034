import { Component, OnInit } from '@angular/core';

import { NotificationTypes, WebSocketService } from '../code-modules/websocket/web-socket.service';
import { MatDialog } from '@angular/material';
import { TaskReminderNotificationComponent } from './app/components/task-reminder-notification/task-reminder-notification.component';

@Component({
  selector: 'app-circle-one',
  templateUrl: './circle-one.component.html',
  styleUrls: ['./circle-one.component.sass']
})
export class CircleOneComponent implements OnInit {

  constructor(
    public eventNotificaionMatDialog: MatDialog,
    private socketConnection: WebSocketService,
  ) { }

  ngOnInit() {
    console.log('Connecting to sockets from context');
    this.socketConnection.socket$.subscribe(data => {
      console.log("data msggg",data )
        if (data.length) {
            data.forEach((notification) => {
              //  notification = {"id": 3, "notification_type": "lead-event",
              //  "data": {"id": 7, "lead": "Finsara_Demo_setup169095939182", 
              //  "title": "Without ESC", "description": "doing testing ",
              //   "event_date": "2023-07-26", "guests": [], "start_time": "15:00:00"},
              //    "created": "2023-07-26T09:25:02.849129Z"}
                if (notification.notification_type === NotificationTypes.NOTIFICATION_LEAD_EVENT) {
                  this.getReminderDialog(notification.data);
                  this.socketConnection.clear({"id":notification.id})
                }
                })
              }
            })  
  }

  getReminderDialog(data){
    const dialogRef = this.eventNotificaionMatDialog.open(TaskReminderNotificationComponent, {
      panelClass: 'co-dialog-panel',
      data: {msg: data},
      height: '350px',
      width: '400px'
  
    });
  
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
