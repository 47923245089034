import { Component, Input, OnInit, Directive, HostListener } from '@angular/core';
import { DataDrivenField, DdfFieldData } from '../ddf.interfaces';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { ValidationMessages, DynamicFieldValidation } from '../../validations/genral-validation';
import { DynamicFieldValidators } from '../ddf.enums';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { TicketDetailsComponent } from 'src/app/circle-one/tickets/components/ticket-details/ticket-details.component';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { SideNavComponent } from 'src/app/circle-one/navbar/components/side-nav/side-nav.component';
import { SidenavService } from 'src/app/circle-one/navbar/components/side-nav/sidenav.service';


@Directive({ selector: '[numberText]' }) export class NumberTextDirective {
  @HostListener('keypress', ['$event']) onKeyPress(event) {
    console.log(event)
    if (event.target.name === "numberText") {
      return new RegExp("^[0-9]*$").test(event.key);
    }
  }


}



@Component({
  selector: 'app-dd-input',
  templateUrl: './dd-input.component.html',
  styleUrls: ['./dd-input.component.sass']
})
export class DdInputComponent implements OnInit {
  // @Directive({selector:'[numberText]'})
  @Input() field: DataDrivenField;
  @Input() showLabels = true;
  @Input() isEdit
  currentUser
  formGroup: FormGroup;
  maxDate;
  minDate;
  phoneNo;
  levelOneData
  levelTwoData
  levelThreeData
  public contactForm: FormGroup;
  @Input() createdDate: Date;
  @Output() eventCall = new EventEmitter<any>();
  levelFourData: any;
  numberTextLimit: any;
  deviceId: any;
  currentUrl: string;
  constructor(private formBuilder: FormBuilder,
    public context: AppContextService,
    public api: ApiService,
    public router: Router,
    public sideNavService: SidenavService
    // public tckDetail:TicketDetailsComponent
  ) {
    this.contactForm = this.formBuilder.group({
      formControlPhone: ['', Validators.required]
    });
  }



  get choices(): Array<any> {
    //console.log( this.field.fieldData.choices.length)
    if (this.field.fieldData.choices && this.field.fieldData.choices.length) {
      return this.field.fieldData.choices
    } else
      return []
  }
  get multiple_choices(): Array<any> {
    //console.log( this.field.fieldData.choices.length)
    if (this.field.fieldData.multiple_choices && this.field.fieldData.multiple_choices.length) {
      return this.field.fieldData.multiple_choices
    } else
      return []
  }
  ngOnInit() {

    // start



    this.currentUrl = this.router.url
    console.log(this.currentUrl)

    this.context.currentUser$.subscribe((user) => {
      this.currentUser = user
    });

    if (this.field.fieldData.type === 'date') {
      setInterval(() => {
        this.field.control.setValue(this.mapToDate(this.field.control.value))
      })
      if (this.currentUser.tenant.sub_domain == 'cyret') {
        if (this.field.controlName == 'Record Date') {
          this.maxDate = new Date()
          this.minDate = new Date(this.createdDate)
        }
      }
    }
    if (this.field.fieldData.type === 'multi-level') {
      console.log(this.field, "dd-input")
      this.api.getNestedDynamicFields(this.field.fieldData.identifier, 1).subscribe((data) => {
        this.levelOneData = data
        this.levelTwoData = undefined
        this.levelThreeData = undefined
        this.levelFourData = undefined
        if (data.length) {
          this.changeMultiLevelHandler(this.field.control.value)
          console.log(this.field.control.value, "check data")
        }
      })

      // this.field.control.setValue(this.phoneNo)

    }

    // debugger
    if (this.field.fieldData.validators.length) {
      this.field.fieldData.validators.forEach((validator) => {


        if (validator != DynamicFieldValidators.REQUIRED) {
          if (this.field.fieldData.field_length) {
            this.field.control.setValidators([Validators.maxLength(this.field.fieldData.field_length), Validators.minLength(this.field.fieldData.field_length)])
          }
        }
        else if (validator == DynamicFieldValidators.REQUIRED && this.field.fieldData.field_length) {
          this.field.control.setValidators([Validators.required, Validators.maxLength(this.field.fieldData.field_length), Validators.minLength(this.field.fieldData.field_length)])
        } else if (validator == DynamicFieldValidators.REQUIRED) {
          this.field.control.setValidators([Validators.required])
        }

      })
    } else {
      if (this.field.fieldData.field_length) {
        this.field.control.setValidators([Validators.maxLength(this.field.fieldData.field_length), Validators.minLength(this.field.fieldData.field_length)])

      }
    }

  }

  changeMultiLevelHandler(event) {
    console.log(event, "change")
    this.levelOneData.forEach(element => {
      if (event == element.choice) {
        this.api.getNestedDynamicFields(element.id, 2).subscribe((data) => {
          this.levelTwoData = data
          this.levelThreeData = undefined
          this.levelFourData = undefined
        })
      }
    });

  }
  changeMultiLevelTwoHandler(event) {
    this.levelTwoData.forEach(element => {
      if (event.value == element.choice) {
        this.api.getNestedDynamicFields(element.id, 3).subscribe((data) => {
          this.levelThreeData = data
          this.levelFourData = undefined
        })
      }
    });
  }
  changeMultiLevelThreeHandler(event) {
    this.levelThreeData.forEach(element => {
      if (event.value == element.choice) {
        this.api.getNestedDynamicFields(element.id, 4).subscribe((data) => {
          this.levelFourData = data
        })
      }
    });
  }
  getErrorMessage(controlName) {
    let errorMessages = DynamicFieldValidation(this.field.control, this.field.controlName)
    return errorMessages[controlName]
  }

  mapToDate(date: string) {
    return date ? new Date(date) : ''
  }
  dateChanged() {
    let record_date = `${this.field.control.value.getFullYear()}-${(this.field.control.value.getMonth() + 1)}-${this.field.control.value.getDate()}`
    this.eventCall.emit(record_date)
  }
  hasError(event: any): void {
    if (!event && this.contactForm.value.formControlPhone !== '') {
      this.contactForm.get('formControlPhone').setErrors(['invalid_cell_phone', true]);
    }
  }
  telInputObject(obj) {
    // console.log(obj,this.phoneNo.iso2);
    if (this.phoneNo)
      obj.setCountry(this.phoneNo.iso2);
  }
  onCountryChange(obj) {
    console.log(obj, "________")
    this.phoneNo = obj
    this.field.control.setValue(`${obj.dialCode}`)
    // obj.setCountry('in');
  }
  getNumber(obj) {
    console.log(obj, ">>>>>>>")

    if (this.field.fieldData.type === 'tel-phone') {
      this.phoneNo = obj
      // setInterval(() => {
      this.field.control.setValue(this.phoneNo)
      // })
    }
  }
  pauseForDial(data) {
    let mobileNo = data.control.value
    this.sideNavService.dialInputValue.next(mobileNo);
    $('#dialPadValue').val(mobileNo);
  }

  checkDeviceIdLength() {
    console.log('aala re');

    if (this.field.control.value) {
      this.deviceId = (this.field.control.value).length
    }
  }
}
