import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {loginUrl} from './auth.urls';

@Injectable({
  providedIn: 'root'
})
export class  IfLoggedIn implements CanActivate, CanActivateChild {
  loggerName = '[AuthGuard: IsLoggedIn]';

  constructor(
    private router: Router,
    private auth: AuthService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    // url has a specific requirement. Login status has to be resolved for further result
    return this.auth.loginStatus$.pipe(
      first(),
      map(loginStatus => {
        console.log(loginStatus , loginUrl)
        localStorage.setItem('urlRoute',state.url);
        console.log(`${this.loggerName} Route ${state.url} should be authenticated. Login status: ${loginStatus}`);
        return loginStatus || this.router.parseUrl(loginUrl);
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
