import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, take, first} from 'rxjs/operators';
import {AuthService} from '../authentication/auth.service';
import {InAppLogger} from '../utils/general-utils/InAppLogger';
import {environment} from '../../../environments/environment';
import {NotificationRead} from '../api/interfaces/Business-Rule/business-rule-api-interfaces';


export enum SocketActions {
    AUTH_SUCCESS = 'auth-success',
}

export enum NotificationTypes {
    NOTIFICATION_KM_NEW_ENTRY = 'km-new-entry',
    NOTIFICATION_EMAIL_NEW_ASSIGN = 'email-new-assign',
    NOTIFICATION_EMAIL_NEW_INCOMING = 'email-new-incoming',
    NOTIFICATION_EMAIL_COUNT_UPDATE = 'email-count-update',
    NOTIFICATION_TICKET_NEW_ASSIGN = 'ticket-new-assign',
    NOTIFICATION_TICKET_RESOLVED = 'ticket-resolved',
    NOTIFICATION_LEAD_NEW_ASSIGN = 'opportunity-new-assign',
    NOTIFICATION_TICKET_EVENT = "ticket-event",
    NOTIFICATION_LEAD_EVENT = "lead-event",
}


export interface SocketResponse {
    actionType: SocketActions | NotificationTypes;
    description: string;
    notification: any;
}

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {

    public url: string = '';
    //static url = 'wss://6tb76e5d7k.execute-api.ap-south-1.amazonaws.com/dev';
    logger = new InAppLogger(WebSocketService.name);
    // initialized = false;

    private socketSubject: BehaviorSubject<Array<NotificationRead>> = new BehaviorSubject<Array<NotificationRead>>(undefined);
    socket$: Observable<Array<NotificationRead>> = this.socketSubject.asObservable().pipe(
        filter(value => Boolean(value)) // suppress un truthy value
    );

    private ws: WebSocket;

    constructor(
        private auth: AuthService,
    ) {
        const wsProtocol = (window.location.protocol === 'https:') ? 'wss://' : 'ws://';
        if (window.location.hostname !== 'localhost') {
            this.url = wsProtocol + window.location.hostname.split(environment.saasDomain)[0] + environment.socketUrl;
        } else {
            this.url = wsProtocol + environment.localTestDomain + '.' + environment.socketUrl;
        }
    }


    connect(url?: string): Observable<any> {
        console.log('socket url', this.url);
        //if (!this.initialized) {
        //console.log("socket not initialized")
        if (url) {
            this.url = url;
        }
        this.ws = new WebSocket(this.url);

        this.ws.onopen = () => {
            this.logger.log('Connected to', this.url);
            this.auth.apiCredentials.pipe(
                map(apiCredentials => 
                    this.ws.send(JSON.stringify(apiCredentials)))
            ).subscribe(() => {
                this.logger.log('Api credentials sent');
            });
        };
        this.ws.onmessage = (messageEvent) => {
           const message = JSON.parse(messageEvent.data) as NotificationRead[];
           console.log(JSON.parse(messageEvent.data))

            this.logger.log('New message event :', messageEvent);

            this.socketSubject.next(message);
        };
        this.ws.onerror = (error) => {
            this.socketSubject.error(error);
        };
        this.ws.onclose = () => {
            this.socketSubject.complete();

            console.log('Socket is closed. Reconnecting the socket connection');
            this.ws = new WebSocket(this.url);
            this.ws.onopen = () => {
                this.logger.log('Connected to', this.url);
                this.auth.apiCredentials.pipe(
                    map(apiCredentials => this.ws.send(JSON.stringify(apiCredentials)))
                ).subscribe(() => {
                    this.logger.log('Api credentials sent');
                });
            };

            // console.log('Socket is closed');
            // this.auth.loginStatus$.pipe(
            //   first()
            // ).subscribe(loginStatus => {
            //   if (loginStatus)
            //     this.auth.logout()
            // })


        };
        //this.initialized = true;
        //}
        return this.socket$;
    }

    open() {

    }

    disconnect() {
        console.log('Closing socket connection');
        if(this.ws.readyState === 0 || this.ws.readyState === 1) {
            this.ws.close();
        }    
    }

    clear(message: NotificationRead) {
        this.logger.log('Clearing:', message);
        return this.ws.send(JSON.stringify(message));
    }

    ping() {
        //console.log("Ping to socket");
        return this.ws.send(JSON.stringify({}));
    }
}
