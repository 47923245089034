import {DayOfWeek, PlatformPermissionIds, PriorityDispositionEnum} from '../../../saas/saas.enums';
import {NotificationTypes, SocketActions} from '../../../websocket/web-socket.service';
import {EmailDashboard, EmailThreadDetailRead} from '../Email/email-api-interfaces';
import {TicketDashboard, TicketRead} from '../Ticket/ticket-api-interfaces';
import { OpportunityRead } from '../Campaign/campaign-api-interfaces';
import { PlatformPermission, UserTeamList } from '../User/user-api-interfaces';

export interface Group {
    readonly id?: number;
    name: string;
    module_permissions:PlatformPermissionIds[];
    department?: NestedDepartment;
    role:string
}

export interface GroupsList {
    count: number;
    /** format: uri */
    next?: string;
    /** format: uri */
    previous?: string;
    results: Group[];
}

export interface NestedDepartment {
    readonly id?: number;
    name: string;
}


export interface Team {
    readonly id?: number;
    name: string;
    agent_module_permissions?:string[];
    manager_module_permissions?:string[];
    role:string[];
    auto_ticket_generate_enabled?:boolean;
}

export interface NestedGroup {
    readonly id?: number;
    name: string;
    department?: number;
}

export interface Topic {
    readonly id?: number;
    name: string;
    entries: TopicEntryRead[];
}

export interface TopicEntryRead {
    readonly id?: number;
    topic?: NestedTopic;
    subject: string;
    content: string;
}

export interface NestedTopic {
    readonly id?: number;
    name: string;
}

export interface TopicsList {
    count: number;
    /** format: uri */
    next?: string;
    /** format: uri */
    previous?: string;
    results: Topic[];
}

export interface TopicEntryUpdate {
    id?: number;
    topic?: number;
    subject: string;
    content: string;
}

export interface TopicEntryList {
    count: number;
    /** format: uri */
    next?: string;
    /** format: uri */
    previous?: string;
    results: TopicEntryRead[];
}

export interface ApiThemeColor {
    mainLayoutColor?: string
    mainLayoutFont?: string
    headerColour?: string
    navBarColour?: string
    navBarGrade?: string
    navFontColour?: string
    activeColour?: string
    headerFontColour?: string
    //logo?: string
}

export interface ContactBulkUpload {
    account?: number;
    file_name: string;
    readonly csv_file?: string;
}
export interface UserBulkUpload {
    file_name: string;
    readonly csv_file?: string;
}
export interface LeadBulkUpload {
    file_name: string;
    readonly csv_file?: string;
    campaign?:number;
    lead?:any
}
//Customer actions => Customer history
export enum ActionType {
    EMAIL = 'email',
    TICKET = 'ticket',
    OPPORTUNITY = 'opportunity'
}

export interface NotificationRead {
    readonly id?: number,
    notification_type?: SocketActions | NotificationTypes
    data?: any,
    created?: string //time stamp
}

export interface MailAttachment {
    filename: string,
    content?: string,
    path?: string
}

export interface HourClock {
    time: number; // in hours
    minutes: number; // in minuts
    hourClock?: string; // AM / PM
}

export interface DailyBusinessHourData {
    startTime: HourClock; // in hours
    endTime: HourClock; // in hours
    holiday: boolean;
}

export interface DayOfWeekBusinessData {
    [DayOfWeek.MON]?: DailyBusinessHourData;
    [DayOfWeek.TUE]?: DailyBusinessHourData;
    [DayOfWeek.WED]?: DailyBusinessHourData;
    [DayOfWeek.THU]?: DailyBusinessHourData;
    [DayOfWeek.FRI]?: DailyBusinessHourData;
    [DayOfWeek.SAT]?: DailyBusinessHourData;
    [DayOfWeek.SUN]?: DailyBusinessHourData;
}

export interface specificTimeSheduler {
    DayOfWeekBusinessData?: DayOfWeekBusinessData,
    timezone?: string
}

export interface BaseNode {
    campaign_name?: number;
    id?: number;
    topic: string;
    sub_topics?: BaseNode[];
    level?: string;
    ticket_name?: number;
    required?: boolean;
    priority?: PriorityDispositionEnum;
    hide?:boolean;
    order_name?:number
}

export interface PhoneBaseNode {
    id?: number;
    disposition: string;
    sub_disposition?: PhoneBaseNode[];
    level?: any;
    required?: boolean;
    priority?: PriorityDispositionEnum;
    hide?:boolean;
}

export interface DispositionDialogDataObject {
    action: ActionType,
    existingData: EmailThreadDetailRead | TicketRead | OpportunityRead
}

export interface BusinessHoursReadUpdate {
    time_zone: string,
    open_24_7: boolean,
    business_hours?: BusinessTime[]
}

export interface BusinessTime {
    readonly id?: number;
    start_hours: number | null;
    start_minutes: number | null;
    end_hours: number | null;
    end_minutes: number | null;
    is_holiday?: boolean;
    day_of_week?:string;
    team?:UserTeamList |number;
}

export interface BusinessHours {
    Mo: BusinessTime;
    Tu: BusinessTime;
    We: BusinessTime;
    Th: BusinessTime;
    Fr: BusinessTime;
    Sa: BusinessTime;
    Su: BusinessTime;
}

export interface Dashboard {
    email: EmailDashboard;
    ticket: TicketDashboard;
}

export enum RuleType {
    GROUP = 'Group based',
    AGENT = 'Agent based'
  }

  export interface Department {
    readonly id?: number;
    name: string;
    readonly groups?: NestedGroup[];
}
