import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {AllAccountTypes, AccountTypes} from '../../../../code-modules/saas/saas.enums';
import {ApiService} from '../../../../code-modules/api/api-services/api.service';
import {ToastrService} from 'ngx-toastr';
import {MatTableDataSource} from '@angular/material/table';
import {ConfirmDialogComponent} from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import {ActionRead, ActionMergeRequest, TicketRead} from 'src/app/code-modules/api/interfaces/Ticket/ticket-api-interfaces';
import {EmailThreadDetailRead} from 'src/app/code-modules/api/interfaces/Email/email-api-interfaces';
import {AppComponent} from 'src/app/app.component';
import {AppContextService} from 'src/app/code-modules/app-context/app-context.service';
import {combineLatest} from 'rxjs';
import {ContactRead, CustomersContactsList} from '../../../../code-modules/api/interfaces/Customer/customer-api-interfaces';
import {ActionType} from '../../../../code-modules/api/interfaces/Business-Rule/business-rule-api-interfaces';
import { element } from 'protractor';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';

export interface ContactHistoryData {
    channel: string;
    date: string;
    disposition: any;
    emailId: string;
    referenceId: string;
}

export interface HistoryDialogData {
    ticket?: TicketRead,
    email?: EmailThreadDetailRead
    action?:ActionRead[]
}


@Component({
    selector: 'app-contact-history-dialog',
    templateUrl: './contact-history-dialog.component.html',
    styleUrls: ['./contact-history-dialog.component.sass']
})
export class ContactHistoryDialogComponent implements OnInit {
    defaultAvatar = 'url(https://www.w3schools.com/howto/img_avatar.png)';
    accountTypes = ActionType;
    faIcons = {
        'email': 'envelope',
        'Phone': 'phone',
        'Chat': 'comments',
        'ticket': 'clipboard-check'
    };
    displayedColumns: string[] = ['channel', 'date', 'disposition', 'emailId', 'emailRefId', 'ticketId', 'ticketMerged'];
    CONTACT_HISTORY: Array<ActionRead> = []
    selectedThreadAction: ActionRead;
    selectedTicketAction: ActionRead;
    action: ActionRead ;
    isContact: boolean = true;
    dataSource = new MatTableDataSource([]);
    // dataSource = [];
    AllAccountTypes = AllAccountTypes;
    actionDetailsFlag = false;
    //objectKeys = Object.keys;
    customerId: number;
    selectedThreadId: number = undefined;
    selectedTicketId: number = undefined;
    duplicateCustomerActionId: number;
    supportEmail: string;
    accountId: number = undefined;
    sortBy = '-created';
    checkDisable : boolean ;
    currentUser;
    constructor(
        public dialogRef: MatDialogRef<ContactHistoryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public inputData: HistoryDialogData,
        private api: ApiService,
        private toaster: ToastrService,
        public confirmDialog: MatDialog,
        public context: AppContextService
    ) {
    }

    ngOnInit() {
        this.context.currentUser$.subscribe((user) => {
            this.currentUser = user
        });
        this.context.currentTenant$.subscribe((tenant) => {
            this.supportEmail = "test@bluewhirl.io"
        });
        this.actionDetailsFlag = false;
        if (this.inputData) {
            if (this.inputData.ticket) {
                this.customerId = this.inputData.ticket.action.contact.id;
                this.selectedTicketId = this.inputData.ticket.id;
                this.accountId = this.getAccountId(this.inputData.ticket.action.contact);

            }
            if (this.inputData.email) {
                this.customerId = this.inputData.email.action.contact.id;
                this.selectedThreadId = this.inputData.email.id;
                this.accountId = this.getAccountId(this.inputData.email.action.contact);
                
            }
            // console.log(this.inputData)
            if(this.inputData.action){
                let accId;
                this.CONTACT_HISTORY = this.inputData.action.filter((element) => {
                    element.action_type === ActionType.EMAIL || element.action_type === ActionType.TICKET
                    accId = element.contact
                });
                this.CONTACT_HISTORY = this.inputData.action
                this.dataSource.data = this.CONTACT_HISTORY
                this.accountId = this.getAccountId(accId)
               
            }
        }
        if (this.customerId) {
            setTimeout(() => this.loadCustomerHistory(this.customerId, undefined));
        }
    }


    getAccountId(contact: ContactRead): number | undefined {
        return contact.account && contact.account.id ? contact.account.id : undefined;
    }

    sortHistory(sortBy: string) {
        this.sortBy = sortBy;
        this.load();
    }

    loadCustomerHistory(customerId: number | undefined, accountId: number | undefined) {
        console.log('loading Customer history');
        //this.loader.start();
        this.api.customerActionList(customerId, accountId, this.sortBy)
            .subscribe((data) => {
                    console.log(data ,'++++++++')
                    this.CONTACT_HISTORY = data
                    // .filter((element) => element.action_type === ActionType.EMAIL || element.action_type === ActionType.TICKET);
                    if (this.selectedThreadId) {
                        this.selectedThreadAction = this.CONTACT_HISTORY.find((res) => (res.thread && res.thread.id === this.selectedThreadId));
                    }
                    if (this.selectedTicketId) {
                        this.selectedTicketAction = this.CONTACT_HISTORY.find((res) => (res.ticket && res.ticket.id === this.selectedTicketId));
                    }
                    
                    this.dataSource.data = this.CONTACT_HISTORY;
                    console.log(this.dataSource.data,"DS")
                    //this.loader.stop();
                },
                (err) => {
                    this.toaster.error(ErrorToaster(err))
                    // this.toaster.error('Something went wrong');
                    //this.loader.stop();
                }
            );
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    viewThread(refId: number,isDisable ,channel): void {
       console.log(refId , isDisable , channel ,";;")
        if(channel == 'email'){
            console.log("CH",this.CONTACT_HISTORY)
            this.CONTACT_HISTORY.forEach((entry) => {
                console.log(entry , "entry")
                // if(entry.ticket == null){
                    if(entry.thread){
                        if(entry.thread.id == refId){
                            this.action = entry
                        }
                        
                    }
                // }else{
                    if(entry.ticket){
                        if(entry.ticket.id == refId){
                            this.action = entry
                        }
                        
                    }
                // }
                
            });
           
        }else{
            
            if(channel === 'Email' || channel === 'Phone' || channel === 'Chat' || channel === 'Social-Media' || channel === 'Whatsapp'){
                console.log(channel,refId , "email history")
                this.action = this.CONTACT_HISTORY.find((entry) => entry.ticket?entry.ticket.id == refId :undefined);
                this.checkDisable = true 
                console.log(refId , channel , isDisable,this.action,this.CONTACT_HISTORY )
                
            }
           
            if(channel === 'ticket'){
                this.action = this.CONTACT_HISTORY.find((entry) => entry.ticket.id == refId);
                this.checkDisable = false
                
                // console.log(this.action ,"ticket")
            }
        }
        
        
       
        this.actionDetailsFlag = true;
    }

    backToHistory(): void {
        console.log('clicked');
        this.actionDetailsFlag = false;
    }

    markAsDuplicate() {
        //this.loader.start();
        const duplicateThread: ActionMergeRequest = {
            duplicate_action: this.action.id
        };
        let actionId: number;
        if (this.selectedTicketAction && this.selectedTicketAction.id) {
            actionId = this.selectedTicketAction.id;
        } else if (this.selectedThreadAction && this.selectedThreadAction.id) {
            actionId = this.selectedThreadAction.id;
        }

        console.log(this.selectedTicketAction, this.selectedThreadAction);
        this.api.actionMerge(duplicateThread, actionId).subscribe(success => {
                //this.loader.stop();
                this.actionDetailsFlag = false;
                this.loadCustomerHistory(this.customerId, undefined);
                this.toaster.success('Success');
            },
            err => {
                //this.loader.stop();
                this.toaster.error(ErrorToaster(err))
                // this.toaster.error('Something went wrong');
                console.log(err);
            });
    }

    openConfirmDialog() {
        const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
            panelClass: 'co-dialog-panel',
            data: 'Confirm to mark as duplicate'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.markAsDuplicate();
            }
        });

    }

    get customFields() {
        return (this.action && this.action.ticket.dynamic_fields) ? Object.keys(this.action.ticket.dynamic_fields) : [];
        return [];
    }

    viewAttachment(path: string) {
        if (path) {
            window.open(path, '_blank');
        } else {
            this.toaster.error('Unable to view the item');
        }
    }

    getProfileImage(avatar: string) {
        if (avatar) {
            return 'url(' + avatar + ')';
        } else {
            return this.defaultAvatar;
        }
    }

    onTypeChange(checked: boolean) {
        this.isContact = !checked;
        this.load();
    }

    load() {
        if (!this.isContact && this.accountId) {
            this.loadCustomerHistory(undefined, this.accountId);
        } else {
            this.loadCustomerHistory(this.customerId, undefined);
        }
    }
}
