import { DdfChoiceData, DdfFieldData,systemFields } from '../ddf.interfaces';
import { FormControl } from '@angular/forms';
import {
  ChoiceFieldConfigurator,
  ChoicesConfigurator,
  DdfConfigurator,
  DynamicFieldConfigurator,
  DynamicFieldConfiguratorBase,
  MultiLevelFieldConfigurator,
  NestedConfigurator,
  SingleChoiceConfigurator,
  DynamicChoiceDefinitionRead,
  DynamicFieldDefinitionRead
} from './Configurators.interfaces';
import { InAppLogger } from '../../general-utils/InAppLogger';
import { DynamicFieldTypes ,DynamicFieldValidators} from '../ddf.enums';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { DdfConfiguratorComponent } from './ddf-configurator/ddf-configurator.component';

export class ChoicesConfiguratorForm implements ChoicesConfigurator {
  logger = new InAppLogger(ChoicesConfiguratorForm.name);
  fields: Array<SingleChoiceConfigurator> = [];
  editMode = true;
  focusedChoiceIndex: number;


  constructor(allChoices?: DynamicChoiceDefinitionRead[], public nestedFieldsConfigurator?: NestedConfigurator) {
    // this.logger.log('ChoiceData:', choiceData);
    // const allChoices = Object.keys(choiceData || {});
    if (allChoices && allChoices.length) {
      allChoices.forEach(choice => {
        this.addChoice(choice.choice, choice.id);
      });
    }
  }

  static buildSingleChoiceConfigurator(value?: string, id?: number): SingleChoiceConfigurator {
    const choiceValueControl = new FormControl(value);
    const displayValueControl = new FormControl(id);
    return {
      value: choiceValueControl,
      id: displayValueControl
    };
  }

  static onBlurChoiceValueControl(choiceValueControl: FormControl, displayValueControl: FormControl) {
    // add auto fill for choice display value
    if (choiceValueControl && choiceValueControl.value && displayValueControl &&!displayValueControl.value) {
      displayValueControl.setValue(choiceValueControl.value, { emitEvent: false });
    }
  }

  
  addChoice(value: string, id: number) {
    const choiceConfig = ChoicesConfiguratorForm.buildSingleChoiceConfigurator(value, id);
    this.fields.push(choiceConfig);
  }

  removeChoiceOnIndex(indexToRemove: number) {
    // this.logger.log(`Removing choice at ${indexToRemove} from `, this.fields);
    this.fields = this.fields.filter((value, index) => index !== indexToRemove);
    if (this.nestedFieldsConfigurator) {
      // also remove from nested configurator
      this.nestedFieldsConfigurator.choices = this.nestedFieldsConfigurator.choices.filter((value, index) => index !== indexToRemove);
    }
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  onFocusChoiceOnIndex(indexWithFocus: number) {
    this.focusedChoiceIndex = indexWithFocus;
    // this.logger.log(`Focus on field with index: ${this.focusedChoiceIndex}`);
  }

  get focusedChoice(): SingleChoiceConfigurator | undefined {
    return (this.focusedChoiceIndex >= 0) && this.fields[this.focusedChoiceIndex];
  }

  get nestedConfigurator(): DdfConfigurator {
    console.log("LLLLLLL")
    return this.focusedChoice && this.getConfiguratorForFieldOnIndex(this.focusedChoiceIndex);
  }

  getConfiguratorForFieldOnIndex(index: number): DdfConfigurator {
    return this.nestedFieldsConfigurator && this.nestedFieldsConfigurator.choices[index];
  }

  removeNestedConfigurator(): void {
    delete this.nestedFieldsConfigurator;
  }
}

export class DdfConfiguratorForm implements DdfConfigurator {
  logger = new InAppLogger(DdfConfiguratorForm.name);
  fields: Array<DynamicFieldConfiguratorBase> = [];
  editMode = true;
  focusedFieldIndex: number;
  // api:DdfConfiguratorComponent;
  constructor(
    public title: string,
    initialData?: Array<DdfFieldData>,
    private requiredFieldCount = 0,
    public systemFields : Array<systemFields> = []) {
    console.log(initialData,requiredFieldCount,"______________________________-")
    if (initialData && initialData.length) {
      initialData.forEach(fieldData => this.addField(fieldData));
    }
  }

  canDeleteFieldOnIndex(index: number): boolean {
    // cannot edit initial fields if they are the system default
    return  index >= this.requiredFieldCount
  }

  static getTitleForNestedConfigurator(choiceDisplayValue: string): string {
    return `Multi level fields for choice '${choiceDisplayValue}'`;
  }

  static buildSingleFieldConfgurator(): DynamicFieldConfigurator {
    return {
      label: new FormControl(),
      type: new FormControl()
    };
  }

  

  removeFieldOnIndex(indexToRemove: number) {
    // this.logger.log(`Removing field at ${indexToRemove} from `, this.fields);
    this.fields = this.fields.filter((value, index) => index !== indexToRemove);
  }

  markAsMandatory(index:number,checked:boolean){
    if(checked){
    this.fields[index].fieldData.validators.push(DynamicFieldValidators.REQUIRED);
    }
    else{
      const spliceIndex = this.fields[index].fieldData.validators.indexOf(DynamicFieldValidators.REQUIRED);
      this.fields[index].fieldData.validators.splice(spliceIndex,1);
    }
  }
  onFocusFieldOnIndex(indexWithFocus: number) {
    this.focusedFieldIndex = indexWithFocus;
    // this.api.getLevelWiseFields(this.fields[this.focusedFieldIndex].fieldData.identifier ,1)
    this.logger.log(`Focus on field with index: ${indexWithFocus}`, this.hasMultipleLevelChoice);
  }
  
  get focusedField(): DynamicFieldConfiguratorBase | undefined {
    return (this.focusedFieldIndex >= 0) && this.fields[this.focusedFieldIndex];
  }

  get hasChoices() {
    return this.focusedField && [DynamicFieldTypes.DROP_DOWN].includes(this.focusedField.type.value);
  }

  get hasMultipleChoices() {
    return this.focusedField && [DynamicFieldTypes.CHECKBOX].includes(this.focusedField.type.value);
  }
  get hasMultipleLevelChoice(){
    return this.focusedField && [DynamicFieldTypes.NESTED].includes(this.focusedField.type.value);
  }

  get choiceConfigurator() {
    return this.hasChoices && (this.focusedField as ChoiceFieldConfigurator | MultiLevelFieldConfigurator).choices;
  }
  get multiLevelConfigurator() {
    return this.hasMultipleLevelChoice && (this.focusedField as ChoiceFieldConfigurator | MultiLevelFieldConfigurator).multi_level;
  }
  get choiceMultipleConfigurator() {
    return this.hasMultipleChoices && (this.focusedField as ChoiceFieldConfigurator | MultiLevelFieldConfigurator).multiple_choices;
  }

  get nestedConfigurator() {
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@22")
    const focusedField = this.focusedField as MultiLevelFieldConfigurator;
    if(this.hasChoices){
      console.log(focusedField,"*&&&&&")
      return this.hasChoices && focusedField.choices && focusedField.choices.nestedConfigurator;
    }
    if(this.hasMultipleChoices){
      return this.hasMultipleChoices && focusedField.multiple_choices && focusedField.multiple_choices.nestedConfigurator;
    }
    if(this.hasMultipleLevelChoice){
      return this.hasMultipleLevelChoice && focusedField.multi_level && focusedField.multi_level.nestedConfigurator;
    }
  }

  get fieldData(): Array<DdfFieldData> {
    return this.fields.map(fieldConfigurator => {
      const fieldData: DdfFieldData = {
        label: fieldConfigurator.label.value,
        type: fieldConfigurator.type.value,
        identifier: fieldConfigurator.label.value,
        validators:fieldConfigurator.fieldData.validators
      };
      if ((fieldConfigurator as MultiLevelFieldConfigurator | ChoiceFieldConfigurator).choices) {
        fieldData.choices = [];
      }
    
      return fieldData;
    });
  }

  addField(fieldData: DdfFieldData) {
    const fieldIndex = this.fields.length;
   
    // this.logger.log('Adding field: ', fieldData, ` at index ${fieldIndex}`);
    let fieldType: DynamicFieldTypes;
   
    if (fieldData.choices) {
      console.log(fieldData ,"**************************8")
      fieldType = DynamicFieldTypes.DROP_DOWN;
    } else if(fieldData.multiple_choices){ 
      fieldType = DynamicFieldTypes.CHECKBOX;
    }else if(fieldData.multiple_level){ 
      fieldType = DynamicFieldTypes.NESTED;
    }else{
      fieldType = fieldData.type;
    }
    const fieldLabelControl = new FormControl(fieldData.label);
    const fieldTypeControl = new FormControl(fieldType);
 
    // add listener on field type to add a nested/choice configurator if required
    fieldTypeControl.valueChanges.subscribe(() => {
      const newFieldType = fieldTypeControl.value;
      console.log(newFieldType ,"%%%%%%%%%%%%%%%")
      // change underlying configurator
      this.fields = this.fields.map((oldField: DynamicFieldConfiguratorBase, index) => {
        if (index === fieldIndex) {
          if (newFieldType === DynamicFieldTypes.DROP_DOWN) {
            // changed type to drop down
         
             console.log(newFieldType)
              return {
                fieldData: oldField.fieldData,
                label: oldField.label,
                type: oldField.type,
                choices: new ChoicesConfiguratorForm()
              };
            
           } else if (newFieldType === DynamicFieldTypes.CHECKBOX) {
            // changed type to drop down
             
              return {
                fieldData: oldField.fieldData,
                label: oldField.label,
                type: oldField.type,
                multiple_choices: new ChoicesConfiguratorForm()
              };
            
           } 
        } else if(newFieldType == DynamicFieldTypes.NESTED){
          console.log(oldField , "1234")
          return {
            fieldData: oldField.fieldData,
            label: oldField.label,
            type: oldField.type,
            multi_level: new ChoicesConfiguratorForm()
          };
        }else {
          return oldField;
        }
      });
    });

  
    if (fieldType === DynamicFieldTypes.DROP_DOWN) {
      const fieldConfigurator: ChoiceFieldConfigurator = {
        fieldData,
        label: fieldLabelControl,
        type: fieldTypeControl,
        choices: new ChoicesConfiguratorForm(fieldData.choices)
      };
      this.fields.push(fieldConfigurator);
    } else if(fieldType === DynamicFieldTypes.CHECKBOX){
      const fieldConfigurator: ChoiceFieldConfigurator = {
        fieldData,
        label: fieldLabelControl,
        type: fieldTypeControl,
        multiple_choices: new ChoicesConfiguratorForm(fieldData.multiple_choices)
      };
      this.fields.push(fieldConfigurator);
    }else if(fieldType == DynamicFieldTypes.NESTED){
      const fieldConfigurator: ChoiceFieldConfigurator = {
        fieldData,
        label: fieldLabelControl,
        type: fieldTypeControl,
        multi_level: new ChoicesConfiguratorForm(fieldData.multiple_level)
      };
      this.fields.push(fieldConfigurator);
    }else{
      const fieldConfigurator: DynamicFieldConfigurator = {
        fieldData,
        label: fieldLabelControl,
        type: fieldTypeControl
      };
      this.fields.push(fieldConfigurator);
    }
  }

  enableEdit() {
    this.editMode = true;
  }

  disableEditMode() {
    this.editMode = false;
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  

}

