import { Component, Inject, OnInit } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { FormArray, FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { EventData } from 'src/app/code-modules/api/interfaces/Customer/customer-api-interfaces';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { ErrorToaster, getErrorText, ValidationMessages } from 'src/app/code-modules/utils/validations/genral-validation';
import { WebSocketService } from 'src/app/code-modules/websocket/web-socket.service';
import { CampaignTemplateDialogComponent } from '../../campaign-template-dialog/campaign-template-dialog.component';
import { TemplateRead } from 'src/app/code-modules/api/interfaces/Email/email-api-interfaces';

@Component({
  selector: 'app-campaign-event-notification',
  templateUrl: './campaign-event-notification.component.html',
  styleUrls: ['./campaign-event-notification.component.sass']
})
export class CampaignEventNotificationComponent implements OnInit {

  // today = new Date()
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public createEventForm: FormGroup;
  data = []
  userVal = new FormControl();
  filteredContacts: Observable<any>;
  leadId: any;
  teamData: any;
  teamId: any = '';
  
  userId: number;
  eventId: any;
  saveBtn: boolean = false;
  updateBtn: boolean = false;
  showMultipleEmail: boolean = false;
  userRole: string;
  userEmail: any;
  routePath: any;
  mailAttachments: Array<any> = [];
  attachment: File
  attachmentString: string
  filterOptions: { 'role': string, 'teams' }

  eventStatuses = [
    { key: 'Active', value: 'active' },
    { key: 'Re-Scheduled', value: 'rescheduled' },
    { key: 'Cancelled', value: 'cancelled' },
    { key: 'Done', value: 'done' },
  ];

  reminderMin = [
    { key: '5 mins Prior to Task', value: '00:05:00' },
    { key: '15 mins Prior to Task', value: '00:15:00' },
    { key: '30 mins Prior to Task', value: '00:30:00' },
    { key: '60 mins Prior to Task', value: '01:00:00' }
  ];

  type: any;
  notification: any;

  config: CKEDITOR.config = {
    toolbarGroups: [
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'align'] },
    ],
    allowedContent: true,
    extraPlugins:
      "dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard,easyimage"
  }
  campaignId: any;
  showDropDownOfMin: boolean = false;
  managerEmailData: any;
  reminderTime: any;
  eventSstatus: any;
  eventStatus: any;
  leadDetails: any;

  constructor(
    public dialogRef: MatDialogRef<CampaignEventNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: CampaignEventNotificationComponent,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private api: ApiService,
    public datepipe: DatePipe,
    private context: AppContextService,
    private route: ActivatedRoute,
    private router: Router,
    private socketConnection: WebSocketService,
    public campTemplatesDialog: MatDialog,
  ) {

    this.context.currentUser$.subscribe((currentuser) => {
      this.userRole = ((currentuser['role']) ? currentuser['role'] : null);
      this.userId = ((currentuser['id']) ? currentuser['id'] : 0);
      this.userEmail = ((currentuser['username']) ? currentuser['username'] : '');
    })
    this.leadId = ((dialogData['lead_id']) ? dialogData['lead_id'] : 0);
    this.campaignId = ((dialogData['campaignId']) ? dialogData['campaignId'] : 0);
    this.eventId = ((dialogData['event_id']) ? dialogData['event_id'] : 0);
    this.type = ((dialogData['type']) ? dialogData['type'] : '');
    this.notification = ((dialogData['notification']) ? dialogData['notification'] : '');

    this.getManagerMailId()

    this.filteredContacts = this.userVal.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          if (typeof value === 'string') {
            return this.api.contactSearchByEmail(value);
          }
        })
      );

  }

  ngOnInit() {

    setTimeout(() => {
      this.createForm();
    }, 100)

    if (this.eventId == 0) {
      this.saveBtn = true;
    } else {
      this.singleEventDetails();
      if (this.type == 'view') {
      } else {
        this.updateBtn = true;
      }
    }
  }  

  singleEventDetails() {
    this.api.singleCampaignEvent(this.eventId).subscribe(result => {
      this.leadId = ((result['lead']) ? result['lead'] : 0);
      // alert(this.ticketId)
      setTimeout(() => {
        this.editForm(result);
      }, 100)
    });
  }

  createForm() {
    let isCheck = this.userEmail.includes('@')
    if (isCheck == true) {
      this.data.push(this.userEmail)
    }
    this.createEventForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      event_date: ['', [Validators.required]],
      start_time: ['', [Validators.required]],
      // escalation_time: ['', [Validators.required]],
      reminder_time: [''],
      status: [''],
      // guests: this.formBuilder.array(this.data, [Validators.required]),
      event_description: ['', [Validators.required]],
    });
    this.showMultipleEmail = true

  }

  editForm(result) {
    this.showDropDownOfMin = true
    this.reminderTime = result['reminder_time']
    this.eventStatus = result['status']
    
    if (result.attachments && result.attachments.length > 0)
      this.mailAttachments = result.attachments

    this.createEventForm = this.formBuilder.group({
      title: [result['title'], Validators.required],
      event_date: [result['event_date'], Validators.required],
      start_time: [result['start_time'], Validators.required],
      // escalation_time: [result['escalation_time'], Validators.required],
      reminder_time: [result['reminder_time']],
      status: [result['status']],
      // guests: this.formBuilder.array(result['guests'], Validators.required),
      event_description: [result['event_description'], Validators.required],
      lead: [result['lead']]
    });

    this.getSingleLeadDetails(result['lead']);
  }

  getSingleLeadDetails(leadId) {
    const CampList = this.api.getSingleLeadDetails(leadId).subscribe((result) => {
      this.leadDetails = result;
    });
  }

  validateArrayNotEmpty(email) {
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (!EMAIL_REGEXP.test(email)) {
      return { "Please provide a valid email": true };
    }

    return null;
  }

  add(event: MatChipInputEvent, form: FormGroup): void {

    const input = event.input;
    const value = event.value;

    // Add name
    if ((value || '').trim()) {
      const control = <FormArray>form.get('guests');
      control.push(this.initName(value.trim()));
      console.log(control);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(form, index) {
    form.get('guests').removeAt(index);
  }

  initName(name: string): FormControl {
    let isEmail = this.validateArrayNotEmpty(name)
    if (isEmail != null) {
      this.toaster.warning('Please enter valid email'); return;
    } else {
      return this.formBuilder.control(name);
    }
  }


  submitEventData() {
    if (this.createEventForm.invalid === true) {
      this.toaster.warning('Please enter all fields'); return;
    }

    let request = <EventData>this.createEventForm.value
    let date = new Date(this.createEventForm.value['event_date']);

    let latest_date = this.datepipe.transform(date, 'yyyy-MM-dd');
    request.event_description = request.event_description.replace(/<[^>]*>/g, '');
    request.event_date = latest_date
    request.lead = this.leadId,
      request.status = ((request.status) ? request.status : 'active');
    request.reminder_time = ((request.reminder_time) ? request.reminder_time : '00:05:00');
    request.created_by = this.userId
    request.attachments = this.mailAttachments.filter((item) => !item.id)
    request.template_attachments = this.mailAttachments.map((item) => item.id).filter(Boolean)
    if (this.userRole != 'admin') {
      this.teamData = JSON.parse(localStorage.getItem('currentTeam'));
      this.teamId = ((this.teamData[0]['id']) ? this.teamData[0]['id'] : null);
      if (this.teamId == null) {
        this.teamId = ''
      }
    }

    request.team = this.teamId;
    this.api.addCampignEvent(request).subscribe(result => {
      this.router.navigate(['/app/campaign/campaign-details/' + this.leadId]);
      this.toaster.success('Task created successully');
      this.dialogRef.close(result);

    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    });
  }

  getReminderTime(time) {
    var sum = time.split(':'); // split it at the colons


    const sum1 = sum[0];
    const sum2 = sum[1] + 2;
    const sum3 = sum[2];

    console.log(sum1 + ':' + sum2 + ':' + sum3)
  }

  updateEventData() {

    let request = <EventData>this.createEventForm.value
    let date = new Date(this.createEventForm.value['event_date']);
    let latest_date = this.datepipe.transform(date, 'yyyy-MM-dd');

    request.event_description = request.event_description.replace(/<[^>]*>/g, '');
    request.event_date = latest_date
    request.status = ((request.status) ? request.status : 'active');

    if (this.showDropDownOfMin == true) {
      request.reminder_time = ((request.reminder_time) ? request.reminder_time : '00:05:00');
    } else {
      request.reminder_time = '00:05:00'
    }

    request.created_by = this.userId
    request.lead = this.leadId
    request.attachments = this.mailAttachments.filter((item) => !item.id)
    this.api.updateCampaignEvent(this.eventId, request).subscribe(result => {
      this.toaster.success('Task updated successully');
      this.dialogRef.close(result);
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const control = <FormArray>this.createEventForm.get('guests');
    control.push(this.initName(event.option.viewValue.trim()));
    this.userVal.setValue(null);
  }

  getErrorMessage() {
    return ValidationMessages(this.createEventForm.controls);
  }

  get singleControls() {
    return this.createEventForm.controls;
  }

  get singleErrors() {
    const errors: any = {};
    Object.keys(this.singleControls).forEach(controlName => {
      errors[controlName] = getErrorText(this.singleControls[controlName]);
    });
    return errors;
  }

  close() {
    this.dialogRef.close();
    this.router.navigate(['/phone-load']);
  }

  openCampaignTemplatesDialog() {
    const emailTemplatesDialog = this.campTemplatesDialog.open(CampaignTemplateDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: {
        campaignId: this.campaignId,
        onAssign: (template) => this.assignTemplate(template)
      },
      height: '450px',
      width: '400px'
    });
    emailTemplatesDialog.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  assignTemplate(template: TemplateRead) {

    this.createEventForm.controls.title.setValue(template.subject)
    this.createEventForm.controls.event_description.setValue(template.content)
    if (template.attachments && template.attachments.length > 0)
      this.mailAttachments = template.attachments;
  }

  onFileInput(event) {
    this.attachment = event.target.files[0];
    if (this.attachment) {
      const arr = this.attachment.name.split('.')
      const file_type = arr.length > 1 ? arr[arr.length - 1] : undefined
      if (file_type && file_type !== 'exe') {
        const reader = new FileReader(); // HTML5 FileReader API
        reader.onload = () => {
          this.attachmentString = reader.result as string;
          var uploadFile = {
            file_name: this.attachment.name,
            file: this.attachmentString
          }
          this.mailAttachments.push(uploadFile);
        };
        reader.readAsDataURL(this.attachment);
      } else {
        this.toaster.error("Unsupported file format")
      }

    }
  }
  removeAttachment(index: number) {
    this.mailAttachments.splice(index, 1);
  }

  getManagerMailId() {
    let loginTeam = JSON.parse(localStorage.getItem("currentTeam"))[0]
    let role: string = 'manager';
    this.filterOptions = {
      role: role,
      teams: loginTeam.id
    }

    this.api.getManagerMailIds(role, loginTeam.id).subscribe(result => {
      if (result.length > 0) {
        this.managerEmailData = result
        this.managerEmailData.forEach(element => {
          let isCheck = element.username.includes('@')

          if (isCheck == true) {
            this.data.push(element.username)
          }
        });
      }
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    });
  }

  reminderAt(event) {
    if (event.checked) {
      this.showDropDownOfMin = true
    } else {
      this.showDropDownOfMin = false
    }
  }

  getControlLabel(type: string) {
    if (this.createEventForm.controls[type]) {
      return this.createEventForm.controls[type].value;
    }    
  }

}

