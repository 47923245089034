import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { element } from 'protractor';
import {BaseNode} from '../../api/interfaces/Business-Rule/business-rule-api-interfaces';
@Component({
  selector: 'app-disposition-tree',
  templateUrl: './disposition-tree.component.html',
  styleUrls: ['./disposition-tree.component.sass']
})
export class DispositionTreeComponent implements OnInit {

  topic = ""
  selectedTopicIndex = "";

  @Input() dispositionTree: Array<BaseNode>;
  @Input() styleClass?:any
  @Input() dispositionList: Array<string>;
  @Input() resetReverseEvent: BehaviorSubject<Boolean>;
  @Output() dispositionTopic = new EventEmitter<any>();
  @Output() showErrors = new EventEmitter<boolean>();
  resetChild = new BehaviorSubject(false);

  //topicControl = new FormControl('', [Validators.required]);
  constructor() { }

  ngOnInit() {

    this.selectedTopicIndex = this.existingDispositionIndex;
    if (this.resetReverseEvent) {
      this.resetReverseEvent.subscribe(reset => {
        if (reset) {
          this.selectedTopicIndex = ''
          this.showErrors.emit(true)
        }
      })
    }
  }

  valueSelected(index) {
    //console.log($event)
    this.selectedTopicIndex = String(index)
    if (index >= 0 && !(this.subTopics && this.subTopics.length)) {
     
      this.dispositionTopic.emit([this.parentTopic.id]);
      this.showErrors.emit(false)
    } else if (index >= 0) {
      
      this.resetChild.next(true)
    } else {
      this.showErrors.emit(true)
    }
   
  }
  selectedSubTopic(topicList) {
    topicList.unshift(this.parentTopic.id)
    //topicList = [this.parentTopic.id]
    this.dispositionTopic.emit(topicList)
  }

  throwErrors(error: boolean) {
    this.showErrors.emit(error)
  }

  get parentTopic(): BaseNode | undefined {
    return this.selectedTopicIndex && this.dispositionTree[Number(this.selectedTopicIndex)]
  }
  get subTopics() {
    return this.parentTopic && this.parentTopic.sub_topics
  }
  get subDispositionList() {
    return (this.dispositionList && this.dispositionList.length > 1 && this.dispositionList.slice(1)) || []
  }

  get existingDisposition() {
    return (this.dispositionList && this.dispositionList.length) ? this.dispositionList[0] : ""
  }
  get existingDispositionIndex() {
    let idx = ""
    if (this.existingDisposition && this.dispositionTree && this.dispositionTree.length) {
     idx = String(this.dispositionTree.findIndex((element) => element.topic === this.existingDisposition))
    return idx
  }
}
}
