import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';

@Component({
  selector: 'app-delete-field-dialog',
  templateUrl: './delete-field-dialog.component.html',
  styleUrls: ['./delete-field-dialog.component.sass']
})
export class DeleteFieldDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteFieldDialogComponent>,
    private api: ApiService,
  ) { }

  ngOnInit() {

  }

  clickOnOk(){
    this.dialogRef.close(true);
  }
  clickOnCancel(){
    this.dialogRef.close(false)
  }

}
