import { Component, Inject, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-task-reminder-notification',
  templateUrl: './task-reminder-notification.component.html',
  styleUrls: ['./task-reminder-notification.component.sass']
})

export class TaskReminderNotificationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TaskReminderNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: TaskReminderNotificationComponent,
    public datepipe: DatePipe,
    private router: Router,
    public campTemplatesDialog: MatDialog,
  ) {}

  data :any;
  ngOnInit() {
    this.data = this.dialogData
  }  

  closeDailog(){
    this.router.navigateByUrl("/campaign/campaign-details/"+this.data.msg.lead);
    this.dialogRef.close();
  }
}

