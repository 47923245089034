import { Injectable } from '@angular/core';
import { ApiServiceBase } from '../api-service-base/api.service.base';
import { ApiReference } from '../api-end-points/api-reference';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {ApiChangePassword, ApiCredentials, ApiCredentials1, ApiForgotPasswordRequest, ApiLoginRequest, ApiResetPassword, TenantSignUpRequest} from '../interfaces/Tenant/tenant-api-interfaces';
import { UserProfileRead } from '../interfaces/User/user-api-interfaces';

@Injectable({
    providedIn: 'root'
})

export class ApiTenantService extends ApiServiceBase {
    apiUrlSource = ApiReference.getCommonApiUrl

    loggerName = '[API/common]';
    constructor(http: HttpClient) {
        super(http)
    }
    public signUpAdmin(signUpRequest: TenantSignUpRequest): Observable<any> {
        return this._apiCreate('adminSignUp', signUpRequest);
    }
    public login(credentials: ApiLoginRequest): Observable<ApiCredentials> {
        return this._apiCreate('login', credentials);
    }

    public authuser(credentials: ApiLoginRequest): Observable<ApiCredentials1> {
        return this._apiCreate('authenticateuser', credentials);
    }


    // public forgotPassword(username: ApiForgotPasswordRequest): Observable<any> {
    //     return this._apiCreate('forgotPassword', username);
    // }

    // public resetPass(credentials: ApiResetPassword) {
    //     return this._apiCreate('resetPassword', credentials);
    // }

    // public changePassword(credentials: ApiChangePassword) {
    //     return this._apiCreate('changePassword', credentials);
    // }

    public loadTenantTeams(userName ,tenantName){
        return this._apiRead('loadTenantTeams',[userName,tenantName])
    }
    
}
