import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiServiceBase } from '../api-service-base/api.service.base';
import { ApiReference } from '../api-end-points/api-reference';
import { Observable } from 'rxjs';
import { TicketsDailyReportRead, TicketAgentPerformanceReportRead, TicketDetailsReportRead, EmailAgentPerformanceReportRead, EmailSLAReportRead, DispositionReportRead, DailyWinCountList, OpportunityTransactionTimeReportList, StatusBarChart, TicketLifeCycleReport } from '../interfaces/Reports/report-api-interfaces';
import { EmailAgentPerformanceReportComponent } from 'src/app/circle-one/reports/components/email-agent-performance-report/email-agent-performance-report.component';
import { OpportunityList } from '../interfaces/Campaign/campaign-api-interfaces';
import { TicketStatusTypes } from '../../saas/saas.enums';

@Injectable({
    providedIn: 'root'
})

export class ReportApiService extends ApiServiceBase {

    loggerName = '[API/tenent]';
    apiUrlSource = ApiReference.tenantApiUrl


    constructor(http: HttpClient) {
        super(http)
    }

    public TicketsDailyReport(gte: string, lte: string, action__assignee: any, team: any, disposition_priority: string[]): Observable<TicketsDailyReportRead[]> {
        return this._apiRead('ticketsDailyReport', null, { gte, lte, action__assignee, team, disposition_priority })
    }

    public TicketAgentPerformanceReport(gte: string, lte: string, agent: any, team: any, disposition_priority: string[]): Observable<TicketAgentPerformanceReportRead[]> {
        return this._apiRead('ticketsAgentPerformanceReport', null, { gte, lte, agent, team, disposition_priority })
    }
    public ticketDetailsReport(gte: string, lte: string, status__in?: string, ticket_name__in?: string, jde_module__in?: string, account_name__in?: number, assignee__in?: string,contact_email__icontains?:string): Observable<TicketDetailsReportRead[]> {
        return this._apiRead('ticketDetailsReport', null, { gte, lte, status__in, ticket_name__in, jde_module__in, account_name__in, assignee__in ,contact_email__icontains})
    }
    public circleonticketDetailsReport(gte: string, lte: string, team: any, action__disposition__get_ticket_name__name?: string
        , status_type?: string, status__status?: string | number, action__assignee__username?: string,action__contact__name?:string,
        action__contact__email__icontains?:string): Observable<TicketDetailsReportRead[]> {
        return this._apiRead('circleonticketDetailsReport', null, {
            gte, lte, team,
            action__disposition__get_ticket_name__name,
            status_type,
            status__status,
            action__assignee__username,
            action__contact__name,
            action__contact__email__icontains
        })
    }
    public ticketLifeCycleReport(gte: string, lte: string, team: any,
        action__disposition__get_ticket_name__name?: string,
        action__assignee?: any): Observable<TicketLifeCycleReport[]> {
        return this._apiRead('ticketLifeCycleReport', null, { gte, lte, team, action__disposition__get_ticket_name__name, action__assignee })
    }
    public emailAgentPerfomanceReport(gte: string, lte: string, agent: number, team: number): Observable<EmailAgentPerformanceReportRead[]> {
        return this._apiRead('emailAgentPerfomanceReport', null, { gte, lte, agent, team })
    }
    public emailSLAReport(gte: string, lte: string, agent: any, team: any): Observable<EmailSLAReportRead[]> {
        return this._apiRead('emailSLAReport', null, { gte, lte, agent, team })
    }
    public dispositionReport(gte: string, lte: string): Observable<DispositionReportRead[]> {
        return this._apiRead('dispositionReport', null, { gte, lte })
    }

    //campaign reports
    public leadDetailsReport(page: number, created__gte: string, created__lt: string, action__assignee: number, created_by: number, campaign: number): Observable<OpportunityList> {
        return this._apiRead('leadDetailsReport', null, { page, created__gte, created__lt, action__assignee, created_by, campaign })
    }
    public leadConversionReport(page: number, start_date: string, end_date: string, action__assignee: number, created_by: number, campaign: number): Observable<DailyWinCountList> {
        return this._apiRead('leadConversionReport', null, { page, start_date, end_date, action__assignee, created_by, campaign })
    }
    public leadLifeCycleReport(page: number, created__gte: string, created__lt: string, action__assignee: number, created_by: number, campaign: number): Observable<OpportunityTransactionTimeReportList> {
        return this._apiRead('leadLifeCycleReport', null, { page, created__gte, created__lt, action__assignee, created_by, campaign })
    }

    public leadStatusBarChart(campaign: number): Observable<StatusBarChart> {
        return this._apiRead('leadStatusBarChart', null, { campaign })
    }
    public ticketDynamicFields(ticket_name__name: string) {
        return this._apiRead('dynamicFieldList', null, { ticket_name__name })
    }
    public ticketStaus(name: string) {
        return this._apiRead('ticketWorkFlowList', null, { name })
    }
    public campStaus(team: any, campaign: any, name: string) {
        return this._apiRead('campWorkFlowList', null, { team, campaign, name })
    }
    public ticketIssueReport(gte: string, lte: string,
        ticket_name__in?: string, status__in?: any, account_name__in?: any,
        requestor__in?: any, jde_module__in?: string, assignee__in?: string): Observable<TicketDetailsReportRead[]> {
        return this._apiRead('ticketIssueReport', null, {
            gte, lte, ticket_name__in,
            status__in, account_name__in, requestor__in, jde_module__in, assignee__in
        })
    }

    public cncTeamWorkLoadReport(gte: string, lte: string, username__in: string, get_assignments__get_ticket__get_dynamic_field_values__value_drop_down: string, get_assignments__disposition__get_ticket_name__name: string) {
        return this._apiRead('cncTeamWorkLoadReport', null, { gte, lte, username__in, get_assignments__get_ticket__get_dynamic_field_values__value_drop_down, get_assignments__disposition__get_ticket_name__name })
    }

    public ticketSlaReport(gte: string, lte: string,ticket_name__in
        ?: string, account_name__in?: any, assignee__in?: string) {
        return this._apiRead('ticketSlaReport', null, { gte, lte, ticket_name__in, account_name__in, assignee__in })
    }
    public downloadPdf(gte: string, lte: string, status__in?: string, ticket_name__in?: string, jde_module__in?: string, account_name__in?: number, assignee__in?: string) {
        return this._apiRead('downloadPdf', null, { gte, lte, status__in, ticket_name__in, jde_module__in, account_name__in, assignee__in })
    }
    public phoneLoginReport(gte: string, lte: string, user_id?: string) {
        return this._apiRead('phoneLoginReport', null, { gte, lte, user_id })
    }

    // public telephonyAgentReport(gte: string, lte: string, comments: string, user: string, campaign_id: string) {
    //     return this._apiRead('telephonyAgentPerformanceReport', null, { gte, lte, comments, user, campaign_id })
    // }
    public telephonyAgentReport(gte: string, lte: string, call_type: string, agent_id: string) {
        return this._apiRead('telephonyAgentPerformanceReport', null, { gte, lte, call_type, agent_id })
    }
    public telephonyAgentContactReport(gte: string, lte: string, call_type: string, user_id: string,queue:any) {
        return this._apiRead('telephonyContactReport', null, { gte, lte, call_type,user_id,queue})
    }
    public telephonyCampaignReport(gte: string, lte: string, campaign__team: any,campaign__campaign_name:any,recepients:any) {
        return this._apiRead('telephonyCampaignReport', null, { gte, lte, campaign__team , campaign__campaign_name,recepients })
    }
    public telephonyBreakReport(gte: string, lte: string, user_id:any,team_id:any) {
        return this._apiRead('telephonyBreakReport', null, {gte,lte,user_id,team_id})
    }
    public telephonyInboundCallReport(gte: string, lte: string, interval:any,queuename:any) {
        return this._apiRead('telephonyInboundCallReport', null, {gte,lte,interval,queuename})
    }
    public telephonyAbandonedReport(gte: string, lte: string,queuename:any) {
        return this._apiRead('telephonyAbandonedReport', null, {gte,lte,queuename})
    }

    public telephonyDispositionReport(gte: string, lte: string, campaign__team: any, id__icontains: any) {
        return this._apiRead('telephonyDispositionReport', null, { gte, lte, campaign__team, id__icontains })
    }

    public ibCdrReport(gte: string, lte: string) {
        return this._apiRead('ibCdrReport', null, { gte, lte })
    }

    public getSubIssueType(gte:string,lte:string,report_type:any,result_format:any,team_id:any) {
        return this._apiRead('getSubIssueType', null, { gte,lte,report_type,result_format,team_id})
    }
  

    public getIssueType(gte:string,lte:string,report_type:any,result_format:any,team_id:any) {
        return this._apiRead('getIssueType', null, { gte,lte,report_type,result_format,team_id})
    }
    public getTeamType(gte:string,lte:string,report_type:any,result_format:any,team_id:any) {
        return this._apiRead('getTeamType', null, { gte,lte,report_type,result_format,team_id})
    }

    public getSatuslevelaudit(for_date:string,report_type:any,result_format:any,team_id:any) {
        return this._apiRead('getStatusAuditReport', null, {for_date,report_type,result_format,team_id})
    }
    public getAuditTicket(gte:string,lte:string,report_type:any,result_format:any,team_id:any) {
        return this._apiRead('getAuditTicket', null, { gte,lte,report_type,result_format,team_id})
    }

    public getLatestAuditTicket(gte:string,lte:string,report_type:any,result_format:any,team_id:any,workflow_id:any,status_type:any) {
        return this._apiRead('getLatestAuditTicket', null, { gte,lte,report_type,result_format,team_id,workflow_id,status_type})
    }

    public campignDetailsReport(gte?:string,lte?:string,campaign?:any,recepients?:any) {
        return this._apiRead('campignDetailsReport', null, {gte,lte,campaign,recepients})
    }

}






