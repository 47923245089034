import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.sass']
})
export class NewPasswordComponent implements OnInit {
  loggerName: ['/newPassword'];
  signupStep = 1;
  totalSteps = 2;
  constructor(
    private router: Router,
  ) {}

  ngOnInit() {

  }

  onNextClick() {
    if (this.signupStep < this.totalSteps) {
      this.signupStep += 1;
    }
  }

}
