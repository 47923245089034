import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getErrorText } from '../../../../code-modules/utils/validations/genral-validation';
import { ApiService } from '../../../../code-modules/api/api-services/api.service';


import { ToastrService } from 'ngx-toastr';

import { AppContextService } from '../../../../code-modules/app-context/app-context.service';

import { TicketRead } from 'src/app/code-modules/api/interfaces/Ticket/ticket-api-interfaces';
import { EmailThreadDetailRead } from 'src/app/code-modules/api/interfaces/Email/email-api-interfaces';
import {
  ActionType,
  BaseNode,
  DispositionDialogDataObject
} from '../../../../code-modules/api/interfaces/Business-Rule/business-rule-api-interfaces';
import { OpportunityRead } from 'src/app/code-modules/api/interfaces/Campaign/campaign-api-interfaces';
import {MatDialog} from '@angular/material/dialog';
import { WorkflowDialogComponent } from 'src/app/circle-one/app/components/workflow-dialog/workflow-dialog.component';



@Component({
  selector: 'app-disposition-dialog',
  templateUrl: './disposition-dialog.component.html',
  styleUrls: ['./disposition-dialog.component.sass']
})
export class DispositionDialogComponent implements OnInit {

  dispostionData: Array<BaseNode> = [];
  dispositionList ;
  error = true;
  editable = false;
  loginTeam

  constructor(
    public dialogRef: MatDialogRef<DispositionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DispositionDialogDataObject,
    private api: ApiService,
    public toaster: ToastrService,
    public context: AppContextService,
    public contactHistoryMatDialog: MatDialog,
  ) {
  }

  ngOnInit() {

    if (JSON.parse(localStorage.getItem("currentTeam"))) {
      this.loginTeam = JSON.parse(localStorage.getItem("currentTeam"))[0]
      // this.selectedSingleTeam = this.loginTeam.id
    }
    console.log(this.loginTeam)
    this.context.fetchDispositionDynamicFields(this.loginTeam.id).subscribe(disposition => {
      if (disposition.length) {
        this.dispostionData = disposition
        console.log(disposition)
      }
      if (this.data && this.data.existingData) {
        if (this.data.action === ActionType.EMAIL) {
         
          
          const emailDisposition = this.data.existingData as EmailThreadDetailRead
          if(emailDisposition.is_replied){
            this.editable = false
          }else{
            this.editable = true
          }
          this.dispositionList = emailDisposition.action.disposition ?[emailDisposition.action.disposition]  : []
      
          console.log(this.dispositionList ,"++++++++++++")
        } else if (this.data.action === ActionType.OPPORTUNITY) {
          this.editable = true
          const opportunityDisposition = this.data.existingData as OpportunityRead
          this.dispositionList = opportunityDisposition.action.disposition ? opportunityDisposition.action.disposition.disposition : []
        }
        else {
          this.editable = false
          const ticketDisposition = this.data.existingData as TicketRead
          this.dispositionList = ticketDisposition.action.disposition ? [ticketDisposition.action.disposition] : []
        }
      }

    });



  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  submit() {
    
    if (this.dispositionList.length) {
      console.log(this.dispositionList.length); 
      console.log(this.dispositionList,",,,,,,,,,,,,")
      this.dialogRef.close({ id: this.dispositionList[this.dispositionList.length - 1] })
    }
    
    
  }

  selectedTopic(values: Array<string>) {
    this.dispositionList = values;
  }
  throwErrors(error: boolean) {
    this.error = error
  }

  

}
