import {HTTP_METHODS, PlatformPermissionIds} from '../../../saas/saas.enums';
import {ApiThemeColor, Team} from '../Business-Rule/business-rule-api-interfaces';

export interface ApiCredentials {
    token: string; // Authorization header with 'Bearer ' on start
    tenant?:string;
    teams?:Team[];
    login_type?:string;
    username?:string;
    

}
export interface ApiCredentials1 {
    token: string; // Authorization header with 'Bearer ' on start
    tenant?:string;
    teams?:Team[];
    login_type?:string;
    username?:string;
    
    message:string;
}
export interface Tenant {
    business_name?: string;
    theme?: object;
    module_permission?: PlatformPermissionIds[];
    /** format: uri */
    readonly logo?: string;
    tenant_domain: string;
    sub_domain: string;
    support_email?: string
    businessRule: any
    country?: string
}

export interface TenantUpdate {
    business_name?: string;
    theme?: ApiThemeColor;
    logo?: string;
}

export interface ApiAction {
    httpMethod: HTTP_METHODS;
    urlPattern: string;
}

export const apiCredentialsNames = ['token'];

export interface ApiLoginRequest {
    username: string;
    password: string;
    tenant:string;
    team?:string;
}

export interface ApiChangePassword {
    old_password: string;
    new_password: string;
}

export interface ApiResetPassword {
    new_password: string;
    email: string;
    token: string;
}

export interface TenantSignUpRequest {
    sub_domain: string;
    admin_username: string;
}

export interface ApiForgotPasswordRequest {
    email: string;
}
