import { Directive, ElementRef, TemplateRef, ViewContainerRef, OnInit, Input, HostListener } from '@angular/core';
import { AppContextService } from '../../app-context/app-context.service';
import { UserRoles } from '../../saas/saas.enums';

const enum Action {
  HIDDEN = "hidden",
  DISABLED = "disabled"
}

@Directive({
  selector: '[adminPrivilege]'
})
export class AdminPrivilegeDirective implements OnInit {
  //   @HostListener('click', ['$event.target'])
  //   onClick(btn) {
  //     console.log('button', btn, 'number of clicks:');
  //  }
  accessPermission: boolean = false;
  actionType: string;


  @Input()
  set adminPrivilege(value: string) {
    this.actionType = value
    this.updateView();

  }
  // @HostListener('click') public onClick(event: any): void {
  //   console.log("event catched")
  //   if (!this.accessPermission && this.actionType && this.actionType === Action.DISABLED){

  //     event.stopPropagation();
  //   }  
  // }
  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    public contex: AppContextService
  ) {

  }

  ngOnInit() {
    this.contex.currentUser$.subscribe((user) => {
      if (user.role && user.role !== UserRoles.AGENT) {
        this.accessPermission = true
        this.updateView();
      }
    })
  }
  updateView() {
    if (this.accessPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    }
    else {
      this.viewContainer.clear();
      // if (this.actionType) {
      //   if (this.actionType === Action.HIDDEN) {
      //     this.viewContainer.clear();
      //   } else {
      //     this.viewContainer.createEmbeddedView(this.templateRef)
      //     this.element.nativeElement['pointer-events'] = 'none'
      //   }
      // }
    }
  }
}
