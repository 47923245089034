import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AppContextService } from '../app-context/app-context.service';
import { map, first } from 'rxjs/operators';
import { noPermissionUrl } from './auth.urls';
import { ModuleUrl } from '../saas/saas.enums';
import { moduleViewPermissions } from '../utils/pipes/has-module-view-access.pipe';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(public router: Router, public route: ActivatedRoute, public context: AppContextService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const moduleId = (next.data && next.data.ModuleId) ? next.data.ModuleId : undefined
   console.log(moduleId,"moduleid")
    if (moduleId) {
      
      return this.context.currentUser$.pipe(
        first(),
        map(user => {
          console.log(user)
          
          if(JSON.parse(localStorage.getItem("currentTeam"))[0].manager_module_permissions){
            const accessPermission = JSON.parse(localStorage.getItem("currentTeam"))[0].manager_module_permissions
            .find((permission) => permission === moduleViewPermissions[moduleId])
            if (accessPermission)
              return true;
            else {
                return this.router.parseUrl(noPermissionUrl);
            }
          }else if(JSON.parse(localStorage.getItem("currentTeam"))[0].agent_module_permissions){
            const accessPermission = JSON.parse(localStorage.getItem("currentTeam"))[0].agent_module_permissions
            .find((permission) => permission === moduleViewPermissions[moduleId])
            if (accessPermission)
              return true;
            else {
                return this.router.parseUrl(noPermissionUrl);
            }
          }else{
            const accessPermission = JSON.parse(localStorage.getItem("currentTeam"))[0].module_permissions
            .find((permission) => permission === moduleViewPermissions[moduleId])
            if (accessPermission)
              return true;
            else {
                return this.router.parseUrl(noPermissionUrl);
            }
          }
       
          
        })
      )
    } else {
      
      return false
    }
  }

}
