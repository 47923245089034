import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DynamicFieldConfigurator, DynamicFieldDefinitionRead, DynamicDefinitionCreate, SingleChoiceConfigurator, DynamicChoiceDefinitionRead, ChoiceFieldConfigurator } from '../Configurators.interfaces';
import { DdfConfiguratorForm } from '../Configurators';
import { DynamicFieldConfigurationTypes, DynamicFieldTypes, DynamicFieldConfigurationHeader, DynamicFieldValidators } from '../../ddf.enums';
import { systemFields, DdfFieldData } from '../../ddf.interfaces';
import { MatDialog, MatPaginator } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { DataDrivenForm } from '../../DataDrivenForm';
import { AddFieldDialogComponent } from '../ddf-configurator/add-field-dialog/add-field-dialog.component';
import { ChoiceFieldInfo, AddChoiceDialogComponent } from '../ddf-configurator/add-choice-dialog/add-choice-dialog.component';
import { FormControl } from '@angular/forms';
import { DeleteFieldDialogComponent } from '../ddf-configurator/delete-field-dialog/delete-field-dialog.component';

import { Router ,NavigationEnd } from '@angular/router';
import { TicketNameRead } from 'src/app/code-modules/api/interfaces/Ticket/ticket-api-interfaces';
import { element } from 'protractor';
import { ErrorToaster } from '../../../validations/genral-validation';
import { UserTeamList } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';

export interface FieldInfo {
  adder: DynamicFieldConfigurator;
}
@Component({
  selector: 'app-ticket-ddf-configurator',
  templateUrl: './ticket-ddf-configurator.component.html',
  styleUrls: ['./ticket-ddf-configurator.component.sass']
})
export class TicketDdfConfiguratorComponent implements OnInit {

  configurator: DdfConfiguratorForm;
  dynamicFieldDefinitions: any;
  myArray = []
  @Input() ddfType: DynamicFieldConfigurationTypes;
  @Input() systemFields: Array<systemFields>
  fieldTypes = DynamicFieldTypes;
  selectData :Array<TicketNameRead> = [];
  selectTicketId : number = null;
  selectedTicketName:any = null;
  dynamicFields:Array<DynamicFieldDefinitionRead>;
  levelOneFields ;
  levelTwoFields ;
  levelThreeFields;
  levelFourFields;
  labelOne
  labelTwo
  labelThree
  labelFour
  teams;
  selectedTeam: any;
  constructor(
    public matDialog: MatDialog,
    private toaster: ToastrService,
    private api: ApiService,
    private context: AppContextService,
  
  ) {
    
   
  }
  
  ngOnInit() {
    
    console.log("TicketDdfConfiguratorComponent")
    
    this.dynamicFieldDefinitions = {
      
      [DynamicFieldConfigurationTypes.TICKET_NAME]: {
        fetch$: this.context.ticketNameDynamicFields$,
        refresh: () => this.context.fetchTicketNameDynamicFields()
      }
      
    }
    
    
    this.loadDyanamicFeilds();
    this.loadUserTeamList()
    this.loadWorkflow();

  }

  loadWorkflow(){
    this.api.ticketWorkFlowList(this.selectedTeam)
    .subscribe((data)=>{
      this.selectData = data.results
    },(err)=>{
      this.toaster.error(ErrorToaster(err))
    })
     
  }
  selectChangeHandler(value){
    console.log("selected ticket name")
    console.log(value)
    this.selectTicketId = value.value.id
    this.selectedTicketName = value.value.name
    this.loadDyanamicFeilds()
  }

  createConfigurator(feilds: DynamicFieldDefinitionRead[]) {

    const ddfFormHeader = DynamicFieldConfigurationHeader[this.ddfType]
    const fieldData = DataDrivenForm.buildFieldData(feilds);
    console.log(ddfFormHeader)
    this.configurator = new DdfConfiguratorForm(ddfFormHeader, fieldData, 0, this.systemFields);

  }

  buildFieldData(feilds: DynamicFieldDefinitionRead[]): DdfFieldData[] {
    let fieldData: DdfFieldData[] = []
    feilds.forEach((field) => {
      let obj = <DdfFieldData>{
        identifier: field.id,
        label: field.label,
        type: field.field_type,
        validators: field.is_required ? [DynamicFieldValidators.REQUIRED] : [],
        choices: field.choices && field.choices.length ? field.choices : undefined
      }
      fieldData.push(obj)
    })
    return fieldData
  }


  loadDyanamicFeilds() {
    
    console.log("loading dfields")
    this.dynamicFieldDefinitions[this.ddfType].fetch$.subscribe((dynamicFields:Array<DynamicFieldDefinitionRead>) => {
      console.log("dynamic fields",dynamicFields)  
        this.dynamicFields = dynamicFields
        this.myArray = []
        this.dynamicFields.filter((row)=>{
          if(row.ticket_name === this.selectTicketId ){
            return this.myArray.push(row)       
          }
        })
        console.log(this.myArray)
        this.createConfigurator(this.myArray)
        if(dynamicFields.length){
          dynamicFields.forEach((dField)=>{
            if(dField.ticket_name === null){
              this.removeDynamicField(dField.id,this.dynamicFields.indexOf(dField))
            }
          })
        }
          
    })
 
  }

  removeDynamicField(identifier: number, index: number) {
    console.log("remove")
   // //this.loader.start();
    this.api.dynamicFieldDelete(identifier).subscribe((success) => {
     // //this.loader.stop();
      this.configurator.removeFieldOnIndex(index)
      this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
      this.dynamicFields = success
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    ////this.loader.stop()
  })
  }

  addDispositionFieldDialog(fieldConfigurator?: DynamicFieldConfigurator): void {
    
    let data: FieldInfo;
    if (fieldConfigurator) {
      data = {
        adder: fieldConfigurator
      };
    }
    const dialogRef = this.matDialog.open(AddFieldDialogComponent, {
      panelClass: 'co-dialog-panel',
      data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Add field dialog was closed:', result);
      if (result) {
          let current = []
          this.dynamicFields.filter((row)=>{     
            if(row.ticket_name === this.selectTicketId){
              current.push(row)
            }
          })   
          
          if(current.find((row) => {return row.label === result.adder.label.value})){
            this.toaster.info("Field Exist")
          }else{
            let dynamicField = <DynamicDefinitionCreate>{
              field_type: result.adder.type.value,
              label: result.adder.label.value,
              is_required: result.required,
              is_unique: result.unique,
              model_name: this.ddfType,
              ticket_name:this.selectTicketId,
              field_length:result.field_length,
              team:result.team
            }
            console.log(dynamicField)
            this.api.dynamicFieldCreate(dynamicField).subscribe((ddf) => {
              this.configurator.addField({
                label: result.adder.label.value,  
                type: result.adder.type.value,
                identifier: ddf.id,
                validators: []
              });
              this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
              // this.selectChangeHandler(this.selectTicketId)
            },(err) => {
              this.toaster.error(ErrorToaster(err))
              console.log(err)}
              )
          }
        }
       
        console.log(this.dynamicFields)
        console.log(this.configurator.fields)
    });
    
   
  }

  addChoiceDialog(choiceConfigurator?: SingleChoiceConfigurator): void {
    let data: ChoiceFieldInfo;
    if (choiceConfigurator) {
      data = {
        adder: choiceConfigurator
      };
    }
    const dialogRef = this.matDialog.open(AddChoiceDialogComponent, {
      panelClass: 'co-dialog-panel',
      data,
    });

    dialogRef.afterClosed().subscribe((result?: ChoiceFieldInfo) => {
      console.log('Add choice dialog was closed:', result);
      if (result) {
        let request = <DynamicChoiceDefinitionRead>{
          field: this.configurator.focusedField.fieldData.identifier,
          choice: result.adder.value.value
        }
        this.api.dynamicFiledChoiceCreate(request).subscribe((ddfChoice) => {
          (this.configurator.focusedField as ChoiceFieldConfigurator)
            .choices.addChoice(result.adder.value.value, ddfChoice.id);
            this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
        },(err)=>{
          this.toaster.error(ErrorToaster(err))
        })
        //this.logger.log('Received choice:', result.adder);

        // .choices.addChoice(result.adder.value.value, result.adder.displayValue.value);
      }
    });
  }
  checked(field: any) {
    const fieldData: DdfFieldData = field.fieldData;
    return (fieldData.validators.indexOf(DynamicFieldValidators.REQUIRED) != -1) ? true : false
  }
  checkedUnique(field: any) {
    const fieldData: DdfFieldData = field.fieldData;
    return (fieldData.validators.indexOf(DynamicFieldValidators.UNIQUE) != -1) ? true : false

  }

  isExistingField(value: string) {
    return this.configurator.fields.find((field) => field.label.value.toLowerCase() === value.toLowerCase())
  }
  removeChoice(choice: FormControl, index: number) {
    ////this.loader.start();
    this.api.dynamicFiledChoiceDelete(choice.value).subscribe((succes) => {
     // //this.loader.stop();
      this.configurator.choiceConfigurator.removeChoiceOnIndex(index);
      this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    ////this.loader.stop()
    
  })
    console.log(choice, index)
  }


  openDeleteFieldDialog(identifier: number, index: number){
    const dialogRef = this.matDialog.open(DeleteFieldDialogComponent, {
      panelClass: 'co-dialog-panel',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('Add field dialog was closed:', result);
      if (result) {
        this.removeDynamicField(identifier,index)
      }
    })
  }
  openDeleteChoiceDialog(identifier, index: number){
    const dialogRef = this.matDialog.open(DeleteFieldDialogComponent, {
      panelClass: 'co-dialog-panel',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('Add field dialog was closed:', result);
      if (result) {
        this.removeChoice(identifier,index)
      }
    })
  }
  openDeleteMultipleChoiceDialog(identifier, index: number){
    const dialogRef = this.matDialog.open(DeleteFieldDialogComponent, {
      panelClass: 'co-dialog-panel',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('Add field dialog was closed:', result);
      if (result) {
        this.removeMultipleChoice(identifier,index)
      }
    })
  }
  openDeleteMultiLevelDialog(identifier,level, index: number ,field_id){
    const dialogRef = this.matDialog.open(DeleteFieldDialogComponent, {
      panelClass: 'co-dialog-panel',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('Add field dialog was closed:', result);
      if (result) {
        this.removemultiLevelConfigurator(identifier,level,index,field_id)
      }
    })
  }

  getLevelOneFields(field,level){
    console.log(field , "^^^^^^^")
    
    if(field.type == "multi-level"){
      this.labelOne = field
      console.log(this.labelOne ,"labelOne")
      // this.getLevelWiseFields(field.identifier,level)
      this.api.getNestedDynamicFields(field.identifier,level)
      .subscribe((data)=>{
        console.log(data,"ppppppp")
        this.levelOneFields = data
        // this.configurator.multiLevelConfigurator.fields = data
        this.levelTwoFields = undefined
        this.levelThreeFields = undefined
        this.levelFourFields = undefined
        this.labelTwo = undefined
        this.labelThree = undefined
        this.labelFour = undefined
        // this.levelOneFields = data
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
    }
    // else{
    //   // this.labelOne = field
    //   console.log(this.labelOne ,"labelOne")
    //   // this.getLevelWiseFields(field.identifier,level)
    //   this.api.getNestedDynamicFields(field,level)
    //   .subscribe((data)=>{
    //     console.log(data,"ppppppp")
    //     this.configurator.multiLevelConfigurator.fields = data
    //     this.levelTwoFields = undefined
    //     this.levelThreeFields = undefined
    //     this.levelFourFields = undefined
    //     this.labelTwo = undefined
    //     this.labelThree = undefined
    //     this.labelFour = undefined
    //     // this.levelOneFields = data
    //   },(err)=>{
    //     this.toaster.error(ErrorToaster(err))
    //   })
    // }
    // this.getLevelWiseFields(field,1)
  }
  getLevelTwoFields(field,level){
    console.log("%%%%%%%%%%%%%%%%%%%%",field,level)
    this.labelTwo = field
    console.log(this.labelTwo ,"labelTwo")
    // this.labelOne = field
    // if(field.focusedField.fieldData.type == "multi-level"){
      // this.getLevelWiseFields(field.focusedField.fieldData.identifier,level)
      this.api.getNestedDynamicFields(field.id,level)
      .subscribe((data)=>{
        console.log(data,"ppppppp")
        // this.configurator.multiLevelConfigurator.fields = data
        this.levelTwoFields = data
        this.levelThreeFields = undefined
        this.levelFourFields = undefined
        this.labelThree = undefined
        this.labelFour = undefined
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
    // }
    
  }
  getLevelThreeFields(field,level){
    console.log("%%%%%%%%%%%%%%%%%%%%",field,level)
    this.labelThree = field
      this.api.getNestedDynamicFields(field.id,level)
      .subscribe((data)=>{
        console.log(data,"ppppppp")
        this.levelThreeFields = data
        this.levelFourFields = undefined
        this.labelFour = undefined
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
    
  }
  getLevelFourFields(field,level){
    console.log("%%%%%%%%%%%%%%%%%%%%",field,level)
    this.labelFour = field
      this.api.getNestedDynamicFields(field.id,level)
      .subscribe((data)=>{
        console.log(data,"ppppppp")
        this.levelFourFields = data
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
    
  }
  getLevelFiveFields(field,level){
    this.api.getNestedDynamicFields(field.id,level)
      .subscribe((data)=>{
        console.log(data,"ppppppp")
        // this.levelFourFields = data
      },(err)=>{
        this.toaster.error(ErrorToaster(err))
      })
  }

  addCheckboxChoiceDialog(choiceMultipleConfigurator?: SingleChoiceConfigurator){
    let data: ChoiceFieldInfo;
    if (choiceMultipleConfigurator) {
      data = {
        adder: choiceMultipleConfigurator
      };
    }
    const dialogRef = this.matDialog.open(AddChoiceDialogComponent, {
      panelClass: 'co-dialog-panel',
      data,
    });
    dialogRef.afterClosed().subscribe((result?: ChoiceFieldInfo) => {
      console.log('Add choice dialog was closed:', result);
      if (result) {
        let request = <DynamicChoiceDefinitionRead>{
          field: this.configurator.focusedField.fieldData.identifier,
          choice: result.adder.value.value
        }
        this.api.dynamicFieldCheckBoxChoiceCreate(request).subscribe((ddfChoice) => {
          (this.configurator.focusedField as ChoiceFieldConfigurator)
            .multiple_choices.addChoice(result.adder.value.value, ddfChoice.id);
            this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
            console.log(this.configurator.choiceMultipleConfigurator ,"%%%%%%%%%%%" )
        },(err)=>{
          this.toaster.error(ErrorToaster(err))
        })
        //this.logger.log('Received choice:', result.adder);

        // .choices.addChoice(result.adder.value.value, result.adder.displayValue.value);
      }
    });
  }
  addNestedChoiceDialog(level,fieldId,choiceMultiLevelConfigurator?: SingleChoiceConfigurator){
    console.log(choiceMultiLevelConfigurator);
    let data: ChoiceFieldInfo;
    if (choiceMultiLevelConfigurator) {
      data = {
        adder: choiceMultiLevelConfigurator
      };
    }
    const dialogRef = this.matDialog.open(AddChoiceDialogComponent, {
      panelClass: 'co-dialog-panel',
      data,
    });
    
    dialogRef.afterClosed().subscribe((result?: ChoiceFieldInfo) => {
      console.log('Add choice dialog was closed:', result);
      // let level = 1
      if (result) {
        let request = <DynamicChoiceDefinitionRead>{
          field: fieldId,
          choice: result.adder.value.value,
          field_type:DynamicFieldTypes.NESTED
        }
    
        this.api.dynamicFieldMultipleLevelCreate(request,level).subscribe((ddfChoice) => {
          (this.configurator.focusedField as ChoiceFieldConfigurator)
            .multi_level.addChoice(result.adder.value.value, ddfChoice.id);
            // this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
            let field_id = {
              id:fieldId
            }
            // let field_id_main = {
            //   identifier:fieldId
            // }
            if(level == 1){
             
              this.getLevelOneFields(this.configurator.focusedField.fieldData , level)
            }
            if(level == 2){
              console.log(level ,request.field, fieldId, "wwwwww")
              this.getLevelTwoFields(field_id, level)
            }
            if(level == 3){
              this.getLevelThreeFields(field_id,level)
            }
            if(level == 4){
                this.getLevelFourFields(field_id ,level)
            }
            console.log(this.configurator.multiLevelConfigurator ,"%%%%%%%%%%%" )
        },(err)=>{
          this.toaster.error(ErrorToaster(err))
        })
       
        //this.logger.log('Received choice:', result.adder);

        // .choices.addChoice(result.adder.value.value, result.adder.displayValue.value);
      }
    });
  }
  removeMultipleChoice(choice: FormControl, index: number) {
    ////this.loader.start();
    this.api.dynamicFieldCheckBoxChoiceDelete(choice.value).subscribe((succes) => {
     // //this.loader.stop();
      this.configurator.choiceMultipleConfigurator.removeChoiceOnIndex(index);
      this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    ////this.loader.stop()
    
    })
    console.log(choice, index)
  }
  
  removemultiLevelConfigurator(choice: FormControl,level, index: number,fieldId){
    this.api.dynamicFieldMultipleLevelDelete(choice,level).subscribe((succes) => {
      //  this.configurator.choiceMultipleConfigurator.removeChoiceOnIndex(index);
      //  this.dynamicFieldDefinitions[this.ddfType].refresh().subscribe()
      console.log(choice , )
      // this.getLevelWiseFields(this.configurator.focusedField.fieldData.identifier , 1)
      // this.getLevelWiseFields(fieldId,level)
      let field_id = {
        id:fieldId
      }
      let field_id_main = {
        identifier:fieldId
      }
      if(level == 1){
        this.getLevelOneFields(this.configurator.focusedField.fieldData, level)
      }
      if(level == 2){
        this.getLevelTwoFields(field_id , level)
      }
      if(level == 3){
        this.getLevelThreeFields(field_id,level)
      }
      if(level == 4){
          this.getLevelFourFields(field_id ,level)
      }
     }, (err) => {
      this.toaster.error(ErrorToaster(err))
     })
  }
  showTeamWiseData() {
    // this.teamId = this.selectedTeam
    console.log(this.selectedTeam)
    this.loadWorkflow();
  }
  loadUserTeamList() {
    this.api.userTeamList()
      .subscribe((team: UserTeamList) => {
        console.log(team, "Team")
        this.teams = team
       
        // this.selectedTeam = this.teams[0].id 
        // console.log(this.teams , this.selectedTeam ,"LLLLLLLLLLLLLLL")
      })
  }
}
