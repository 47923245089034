import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class TicketService {
    selectedWorkFlow= new BehaviorSubject<any>({});
    selectedTab = new BehaviorSubject<any>("")
getWorkFlow() {
  this.selectedWorkFlow.subscribe((res:any) => {
    console.log(res,'selectedworkflow...........................')
  })
}
  }