import {Component, Inject, OnInit, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../code-modules/api/api-services/api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import { InAppLogger } from 'src/app/code-modules/utils/general-utils/InAppLogger';
import { DataDrivenForm } from 'src/app/code-modules/utils/data-driven-forms/DataDrivenForm';
import { AppContextService } from '../../../../code-modules/app-context/app-context.service';
import { ErrorToaster, ValidationMessages } from 'src/app/code-modules/utils/validations/genral-validation';
import {AccountRead, ContactCreateUpdate, Tag} from '../../../../code-modules/api/interfaces/Customer/customer-api-interfaces';
@Component({
  selector: 'app-create-contact-dialog',
  templateUrl: './create-contact-dialog.component.html',
  styleUrls: ['./create-contact-dialog.component.sass']
})
export class CreateContactDialogComponent implements OnInit {
  
  customerTags = []
  selectedContactTags : number[]=[]
  selectedAccountTags : number[]=[]
  logger = new InAppLogger(CreateContactDialogComponent.name)
  isContact = false;
  ContactForm: FormGroup;
  accountForm: FormGroup;
  customerAccountId: number;
  accountSelect = true;
  //dynamicForm: any;
  dynamicAccountForm:any;
  dynamicContactForm : any;

  constructor(
    public dialogRef: MatDialogRef<CreateContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public contactData:any,
    private  formBuilder: FormBuilder,
    private api: ApiService,
    private toaster: ToastrService,

    private context: AppContextService,
  ) {
  }

  ngOnInit() {
    this.api.CustomerTagList().subscribe((tags) => {
      this.customerTags = tags
    },(err)=>{
      this.toaster.error(ErrorToaster(err))
    })

    this.context.customerContactDynamicFields$.subscribe(fieldData => {
      this.logger.log('Loaded contact fields:', fieldData);
      if (fieldData.length) {
        this.dynamicContactForm = new DataDrivenForm(fieldData, {});
      } else {
        this.dynamicContactForm = new DataDrivenForm([]);
      }
    //  //this.loader.stop();
    // console.log("in contact dialog component",this.contactData.value.toAddress);
    });


    this.ContactForm = this.formBuilder.group({
      name: ['', [
        Validators.required,
      ]],
      email: [this.contactData.email, [
        Validators.required,
        Validators.email
        ]]
        // tags: ['']
        }); 
        console.log("in contact dialog component",this.contactData);
      // email: [this.contactData.value.toAddress, [
      //   Validators.required,
      //   Validators.email
      // ]]
      // // tags: ['']
    // });

    // this.context.customerAccountDynamicFields$.subscribe(fieldData => {
    //   this.logger.log('Loaded  account fields :', fieldData);
    //   if (fieldData.length) {
    //     this.dynamicAccountForm = new DataDrivenForm(fieldData, {});
    //   } else {
    //     this.dynamicAccountForm = new DataDrivenForm([]);
    //   }
    //  // //this.loader.stop();
    // });
    // this.accountForm = this.formBuilder.group({
    //   name: ['', [Validators.required]]
    // });
  }

  // onNoClick(): void {
  //
  // }

  onTypeChange(e) {
    this.isContact = e;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  onClick() {
    console.log('submit clicked');
    
    if (this.ContactForm.valid && this.dynamicContactForm.formGroup.valid) {
      this.submitContacts();
    }
    // } else if (this.accountForm.valid && this.dynamicAccountForm.formGroup.valid) {
    //   this.submitAccounts();
    // }
    else{
      this.toaster.info('Please Fill Required Fields');
      this.ContactForm.markAllAsTouched();
      this.dynamicContactForm.formGroup.markAllAsTouched();
    //  this.accountForm.markAllAsTouched();
     // this.dynamicAccountForm.formGroup.markAllAsTouched();
    }
    // this.dialogRef.close();
  }

  submitContacts() {
    
      const contactValues:ContactCreateUpdate = {
        name: this.ContactForm.value.name,
        email: this.ContactForm.value.email,
        phone_number:this.ContactForm.value.phone_number,
        whatsapp_number:this.ContactForm.value.whatsapp_number,
        twitter_id:this.ContactForm.value.twitter_id,
        facebook_id:this.ContactForm.value.facebook_id,
        is_lead_contact: false
      };
      contactValues.dynamic_fields = this.dynamicContactForm.formGroup.value
      contactValues.tags = this.selectedContactTags;
      contactValues.account = this.customerAccountId ? this.customerAccountId : null;
      let updateApi :any
      if(this.contactData && this.contactData.id){
        updateApi =  this.api.contactUpdate(contactValues, this.contactData.id);
      }
      else
      updateApi = this.api.contactCreate(contactValues);
      ////this.loader.start();

      updateApi.subscribe(resp => {
        console.log('Contact Create Success.', this.contactData.id);
       // //this.loader.stop();
        this.toaster.success('Contact creation success');
        this.dialogRef.close(true);
      }, err => {
        console.log('Contact creation Failed.', this.contactData.id);
        ////this.loader.stop();
        this.toaster.error('Contact creation failed');
      });
  
  }

  submitAccounts() {
    // let data  = <AccountCreateUpdate>this.accountForm.value;
    // data.dynamic_fields = this.dynamicAccountForm.formGroup.value
    // data.tags = this.selectedAccountTags
    // ////this.loader.start();
    // this.api.accountCreate(data).subscribe(resp => {
    //   console.log('Account Creation Success.', resp, this.isContact);
    //  this.customerAccountId = resp.id;
    //   this.isContact = !this.isContact;
    //   this.accountSelect = false;
    // //  //this.loader.stop();
    //   this.toaster.success('Please Add Contact', 'Account Created');
    //   //this.dialogRef.close(true);
    // }, err => {
    //  // //this.loader.stop();
    //   console.log('Account Creation Failed.');
    // });
  }


  toggleAccountTagSelection(tag:Tag){
    const index = this.selectedAccountTags.indexOf(tag.id);
    (index > -1) ?  this.selectedAccountTags.splice(index,1) : this.selectedAccountTags.push(tag.id)
  }

  isSelectedAccountTag(tag:Tag){
    return this.selectedAccountTags.includes(tag.id)
  }
  toggleContactTagSelection(tag:Tag){
    const index = this.selectedContactTags.indexOf(tag.id);
    (index > -1) ?  this.selectedContactTags.splice(index,1) : this.selectedContactTags.push(tag.id)
  }

  isSelectedContactTag(tag:Tag){
    return this.selectedContactTags.includes(tag.id)
  }

  getContactErrorMessage(){
    return ValidationMessages(this.ContactForm.controls);
  }
  getAccountErrorMessage(){
    return ValidationMessages(this.accountForm.controls);
  }
}
