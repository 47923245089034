import {Injectable} from '@angular/core';
import {ApiService} from '../api/api-services/api.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, filter, first, map, tap} from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Router} from '@angular/router';
import {logoutUrl} from './auth.urls';
import { LoaderService } from '../utils/loader/loader.service';
import {ApiCredentials, apiCredentialsNames, ApiLoginRequest} from '../api/interfaces/Tenant/tenant-api-interfaces';
import { ApiTenantService } from '../api/api-services/api-tenant-service';
import { ErrorToaster } from '../utils/validations/genral-validation';
import { MatDialog } from '@angular/material';
import { ReleaseSessionComponent } from '../utils/components/release-session/release-session.component';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggerName = '[Auth]';
  private initialized: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private loginStatus: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  loginStatus$: Observable<boolean> = this.loginStatus.asObservable();
  loginTenant:string
  loginTeam
  constructor(
    private api: ApiService,
    ////private loader: LoaderService,
    public tenantAPI:ApiTenantService,
    private router: Router,
    public dialog: MatDialog,
    public toaster: ToastrService
  ) {
  }

  set apiCredentials(credentials: Observable<ApiCredentials>) {
    credentials.pipe(
      first()
    ).subscribe(credValue => {
      window.onbeforeunload = function() {
        localStorage.setItem( 'session', JSON.stringify(credValue) );
      };
      apiCredentialsNames.forEach(credTokenName => {
        localStorage.setItem(credTokenName, credValue[credTokenName]);
      });
    });
  }

  get apiCredentials(): Observable<ApiCredentials> {
    if (apiCredentialsNames.every(credToken => Boolean(localStorage.getItem(credToken)))) {
      const apiCredentials = this.buildCredentials();
      return of(apiCredentials);
      // if (this.isAccessCodeValid(apiCredentials)) {
      //   return of(apiCredentials);
      // } 
      // else {
      //   console.log(`${this.loggerName} Access token expired. Refreshing...`);
      //   return this.refreshAccessCode().pipe(
      //     map(refreshSuccess => {
      //       if (refreshSuccess) {
      //         return this.buildCredentials();
      //       } else {
      //         return null;
      //       }
      //     })
      //   );
      // }

    } else {
      return of(null);
    }
  }

  private buildCredentials(): ApiCredentials {
    return {
      token: localStorage.getItem('token')
    };
  }

  private isAccessCodeValid(credentials): boolean {
    try {
      const jwt: any = jwt_decode(credentials.accesstoken);
      return new Date() < new Date(Number(jwt.exp) * 1000);
    } catch (e) {
      return null;
    }
  }

  private purgeApiCredentials() {
    apiCredentialsNames.forEach(credToken => {
      localStorage.removeItem(credToken);
    });
  }

  public initialize(): Observable<boolean> {
    return this.initialized.asObservable().pipe(
      filter(initStatus => {
        if (initStatus) {
          return initStatus;
        } else {
          this._initialize();
          return false;
        }
      })
    );
  }

  private _initialize() {
    if (!this.initialized.value) {
      this.apiCredentials.subscribe(credentials => {        
        if (credentials) {
          this.loginStatus.next(true);
        } else {
          this.purgeApiCredentials();
          this.loginStatus.next(false);
        }
        this.initialized.next(true);
      });

    }
  }
  authuser(credentials: ApiLoginRequest) {
    return this.tenantAPI.authuser(credentials).pipe(
      tap(authTokens => {
        localStorage.setItem('loginToken',JSON.stringify(authTokens))
        localStorage.setItem('currentTeam', JSON.stringify(authTokens.teams));
        // this.loginTeam = authTokens.teams[0]
        this.apiCredentials = of(authTokens);
        this.loginTenant = credentials.tenant
                // this.loginStatus.next(true);
        this.initialized.next(false);
      }, error => {
        this.toaster.error(ErrorToaster(error))
        // if(ErrorToaster(error) == 'User already logged-in'){
        //   // this.api.releaseSession(undefined , credentials.username).subscribe((data)=>{
        //   //   console.log(data)
        //     this.dialog.open(ReleaseSessionComponent, {
        //       data: credentials
        //     });
        //   // },(err)=>{
        //   //   console.log(err)
        //   // })
        // }
      }));
  }
  login(credentials: ApiLoginRequest) {
    return this.tenantAPI.login(credentials).pipe(
      tap(authTokens => {
        localStorage.setItem('loginToken',JSON.stringify(authTokens))
        localStorage.setItem('currentTeam', JSON.stringify(authTokens.teams));
        // this.loginTeam = authTokens.teams[0]
        this.apiCredentials = of(authTokens);
        this.loginTenant = credentials.tenant
                // this.loginStatus.next(true);
        this.initialized.next(false);
      }, error => {
        this.toaster.error(ErrorToaster(error))
        // if(ErrorToaster(error) == 'User already logged-in'){
        //   // this.api.releaseSession(undefined , credentials.username).subscribe((data)=>{
        //   //   console.log(data)
        //     this.dialog.open(ReleaseSessionComponent, {
        //       data: credentials
        //     });
        //   // },(err)=>{
        //   //   console.log(err)
        //   // })
        // }
      }));
  }


  logout(type?) {
    this.purgeApiCredentials();
    this.loginStatus.next(false);
    if(!type){
      this.router.navigate([logoutUrl]);
    }
    
    return true
  }

  
}
