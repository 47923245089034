import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { ApiService } from '../api/api-services/api.service';
import { AuthService } from '../authentication/auth.service';
import { filter, first, map, tap } from 'rxjs/operators';
import { DdfFieldData } from '../utils/data-driven-forms/ddf.interfaces';
import { DynamicFieldConfigurationTypes } from '../utils/data-driven-forms/ddf.enums';
import { WebSocketService, NotificationTypes, SocketActions } from '../websocket/web-socket.service';
import { DynamicFieldDefinitionRead } from '../utils/data-driven-forms/Configuration/Configurators.interfaces';
import { Router } from '@angular/router';
import { Tenant } from '../api/interfaces/Tenant/tenant-api-interfaces';
import { UserProfileRead } from '../api/interfaces/User/user-api-interfaces';
import { BaseNode, NotificationRead } from '../api/interfaces/Business-Rule/business-rule-api-interfaces';
import { EmailCountRequest, TicketCountRequest } from '../api/interfaces/Email/email-api-interfaces';
import { ApiTenantService } from '../api/api-services/api-tenant-service';
import { ToastrService } from 'ngx-toastr';
import { ErrorToaster } from '../utils/validations/genral-validation';

@Injectable({
    providedIn: 'root'
})
export class AppContextService {
    loggerName = '[Context]';

    // currentUserPermissions = []

    /**
     * Internal value for context service to easily manage asynchronous initialization calls
     */
    private initialized: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /**
     * Whether all the context existingTopic has been loaded. false could be a failure.
     */
    contextReady: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

    // Context existingTopic:
    // private users: BehaviorSubject<Array<CurrentUserDetails>> = new BehaviorSubject([]);

    //private
    private currentUser: BehaviorSubject<UserProfileRead> = new BehaviorSubject(undefined);
    currentUser$: Observable<UserProfileRead> = this.currentUser.asObservable().pipe(
        filter(value => Boolean(value)) // filter out un truthy values
    );

    private currentTenant: BehaviorSubject<Tenant> = new BehaviorSubject(undefined);
    currentTenant$: Observable<Tenant> = this.currentTenant.asObservable().pipe(
        filter(value => Boolean(value)) // filter out un truthy values
    );

    private dispositionDynamicFields: BehaviorSubject<Array<BaseNode>> = new BehaviorSubject(undefined);
    dispositionDynamicFields$: Observable<Array<BaseNode>> = this.dispositionDynamicFields.asObservable().pipe(
        filter(value => Boolean(value)) // filter out un truthy values
    );

    private customerAccountDynamicFields: BehaviorSubject<Array<DynamicFieldDefinitionRead>> = new BehaviorSubject(undefined);
    customerAccountDynamicFields$: Observable<Array<DynamicFieldDefinitionRead>> = this.customerAccountDynamicFields.asObservable().pipe(
        filter(value => Boolean(value)) // filter out un truthy values
    );

    private customerContactDynamicFields: BehaviorSubject<Array<DynamicFieldDefinitionRead>> = new BehaviorSubject(undefined);
    customerContactDynamicFields$: Observable<Array<DynamicFieldDefinitionRead>> = this.customerContactDynamicFields.asObservable().pipe(
        filter(value => Boolean(value)) // filter out un truthy values
    );

    private userDynamicFields: BehaviorSubject<Array<DynamicFieldDefinitionRead>> = new BehaviorSubject(undefined);
    userDynamicFields$: Observable<Array<DynamicFieldDefinitionRead>> = this.userDynamicFields.asObservable().pipe(
        filter(value => Boolean(value)) // filter out un truthy values
    );

    private ticketNameDynamicFields: BehaviorSubject<Array<DynamicFieldDefinitionRead>> = new BehaviorSubject(undefined)
    ticketNameDynamicFields$: Observable<Array<DynamicFieldDefinitionRead>> = this.ticketNameDynamicFields.asObservable().pipe(
        filter(value => Boolean(value)) // filter out un truthy values
    );

    private campaignDynamicFields: BehaviorSubject<Array<DynamicFieldDefinitionRead>> = new BehaviorSubject(undefined)
    campaignDynamicFields$: Observable<Array<DynamicFieldDefinitionRead>> = this.campaignDynamicFields.asObservable().pipe(
        filter(value => Boolean(value)) // filter out un truthy values
    );

    // private ticketDynamicFields: BehaviorSubject<Array<DynamicFieldDefinitionRead>> = new BehaviorSubject(undefined);
    // ticketDynamicFields$: Observable<Array<DynamicFieldDefinitionRead>> = this.ticketDynamicFields.asObservable().pipe(
    //     filter(value => Boolean(value)) // filter out un truthy values
    // );

    private opportunityDynamicFields: BehaviorSubject<Array<DynamicFieldDefinitionRead>> = new BehaviorSubject(undefined);
    opportunityDynamicFields$: Observable<Array<DynamicFieldDefinitionRead>> = this.opportunityDynamicFields.asObservable().pipe(
        filter(value => Boolean(value)) // filter out un truthy values
    );
    private orderDynamicFields: BehaviorSubject<Array<DynamicFieldDefinitionRead>> = new BehaviorSubject(undefined)
    orderDynamicFields$: Observable<Array<DynamicFieldDefinitionRead>> = this.orderDynamicFields.asObservable().pipe(
        filter(value => Boolean(value)) // filter out un truthy values
    );
    private emailCount: BehaviorSubject<EmailCountRequest> = new BehaviorSubject(undefined);
    emailCount$: Observable<EmailCountRequest> = this.emailCount.asObservable().pipe(
        filter(value => Boolean(value)) // filter out un truthy values
    );
    private eventCount: BehaviorSubject<TicketCountRequest> = new BehaviorSubject(undefined);
    eventCount$: Observable<TicketCountRequest> = this.eventCount.asObservable().pipe(
        filter(value => Boolean(value)) // filter out un truthy values
    );
    notifications: NotificationRead[] = [];


    public initialize(): Observable<boolean> {
        return this.initialized.asObservable().pipe(
            filter(initStatus => {
                if (initStatus) {
                    return initStatus;
                } else {
                    this._initialize();
                    return false;
                }
            })
        );
    }

    constructor(
        private api: ApiService,
        private auth: AuthService,
        private socketConnection: WebSocketService,
        private toaster:ToastrService
    ) {
    }

    clearAll() {
        this.initialized = new BehaviorSubject(false);
        this.contextReady = new BehaviorSubject(undefined);
        // this.users = new BehaviorSubject([]);
        this.currentUser = new BehaviorSubject(undefined);
        this.currentTenant = new BehaviorSubject(undefined);
        this.dispositionDynamicFields = new BehaviorSubject(undefined);
        this.customerAccountDynamicFields = new BehaviorSubject(undefined);
        this.customerContactDynamicFields = new BehaviorSubject(undefined);
        this.userDynamicFields = new BehaviorSubject(undefined);
        this.emailCount = new BehaviorSubject(undefined);
        this.eventCount = new BehaviorSubject(undefined);
        this.socketConnection.disconnect();


    }

    private _initialize() {
        if (!this.initialized.value) {
            console.log(`${this.loggerName} Initializing..`);

            this.auth.initialize().subscribe(() => this._loadAllContext());
            // this.auth.loginStatus.subscribe(() => this._loadAllContext());

            this.initialized.next(true);
        }
    }

    private _loadAllContext() {
        this.contextReady.next(false);
        // list of api hits to get context existingTopic:
        let contextDataApiRequests = [];
        this.auth.loginStatus$.pipe(
            first()
        ).subscribe(loginStatus => {
            if (loginStatus) {
                // list of api hits  if logged in
                contextDataApiRequests = contextDataApiRequests.concat(this._loggedInContextApiRequests());
            }
            // list of apis to hit in any case:
            contextDataApiRequests = contextDataApiRequests.concat(this._contextDataApiRequests());

            console.log(`${this.loggerName} (Re)loading all data..`, loginStatus, contextDataApiRequests);

            if (contextDataApiRequests.length > 0) {
                // once all apis are hit, contextReady has to be set
                forkJoin(contextDataApiRequests).subscribe(() => {
                    this.contextReady.next(true);
                    console.log(`${this.loggerName} Data loading complete:`, {
                        //   users: this.users.value
                    });
                }, error => {
                    this.contextReady.next(false);
                    this.contextReady.error('Data loading failed');
                    console.log(`${this.loggerName} Data loading failed:`, error, {
                        //   users: this.users.value
                    });
                    this.socketConnection.disconnect();
                    this.auth.logout();
                });
            } else {
                // no existingTopic to fetch.
                console.log(`${this.loggerName} Context data fetch skipped. Nothing to fetch`);
                this.contextReady.next(true);
            }
        });

    }

    private _loggedInContextApiRequests(): Array<Observable<any>> {
        console.log("calling _loggedInContextApiRequests" , localStorage.getItem('token'))
        return [
            this.fetchCurrentUser(),

            this.fetchCustomerAccountDynamicFields(),
            this.fetchCustomerContactDynamicFields(),
            this.fetchUserDynamicFields(),
            this.fetchDispositionDynamicFields(JSON.parse(localStorage.getItem("currentTeam"))[0].id),
            this.fetchTicketNameDynamicFields(),
            this.fetchCampaignDynamicFields(),
            // this.fetchCustomerAccountDynamicFields(),
            // this.fetchCustomerContactDynamicFields(),
            // this.fetchUserDynamicFields(),
            // this.fetchTicketDynamicFields(),
            this.initializeWebSocket(),
            this.fetchOrderDynamicFields(),
            // this.fetchOpportunityDynamicFields()

        ];
    }


    private _contextDataApiRequests(): Array<Observable<any>> {
        return [];
    }

    fetchCurrentUser(): Observable<UserProfileRead> {
        return this.api.fetchCurrentUser().pipe(
            tap((user) => { // using map instead of tap here is intentional
                console.log(`${this.loggerName} Current user details fetched:`, user);
                this.currentUser.next(user);
                this.currentTenant.next(user.tenant);
                // if (user.tenant && user.tenant.theme) {
                // applyThemeConfig(user.tenant.theme);
                // }
            }, error => {
                console.error(`${this.loggerName} Failed to fetch current user:`, error);
                this.toaster.error(ErrorToaster(error))
            })
        );
    }


    fetchDispositionDynamicFields(team_id): Observable<Array<BaseNode>> {
        return this.api.getDispositionTree(team_id).pipe(
            map(fieldData => fieldData || []),
            tap((dispositions) => { // using map instead of tap here is intentional
                console.log(`${this.loggerName} Disposition dynamic fields fetched:`, dispositions);
                this.dispositionDynamicFields.next(dispositions);
            }, error => {
                console.error(`${this.loggerName} Failed to fetch Disposition dynamic fields:`, error);
            })
        );
    }

    fetchCustomerAccountDynamicFields(): Observable<Array<DynamicFieldDefinitionRead>> {
        return this.api.dynamicFieldList(DynamicFieldConfigurationTypes.CUSTOMER_ACCOUNT).pipe(
            map(fieldData => fieldData || []),
            tap((dynamicFields) => { // using map instead of tap here is intentional
                console.log(`${this.loggerName} customer account dynamic fields fetched:`, dynamicFields);
                this.customerAccountDynamicFields.next(dynamicFields);
            }, error => {
                console.error(`${this.loggerName} Failed to fetch customer account dynamic fields:`, error);
            })
        );
    }

    fetchCustomerContactDynamicFields(): Observable<Array<DynamicFieldDefinitionRead>> {
        return this.api.dynamicFieldList(DynamicFieldConfigurationTypes.CUSTOMER_CONTACT).pipe(
            map(fieldData => fieldData || []),
            tap((dynamicFields) => { // using map instead of tap here is intentional
                console.log(`${this.loggerName} customer contact dynamic fields fetched:`, dynamicFields);
                this.customerContactDynamicFields.next(dynamicFields);
            }, error => {
                console.error(`${this.loggerName} Failed to fetch customer contact dynamic fields:`, error);
            })
        );
    }


    fetchUserDynamicFields(): Observable<Array<DynamicFieldDefinitionRead>> {
        console.log("in fetchUserDynamicFields" + DynamicFieldConfigurationTypes.USER_PROFILE)
        return this.api.dynamicFieldList(DynamicFieldConfigurationTypes.USER_PROFILE).pipe(
            map(dynamicField => dynamicField || []),
            tap((dynamicFields) => { // using map instead of tap here is intentional
                console.log(`${this.loggerName} user profile dynamic fields fetched:`, dynamicFields);
                this.userDynamicFields.next(dynamicFields);
            }, error => {
                console.error(`${this.loggerName} Failed to fetch user profile dynamic fields:`, error);
            })
        );
    }
    fetchTicketNameDynamicFields(): Observable<Array<DynamicFieldDefinitionRead>> {
        console.log("in fetchTicketNameDynamicFields" + DynamicFieldConfigurationTypes.TICKET_NAME)
        return this.api.dynamicFieldList(DynamicFieldConfigurationTypes.TICKET_NAME)
            .pipe(
                map(fieldData => fieldData || []),
                tap((dynamicFields) => { // using map instead of tap here is intentional
                    console.log(`${this.loggerName}  ticket name dynamic fields fetched:`, dynamicFields);
                    this.ticketNameDynamicFields.next(dynamicFields);
                    // console.log("test",this.ticketNameDynamicFields.next(dynamicFields))
                },
                    error => {
                        console.error(`${this.loggerName} Failed to fetch ticket name dynamic fields:`, error);
                    })
            );
    }
    fetchCampaignDynamicFields(): Observable<Array<DynamicFieldDefinitionRead>> {
        console.log("in fetchCampaingDynamicFields" + DynamicFieldConfigurationTypes.CAMPAIGN)
        return this.api.dynamicFieldList(DynamicFieldConfigurationTypes.CAMPAIGN)
            .pipe(
                map(fieldData => fieldData || []),
                tap((dynamicFields) => { // using map instead of tap here is intentional
                    console.log(`${this.loggerName}  campaign dynamic fields fetched:`, dynamicFields);
                    this.campaignDynamicFields.next(dynamicFields);
                    // console.log("test",this.ticketNameDynamicFields.next(dynamicFields))
                },
                    error => {
                        console.error(`${this.loggerName} Failed to fetch campaign dynamic fields:`, error);
                    })
            );
    }
    fetchOrderDynamicFields(): Observable<Array<DynamicFieldDefinitionRead>> {
        console.log("in fetchCampaingDynamicFields" + DynamicFieldConfigurationTypes.ORDER)
        return this.api.dynamicFieldList(DynamicFieldConfigurationTypes.ORDER)
            .pipe(
                map(fieldData => fieldData || []),
                tap((dynamicFields) => { // using map instead of tap here is intentional
                    console.log(`${this.loggerName}  campaign dynamic fields fetched:`, dynamicFields);
                    this.orderDynamicFields.next(dynamicFields);
                    // console.log("test",this.ticketNameDynamicFields.next(dynamicFields))
                },
                    error => {
                        console.error(`${this.loggerName} Failed to fetch campaign dynamic fields:`, error);
                    })
            );
    }
    // fetchTicketDynamicFields(): Observable<Array<DynamicFieldDefinitionRead>> {
    //     return this.api.dynamicFieldList(DynamicFieldConfigurationTypes.TICKET).pipe(
    //         map(fieldData => fieldData.results || []),
    //         tap((dynamicFields) => { // using map instead of tap here is intentional
    //             console.log(`${this.loggerName}  ticket dynamic fields fetched:`, dynamicFields);
    //             this.ticketDynamicFields.next(dynamicFields);
    //         }, error => {
    //             console.error(`${this.loggerName} Failed to fetch ticket dynamic fields:`, error);
    //         })
    //     );
    // }

    // fetchOpportunityDynamicFields(): Observable<Array<DynamicFieldDefinitionRead>> {
    //     return this.api.dynamicFieldList(DynamicFieldConfigurationTypes.OPPORTUNITY).pipe(
    //         map(fieldData => fieldData.results || []),
    //         tap((dynamicFields) => { // using map instead of tap here is intentional
    //             console.log(`${this.loggerName}  opportunity dynamic fields fetched:`, dynamicFields);
    //             this.opportunityDynamicFields.next(dynamicFields);
    //         }, error => {
    //             console.error(`${this.loggerName} Failed to fetch opportunity dynamic fields:`, error);
    //         })
    //     );
    // }

    initializeWebSocket() {
        this.socketConnection.connect();
        console.log('Connecting to sockets from context');
        this.socketConnection.socket$.subscribe(data => {
            if (data.length) {
                data.forEach((notification) => {
                    if (notification.notification_type === NotificationTypes.NOTIFICATION_EMAIL_COUNT_UPDATE) {
                        this.emailCount.next(notification.data);
                    } else if (notification.notification_type !== SocketActions.AUTH_SUCCESS && notification.notification_type === NotificationTypes.NOTIFICATION_TICKET_EVENT) {
                        if (!this.notifications.some((item) => item.data.id == notification.data.id)) {
                            this.notifications.unshift(notification);
                        }
                    }

                    if (notification.notification_type === NotificationTypes.NOTIFICATION_TICKET_EVENT) {
                        this.eventCount.next(notification.data);
                    }
                    console.log(this.eventCount)

                });
            }

        });
        this.auth.loginStatus$.subscribe(s => {
            if (!s) {
                this.socketConnection.disconnect();
            }
        });
        return of(true);
    }

    // getUsersList(callback) {
    //   return this.users.subscribe((data) => {
    //     callback(data);
    //   });
    // }

}
