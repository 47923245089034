import { ApiReference } from '../api-end-points/api-reference';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export class ApiServiceBase {
    loggerName = '[API]';
    apiUrlSource;

    constructor(
        private http: HttpClient,
    ) {
    }

    private _apiHttpRequest(
        httpMethodName: string,
        apiName: string,
        body?: object | FormData,
        urlParams?: Array<any>,
        queryParams?: object,
        options?: object
    ): Observable<any> {
        const actionUrl = this.apiUrlSource(apiName, urlParams, queryParams);
        // console.log(`${this.loggerName} Building request for: ${apiName} URL: ${actionUrl}`);
        if (['get', 'delete'].includes(httpMethodName)) {
            // either get or delete. no body required
            return this.http[httpMethodName](actionUrl, options);
        } else {
            // body required
            return this.http[httpMethodName](actionUrl, body, options);
        }
    }

    // GET:
    public _apiRead(apiName, urlParams?, queryParams?: object, options?) {
        return this._apiHttpRequest('get', apiName, null, urlParams, queryParams, options);
    }

    // POST:
    public _apiCreate(apiName, body, urlParams?, options?) {
// console.log("apiName :---", apiName)

        return this._apiHttpRequest('post', apiName, body, urlParams, options);
    }

    // PUT

    public _apiUpdate(apiName, body, urlParams?, options?) {
        
        return this._apiHttpRequest('put', apiName, body, urlParams, options);
    }

    // PATCH
    public _apiPartialUpdate(apiName, body, urlParams?, options?) {
        return this._apiHttpRequest('patch', apiName, body, urlParams, options);
    }

    // DELETE
    public _apiDelete(apiName, urlParams?, options?) {
        return this._apiHttpRequest('delete', apiName, null, urlParams, options);
    }

    public _apiDelete1(apiName,body, urlParams) {
      
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        body:body
      }

    const url=this.apiUrlSource(apiName)
    return this.http.delete(url,options);
        
    }

    public _apiMultipleDelete(apiName,body) {
        console.log(body , 'body')
        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          }),
          body:body
        }
        console.log(options , 'options')

      const url=this.apiUrlSource(apiName)
      return this.http.delete(url,options);
          
      }
  


}
