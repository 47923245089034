import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Phone } from '../model/phone.model';

@Component({
  selector: 'app-phone-loader',
  templateUrl: './phone-loader.component.html',
  styleUrls: ['./phone-loader.component.sass']
})
export class PhoneLoaderComponent implements OnInit {
  routePath: any;
  id: any;
  leadMobile: any;
  leadId: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public phoneModel: Phone,
  ) {
    this.route.queryParams.subscribe(param => {
      this.routePath = (((param.path)) ? param.path : '');
      console.log(this.routePath)
      this.id = (((param.id)) ? param.id : '');
    });
  }

  ngOnInit() {
    this.phoneModel.callTimerEvent && this.phoneModel.callTimerEvent.unsubscribe();

    
    if (this.id != '') {
      var allData = { 'id': this.id };
      this.router.navigate([this.routePath], { queryParams: allData });
    } else {
      console.log(this.routePath)
      this.router.navigate([this.routePath]);
    }

  }

}
