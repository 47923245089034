import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';

@Component({
  selector: 'app-release-session',
  templateUrl: './release-session.component.html',
  styleUrls: ['./release-session.component.sass']
})
export class ReleaseSessionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ReleaseSessionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private api: ApiService) { }

  ngOnInit() {
    console.log(this.data)
  }

  releaseSession(){
        this.api.releaseSession(undefined , this.data.username).subscribe((data)=>{
            console.log(data)
            this.dialogRef.close();
        },(err)=>{
          console.log(err)
        })
  }

  clickOnCancel(){
    this.dialogRef.close(false)
  }
}
