import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ToastrService} from 'ngx-toastr';
import {passwordMatchValidator} from '../../../../../shared/utils/validators/password-match-validator';
import {ErrorStateMatcher} from '@angular/material';
import {ApiService} from '../../../../../code-modules/api/api-services/api.service';
import { LoaderService } from 'src/app/code-modules/utils/loader/loader.service';
import {ApiResetPassword} from '../../../../../code-modules/api/interfaces/Tenant/tenant-api-interfaces';
import { ApiTenantService } from 'src/app/code-modules/api/api-services/api-tenant-service';

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})
export class ResetPasswordComponent implements OnInit {
  loggerName = '[/reset]';
  hidePassword = true;
  resetForm: FormGroup;
  errorMatcher = new CrossFieldErrorMatcher();
  token: string;
  @Output() gotoNext = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    // private api: ApiTenantService,
    private router: Router,
    private  route: ActivatedRoute,
    ////private loader: LoaderService,
    private toaster: ToastrService,
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(qParams => {
      this.token = qParams.token;
      console.log(`${this.loggerName} token received: ${this.token}`);
    });
   // //this.loader.start();
    this.resetForm = this.formBuilder.group({
        password: ['', [Validators.required,
          Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required,
          Validators.minLength(6)]],
      email: ['', [Validators.required,
      Validators.email]]
      },
      {validators: passwordMatchValidator});
  //  //this.loader.stop();
  }

  submit() {
    console.log(this.resetForm);
    const credentials: ApiResetPassword = {
      new_password: this.resetForm.value.confirmPassword,
      email: this.resetForm.value.email,
      token: this.token
    };
    if (this.resetForm.valid) {
      //this.loader.start();
      this.api.resetPass(credentials).subscribe(() => {
        this.gotoNext.emit(true);
        console.log(`${this.loggerName} Reset success`);
        //this.loader.stop();
        // this.router.navigate([loginSuccessUrl]);
      }, () => {
        //this.loader.stop();
        this.toaster.error('Please try again later', 'Reset is failed');
        console.error(`${this.loggerName} Reset failed`);
      });
    }
  }

}

