import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { filter, first, map, mergeMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, zip } from 'rxjs';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
// import { Department, Group, NestedGroup } from 'src/app/code-modules/api/interfaces/Business-Rule/business-rule-api-interfaces';
import { UserProfileRead, UserProfileUpdate, UserTeamList } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { hasModuleEditPermission } from 'src/app/code-modules/saas/hasModuleEditPermission';
import { AllModules, UserRoles, ModuleIds, getModuleName } from 'src/app/code-modules/saas/saas.enums';
import { Team } from 'src/app/code-modules/api/interfaces/Business-Rule/business-rule-api-interfaces';



export interface Params {
  id: string;
}

export interface TicketTable {
  ticketId: string;
  emailId: string;
  subject: string;
  requested: string;
  updated: string;
  status: string;
}

const TICKET_DATA: TicketTable[] = [
  {
    emailId: 'doe@stunntech.com',
    ticketId: '#123',
    subject: 'Samuels Vietnamese Dim Sum',
    requested: '18/05/18',
    updated: '29/03/18',
    status: 'Processing'
  }
];

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.sass']
})
export class UserDetailComponent implements OnInit {
  loggerName = '[/users/details]';
  userId$: Observable<string>;
  userSubject$: BehaviorSubject<UserProfileRead> = new BehaviorSubject(undefined);
  user$: Observable<UserProfileRead> = this.userSubject$.asObservable().pipe(filter(value => Boolean(value)));
  allModules = AllModules;
  userRoles = UserRoles;
  selectedUserRole: UserRoles;
  userId: number;
  username: string;
  authorizedAdmin = false;
  selectedUser: UserProfileRead;
  dynamic_fields=[]
  displayedColumns: string[] = ['first_name', 'last_name', 'email', 'role'];
  dataSource: MatTableDataSource<UserProfileRead>
  //selection = new SelectionModel<TicketTable>(true, []);

  permissionsForm: FormGroup;
  permissionFormInitialised = false;
  public userDepartment$: Observable<UserTeamList>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedGroups: any = [];

  constructor(
    private route: ActivatedRoute,
    private context: AppContextService,
    private formBuilder: FormBuilder,
    private api: ApiService,

    private toaster: ToastrService,
    public addDepartmentDialog: MatDialog,
    public router: Router,
  ) {
  }

  ngOnInit() {
   // //this.loader.start();

    this.route.params.subscribe(params => {
      this.userId = Number(params.id);
      if (this.userId) {
        this.loadUserDetails();
        this.userDepartment$ = this.api.userTeamList();
      }
    })

  }

  loadUserDetails() {
    //this.loader.start();
    this.api.userDetails(this.userId).subscribe((user) => {
      console.log(user,"User")
      this.dataSource = new MatTableDataSource<UserProfileRead>([user]);
      this.userSubject$.next(user);
      this.username = user.username;
      // role
      this.selectedUser = user;
      this.selectedUserRole = user.role;
      this.fillSelectedGroup(user.teams)
      this.setDynamicfields();
     // this.selectedGroups = user.groups || [];
      // moduile permissions form
      const permissionsFormSource: any = {};
      // this.allModules.forEach(modId => {
      //   permissionsFormSource[`${modId}`] = [hasModuleEditPermission(user, modId)];
      // });
     // console.log(permissionsFormSource);
      this.permissionsForm = this.formBuilder.group(permissionsFormSource);
      this.permissionFormInitialised = true;
    //  //this.loader.stop();
    }, (err) => {
      ////this.loader.stop();
      this.toaster.error("Failed to fetch user details")
    })
  }

  setDynamicfields() {
    if (Object.values(this.selectedUser.dynamic_fields).length) {
      this.dynamic_fields = Object.keys(this.selectedUser.dynamic_fields)
      this.displayedColumns = [...this.displayedColumns,...this.dynamic_fields]
      this.displayedColumns = [...new Set(this.displayedColumns)]
    }
  }
  
  fillSelectedGroup(groups:Array<Team>){
    console.log(groups)
    if(groups.length){
      groups.forEach((group) => {
        if(!this.selectedGroups.includes(group.id)){
          this.selectedGroups.push(group.id)
        }
      })
      console.log(this.selectedGroups)
    }
    
  }

  save() {
  
    // //this.loader.start();
    ;
    let permissions = [];
    this.allModules.forEach(modId => {
      if (this.permissionsForm.value[`${modId}`]) {
        permissions.push(modId+":enable");
      }
    });
    const updateRequest= <UserProfileUpdate> {
      teams : this.selectedGroups,
      module_permissions : permissions,
      role : this.selectedUserRole
    }
    console.log(updateRequest ,this.selectedGroups, "********************************************")
    this.api.userUpdate(updateRequest,this.selectedUser.id).subscribe(result => {
      console.log(`${this.loggerName}`, result);
     // //this.loader.stop();
      this.loadUserDetails();
      this.toaster.success('User Updated');
      this.context.fetchCurrentUser();

    }, err => {
      console.log(`${this.loggerName}`, err);
     // //this.loader.stop();
      // if (err && err.status && err.status === 403){
      //   this.toaster.error(err.error)
      // }
      if(err.status && err.status === 400 && err.error.role)
      this.toaster.error(err.error.role)
      else
        this.toaster.error('User Update Failed');
    });
  }

  updatePermissions() {

  }


  // getGroups$(department: Team) {
  //   const departmentId = department.id;
  //   if (!this.groups[departmentId]) {
  //     this.groups[departmentId] = department.groups;
  //     //this.groups[departmentId] = this.api.userGroupList(departmentId);
  //   }
  //   return this.groups[departmentId];
  // }

  toggleGroupSelection(group) {
    if (!this.selectedGroups.includes(group.id)) {
      this.selectedGroups.push(group.id);
    } else {
      this.selectedGroups = this.selectedGroups.filter(grp => grp !== group.id);
    }
  }

  isSelected(group): boolean {
    if(group.id){
    return this.selectedGroups.includes(group.id);
    }
  }

  getModule(moduleId: ModuleIds): string {
    return getModuleName(moduleId);
  }

  onRoleChange() {

    // this.allModules.forEach(modId => {
    //   if (this.selectedUserRole == this.userRoles.ADMIN)
    //     this.permissionsForm.controls[`${modId}`].setValue(true)
    //   else
    //     this.permissionsForm.controls[`${modId}`].setValue(hasModuleEditPermission(this.selectedUser, modId))
    // })

  }

}
