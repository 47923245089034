
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { Subscription } from 'rxjs';
import { EventNotificationComponent } from 'src/app/circle-one/app/components/event-notification/event-notification.component';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { Inject } from '@angular/core';
import { NotificationTypes, WebSocketService } from 'src/app/code-modules/websocket/web-socket.service';
import { Router } from '@angular/router';
import { NotificationRead } from 'src/app/code-modules/api/interfaces/Business-Rule/business-rule-api-interfaces';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';

export interface EventData {



}



@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.sass']
})
export class UserNotificationComponent implements OnInit {



  displayedColumns: string[] = ['sr.no', 'title', 'event_date', 'start_time', 'created'];

  dataSource: MatTableDataSource<EventData>;
  customerTags: any = [];
  allContact: any = [];
  subscribers: Array<Subscription> = []
  totalEvents: number;
  contactNext = false;
  contactPrevious = false;
  userRole: string;
  teamData: any;
  teamId: any = '';
  userId: any;
  notificationData: any = [];
  eventData: any = [];
  notifications: NotificationRead[] = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: UserNotificationComponent,
    public dialogRef: MatDialogRef< UserNotificationComponent >,
    public confirmDialog: MatDialog,
    private api: ApiService,
    public eventNotificaionMatDialog: MatDialog,
    public toaster: ToastrService,
    public datepipe: DatePipe,
    private context: AppContextService,
    private socketConnection: WebSocketService,
    private router: Router,

  ) {
    this.teamId = ((dialogData['teamId']) ? dialogData['teamId'] : '');

    this.context.currentUser$.subscribe((currentuser) => {
      this.userRole = ((currentuser['role']) ? currentuser['role'] : null);
      this.userId = ((currentuser['id']) ? currentuser['id'] : 0);
    })
  }

  ngOnInit() {
    this.listEvent()
  }

  listEvent() {
    const EventList = this.api.listEventData('',this.teamId,'','').subscribe((result) => {
      console.log(result)
      this.totalEvents = result.count;
      this.contactNext = result.next && result.next !== null ? true : false
      this.contactPrevious = result.previous && result.previous !== null ? true : false
      this.dataSource = new MatTableDataSource<EventData>(result.results);
      this.allContact = result;
    })
    this.subscribers.push(EventList)
   
  }
  openConfirmDialog() {
    const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: "Want to delete ?"
    });
    return dialogRef;
  }

  editEvent(id) {
    const dialogRef = this.eventNotificaionMatDialog.open(EventNotificationComponent, {
      panelClass: 'co-dialog-panel',
      data: { bulkUpload: true, event_id: id },
      // position: { left: '230px', top: '50px' }
      height: '513px',
      width: '532px'

    });
    dialogRef.afterClosed().subscribe(result => {
      this.listEvent()
    });
  }



  deleteEvent(id) {
    event.stopPropagation();
    const dialogRef = this.openConfirmDialog()
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const deleteAccount = this.api.deleteEvent(id).subscribe((success) => {
          this.toaster.success("Task deleted successfully")
          this.listEvent()
        }, (err) => {
          this.toaster.error("Something went wrong")
        })
        this.subscribers.push(deleteAccount)
      }
    })
  }

  ViewEvent(id, event_id) {
    const dialogRef = this.eventNotificaionMatDialog.open(EventNotificationComponent, {
      panelClass: 'co-dialog-panel',
      data: { bulkUpload: true, event_id: event_id, 'type': 'view'},
      height: '513px',
      width: '532px'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
