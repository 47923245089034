import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InviteDialogComponent } from './invite-dialog/invite-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { UserProfileRead, UserProfileUpdate, UserTeamList } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';
import { UserRoles } from 'src/app/code-modules/saas/saas.enums';
import { ArchieveDialogComponent } from 'src/app/shared/components/archieve-dialog/archieve-dialog.component';
import { ResetPasswordDialogComponent } from './reset-password-dialog/reset-password-dialog.component';
import * as saveAs from 'file-saver';
import { Phone } from 'src/app/circle-one/telephony/model/phone.model';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass']
})
export class UsersComponent implements OnInit, OnDestroy {
  subscribers: Array<Subscription> = []
  p = 1
  loggerName = '[/app/users]';
  searchTerm: string;
  users: UserProfileRead[] = [];

  order: string = 'username';

  random = Number(new Date())
  loggedUser = "";
  defaultAvatar = 'https://www.w3schools.com/howto/img_avatar.png';
  loggedUserRole = "";
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  emailInterval: any;
  ticketInterval: any;
  teamData: any;
  teams: UserTeamList;
  selectedTeam: any
  singleTeamArr: any = [];
  allTeam: boolean = true;
  singleTeam: boolean = false;
  selectedSingleTeam: number;
  userDeleteBtn: boolean = false;
  userResetPassword: boolean = false;
  teamId: any;
  userStatus: any;
  currentRole: string;
  allTeamData: any;
  constructor(
    public dialog: MatDialog,
    public api: ApiService,
    private context: AppContextService,
    private toaster: ToastrService,
    public confirmDialog: MatDialog,
    public contex: AppContextService,
    public archieveDialog: MatDialog,
    public phoneModel: Phone


  ) {
    this.contex.currentUser$.subscribe((user) => {
      if (user.role && user.role === UserRoles.ADMIN) {
        this.userDeleteBtn = true
        this.userResetPassword = true
      }
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(InviteDialogComponent, {
      panelClass: 'co-dialog-panel',
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.loadAllUsers();
    })
  }

  ngOnInit() {

    let urlRoute = localStorage.getItem('urlRoute');
    this.phoneModel.allBackUrls.unshift(urlRoute);
    this.phoneModel.lastUrl = urlRoute
    
    this.context.fetchUserDynamicFields().subscribe((data) => {
      console.log(data, "#####################################################3")
    })
    /*stop autorefresh interval of email & ticket */
    this.emailInterval = localStorage.getItem('emailInterval');
    if (this.emailInterval) {
      clearInterval(this.emailInterval);
    }

    this.ticketInterval = localStorage.getItem('ticketInterval');
    if (this.ticketInterval) {
      clearInterval(this.ticketInterval);
    }
    /*end of function*/


    this.context.currentUser$.subscribe((currentuser) => {
      this.loggedUser = currentuser.username;
      this.loggedUserRole = currentuser.role;
    })

    this.teamData = JSON.parse(localStorage.getItem('currentTeam'));
    this.teamId = ((this.teamData[0]['id']) ? this.teamData[0]['id'] : null);

    if (this.teamId != null && this.loggedUserRole != 'admin') {
      this.loadTeamWiseUsers(this.teamId)
      this.loadSingleUserTeamList(this.teamId)
      this.allTeam = false;
      this.singleTeam = true;
    } else {
      this.allTeam = true;
      this.singleTeam = false;
      this.loadAllUsers();
      this.loadUserTeamList();
    }
    this.getCurrentUser()
  }

  loadAllUsers() {
    ////this.loader.start();
    const userListApi = this.api.listUsers(undefined, undefined, this.userStatus).subscribe((users) => {
      this.users = users;
    }, (err) => {
      this.toaster.error("Failed to load users");
      this.toaster.error(ErrorToaster(err))
    })
    this.subscribers.push(userListApi)
  }

  loadTeamWiseUsers(teamId) {
    ////this.loader.start();
    const userListApi = this.api.listTeamWiseUsers(teamId, this.userStatus).subscribe((users) => {
      this.users = users;
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    })
    this.subscribers.push(userListApi)
  }

  loadUserTeamList() {
    this.api.userTeamList()
      .subscribe((team: UserTeamList) => {
        console.log(team, "Team")
        this.teams = team
      }, (err) => {
        this.toaster.error(ErrorToaster(err))
      })
  }

  loadSingleUserTeamList(teamId) {
    this.api.loadSingleUserTeamList(teamId)
      .subscribe((team) => {
        this.singleTeamArr.push({ id: team['id'], name: team['name'], module_permissions: team['module_permissions'] })
        this.selectedSingleTeam = team['name'];
      }, (err) => {
        this.toaster.error(ErrorToaster(err))
      })
  }



  getProfileImage(avatar: string) {
    // this.random = Number(new Date())
    if (avatar) {
      return 'url(' + avatar + '?' + this.random + ')';
    } else

      return 'url(' + this.defaultAvatar + ')';
  }

  openConfirmDialog() {
    const dialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: "Do you really want to delete these user? This process cannot be undone."
    });
    return dialogRef;
  }
  removeUser(user) {
    const dialogRef = this.openConfirmDialog()
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(user)
        if (this.loggedUserRole === "admin" && this.loggedUser != user.username) {
          // //this.loader.start();
          const removeApi = this.api.removeUser(user.id).subscribe((success) => {
            this.toaster.success("User has been deleted");
            this.loadAllUsers();
          }, (err) => {
            this.toaster.error(ErrorToaster(err))
            // this.toaster.error("Something went wrong");
          }, () => {
            //  //this.loader.stop();
          })
          this.subscribers.push(removeApi)

        } else {
          this.toaster.info("Unauthorized Action!")
        }
      }
    })
  }
  ngOnDestroy() {
    this.subscribers.forEach((sub) => sub.unsubscribe())

  }

  showTeamWiseData() {
    if (this.selectedTeam == 0) {
      this.loadAllUsers()
    } else {
      this.loadTeamWiseUsers(this.selectedTeam);
    }
  }

  openArchievDialog(user) {
    if (user.is_disable == true) {
      var message = 'Want to enable user?';
    } else {
      var message = 'Are you sure you want to deactivate this user ? If deactivated today, this user can access the application only after 7 days.';
    }
    const dialogRef = this.archieveDialog.open(ArchieveDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: message,
      width: '311px'

    });
    return dialogRef;
  }

  archieveUser(user) {
    console.log(user.is_disable)

    if (user.is_disable == true) {
      var status = 'enable';
    } else {
      var status = 'disable';
    }
    const dialogRef = this.openArchievDialog(user)
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.api.archieveUserUpdate(user.id, status).subscribe(result => {
          console.log(`${this.loggerName}`, result);
          this.fetchUsers()
          this.toaster.success('User Updated');

        }, err => {
          console.log(`${this.loggerName}`, err);
          // this.toaster.error(ErrorToaster(err))
          if (err.status && err.status === 400 && err.error.role)
            this.toaster.error(err.error.role)
          else
            this.toaster.error(err.error.user);
        });
      }
    });
  }

  onTypeChange(event) {
    console.log(event)
    if (event.checked) {
      this.userStatus = 'disable';
    } else {
      this.userStatus = 'enable';
    }
    this.fetchUsers()
  }

  fetchUsers() {
    if (this.teamId != null && this.loggedUserRole != 'admin') {
      this.loadTeamWiseUsers(this.teamId)
    } else {
      this.loadAllUsers();
    }
  }
  releaseSession(user) {
    // console.log(user);
    this.api.releaseSession(user.id).subscribe((data) => {
      console.log(data)
      this.loadAllUsers();
      // this.auth.logout()
    }, (err) => {
      this.toaster.error(ErrorToaster(err))
    })
  }

  // openChnagePassword() {
  //   const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
  //     panelClass: 'co-dialog-panel',
  //     width: '400px',
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     this.loadAllUsers();
  //   })
  // }
  openChnagePassword(username) {
    const dialPadDialog = this.dialog.open(ResetPasswordDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: { bulkUpload: true, username },
      width: '400px',
    });
    dialPadDialog.afterClosed().subscribe(result => {
      this.loadAllUsers();
    });
  }
  getCurrentUser() {
    this.api.fetchCurrentUser()
      .subscribe((user) => {
        this.currentRole = user.role;
      }, (err) => {
        this.toaster.error(ErrorToaster(err))
      })
  }

  downloadCSV(type) {
    let data;
    var teamName = ''
    data = this.users.map((item) => {
      teamName = ''
      this.allTeamData = item['teams']

      if (this.allTeamData.length > 0) {
        this.allTeamData.forEach(element => {
          if (teamName != '') {
            teamName = `${teamName},${element.name}`
          } else {
            teamName = `${element.name}`
          }

        });
      }

      let obj = {
        user_id: item.id,
        username: item.username,
        first_name: ((item['first_name']) ? item['first_name'] : ''),
        last_name: ((item['last_name']) ? item['last_name'] : ''),
        phone_number: ((item['phone']) ? item['phone'] : ''),
        teams: teamName,
        role: ((item['role']) ? item['role'] : ''),
        created: ((item['created']) ? item['created'] : ''),
        last_updated: ((item['last_updated']) ? item['last_updated'] : ''),
        current_status: ((item['is_disable'] == true) ? 'De-Active' : 'Active')
      };

      return obj
    })
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, 'User-Details.csv');
  }

}

