import { enumAsList } from '../utils/general-utils/enum-utils';


export enum PlatformPermissionIds {
  USERS_ENABLE = 'users:enable',
  SUBSCRIPTION_ENABLE = 'subscription:enable',
  BUSINESS_RULES_ENABLE = 'settings:enable',
  EMAIL_ENABLE = 'email:enable',
  TICKET_ENABLE = 'ticket:enable',
  KNOWLEDGE_MANAGEMENT_ENABLE = 'knowledge-management:enable',
  DASHBOARD_ENABLE = 'dashboard:enable',
  CUSTOMERS_ENABLE = 'customers:enable',
  REPORTS_ENABLE = 'reports:enable',
  CHAT_ENABLE = 'chat:enable',
  PHONE_ENABLE = 'phone:enable',
  WHATSAPP_ENABLE='whatsapp:enable',
  SOCIAL_MEDIA_ENABLE='social-media:enable',
  CAMPAIGNS_ENABLE='campaigns:enable'

}



export enum ModuleIds {
  USERS = 'users',
  SUBSCRIPTION = 'subscription',
  BUSINESS_RULES = 'settings',
  EMAIL = 'email',
  TICKET = 'ticket',
  KNOWLEDGE_MANAGEMENT = 'knowledge-management',
  DASHBOARD = 'dashboard',
  CUSTOMERS = 'customers',
  REPORTS = 'reports',
  CHAT = 'chat',
  PHONE = 'phone',
  WHATSAPP = 'whatsapp',
  SOCIAL_MEDIA = 'social-media',
  CAMPAIGNS= 'campaigns'
}

export const AllModules = enumAsList<ModuleIds>(ModuleIds);
export const ModuleNames = {
  [ModuleIds.USERS]: 'Users',
  [ModuleIds.SUBSCRIPTION]: 'Subscription',
  [ModuleIds.BUSINESS_RULES]: 'Settings',
  [ModuleIds.EMAIL]: 'Email',
  [ModuleIds.TICKET]: 'Ticket',
  [ModuleIds.KNOWLEDGE_MANAGEMENT]: 'Knowledge Management',
  [ModuleIds.DASHBOARD]: 'Dashboard',
  [ModuleIds.CUSTOMERS]: 'Customers',
  [ModuleIds.REPORTS]: 'Reports',
  [ModuleIds.CHAT]: 'Chat',
  [ModuleIds.PHONE]: 'Phone',
  [ModuleIds.WHATSAPP]:'Whatsapp',
  [ModuleIds.SOCIAL_MEDIA]: 'Social Media',
  [ModuleIds.CAMPAIGNS]:'Campaign'
};


export const ModuleUrl = {
  [PlatformPermissionIds.USERS_ENABLE]: '/app/users',
  [PlatformPermissionIds.SUBSCRIPTION_ENABLE]: '/app/subscription',
  [PlatformPermissionIds.BUSINESS_RULES_ENABLE]: '/app/business-rules',
  [PlatformPermissionIds.EMAIL_ENABLE]: '/app/email',
  [PlatformPermissionIds.TICKET_ENABLE]: '/app/tickets',
  [PlatformPermissionIds.KNOWLEDGE_MANAGEMENT_ENABLE]: '/app/knowledge-management',
  [PlatformPermissionIds.DASHBOARD_ENABLE]: '/app/dashboard',
  [PlatformPermissionIds.CUSTOMERS_ENABLE]: '/app/customers',
  [PlatformPermissionIds.REPORTS_ENABLE]: '/app/reports',
  [PlatformPermissionIds.CHAT_ENABLE]: '/app/chat',
  [PlatformPermissionIds.PHONE_ENABLE]: '/app/phone',
  [PlatformPermissionIds.WHATSAPP_ENABLE]: '/app/whatsapp',
  [PlatformPermissionIds.SOCIAL_MEDIA_ENABLE]:'/app/social-media',
  [PlatformPermissionIds.CAMPAIGNS_ENABLE]:'/app/campaign'
}

export function getModuleName(moduleId: ModuleIds): string {
  return ModuleNames[moduleId];
}

export enum SubscriptionPlanIds {
  FULL = 'full'
}

export enum BillingTypes {
  MONTHLY = 'monthly',
  YEARLY = 'yearly'
}

export enum AvailableVolumes {
  UPTO_TEN = 10,
  UPTO_FIFITY = 50,
  UPTO_HUNDRED = 100
}

export enum UserRoles {
  ADMIN = 'admin',
  MANAGER = 'manager',
  AGENT = 'agent'
}

// export enum UserGender {
//   MALE = 'male',
//   FEMALE = 'female'
// }

export enum EmailStatusEnum {
  ACTIVE = 'active',
  CLOSED = 'closed',
  EXCEPTION = 'exception'
}

export enum TicketEventEnum {
  ACTIVE = 'active',
  CLOSED = 'closed'
}

export enum TicketStatusEnum{
  ALL = '',
  ACTIVE = 'active',
  WIP = 'wip',
  CLOSED = 'closed',
  REOPEN='reopen'
}
export enum CampaignStatusEnum{
  ALL = '',
  ACTIVE = 'active',
  WIP = 'wip',
  CLOSED = 'closed'
}
// export namespace EmailBinTypes {
//   export function all() {
//     return enumAsList<EmailBinTypes>(EmailBinTypes);
//   }
// }
export enum AccountTypes {
  EMAIL = "email",
  CALL = "call",
  CHAT = "chat"
}

export const AllUserRoles = enumAsList<UserRoles>(UserRoles);


export const AllAccountTypes = enumAsList<AccountTypes>(AccountTypes)

export enum HTTP_METHODS {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete'
}


export enum EventTypes {
  EMAIL = "email",
  KMENTRY = "kmentry"
}

export const AllEventTypes = enumAsList<EventTypes>(EventTypes);

export enum AllocationActions {
  ADD_RULE = "Add Rule",
  EDIT_RULE = "Update Rule",
  ADD_LIMIT = "Add Allocation Limit",
  EDIT_LIMIT = "Update Allocation Limit",
  ADD_TICKET_LIMIT = "Add Tikcet Allocation Limit",
  EDIT_TICKET_LIMIT = "Update Tikcet Allocation Limit"
}

export enum TicketStatusTypes {
  ACTIVE = 'active',
  IN_PROGRESS = 'wip',
  CLOSED = 'closed',
  UNKNOWN = 'unknown',
  REOPEN = 'reopen'
}


export enum TicketPriorityTypes {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum PriorityDispositionEnum {
  URGENT = 'urgent',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low'
}

export enum NotesTypes {
  CUSTOMER = "customer",
  INTERNAL = "internal"
}

export enum DayOfWeek {
  MON = 'monday',
  TUE = 'tuesday',
  WED = 'wednesday',
  THU = 'thursday',
  FRI = 'friday',
  SAT = 'saturday',
  SUN = 'sunday'
}
