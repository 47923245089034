import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { ApiLoginRequest } from 'src/app/code-modules/api/interfaces/Tenant/tenant-api-interfaces';
import { UserTeamList } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { AuthService } from 'src/app/code-modules/authentication/auth.service';
import { loginSuccessUrl } from 'src/app/code-modules/authentication/auth.urls';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';

@Component({
  selector: 'app-switch-team-dialog',
  templateUrl: './switch-team-dialog.component.html',
  styleUrls: ['./switch-team-dialog.component.sass']
})
export class SwitchTeamDialogComponent implements OnInit {
  userRole
  teams
  redirectSubscription: Subscription;
  constructor(
    private toaster: ToastrService,
    private api: ApiService,
    public context: AppContextService,
    private auth: AuthService,
    private router: Router,
    public dialogRef: MatDialogRef<SwitchTeamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public selectedTeam
  ) { }

  ngOnInit() {
    console.log(this.selectedTeam ,",.,")
    this.context.currentUser$.subscribe((user)=>{
      this.userRole = user
    })
    this.loadUserTeamList()
  }
  loadUserTeamList(){
    if(this.userRole.role == 'admin'){
      this.api.userTeamList()
      .subscribe((team:UserTeamList)=>{
        console.log(team,"Team")
        this.teams = team
      })
    }else{
      this.api.userDetails(this.userRole.id)
      .subscribe((team)=>{
        console.log(team,"Team")
        this.teams = team.teams
      })
    }
    
  }
  changeUserHandler(event){
    console.log(event.value , this.selectedTeam ,"...")

  }
  submit(){
    const credentials: ApiLoginRequest = {
      username: localStorage.getItem('username'),
      password: localStorage.getItem('password'),
      tenant: window.location.hostname.split('.')[0],
      team: this.selectedTeam
    };
    console.log(credentials)
    let logoutStatus
    if(credentials){
      // logoutStatus = this.auth.logout('switch')
      this.api.logout().subscribe((data) => {
        console.log(data)
        // logoutStatus = true
        this.auth.login(credentials).subscribe((data) => {
          console.log(` Login success`);
          console.log("data",data)
          this.redirectSubscription = this.context.contextReady.subscribe(ready => {
            if (ready === true) {
              this.router.navigate([loginSuccessUrl]);
              this.dialogRef.close();
              window.location.reload();
            }    
          }, err => {
            console.log("unexpected error", err);
            this.toaster.error("Please try after some time", "Something went wrong")
          })
        }, (err) => {
          this.toaster.error(ErrorToaster(err))
          console.error(` Login failed`, err);
        });
      })
      console.log(logoutStatus , "logout status")
    }
    
    if(logoutStatus){
      // this.auth.login(credentials).subscribe((data) => {
      //   console.log(` Login success`);
      //   console.log("data",data)
      //   this.redirectSubscription = this.context.contextReady.subscribe(ready => {
      //     if (ready === true) {
      //       this.router.navigate([loginSuccessUrl]);
      //       this.dialogRef.close();
      //       window.location.reload()
      //     }
  
      //   }, err => {
      //     console.log("unexpected error", err);
      //     this.toaster.error("Please try after some time", "Something went wrong")
      //   })
      // }, (err) => {
      //   this.toaster.error(ErrorToaster(err))
      //   console.error(` Login failed`, err);
      // });
    }
    
  }

  ngOnDestroy() {
    if (this.redirectSubscription) {
      console.log('unsub')
      this.redirectSubscription.unsubscribe()
    }
  }
}
