import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppContextService } from '../../../../code-modules/app-context/app-context.service';
import { ApiService } from '../../../../code-modules/api/api-services/api.service';
import { TicketStatusTypes, UserRoles } from 'src/app/code-modules/saas/saas.enums';
import { AllStatus, TicketRead, TicketInProgressStatusRead, StatusDisplay } from 'src/app/code-modules/api/interfaces/Ticket/ticket-api-interfaces';
import { identifierModuleUrl } from '@angular/compiler';
import { element } from 'protractor';
import { UserProfileRead, UserProfileUpdate, UserTeamList } from '../../../../code-modules/api/interfaces/User/user-api-interfaces';
@Component({
  selector: 'app-change-assignee-dialog',
  templateUrl: './change-assignee-dialog.component.html',
  styleUrls: ['./change-assignee-dialog.component.sass']
})
export class ChangeAssigneeDialogComponent implements OnInit {
  teams:UserTeamList
  selectedTeam =""
  users: UserProfileRead[];
  selectedUserId
  selectedTeamId
  userRole
  addUser = false;
  loginTeam;
  constructor(
    public dialogRef: MatDialogRef<ChangeAssigneeDialogComponent>,
    public context: AppContextService,
    private toaster: ToastrService,
    private api: ApiService
  ) {
  }

  ngOnInit() {
    const currTeamId = JSON.parse(localStorage.getItem('currentTeam'))[0].id;
    this.api.getTicketAccessRightList(currTeamId).subscribe((resp)=>{

console.log("")

      if (resp.length && !resp[0].ticket_auto_team){
        this.teams = resp[0].team;
      } else {
        this.loadAllTeams(currTeamId)
      }
    })
    this.context.currentUser$.subscribe((user)=>{
      this.loginTeam = JSON.parse(localStorage.getItem("currentTeam"))[0]

      this.userRole = user
    })
  }

  loadAllTeams(currTeamId){
    this.api.userTeamList()
    .subscribe((data: any)=>{
      this.teams = data

    
      // this.loadUserList()
    })
  }
  
  loadUserList(){
    this.api.listUsers(this.selectedTeamId).subscribe((usersList)=>{
      this.users = usersList
    })
  }
  changeTeamHandler(event){
    console.log(event.value)
    this.selectedTeamId = event.value
    this.loadUserList();
  }

  changeUserHandler(event){
    console.log(event.value)
    this.selectedUserId = event.value
  }
  onSelectAssigneeClick(){
    let obj = {
      userId : this.selectedUserId ? this.selectedUserId : null,
      teamId : this.selectedTeamId
    }
    this.dialogRef.close(obj);
  }
}
