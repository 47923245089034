import { Component, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TemplateRead } from 'src/app/code-modules/api/interfaces/Email/email-api-interfaces';
import { MatPaginator, PageEvent } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';


@Component({
  selector: 'app-campaign-template-dialog',
  templateUrl: './campaign-template-dialog.component.html',
  styleUrls: ['./campaign-template-dialog.component.sass']
})
export class CampaignTemplateDialogComponent implements OnInit {
  templates: TemplateRead[] = [];
  subscribers: Array<Subscription> = []
  selectTemplate: number;
  selectedTemplate: TemplateRead;
  templateData: any;
  templateDetails = false;
  search = new FormControl('')
  pageNumber = 1;
  listPerPage = 20
  next = false;
  previous = false;
  totalCount: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  campaignId: any;

  constructor(
    public dialogRef: MatDialogRef<CampaignTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: CampaignTemplateDialogComponent,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private toaster: ToastrService
  ) {
    this.campaignId = ((dialogData['campaignId']) ? dialogData['campaignId'] : 0);
  }

  ngOnInit() {
    this.loadTemplates()
    const searchHit = this.search.valueChanges
   .pipe(
     debounceTime(500)
   ).subscribe((value) => {
     this.resetPage()
    this.loadTemplates()
   })
    this.subscribers.push(searchHit)
  }
  resetPage() {
    this.pageNumber = 1,
    this.paginator.pageIndex = 0
}
  loadTemplates() {
    this.api.getCampaignWiseTemplate(this.pageNumber,this.campaignId).subscribe(templates => {
      this.templates = templates.results
      this.totalCount = templates.count;
      this.next = templates.next && templates.next !== null ? true : false;
      this.previous = templates.previous && templates.previous !== null ? true : false;
    });
  }

  onNoClick(): void {
    this.templateData = this.templates.find(value => (this.selectTemplate === value.id));
     console.log(this.templateData ,"dat")
    if (this.templateData) {
      this.data.onAssign(this.templateData);
      this.dialogRef.close();
    }
  }

  viewTemplate(item: TemplateRead) {
    this.selectTemplate = item.id
    this.templateDetails = true;
    this.selectedTemplate = item;
  }
  backToList() {
    this.templateDetails = false;
  }
  viewAttachment(path: string) {
    if (path)
      window.open(path, '_blank')
    else
      this.toaster.error("Unable to view the item")
  }
  pageChange($event: PageEvent) {
    if ($event.pageIndex > $event.previousPageIndex) {
      if (this.next) {
        this.pageNumber++;
        this.loadTemplates();
      }
    } else {
      if (this.previous) {
        this.pageNumber--;
        this.loadTemplates();
      }
    }
  }
  ngOnDestroy() {
    this.subscribers.forEach((sub) => sub.unsubscribe())
  }
}

