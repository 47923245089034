import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { ErrorToaster } from 'src/app/code-modules/utils/validations/genral-validation';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.sass']
})
export class ClientProfileComponent implements OnInit {
  avatarSrc = 'https://www.w3schools.com/howto/img_avatar.png';
  userProfileForm: FormGroup;
  clientData
  selectedAccount
  uploadedAvatar:any;
  avatarChanged = false;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private context: AppContextService,
    private toaster: ToastrService
  ) { }

  ngOnInit() {
    this.loadClientDetatils()
    
      this.userProfileForm = this.formBuilder.group({
        user_name: ['', [Validators.required]],
        // password: ['', [Validators.required]],
        account: ['', [Validators.required]],
      });
    
  }

  loadClientDetatils(){
    this.api.clientDetails()
    .subscribe((data)=>{
      console.log(data , ".....")
      this.clientData = data
      if(this.clientData){
        this.selectedAccount = this.clientData.account[0]
        this.avatarSrc = this.clientData.avatar ? this.clientData.avatar + '?random=' + String(Number(new Date())) : this.avatarSrc
        console.log(this.selectedAccount , "**")
        this.userProfileForm = this.formBuilder.group({
          user_name: [this.clientData.username, [Validators.required]],
          // password: ['', [Validators.required]],
          account: [this.selectedAccount.name, [Validators.required]],
        });
      }
    },(err)=>{
      this.toaster.error(ErrorToaster(err))
    })
  }
  onFileChanged(event) {
    this.uploadedAvatar = event.target.files[0];
    if (this.uploadedAvatar) {
      const reader = new FileReader(); // HTML5 FileReader API

      reader.onload = () => {
        this.avatarSrc = reader.result as string;
        this.avatarChanged = true;
        console.log(this.avatarSrc);
      };

      reader.readAsDataURL(this.uploadedAvatar);
    }
  }
  submit() {
   
      //console.log(this.dynamicForm.value,this.dynamicForm.fields,this.dynamicForm.formGroup.value)
      const updateRequest = {
        username : this.userProfileForm.value.user_name,
        account : this.selectedAccount.id,
        avatar : this.avatarChanged ? this.avatarSrc : undefined,
      }
      console.log(updateRequest ,"req")

      this.api.clientProfileUpdate(updateRequest)
      .subscribe((data)=>{
        console.log(data)
        this.loadClientDetatils()
      },(err)=>{
        this.toaster.error(ErrorToaster(err));
      })
     
    }
}
// clientProfileUpdate