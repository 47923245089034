import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IfLoggedIn } from '../code-modules/authentication/auth.guard.ifLoggedIn';
import { RoleGuard } from '../code-modules/authentication/role.guard';
import { ModuleIds } from '../code-modules/saas/saas.enums';
import { DashboardComponent } from './app/components/dashboard/dashboard.component';
import { UserProfileComponent } from './app/components/user-profile/user-profile.component';
import { CircleOneComponent } from './circle-one.component';
import { UserDetailComponent } from './app/components/users/user-detail/user-detail.component';
import { UsersComponent } from './app/components/users/users.component';
import { AccountDetailsComponent } from './app/components/customers/account-details/account-details.component';
import { ContactDetailsEditComponent } from './app/components/customers/contact-details-edit/contact-details-edit.component';
import { CustomersComponent } from './app/components/customers/customers.component';
import { PhoneLoaderComponent } from './telephony/phone-loader/phone-loader.component';
import { EventNotificationComponent } from './app/components/event-notification/event-notification.component';
import { CampaignEventNotificationComponent } from './app/components/campaign-event-notification/campaign-event-notification.component';
import { HelpComponent } from './app/components/help/help.component';
import { TelephonyCallHistoryComponent } from './app/components/telephony-call-history/telephony-call-history.component';
// import { HelpComponent } from '../components/help/help.component';


const routes: Routes = [
  {
    path: '',
    component: CircleOneComponent,
    canActivate: [IfLoggedIn],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'business-rules',
        loadChildren: () => import('./business-rules/business-rules.module').then(m => m.BusinessRulesModule)
      },
      {
        path: 'email',
        loadChildren: () => import('./email/email.module').then(m => m.EmailModule)
      }, 
       {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'tickets',
        loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule)
      },
      {
        path:'campaign',
        loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule)
      },
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          ModuleId: ModuleIds.DASHBOARD
        },
        canActivate: [RoleGuard]
    
      },
      {
        path: 'users',
        component: UsersComponent,
        data: {
          ModuleId: ModuleIds.USERS
        },
        canActivate: [RoleGuard]
      },{
        path: 'user-profile',
        component: UserProfileComponent
      },
      
      {
        path: 'help',
        component: HelpComponent
      },


      {
        path: 'users/:id',
        component: UserDetailComponent,
        data: {
          ModuleId: ModuleIds.USERS
        },
       canActivate: [RoleGuard]
      },
      {
        path: 'customers',
        component: CustomersComponent,
        data: {
          ModuleId: ModuleIds.CUSTOMERS
        },
       canActivate: [RoleGuard]
      },
      {
        path: 'customers/account/:id',
        component: AccountDetailsComponent
        ,
        data: {
          ModuleId: ModuleIds.CUSTOMERS
        },
        canActivate: [RoleGuard]
      },
      {
        path: 'customers/contact/:id/edit',
        component: ContactDetailsEditComponent
        ,
        data: {
          ModuleId: ModuleIds.CUSTOMERS
        },
       canActivate: [RoleGuard]
      },
      {
        path: 'phone-load',
        component: PhoneLoaderComponent,
      },
      {
        path: 'event-load',
        component: EventNotificationComponent,
      },
      {
        path: 'campaign-event',
        component:CampaignEventNotificationComponent,
      },
      {
        path: 'call-history',
        component:TelephonyCallHistoryComponent,
      },
     
    ]
  }

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class CircleOneRoutingModule { }
