import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent implements OnInit {
  loggerName = '[/forgotPassword]';
  signupStep = 1;
  totalSteps = 2;
  constructor(
    private router: Router,
  ) {}

  ngOnInit() {

  }

  onNextClick() {
    if (this.signupStep < this.totalSteps) {
      this.signupStep += 1;
    }
  }

}

