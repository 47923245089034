import { Component, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import { AccountCreateUpdate, Tag } from 'src/app/code-modules/api/interfaces/Customer/customer-api-interfaces';
import { UserTeamList } from 'src/app/code-modules/api/interfaces/User/user-api-interfaces';
import { AppContextService } from 'src/app/code-modules/app-context/app-context.service';
import { DataDrivenForm } from 'src/app/code-modules/utils/data-driven-forms/DataDrivenForm';

@Component({
  selector: 'app-updateaccountform',
  templateUrl: './updateaccountform.component.html',
  styleUrls: ['./updateaccountform.component.sass']
})
export class UpdateaccountformComponent implements OnInit {
  customerTags :Tag[]=[]
  selectedTags =[];
  selectedTeams=[];
  updateAccountForm: FormGroup;
  // createAccountFormSubmitted = false;
  dynamicForm = new DataDrivenForm([]);
  accountDetails;
  userRole
  teams;
  constructor(
    public dialogRef: MatDialogRef<UpdateaccountformComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private context: AppContextService,
    private toaster: ToastrService
  ) { }

  ngOnInit() {
    this.context.currentUser$.subscribe((user)=>{
      this.userRole = user
      console.log( this.userRole ,"users")
    })
    this.accountDetails = this.data
    this.selectedTags=this.data.tags;
    this.selectedTeams=this.data.accessible_teams;
    this.context.customerAccountDynamicFields$.subscribe(fieldData => {
      
      if (fieldData.length) {
        this.dynamicForm = new DataDrivenForm(fieldData, this.accountDetails.dynamic_fields || {});
      } 
      this.api.CustomerTagList().subscribe((tags) => {
        //this.customerTagData = tags;
        this.customerTags = tags
       // //this.loader.stop();
      }, (err) => {
        ////this.loader.stop();
      })
this.loadUserTeamList();

    });
    this.updateAccountForm = this.formBuilder.group({
      name: [`${this.accountDetails.name}`, [Validators.required]]
    });
  }
  // serchTeam =[];
  // loadUserTeamList() {
  //   this.api.ConatctTeamList()
  //     .subscribe((team) => {
  //       console.log(team, "Team")
  //       // this.team = team
  //       this.serchTeam=team
  //       // this.customerTeamsEdit = this.edit ? this.selectedTeams : this.serchTeam;

  //     })
  // }

  loadUserTeamList(){
    if(this.userRole.role == 'admin'){
      this.api.userTeamList()
      .subscribe((team:UserTeamList)=>{
        console.log(team,"Team")
        this.teams = team
      })
    }else{
      this.api.userDetails(this.userRole.id)
      .subscribe((team)=>{
        console.log(team,"Team")
        this.teams = team.teams
        console.log(this.teams,"login teams" )
      })
    }
    
  }


  submit(){
    this.data.tags=  this.selectedTags; 
    this.data.accessible_teams=this.selectedTeams;
    const data={
      'name': this.accountDetails.name,
    }
    let request = <AccountCreateUpdate> data;
    request.dynamic_fields = this.dynamicForm.formGroup.value
    request.tags = this.getTagIds()
    request.accessible_teams=this.getTeamIds();

    this.api.accountUpdate(request,this.data.id)
    .subscribe((data)=>{
      this.toaster.success('Account Updated Successfully.');
      this.dialogRef.close(this.data);
    })
  }
  toggleTagSelection(tag: Tag) {
 
    const index = this.selectedTags.map(obj => { return obj.id }).indexOf(tag.id);
      index > -1 ? this.selectedTags.splice(index, 1) : this.selectedTags.push(tag)
  
  }

  isSelectedTag(tag: Tag) {
    return this.selectedTags.map((obj => { return obj.id })).includes(tag.id)

  }
  
  

  getTagIds() {
    let tags = [];
    this.selectedTags.forEach((tag) => {
      tags.push(tag.id)
    })
    return tags
  }
  toggleTeamSelection(team:Tag) {
   
      const index = this.selectedTeams.map(obj => { return obj.id }).indexOf(team.id);
      index > -1 ? this.selectedTeams.splice(index, 1) : this.selectedTeams.push(team)
    
  }
  getTeamIds() {
    let teams = [];
    this.selectedTeams.forEach((team) => {
      teams.push(team.id)
    })
    return teams
  }
  isSelectedTeam(team:Tag) {
    return this.selectedTeams.map((obj => { return obj.id })).includes(team.id)
  }
}
