import { Pipe, PipeTransform } from '@angular/core';
import {ContactRead} from '../../api/interfaces/Customer/customer-api-interfaces';

@Pipe({
  name: 'filterEmail'
})

export class EmailFilterPipe implements PipeTransform {

  transform(contacts: Array<ContactRead>,emailId:string): Array<object> {

    if (!contacts || !emailId) {
      // console.log('result:', userList)
      return [];
    } else if (contacts.length > 0) {
      if (emailId) {
        contacts = contacts.filter(contact => (contact.email.toString().match(new RegExp(emailId,'gi'))));
      }
      return contacts;
    } else {
     
      return contacts;
    }
  }
}
