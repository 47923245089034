import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NgModule, ViewChild, ElementRef,VERSION ,OnInit, Input} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import funnel from 'highcharts/modules/funnel';
Exporting(Highcharts);
funnel(Highcharts);


// Exporting(Highcharts);
// funnel(Highcharts);

@Component({
  selector: 'app-funnel',
  templateUrl: './funnel.component.html',
  styleUrls: ['./funnel.component.sass']
})
export class FunnelComponent implements OnInit {

    name = `Angular! v${VERSION.full}`;
    @ViewChild("container", { read: ElementRef, static: true }) container: ElementRef;
   
    constructor() { }
    ngOnInit(){
        Highcharts.chart( {
            chart: {
                renderTo: this.container.nativeElement,
                type: 'funnel'
            },
            title: {
                text: 'Sales funnel'
            },
            
            legend: {
                enabled: false
            },

            series: [{
                name: 'Unique users',
                type:'funnel',
                data: [
                    1300,300,456,543
                ]
            }],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        exporting: {
                            enabled: false
                          },
                          credits:{
                              enabled:false
                          },
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    inside: true
                                },
                            }
                        }
                    }
                }]
            }
          
      
        });        
    }
}
