/**
 * Automatically unsubscribe all subscriptions of a component once it is destroyed
 * To avoid unexpected behaviours and potential memory leak.
 */
import {OnDestroy} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';

export class AutoUnsubscribeComponentBase implements OnDestroy {
  subscriptions: Array<Subscription> = [];

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  /**
   * Create a subscription to the provided observable/subject, which will be automatically
   * unsubscribed when the component is destroyed.
   * @param obs Any subscribable value
   * @param callback Subscribe() callback
   */
  newSubscription<T>(obs: Observable<T> | Subject<T>, callback: (value: T) => void) {
    this.subscriptions.push(obs.subscribe(callback));
  }
}
